import React, { Component } from "react";
import Button from "../CustomButton/CustomButton.jsx";
import AsyncSelectWrapper from "../AsyncSelect/AsyncSelectWrapper.jsx";
import Select from "react-select";
import Card from "../Card/Card.jsx";
import {
    Table,
    FormControl
} from "react-bootstrap";

class ExpertElementsListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            elements: this.props.elements || [],
            isPreviewOnly: this.props.isPreviewOnly || false
        };
        this.warehouseOptions = {
            "agata": [
                { "value": "Butik", "label": "Butik" },
                { "value": "Ekspo", "label": "Ekspo" },
                { "value": "Główny", "label": "Główny" },
                { "value": "Główny/Ekspo", "label": "Główny/Ekspo" },
                { "value": "Główny/Zewnętrzny", "label": "Główny/Zewnętrzny" },
                { "value": "Główny/Zewnętrzny/Ekspo", "label": "Główny/Zewnętrzny/Ekspo" },
                { "value": "Zewnętrzny", "label": "Zewnętrzny" },
                { "value": "Zewnętrzny/Ekspo", "label": "Zewnętrzny/Ekspo" },
                { "value": "BBE", "label": "BB Magazyn Ekspozycja" },
                { "value": "BBG", "label": "BB Magazyn główny" },
                { "value": "BBQ", "label": "BB Magazyn Butik" },
                { "value": "BDE", "label": "BD Magazyn Ekspozycja" },
                { "value": "BDG", "label": "BD Magazyn główny" },
                { "value": "BDQ", "label": "BD Magazyn Butik" },
                { "value": "BSE", "label": "BS Magazyn Ekspozycja" },
                { "value": "BSG", "label": "BS Magazyn główny" },
                { "value": "BSQ", "label": "BS Magazyn Butik" },
                { "value": "BSS", "label": "BS Magazyn główny Starosielce" },
                { "value": "CCE", "label": "CC Magazyn Ekspozycja" },
                { "value": "CCG", "label": "CC Magazyn główny" },
                { "value": "CCQ", "label": "CC Magazyn Butik" },
                { "value": "ECG", "label": "EC Magazyn główny" },
                { "value": "GKE", "label": "GK Magazyn Ekspozycja" },
                { "value": "GKG", "label": "GK Magazyn główny" },
                { "value": "GKK", "label": "GK Magazyn główny Kowale" },
                { "value": "GKQ", "label": "GK Magazyn Butik" },
                { "value": "GLCZ", "label": "GL Magazyn Główny" },
                { "value": "GLE", "label": "GL Magazyn Ekspozycja" },
                { "value": "GLG", "label": "GL Magazyn główny" },
                { "value": "GLQ", "label": "GL Magazyn Butik" },
                { "value": "GWE", "label": "GW Magazyn Ekspozycja" },
                { "value": "GWG", "label": "GW Magazyn główny" },
                { "value": "GWQ", "label": "GW Magazyn Butik" },
                { "value": "JGE", "label": "JG Magazyn Ekspozycja" },
                { "value": "JGG", "label": "JG Magazyn główny" },
                { "value": "JGQ", "label": "JG Magazyn Butik" },
                { "value": "KAE", "label": "KA Magazyn Ekspozycja" },
                { "value": "KAG", "label": "KA Magazyn główny" },
                { "value": "KAQ", "label": "KA Magazyn Butik" },
                { "value": "KIE", "label": "KI Magazyn Ekspozycja" },
                { "value": "KIG", "label": "KI Magazyn główny" },
                { "value": "KIQ", "label": "KI Magazyn Butik" },
                { "value": "KLE", "label": "KL Magazyn Ekspozycja" },
                { "value": "KLG", "label": "KL Magazyn główny" },
                { "value": "KLN", "label": "KL Magazyn główny N" },
                { "value": "KLQ", "label": "KL Magazyn Butik" },
                { "value": "KRE", "label": "KR Magazyn Ekspozycja" },
                { "value": "KRG", "label": "KR Magazyn główny" },
                { "value": "KRQ", "label": "KR Magazyn Butik" },
                { "value": "KRS", "label": "KR Magazyn główny" },
                { "value": "LBE", "label": "LB Magazyn Ekspozycja" },
                { "value": "LBG", "label": "LB Magazyn główny" },
                { "value": "LBQ", "label": "LB Magazyn Butik" },
                { "value": "LUE", "label": "LU Magazyn Ekspozycja" },
                { "value": "LUG", "label": "LU Magazyn główny" },
                { "value": "LUQ", "label": "LU Magazyn Butik" },
                { "value": "ŁDE", "label": "ŁD Magazyn Ekspozycja" },
                { "value": "ŁDG", "label": "ŁD Magazyn główny" },
                { "value": "ŁDQ", "label": "ŁD Magazyn Butik" },
                { "value": "NSE", "label": "NS Magazyn Ekspozycja" },
                { "value": "NSG", "label": "NS Magazyn główny" },
                { "value": "NSQ", "label": "NS Magazyn Butik" },
                { "value": "OPE", "label": "OP Magazyn Ekspozycja" },
                { "value": "OPG", "label": "OP Magazyn główny" },
                { "value": "OPQ", "label": "OP Magazyn Butik" },
                { "value": "PŁE", "label": "PŁ Magazyn Ekspozycja" },
                { "value": "PŁG", "label": "PŁ Magazyn główny" },
                { "value": "PŁQ", "label": "PŁ Magazyn Butik" },
                { "value": "PTG", "label": "PT Magazyn główny" },
                { "value": "PTN", "label": "PT Magazyn główny" },
                { "value": "PZE", "label": "PZ Magazyn Ekspozycja" },
                { "value": "PZG", "label": "PZ Magazyn główny" },
                { "value": "PZPL", "label": "PZ Magazyn główny" },
                { "value": "PZQ", "label": "PZ Magazyn Butik" },
                { "value": "RUE", "label": "RU Magazyn Ekspozycja" },
                { "value": "RUG", "label": "RU Magazyn główny" },
                { "value": "RUQ", "label": "RU Magazyn Butik" },
                { "value": "RYDZ", "label": "RY Magazyn Wodzisławska" },
                { "value": "RYE", "label": "RY Magazyn Ekspozycja" },
                { "value": "RYG", "label": "RY Magazyn główny" },
                { "value": "RYQ", "label": "RY Magazyn Butik" },
                { "value": "RZE", "label": "RZ Magazyn Ekspozycja" },
                { "value": "RZG", "label": "RZ Magazyn główny" },
                { "value": "RZQ", "label": "RZ Magazyn Butik" },
                { "value": "SKE", "label": "SK Magazyn Ekspozycja" },
                { "value": "SKG", "label": "SK Magazyn główny" },
                { "value": "SKQ", "label": "SK Magazyn Butik" },
                { "value": "SŁE", "label": "SŁ Magazyn Ekspozycja" },
                { "value": "SŁG", "label": "SŁ Magazyn główny" },
                { "value": "SŁQ", "label": "SŁ Magazyn Butik" },
                { "value": "SWE", "label": "SW Magazyn Ekspozycja" },
                { "value": "SWG", "label": "SW Magazyn główny" },
                { "value": "SWQ", "label": "SW Magazyn Butik" },
                { "value": "SZE", "label": "SZ Magazyn Ekspozycja" },
                { "value": "SZG", "label": "SZ Magazyn główny" },
                { "value": "SZQ", "label": "SZ Magazyn Butik" },
                { "value": "SZS", "label": "SZ Magazyn główny" },
                { "value": "TOE", "label": "TO Magazyn Ekspozycja" },
                { "value": "TOG", "label": "TO Magazyn główny" },
                { "value": "TOQ", "label": "TO Magazyn Butik" },
                { "value": "WAE", "label": "WA Magazyn Ekspozycja" },
                { "value": "WAG", "label": "WA Magazyn główny" },
                { "value": "WAOK", "label": "WA Magazyn główny" },
                { "value": "WAQ", "label": "WA Magazyn Butik" },
                { "value": "WKE", "label": "WK Magazyn Ekspozycja" },
                { "value": "WKG", "label": "WK Magazyn główny" },
                { "value": "WKQ", "label": "WK Magazyn Butik" },
                { "value": "WŁE", "label": "WŁ Magazyn Ekspozycja" },
                { "value": "WŁG", "label": "WŁ Magazyn główny" },
                { "value": "WŁQ", "label": "WŁ Magazyn Butik" },
                { "value": "WRD", "label": "WR Magazyn Główny" },
                { "value": "WRE", "label": "WR Magazyn Ekspozycja" },
                { "value": "WRG", "label": "WR Magazyn główny" },
                { "value": "WRQ", "label": "WR Magazyn Butik" },
                { "value": "WTQ", "label": "WT Magazyn Butik" },
                { "value": "WWE", "label": "WW Magazyn Ekspozycja" },
                { "value": "WWG", "label": "WW Magazyn główny" },
                { "value": "WWQ", "label": "WW Magazyn Butik" },
                { "value": "ZGE", "label": "ZG Magazyn Ekspozycja" },
                { "value": "ZGG", "label": "ZG Magazyn główny" },
                { "value": "ZGQ", "label": "ZG Magazyn Butik" }
            ],
            "brw": [
                { "value": "Salon Meblowy Poznań Serbska", "label": "Salon Meblowy Poznań Serbska" },
                { "value": "POZNAŃ", "label": "POZNAŃ" }
            ]
        };
        this.assemblyStakeOptions = [
            { "value": 2, "label": "2%" },
            { "value": 3, "label": "3%" },
            { "value": 4, "label": "4%" },
            { "value": 5, "label": "5%" },
            { "value": 6, "label": "6%" },
            { "value": 7, "label": "7%" },
            { "value": 9, "label": "9%" },
            { "value": 10, "label": "10%" },
        ];
        this.elementPrototype = {
            "uuid": "",
            "type": this.props.assemblyMode ? "assembly" : this.props.listType,
            "name": "",
            "description": "",
            "codeEan": "",
            "size": "",
            "quantity": "1",
            "numberOfPackages": "1",
            "weight": "",
            "warehouse": "",
            "value": "",
            "percentStakeAssembler": "",
            "trash": false
        };
    }

    handleAddElement = (element) => {
        this.setState({ elements: this.state.elements.concat([element]) }, () => { this.props.onElementsChange(this.state.elements) });
    };

    handleElementChange = (idx, field) => evt => {
        let self = this;
        const newelements = this.state.elements.map((element, sidx) => {
            if (idx !== sidx) return element;
            let updatedelement = { ...element };
            let newValue = evt === null ? null : evt.target ? evt.target.value : evt.value;

            updatedelement[field] = newValue;

            if (field === 'codeEan' && self.props.assemblyMode) {
                let findTax = self.props.assemblyTaxList.filter(taxElem => { return taxElem.Code.toString(10) === newValue });
                if (findTax.length) {
                    updatedelement['percentStakeAssembler'] = self.assemblyStakeOptions.filter(asElem => { return asElem.value === findTax[0].Tax })[0].value;
                }
            }

            return updatedelement;
        });
        this.setState({ elements: newelements }, () => { this.props.onElementsChange(this.state.elements) });
    };

    handleElementChangeMultiple(idx, fields) {
        const newelements = this.state.elements.map((element, sidx) => {
            if (idx !== sidx) return element;
            let updatedelement = { ...element };

            Object.entries(fields).forEach(([objectKey, objectValue], keyIndex) => {
                updatedelement[objectKey] = objectValue;
            });

            return updatedelement;
        });
        this.setState({ elements: newelements }, () => { this.props.onElementsChange(this.state.elements) });
    };

    handleTrashElement = idx => () => {
        const newelements = this.state.elements.map((element, sidx) => {
            if (idx !== sidx) return element;
            return { ...element, trash: true };
        });
        this.setState({ elements: newelements }, () => { this.props.onElementsChange(this.state.elements) });
    };

    render() {

        let selectedWarehouseOptions = this.warehouseOptions[this.props.companyIndustry] || [];

        let cargoList = (
            <Card
                tableFullWidth
                title={
                    <div>
                        Lista towarów
                        <Button bsStyle="info" pullRight round onClick={() => { this.handleAddElement(this.elementPrototype) }} disabled={this.state.isPreviewOnly}>
                            <span className="btn-label" style={{ paddingRight: "5px" }}>
                                <i className="fa fa-plus" />
                            </span>
                            dodaj element
                        </Button>
                    </div>
                }
                content={
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Nr artykułu</th>
                                <th>Objętość (m<sup>3</sup>)</th>
                                <th className="text-right">Opcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!(this.state.elements.length) ? <tr><td colSpan="4" className="text-center text-muted">- brak danych -</td></tr> :
                                this.state.elements.map((element, idx) => {
                                    if (element.trash) {
                                        return null;
                                    }
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`...`}
                                                    value={element.name || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'name')}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`(opcjonalnie)`}
                                                    value={element.codeEan || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'codeEan')}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="number"
                                                    step="any"
                                                    lang="en"
                                                    placeholder={`(opcjonalnie)`}
                                                    value={element.size || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'size')}
                                                />
                                            </td>
                                            <td className="text-right">
                                                <Button
                                                    onClick={this.handleTrashElement(idx)}
                                                    bsStyle="default"
                                                    disabled={this.state.isPreviewOnly}
                                                    simple
                                                    icon>
                                                    <i className="fa fa-times" />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                        </tbody>
                    </Table>
                }
            />
        );

        let cargoWeightList = (
            <Card
                tableFullWidth
                title={
                    <div>
                        Lista towarów
                        <Button bsStyle="info" pullRight round onClick={() => { this.handleAddElement(this.elementPrototype) }} disabled={this.state.isPreviewOnly}>
                            <span className="btn-label" style={{ paddingRight: "5px" }}>
                                <i className="fa fa-plus" />
                            </span>
                            dodaj element
                        </Button>
                    </div>
                }
                content={
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>Nazwa</th>
                                <th>Nr artykułu</th>
                                <th>Liczba paczek</th>
                                <th>Objętość (m<sup>3</sup>)</th>
                                <th>Waga (kg)</th>
                                <th className="text-right">Opcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!(this.state.elements.length) ? <tr><td colSpan="6" className="text-center text-muted">- brak danych -</td></tr> :
                                this.state.elements.map((element, idx) => {
                                    if (element.trash) {
                                        return null;
                                    }
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`...`}
                                                    value={element.name || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'name')}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`(opcjonalnie)`}
                                                    value={element.codeEan || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'codeEan')}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="number"
                                                    step="1"
                                                    lang="en"
                                                    placeholder={`...`}
                                                    style={{ width: 60 }}
                                                    value={element.numberOfPackages || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'numberOfPackages')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="number"
                                                    step="any"
                                                    lang="en"
                                                    placeholder={`(opcjonalnie)`}
                                                    value={element.size || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'size')}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="number"
                                                    step="any"
                                                    lang="en"
                                                    placeholder={`...`}
                                                    style={{ width: 80 }}
                                                    value={element.weight || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'weight')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td className="text-right">
                                                <Button
                                                    onClick={this.handleTrashElement(idx)}
                                                    bsStyle="default"
                                                    disabled={this.state.isPreviewOnly}
                                                    simple
                                                    icon>
                                                    <i className="fa fa-times" />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                        </tbody>
                    </Table>
                }
            />
        );

        let jyskList = (
            <Card
                tableFullWidth
                title={
                    <div>
                        Lista towarów
                        <Button bsStyle="info" pullRight round onClick={() => { this.handleAddElement(this.elementPrototype) }} disabled={this.state.isPreviewOnly}>
                            <span className="btn-label" style={{ paddingRight: "5px" }}>
                                <i className="fa fa-plus" />
                            </span>
                            dodaj element
                        </Button>
                    </div>
                }
                content={
                    <div className="table-responsive" style={{ paddingBottom: 220 }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Liczba</th>
                                    <th style={{ minWidth: 270 }}>Nazwa</th>
                                    <th style={{ minWidth: 170 }}>Nr artykułu</th>
                                    <th>Waga</th>
                                    <th className="text-right">Opcje</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!(this.state.elements.length) ? <tr><td colSpan="4" className="text-center text-muted">- brak danych -</td></tr> :
                                    this.state.elements.map((element, idx) => {
                                        if (element.trash) {
                                            return null;
                                        }
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <FormControl
                                                        type="number"
                                                        step="1"
                                                        lang="en"
                                                        placeholder={`...`}
                                                        style={{ width: 60 }}
                                                        value={element.quantity || ""}
                                                        disabled={this.state.isPreviewOnly}
                                                        onChange={this.handleElementChange(idx, 'quantity')}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </td>
                                                <td>
                                                    <AsyncSelectWrapper
                                                        endpoint="/external/jysk-article-select"
                                                        cache={false}
                                                        preload={false}
                                                        placeholder={'...'}
                                                        disabled={this.state.isPreviewOnly}
                                                        value={element.name ? { label: element.name, value: element.value } : null}
                                                        onChange={(article) => { this.handleElementChangeMultiple(idx, { name: article.data.articleDescription, codeEan: article.data.article, weight: article.data.grossWeight }) }}
                                                        params={{ label: "articleDescription" }}
                                                        {...this.props}
                                                    />
                                                </td>
                                                <td>
                                                    <AsyncSelectWrapper
                                                        endpoint="/external/jysk-article-select"
                                                        cache={false}
                                                        preload={false}
                                                        placeholder={'...'}
                                                        disabled={this.state.isPreviewOnly}
                                                        value={element.codeEan ? { label: element.codeEan, value: element.value } : null}
                                                        onChange={(article) => { this.handleElementChangeMultiple(idx, { name: article.data.articleDescription, codeEan: article.data.article, weight: article.data.grossWeight }) }}
                                                        params={{ label: "article" }}
                                                        {...this.props}
                                                    />
                                                </td>
                                                <td>
                                                    <FormControl
                                                        type="number"
                                                        step="any"
                                                        lang="en"
                                                        placeholder={`...`}
                                                        style={{ width: 80 }}
                                                        value={element.weight || ""}
                                                        disabled={this.state.isPreviewOnly}
                                                        onChange={this.handleElementChange(idx, 'weight')}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </td>
                                                <td className="text-right">
                                                    <Button
                                                        onClick={this.handleTrashElement(idx)}
                                                        bsStyle="default"
                                                        disabled={this.state.isPreviewOnly}
                                                        simple
                                                        icon>
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                            </tbody>
                        </Table>
                    </div>
                }
            />
        );

        let invoiceList = (
            <Card
                tableFullWidth
                title={
                    <div>
                        Lista rachunków
                        <Button bsStyle="info" pullRight round onClick={() => { this.handleAddElement(this.elementPrototype) }} disabled={this.state.isPreviewOnly}>
                            <span className="btn-label" style={{ paddingRight: "5px" }}>
                                <i className="fa fa-plus" />
                            </span>
                            dodaj element
                        </Button>
                    </div>
                }
                content={
                    <Table responsive style={{ marginBottom: "180px" }}>
                        <thead>
                            <tr>
                                <th style={{ minWidth: "120px" }}>Nr rachunku</th>
                                <th style={{ minWidth: "120px" }}>Opis</th>
                                <th style={{ minWidth: "120px" }}>Kod kreskowy</th>
                                <th style={{ minWidth: "120px" }}>Wartość</th>
                                <th style={{ minWidth: "120px" }}>Waga</th>
                                <th style={{ minWidth: "120px" }}>Magazyn</th>
                                <th className="text-right">Opcje</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!(this.state.elements.length) ? <tr><td colSpan="6" className="text-center text-muted">- brak danych -</td></tr> :
                                this.state.elements.map((element, idx) => {
                                    if (element.trash) {
                                        return null;
                                    }
                                    return (
                                        <tr key={idx}>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`...`}
                                                    value={element.name || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'name')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`...`}
                                                    value={element.description || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'description')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="text"
                                                    placeholder={`...`}
                                                    value={element.codeEan || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'codeEan')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="number"
                                                    step="any"
                                                    lang="en"
                                                    placeholder={`...`}
                                                    value={element.value || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'value')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td>
                                                <FormControl
                                                    type="number"
                                                    step="any"
                                                    lang="en"
                                                    placeholder={`...`}
                                                    value={element.weight || ""}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={this.handleElementChange(idx, 'weight')}
                                                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                />
                                            </td>
                                            <td>
                                                <Select
                                                    className="react-select primary react-select-intable"
                                                    classNamePrefix="react-select"
                                                    placeholder="Wybierz.."
                                                    value={selectedWarehouseOptions.filter(itm => itm.value === element.warehouse)[0]}
                                                    onChange={this.handleElementChange(idx, 'warehouse')}
                                                    options={selectedWarehouseOptions}
                                                    isClearable={true}
                                                    isDisabled={this.state.isPreviewOnly}
                                                />
                                            </td>
                                            <td className="text-right">
                                                <Button
                                                    onClick={this.handleTrashElement(idx)}
                                                    bsStyle="default"
                                                    disabled={this.state.isPreviewOnly}
                                                    simple
                                                    icon>
                                                    <i className="fa fa-times" />
                                                </Button>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                        </tbody>
                    </Table>
                }
            />
        );

        let invoiceAssemblyList = (
            <Card
                tableFullWidth
                title={
                    <div>
                        Lista rachunków
                        <Button bsStyle="info" pullRight round onClick={() => { this.handleAddElement(this.elementPrototype) }} disabled={this.state.isPreviewOnly}>
                            <span className="btn-label" style={{ paddingRight: "5px" }}>
                                <i className="fa fa-plus" />
                            </span>
                            dodaj element
                        </Button>
                    </div>
                }
                content={
                    <div class="table-responsive" style={{ overflowY: 'scroll' }}>
                        <Table style={{ marginBottom: "200px" }}>
                            <thead>
                                <tr>
                                    <th style={{ minWidth: "120px" }}>Nr rachunku</th>
                                    <th style={{ minWidth: "120px" }}>Kod kreskowy</th>
                                    <th style={{ minWidth: "120px" }}>Wartość</th>
                                    <th style={{ minWidth: "120px" }}>Opis</th>
                                    <th style={{ minWidth: "120px" }}>Stawka %</th>
                                    <th className="text-right">Opcje</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!(this.state.elements.length) ? <tr><td colSpan="6" className="text-center text-muted">- brak danych -</td></tr> :
                                    this.state.elements.map((element, idx) => {
                                        if (element.trash) {
                                            return null;
                                        }
                                        return (
                                            <tr key={idx}>
                                                <td>
                                                    <FormControl
                                                        type="text"
                                                        placeholder={`...`}
                                                        value={element.name || ""}
                                                        disabled={this.state.isPreviewOnly}
                                                        onChange={this.handleElementChange(idx, 'name')}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </td>
                                                <td>
                                                    <FormControl
                                                        type="text"
                                                        placeholder={`...`}
                                                        value={element.codeEan || ""}
                                                        disabled={this.state.isPreviewOnly}
                                                        onChange={this.handleElementChange(idx, 'codeEan')}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </td>
                                                <td>
                                                    <FormControl
                                                        type="number"
                                                        step="any"
                                                        lang="en"
                                                        placeholder={`...`}
                                                        value={element.value || ""}
                                                        disabled={this.state.isPreviewOnly}
                                                        onChange={this.handleElementChange(idx, 'value')}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </td>
                                                <td>
                                                    <FormControl
                                                        type="text"
                                                        placeholder={`...`}
                                                        value={element.description || ""}
                                                        disabled={this.state.isPreviewOnly}
                                                        onChange={this.handleElementChange(idx, 'description')}
                                                        onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                                                    />
                                                </td>
                                                <td>
                                                    <Select
                                                        className="react-select primary react-select-intable"
                                                        classNamePrefix="react-select"
                                                        placeholder="Wybierz.."
                                                        value={this.assemblyStakeOptions.filter(itm => itm.value === element.percentStakeAssembler)[0]}
                                                        onChange={this.handleElementChange(idx, 'percentStakeAssembler')}
                                                        options={this.assemblyStakeOptions}
                                                        isClearable={true}
                                                        isDisabled={this.state.isPreviewOnly}
                                                    />
                                                </td>
                                                <td className="text-right">
                                                    <Button
                                                        onClick={this.handleTrashElement(idx)}
                                                        bsStyle="default"
                                                        disabled={this.state.isPreviewOnly}
                                                        simple
                                                        icon>
                                                        <i className="fa fa-times" />
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    )}
                            </tbody>
                        </Table>
                    </div>
                }
            />
        );

        switch (this.props.listType) {
            case "cargo":
                return cargoList;
            case "cargoWeight":
                return cargoWeightList;
            case "jysk":
                return jyskList;
            default:
                if (this.props.assemblyMode) {
                    return invoiceAssemblyList;
                }
                return invoiceList;
        }

    }

}

export default ExpertElementsListComponent;