import React, { Component } from "react";
import Button from "../CustomButton/CustomButton.jsx";
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import ExpertUploadComponent from "./UploadComponent";
import Select from "react-select";
import { Table, FormControl, Modal, FormGroup, ControlLabel, Row, Col } from "react-bootstrap";

class ExpertZonesListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zones: this.props.zones || [],
            lastMileWarehouses: [],

            importShow: false,
            importFiles: [],
            importLoading: false,
            forceVisibe: false
        };
        this.zonePrototype = {
            "uuid": "",
            "name": "",
            "points": "",
            "perimeter": "",
            "weight": "",
            "postalCode": "",
            "basePriceClient": "",
            "basePriceFleet": "",
            "basePriceAssembler": "",
            "basePriceReal": "",
            "perimeterPriceClient": "",
            "perimeterPriceFleet": "",
            "perimeterPriceAssembler": "",
            "perimeterPriceReal": "",
            "transferRequired": false,
            "lastMileWarehouseUuid": "",
            "slotDelay": "0",
            "trash": false
        };
    }

    componentDidMount() {
        _api.request(
            `/admin/transfer/list-last-mile-warehouses`,
            {},
            (data) => {
                this.setState({ lastMileWarehouses: data || [] })
            }
        );
    }

    handleAddZone = (service) => {
        this.setState({ zones: this.state.zones.concat([service]) }, () => { this.props.onZonesChange(this.state.zones) });
    };

    handleZoneChange = (idx, field, newValue) => evt => {
        const newZones = this.state.zones.map((zone, sidx) => {
            if (idx !== sidx) return zone;
            let updatedZone = { ...zone };
            updatedZone[field] = typeof newValue === "undefined" ? evt ? evt.value || evt.target.value : null : newValue;
            return updatedZone;
        });
        this.setState({ zones: newZones }, () => { this.props.onZonesChange(this.state.zones) });
    };

    handleTrashZone = idx => () => {
        const newZones = this.state.zones.map((zone, sidx) => {
            if (idx !== sidx) return zone;
            return { ...zone, trash: true };
        });
        this.setState({ zones: newZones }, () => { this.props.onZonesChange(this.state.zones) });
    };

    importSubmit(event) {
        event.preventDefault();
        let self = this;
        if (!self.state.importFiles.length) {
            self.props.showNoty('tr', 'error', 'Wybierz plik(i)');
            return;
        }
        self.setState({ importLoading: true });
        self.props.showNoty('tr', 'info', 'Wgrywanie pliku..');
        _api.request(`/admin/import/excel-to-json/zones`,
            {
                "files": self.state.importFiles
            },
            (data) => {
                if (document.getElementById("upForm")) {
                    document.getElementById("upForm").reset();
                }
                self.props.showNoty('tr', 'success', 'Plik został zaimportowany');

                let newZones = self.state.zones.map(zone => { let newZone = { ...zone }; newZone.trash = true; return newZone }) || [];
                newZones = newZones.concat(data);
                self.setState({ importFiles: [], importLoading: false, importShow: false, zones: newZones }, () => { this.props.onZonesChange(this.state.zones) });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas odczytu pliku');
                self.setState({ importLoading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        let importFilesList = [];
        for (var i = 0; i < this.state.importFiles.length; i++) {
            importFilesList.push(<p key={"file" + i}><i className="fa fa-file" /> {this.state.importFiles[i].name}</p>);
        }

        const importModal = (
            <Modal show={this.state.importShow} onHide={() => { this.setState({ importShow: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Import</Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.importSubmit.bind(this)} id="upForm">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>Wybierz plik do importu: </ControlLabel>
                                    <ExpertUploadComponent onSelectedFiles={files => { this.setState({ importFiles: files }) }} />
                                </FormGroup>
                                <FormGroup>
                                    {importFilesList}
                                </FormGroup>
                                <Button type="submit" disabled={this.state.importLoading} className="pull-right btn-fill btn-wd btn-icon btn btn-success" onClick={!this.state.importLoading ? this.importSubmit.bind(this) : null}>
                                    <i className="fa fa-save" />  {this.state.importLoading ? 'Ładowanie…' : 'Wgraj plik'}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )

        let zonesPerimeter = (
            <div>
                <Button bsStyle="info" round onClick={() => { this.handleAddZone(this.zonePrototype) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    dodaj strefę
                </Button>
                <Table responsive className="table-zones">
                    <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Punkty</th>
                            <th>Zasięg&nbsp;km</th>
                            <th>Cena&nbsp;bazowa (Klient)</th>
                            <th>Cena&nbsp;bazowa (Flota)</th>
                            <th>Cena&nbsp;bazowa (Montaż)</th>
                            <th>Cena&nbsp;bazowa (Expert)</th>
                            <th>Cena&nbsp;za&nbsp;km (Klient)</th>
                            <th>Cena&nbsp;za&nbsp;km (Flota)</th>
                            <th>Cena&nbsp;za&nbsp;km (Montaż)</th>
                            <th>Cena&nbsp;za&nbsp;km (Expert)</th>
                            {
                                this.props.allowTransferable && (
                                    <>
                                        <th>Wymaga transferu</th>
                                        <th style={{ minWidth: 200 }}>Magazyn końcowy</th>
                                        <th>Czas transferu (dni)</th>
                                    </>
                                )
                            }
                            <th className="text-right">Opcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!(this.state.zones.length) ? <tr><td colSpan={this.props.allowTransferable ? 14 : 12} className="text-center text-muted">- brak danych -</td></tr> :
                            this.state.zones.map((zone, idx) => {
                                if (zone.trash) {
                                    return (<></>)
                                }
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <FormControl
                                                type="text"
                                                placeholder={`...`}
                                                value={zone.name || ""}
                                                onChange={this.handleZoneChange(idx, 'name')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="1"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.points || ""}
                                                onChange={this.handleZoneChange(idx, 'points')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeter || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeter')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceClient || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceClient')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceFleet || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceFleet')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceAssembler || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceAssembler')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceReal || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceReal')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceClient || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceClient')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceFleet || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceFleet')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceAssembler || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceAssembler')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceReal || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceReal')}
                                            />
                                        </td>
                                        {
                                            this.props.allowTransferable && (
                                                <>
                                                    <td>
                                                        <Checkbox
                                                            number={`cb-${idx}-transfer`}
                                                            checked={zone.transferRequired || false}
                                                            onChange={this.handleZoneChange(idx, 'transferRequired', !zone.transferRequired)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={zone.lastMileWarehouseUuid ? this.state.lastMileWarehouses.find(itm => itm.value === zone.lastMileWarehouseUuid) : null}
                                                            placeholder="Wybierz.."
                                                            onChange={this.handleZoneChange(idx, 'lastMileWarehouseUuid')}
                                                            options={this.state.lastMileWarehouses}
                                                            isClearable={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            type="number"
                                                            step="any"
                                                            lang="en"
                                                            placeholder={`...`}
                                                            value={zone.slotDelay || ""}
                                                            onChange={this.handleZoneChange(idx, 'slotDelay')}
                                                        />
                                                    </td>
                                                </>
                                            )
                                        }
                                        <td>
                                            <Button
                                                onClick={this.handleTrashZone(idx)}
                                                bsStyle="default"
                                                simple
                                                icon>
                                                <i className="fa fa-times" />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                            )}
                    </tbody>
                </Table>
            </div>
        );

        let zonesWeightPostalCode = (
            <div style={{ paddingBottom: 100 }}>
                <Button bsStyle="info" round onClick={() => { this.handleAddZone(this.zonePrototype) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    dodaj strefę
                </Button>
                <Table responsive className="table-zones">
                    <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Punkty</th>
                            <th>Zakres&nbsp;kg</th>
                            <th>Zasięg&nbsp;km</th>
                            <th>Kody pocztowe</th>
                            <th>Cena&nbsp;bazowa (Klient)</th>
                            <th>Cena&nbsp;bazowa (Flota)</th>
                            <th>Cena&nbsp;bazowa (Montaż)</th>
                            <th>Cena&nbsp;bazowa (Expert)</th>
                            <th>Cena&nbsp;za&nbsp;km (Klient)</th>
                            <th>Cena&nbsp;za&nbsp;km (Flota)</th>
                            <th>Cena&nbsp;za&nbsp;km (Montaż)</th>
                            <th>Cena&nbsp;za&nbsp;km (Expert)</th>
                            {
                                this.props.allowTransferable && (
                                    <>
                                        <th>Wymaga transferu</th>
                                        <th style={{ minWidth: 200 }}>Magazyn końcowy</th>
                                        <th>Czas transferu (dni)</th>
                                    </>
                                )
                            }
                            <th className="text-right">Opcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!(this.state.zones.length) ? <tr><td colSpan={this.props.allowTransferable ? 14 : 12} className="text-center text-muted">- brak danych -</td></tr> :
                            this.state.zones.map((zone, idx) => {
                                if (zone.trash) {
                                    return (<></>)
                                }
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <FormControl
                                                type="text"
                                                placeholder={`...`}
                                                value={zone.name || ""}
                                                onChange={this.handleZoneChange(idx, 'name')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="1"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.points || ""}
                                                onChange={this.handleZoneChange(idx, 'points')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.weight || ""}
                                                onChange={this.handleZoneChange(idx, 'weight')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeter || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeter')}
                                            />
                                        </td>
                                        <td>
                                            <textarea
                                                className="form-control"
                                                value={zone.postalCode}
                                                rows={1}
                                                onChange={this.handleZoneChange(idx, 'postalCode')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceClient || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceClient')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceFleet || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceFleet')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceAssembler || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceAssembler')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceReal || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceReal')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceClient || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceClient')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceFleet || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceFleet')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceAssembler || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceAssembler')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceReal || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceReal')}
                                            />
                                        </td>
                                        {
                                            this.props.allowTransferable && (
                                                <>
                                                    <td>
                                                        <Checkbox
                                                            number={`cb-${idx}-transfer`}
                                                            checked={zone.transferRequired || false}
                                                            onChange={this.handleZoneChange(idx, 'transferRequired', !zone.transferRequired)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={zone.lastMileWarehouseUuid ? this.state.lastMileWarehouses.find(itm => itm.value === zone.lastMileWarehouseUuid) : null}
                                                            placeholder="Wybierz.."
                                                            onChange={this.handleZoneChange(idx, 'lastMileWarehouseUuid')}
                                                            options={this.state.lastMileWarehouses}
                                                            isClearable={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            type="number"
                                                            step="any"
                                                            lang="en"
                                                            placeholder={`...`}
                                                            value={zone.slotDelay || ""}
                                                            onChange={this.handleZoneChange(idx, 'slotDelay')}
                                                        />
                                                    </td>
                                                </>
                                            )
                                        }
                                        <td>
                                            <Button
                                                onClick={this.handleTrashZone(idx)}
                                                bsStyle="default"
                                                simple
                                                icon>
                                                <i className="fa fa-times" />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                            )}
                    </tbody>
                </Table>
            </div>
        );

        let zonesPostalCodeOnly = (
            <div style={{ paddingBottom: 100 }}>
                {importModal}
                <Button bsStyle="info" round onClick={() => { this.handleAddZone(this.zonePrototype) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    dodaj strefę
                </Button>
                {` `}
                <Button bsStyle="info" round onClick={() => { this.setState({ importShow: true }) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    wczytaj strefy
                </Button>
                <Table responsive className="table-zones">
                    <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Punkty</th>
                            <th>Kody pocztowe</th>
                            <th>Cena&nbsp;bazowa (Klient)</th>
                            <th>Cena&nbsp;bazowa (Flota)</th>
                            <th>Cena&nbsp;bazowa (Montaż)</th>
                            <th>Cena&nbsp;bazowa (Expert)</th>
                            <th>Cena&nbsp;za&nbsp;km (Klient)</th>
                            <th>Cena&nbsp;za&nbsp;km (Flota)</th>
                            <th>Cena&nbsp;za&nbsp;km (Montaż)</th>
                            <th>Cena&nbsp;za&nbsp;km (Expert)</th>
                            {
                                this.props.allowTransferable && (
                                    <>
                                        <th>Wymaga transferu</th>
                                        <th style={{ minWidth: 200 }}>Magazyn końcowy</th>
                                        <th>Czas transferu (dni)</th>
                                    </>
                                )
                            }
                            <th className="text-right">Opcje</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!(this.state.zones.length) ? <tr><td colSpan={this.props.allowTransferable ? 14 : 12} className="text-center text-muted">- brak danych -</td></tr> :
                            this.state.zones.map((zone, idx) => {
                                if (zone.trash) {
                                    return (<></>)
                                }
                                return (
                                    <tr key={idx}>
                                        <td>
                                            <FormControl
                                                type="text"
                                                placeholder={`...`}
                                                value={zone.name || ""}
                                                onChange={this.handleZoneChange(idx, 'name')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="1"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.points || ""}
                                                onChange={this.handleZoneChange(idx, 'points')}
                                            />
                                        </td>
                                        <td>
                                            <textarea
                                                className="form-control"
                                                value={zone.postalCode}
                                                rows={1}
                                                onChange={this.handleZoneChange(idx, 'postalCode')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceClient || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceClient')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceFleet || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceFleet')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceAssembler || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceAssembler')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.basePriceReal || ""}
                                                onChange={this.handleZoneChange(idx, 'basePriceReal')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceClient || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceClient')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceFleet || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceFleet')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceAssembler || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceAssembler')}
                                            />
                                        </td>
                                        <td>
                                            <FormControl
                                                type="number"
                                                step="any"
                                                lang="en"
                                                placeholder={`...`}
                                                value={zone.perimeterPriceReal || ""}
                                                onChange={this.handleZoneChange(idx, 'perimeterPriceReal')}
                                            />
                                        </td>
                                        {
                                            this.props.allowTransferable && (
                                                <>
                                                    <td>
                                                        <Checkbox
                                                            number={`cb-${idx}-transfer`}
                                                            checked={zone.transferRequired || false}
                                                            onChange={this.handleZoneChange(idx, 'transferRequired', !zone.transferRequired)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={zone.lastMileWarehouseUuid ? this.state.lastMileWarehouses.find(itm => itm.value === zone.lastMileWarehouseUuid) : null}
                                                            placeholder="Wybierz.."
                                                            onChange={this.handleZoneChange(idx, 'lastMileWarehouseUuid')}
                                                            options={this.state.lastMileWarehouses}
                                                            isClearable={true}
                                                        />
                                                    </td>
                                                    <td>
                                                        <FormControl
                                                            type="number"
                                                            step="any"
                                                            lang="en"
                                                            placeholder={`...`}
                                                            value={zone.slotDelay || ""}
                                                            onChange={this.handleZoneChange(idx, 'slotDelay')}
                                                        />
                                                    </td>
                                                </>
                                            )
                                        }
                                        <td>
                                            <Button
                                                onClick={this.handleTrashZone(idx)}
                                                bsStyle="default"
                                                simple
                                                icon>
                                                <i className="fa fa-times" />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            }
                            )}
                    </tbody>
                </Table>
            </div>
        );


        return this.state.zones.length > 50 && !this.state.forceVisibe ?
            (
                <Button bsStyle="danger" round onClick={() => { this.setState({ forceVisibe: true }) }}>
                    duża liczba danych. Załadować mimo to?
                </Button>
            ) : this.props.mode === "perimeter" ? zonesPerimeter
                : this.props.mode === "weightPostalCode" ? zonesWeightPostalCode
                    : zonesPostalCodeOnly;

    }

}

export default ExpertZonesListComponent;