/* global google */
/* global _api */
import React, { Component } from "react";
import "moment/min/locales";
import moment from "moment";
import "moment/min/locales";
import Datetime from "react-datetime";
import {
    Col,
    FormGroup,
} from "react-bootstrap";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
    DirectionsRenderer
} from "react-google-maps";

import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

class HubMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultCenter: props.defaultCenter,
            markers: [],
            directionsMarkers: [],
            slots: [],
            showingInfoWindow: false,
            positionInfoWindow: null,
            dataInfoWindow: null,
            directions: null,
            distance: null,
            duration: null,
            origin: null,
            destination: null
        };
        this.directionsService = new google.maps.DirectionsService();
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        let self = this;
        let usedLatLng = [];
        _api.request(`/admin/klose/map/${this.props.dateFrom.format("YYYY-MM-DD")}/${this.props.dateTo.format("YYYY-MM-DD")}`, {},
            (data) => {

                data.map((place, index) => {
                    let pos = { lat: parseFloat(place.addressRecipientLat), lng: parseFloat(place.addressRecipientLng) };
                    let used = usedLatLng.filter(obj => obj.lat === pos.lat && obj.lng === pos.lng);
                    if (used.length) {
                        pos.lat += this.randomInteger(50, 80) * 0.0000089;
                        pos.lng += this.randomInteger(50, 80) * 0.0000089;
                    }
                    usedLatLng.push(pos);
                    place.pos = pos;
                    return place;
                });

                self.setState({ markers: data });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    setOrderDate(preorderUuid, date) {
        let self = this;
        self.props.showNoty('tr', 'info', `Trwa zapisywanie danych`);
        _api.request(`/admin/klose/create-order/${preorderUuid}`,
            {
                'dayIdent': date ? moment(date).format("YYYY-MM-DD") : null
            },
            (data) => {
                self.props.showNoty('tr', 'success', `Numer nowego zlecenia: ${data.ident}`);
                self.fetchData();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    markerImage(bgColor, type) {
        let carIcon = `<g transform="translate(7,6) scale(.8)" fill="white"><rect height="13" width="15" x="1" y="3"/><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"/><circle cx="5.5" cy="18.5" r="2.5"/><circle cx="18.5" cy="18.5" r="2.5"/></g>`;
        let wrenchIcon = `<g transform="translate(6,6) scale(.4)" fill="white"><path d="M46.46,8.434l-6.19,6.19l-6.992-0.707l-0.785-7.071l6.148-6.148c-4.398-1.508-9.47-0.585-12.943,2.889  c-3.565,3.565-4.432,8.829-3,13.054L0.524,38.813c-1.22,1.221-0.778,3.17,0.442,4.391l2.947,2.945  c1.22,1.221,3.169,1.662,4.39,0.442l22.172-22.172c4.227,1.431,9.516,0.591,13.08-2.975C47.063,17.936,48.01,12.838,46.46,8.434z" /></g>`
        let selectedIcon = null;
        switch (type) {
            case 'assembly':
                selectedIcon = wrenchIcon;
                break;

            default:
                selectedIcon = carIcon;
                break
        }

        return encodeURIComponent(`
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="myGradient" gradientUnits="userSpaceOnUse" x1="5" y1="16" x2="27" y2="16" gradientTransform="rotate(90 16 16)">
                        <stop offset="0" stop-color="white" />
                        <stop offset="1" stop-color="black" />
                    </linearGradient>
                    <filter id="dropshadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
                        <feOffset dx="0" dy="0" result="offsetblur" />
                        <feFlood flood-color="#000" flood-opacity=".25" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <path fill="${bgColor}" stroke="white" stroke-width="1.5" style="filter:url(#dropshadow)" d="M3.5 3.5h25v25h-25z" />
                <path opacity=".15" fill="url(#myGradient)" d="M5 5h22v22H5z" />
                ${selectedIcon}
                />
            </svg>
        `);
    }

    onMarkerClick = (marker, data, position) => {
        this.setState({
            showingInfoWindow: true,
            dataInfoWindow: data,
            positionInfoWindow: position
        });
    }

    onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                dataInfoWindow: null,
                positionInfoWindow: null
            });
        }
    }

    bindPreorderWithDate = (preorderUuid, date) => {

    }

    onMarkerClustererClick(markerClusterer) {
        const clickedMarkers = markerClusterer.getMarkers()
        console.log(`Current clicked markers length: ${clickedMarkers.length}`)
        console.log(clickedMarkers)
    }

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    render() {

        let dir = (<></>);

        if (this.state.directions) {
            dir = <DirectionsRenderer
                directions={this.state.directions}
                options={{ suppressMarkers: true }}
            />;
        }

        return (
            <GoogleMap
                defaultCenter={this.state.defaultCenter}
                center={this.state.defaultCenter}
                onClick={this.onMapClick}
                defaultZoom={this.props.defaultZoom || 9}
                defaultOptions={{
                    mapTypeControl: false
                }}
            >
                <MarkerClusterer
                    onClick={(markerClusterer) => { this.onMarkerClustererClick(markerClusterer) }}
                    averageCenter
                    enableRetinaIcons
                    gridSize={2}>
                    {
                        this.state.markers.length
                            ?
                            this.state.markers.map((place, index) => {
                                const icon = {
                                    url: 'data:image/svg+xml;utf-8,' + this.markerImage(place.slotDayIdent ? '#00FF00' : '#000000', place.type),
                                    anchor: new google.maps.Point(16, 16),
                                    scaledSize: new google.maps.Size(32, 32)
                                }
                                if (place.trash) {
                                    return null
                                }
                                return (
                                    <Marker
                                        key={`marker-place-${index}`}
                                        icon={icon}
                                        position={place.pos}
                                        onClick={(evt) => { this.onMarkerClick(evt, place, place.pos); }}
                                    ></Marker>
                                )
                            })
                            :
                            <></>
                    }
                </MarkerClusterer>

                {
                    this.state.directionsMarkers.length
                        ?
                        this.state.directionsMarkers.map((place, index) => {
                            return (
                                <Marker
                                    key={`direction-place-${index}`}
                                    label={place.label}
                                    position={place.position}
                                ></Marker>
                            )
                        })
                        :
                        <></>
                }

                { dir}
                {
                    this.state.showingInfoWindow &&
                    (
                        <InfoWindow
                            position={this.state.positionInfoWindow}
                            visible={this.state.showingInfoWindow}
                            onCloseClick={(e) => { this.setState({ showingInfoWindow: false }) }}
                        >
                            <div className="content" style={{ padding: "0 15px 0 0", width: '300px' }}>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-circle" style={{ color: this.state.dataInfoWindow.slotDayIdent ? '#00FF00' : '#000000' }} /> Zlecenie {this.state.dataInfoWindow.ident}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-user-o" /> {this.state.dataInfoWindow.addressRecipientName}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-dot-circle-o" /> {this.state.dataInfoWindow.addressRecipientStreetAddress} {this.state.dataInfoWindow.addressRecipientStreetNumber}, {this.state.dataInfoWindow.addressRecipientCityName}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-clock-o" /> {this.state.dataInfoWindow.deliveryDateFrom} - {this.state.dataInfoWindow.deliveryDateTo}</p>
                                <FormGroup>
                                    <Col style={{ marginBottom: "5px" }}>
                                        <Datetime
                                            timeFormat={false}
                                            inputProps={{ placeholder: "Wybierz datę" }}
                                            value={moment(this.state.dataInfoWindow.slotDayIdent)}
                                            onChange={value =>
                                                this.setOrderDate(this.state.dataInfoWindow.uuid, value)
                                            }
                                        />
                                    </Col>
                                </FormGroup>

                            </div>
                        </InfoWindow>
                    )
                }
            </GoogleMap >
        );
    }
}

export default withGoogleMap(HubMap);