import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

class LocationSearchInput extends React.Component {

    constructor(props) {
        super(props);
        this.state = { address: this.props.value || '', addressComponents: null, addressLatLng: null };
    }

    handleChange = address => {
        this.setState({ address: address });
    }

    handleSelect = address => {
        let self = this;
        this.setState({ address });
        geocodeByAddress(address)
            .then((results) => {
                self.setState({ addressComponents: results[0] });
                return getLatLng(results[0]);
            })
            .then((latLng) => {
                self.setState({ addressLatLng: latLng });
                self.props.onAddressChange(self.state);
            })
            .catch((error) => console.error('Error', error));
    };

    handleNotFound = error => {
        this.props.onAddressNotFound(error);
    }

    render() {
        return (
            <PlacesAutocomplete
                name="address"
                value={this.state.address}
                onChange={this.handleChange}
                onSelect={this.handleSelect}
                onError={this.handleNotFound}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input
                            {...getInputProps({
                                placeholder: '',
                                className: 'form-control',
                                name: ''
                            })}
                        />
                        <div className="autocomplete-dropdown-container">
                            {loading && <div className="suggestion-loading">Ładowanie...</div>}
                            {suggestions.map(suggestion => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                        })}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        );
    }
}

export default LocationSearchInput;