import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { style } from "../variables/Variables.jsx";
import NotificationSystem from "react-notification-system";
import Footer from "../components/Footer/Footer.jsx";
import routes from "../routes.js";
import bgImage from "../assets/img/expert/pattern-light.svg";

class Track extends Component {

    constructor(props) {
        super(props);
        this.state = {
            _notificationSystem: null
        };
    }

    componentDidMount() {
        this.setState({ _notificationSystem: this.refs.notificationSystem });
    }

    showNoty = (position, level, content) => {
        this.state._notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (<div>{content}</div>),
            level: level,
            position: position,
            autoDismiss: 5
        });
    };

    getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (["/track", "/enet"].includes(prop.layout)) {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        render={routeProps => (
                            <prop.component
                                {...routeProps}
                                showNoty={this.showNoty}
                            />
                        )}
                    />
                );
            } else {
                return null;
            }
        });
    };

    render() {
        return (
            <div>
                <div className="wrapper wrapper-full-page wrapper-full-page-tracker">
                    <NotificationSystem ref="notificationSystem" style={style} />
                    <div
                        className={"full-page tracker-page"}
                        data-color="orange"
                        data-image={bgImage}
                    >
                        <div className="content">
                            <Switch>{this.getRoutes(routes)}</Switch>
                        </div>
                        <Footer transparent />
                        <div
                            className="full-page-background"
                            style={{ backgroundImage: "url(" + bgImage + ")" }}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default Track;
