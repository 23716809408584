/* global _api */
import React, { Component } from "react";
import moment from "moment";
import "moment/min/locales";
import PropagateLoader from 'react-spinners/PropagateLoader';
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import {
    Row,
    Col,
    Table
} from "react-bootstrap";

moment.locale('pl');

class ExpertOrderPrinterComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: true,
            schedule: this.props.schedule || "",
            orders: this.props.orders || [],
            ordersChecked: [],
            checkAll: false,
            pdfIsGenerating: false
        };
    }

    componentDidMount() {
        if (this.props.schedule) {
            this.loadSchedule(this.props.schedule);
        } else {
            this.setState({ isLoading: false })
        }
    }

    loadSchedule(scheduleUuid) {
        let self = this;
        self.setState({ schedule: scheduleUuid, isLoading: true });
        _api.request(`/admin/schedule/details/${scheduleUuid}`, {},
            (data) => {
                self.setState(
                    {
                        isLoading: false,
                        schedule: data.schedule.uuid,
                        orders: data.order || []
                    },
                    () => {
                        self.checkAll(true);
                    }
                );
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    manageOrder(order, checked) {
        if (checked) {
            this.setState({
                ordersChecked: this.state.ordersChecked.concat([{ id: order }])
            });
        } else {
            this.setState({
                ordersChecked: this.state.ordersChecked.filter(function (item) { return item.id !== order })
            });
        }
    }

    checkAll(checked) {
        if (checked) {
            let ordersChecked = [];
            this.state.orders.forEach((element) => {
                ordersChecked.push({ id: element.id });
            });
            this.setState({ ordersChecked: ordersChecked, checkAll: true });
        } else {
            this.setState({ ordersChecked: [], checkAll: false });
        }
    }

    generateLink() {
        let self = this;
        let ids = [];
        self.state.ordersChecked.forEach((element) => {
            ids.push(element.id);
        });
        self.setState({ pdfIsGenerating: true });
        _api.request(`/admin/order/pdf`,
            {
                schedule: {
                    uuid: self.state.schedule,
                },
                orders: ids
            },
            (data) => {
                self.setState({ pdfIsGenerating: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ pdfIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const scheduleLoading = (
            <div className="main-content">
                <div style={{ "paddingBottom": "26px" }}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        css={{
                            "display": "block",
                            "margin": "10px auto",
                            "width": "1px"
                        }}
                        color={'#777'}
                        loading={true}
                    />
                </div>
            </div>
        );

        const scheduleLoaded = (
            <Row>
                <Col sm={12}>
                    <div style={{ maxHeight: "300px", overflowX: "hidden", overflowY: "scroll" }}>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <Checkbox
                                            key="check-all"
                                            number={99999}
                                            checked={this.state.checkAll}
                                            onChange={event => {
                                                this.checkAll(event.target.checked);
                                            }}
                                        />
                                    </th>
                                    <th>Numer zlecenia</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!(this.state.orders.length) ? <tr><td colSpan="2" className="text-center text-muted">- brak danych -</td></tr> :
                                    this.state.orders.map((element, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <Checkbox
                                                        key={element.id}
                                                        number={key}
                                                        checked={this.state.ordersChecked.some(e => e.id === element.id)}
                                                        onChange={event => {
                                                            this.manageOrder(element.id, event.target.checked);
                                                        }}
                                                    />
                                                </td>
                                                <td>{element.ident}</td>
                                            </tr>
                                        )
                                    }
                                    )}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="2" className="text-center">
                                        {
                                            this.state.ordersChecked.length ?
                                                this.state.pdfIsGenerating ?
                                                    (

                                                        <button style={{ width: "100%" }} className="btn btn-success btn-wd" disabled>Generowanie…</button>
                                                    )
                                                    :
                                                    (
                                                        <button style={{ width: "100%" }} className="btn btn-success btn-fill btn-wd" onClick={() => { this.generateLink(); }}>Pobierz PDF</button>
                                                    )
                                                :
                                                (
                                                    <button style={{ width: "100%" }} className="btn btn-default btn-wd" disabled>Pobierz PDF</button>
                                                )
                                        }
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </Col>
            </Row>
        );

        return (
            <div>
                {this.state.isLoading ? scheduleLoading : scheduleLoaded}
            </div>
        )

    }

}

export default ExpertOrderPrinterComponent;
