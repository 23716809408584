/* global _api */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from "moment";
import "moment/min/locales";
import Select from "react-select";
import Datetime from "react-datetime";
import ChartistGraph from "react-chartist";
import tooltip from "chartist-plugin-tooltip";
import ExpertUploadComponent from "../../../components/Expert/UploadComponent.jsx";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    ControlLabel,
    Badge
} from "react-bootstrap";
import Datatable from "../../../components/Datatable";
import { $STATUS2_DOSTARCZONE, $STATUS2_NIEZREALIZOWANE, $STATUS2_ZGLOSZONOREKLAMACJE } from "../../../constants.js";

moment.locale('pl');

class ExpertAdminReport extends Component {

    constructor(props) {
        super(props);
        this.getProtoChart = () => {
            return Object.assign({}, {
                labels: [],
                series: []
            })
        };
        this.state = {
            isRefreshing: false,
            data: [],
            additionalFiles: [],
            pages: -1,
            loading: true,
            uploading: false,
            showOrderModal: false,
            orderTracker: null,
            department: null,
            departmentItems: [],
            departmentLoading: true,
            company: null,
            companyItems: [],
            companyLoading: true,
            fleet: null,
            fleetItems: [],
            fleetLoading: true,
            type: null,
            dateFrom: moment().startOf('month').format("YYYY-MM-DD"),
            dateTo: moment().endOf('month').format("YYYY-MM-DD"),
            excelIsGenerating: false,
            availableTypes: [
                { value: "xlsx", label: "EXPERT" },
                { value: "xlsx-agata", label: "AGATA" }
            ],
            allOrderData: this.getProtoChart(),
            allAvailabilityData: this.getProtoChart(),
            allPunctualityData: this.getProtoChart(),
            allStatusesData: this.getProtoChart(),
            departmentOrderData: this.getProtoChart(),
            departmentAvailabilityData: this.getProtoChart(),
            departmentPunctualityData: this.getProtoChart(),
            departmentStatusesData: this.getProtoChart(),
            companyOrderData: this.getProtoChart(),
            companyAvailabilityData: this.getProtoChart(),
            companyPunctualityData: this.getProtoChart(),
            companyStatusesData: this.getProtoChart(),
        };
        this.labelInterpolationFnc = (value, index) => {
            let diff = Math.abs(moment(this.state.dateTo).diff(moment(this.state.dateFrom), 'days'));
            if (diff <= 15) {
                return value;
            }
            if (diff <= 31) {
                return index % 3 === 0 ? value : null;
            }
            if (diff <= 90) {
                return index % 7 === 0 ? value : null;
            }
            if (diff <= 120) {
                return index % 14 === 0 ? value : null;
            }
            return index % 30 === 0 ? value : null;
        };
        this.chartLineOptions = {
            showArea: false,
            height: "350px",
            axisX: {
                labelInterpolationFnc: this.labelInterpolationFnc,
                showGrid: false
            },
            axisY: {
                onlyInteger: true
            },
            onlyInteger: true,
            lineSmooth: true,
            showLine: true,
            showPoint: true,
            fullWidth: true,
            chartPadding: {
                right: 50
            },
            plugins: [tooltip()]
        };
        this.chartBarOptions = {
            showArea: false,
            height: "350px",
            onlyInteger: true,
            fullWidth: true,
            chartPadding: {
                right: 50
            },
            plugins: [tooltip()]
        };
        this.chartResponsive = [
            [
                "screen and (max-width: 640px)",
                {
                    axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        }
                    }
                }
            ]
        ];
        this.charts = [
            {
                type: "Line",
                title: "Wszystkie: zlecenia",
                key: "allOrder",
                transform: (data) => {
                    let result = this.getProtoChart();
                    data.orderScheduled.forEach(elem => {
                        result.labels.push(elem.date);
                        result.series.push(elem.count);
                    });
                    result.series = [result.series];
                    return result;
                },
                options: Object.assign({}, this.chartLineOptions)
            },
            {
                type: "Bar",
                title: "Wszystkie: dostępność",
                key: "allAvailability",
                transform: (data) => {
                    let result = this.getProtoChart();
                    result.labels = [
                        `do 2 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.up_to_two * 100 / data.stats.aggregated.total) : 0}%)`,
                        `3 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.plus_three * 100 / data.stats.aggregated.total) : 0}%)`,
                        `powyżej 3 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.more_than_three * 100 / data.stats.aggregated.total) : 0}%)`
                    ]
                    result.series = [
                        [
                            data.stats.aggregated.up_to_two || 0,
                            data.stats.aggregated.plus_three || 0,
                            data.stats.aggregated.more_than_three || 0
                        ]
                    ];
                    return result;
                },
                options: Object.assign({}, this.chartBarOptions),
                buttons: (
                    <Button disabled={this.state.excelIsGenerating} bsStyle="default" pullRight round onClick={() => { this.generateLink(`/admin/order/availability`) }}>
                        <span className="btn-label">
                            <i className="fa fa-download" /> Pobierz .xlsx
                        </span>
                    </Button>
                )
            },
            {
                type: "Line",
                title: "Wszystkie: punktualność",
                key: "allPunctuality",
                transform: (data) => {
                    let result = this.getProtoChart();
                    let series0 = [];
                    let series1 = [];
                    for (const [key, value] of Object.entries(data.stats.by_date)) {
                        result.labels.push(moment(key).format("DD.MM"));
                        series0.push({ "meta": `Wszystkie zlecenia`, "value": value.total });
                        series1.push({ "meta": `Dostarczone na czas (${value.total ? Math.round(value.on_time * 100 / value.total) : 0}%)`, "value": value.on_time });
                    }
                    result.series = [series0, series1];
                    return result;
                },
                options: Object.assign({}, this.chartLineOptions),
                buttons: (
                    <Button disabled={this.state.excelIsGenerating} bsStyle="default" pullRight round onClick={() => { this.generateLink(`/admin/order/punctuality`) }}>
                        <span className="btn-label">
                            <i className="fa fa-download" /> Pobierz .xlsx
                        </span>
                    </Button>
                )
            },
            {
                type: "Bar",
                title: "Wszystkie: statusy",
                key: "allStatuses",
                transform: (data) => {
                    let result = this.getProtoChart();
                    result.labels = [
                        `Dostarczone (${data.stats.aggregated.total ? Math.round((data.stats.aggregated[$STATUS2_DOSTARCZONE] || 0) * 100 / data.stats.aggregated.total) : 0}%)`,
                        `Niedostarczone (${data.stats.aggregated.total ? Math.round((data.stats.aggregated[$STATUS2_NIEZREALIZOWANE] || 0) * 100 / data.stats.aggregated.total) : 0}%)`,
                        `Zgłoszono reklamację (${data.stats.aggregated.total ? Math.round((data.stats.aggregated[$STATUS2_ZGLOSZONOREKLAMACJE] || 0) * 100 / data.stats.aggregated.total) : 0}%)`,
                    ]
                    result.series = [
                        [
                            data.stats.aggregated[$STATUS2_DOSTARCZONE] || 0,
                            data.stats.aggregated[$STATUS2_NIEZREALIZOWANE] || 0,
                            data.stats.aggregated[$STATUS2_ZGLOSZONOREKLAMACJE] || 0
                        ]
                    ];
                    return result;
                },
                options: Object.assign({}, this.chartBarOptions)
            },
            {
                type: "Line",
                title: "Oddział: zlecenia",
                key: "departmentOrder",
                transform: (data) => {
                    let result = this.getProtoChart();
                    data.orderScheduled.forEach(elem => {
                        result.labels.push(elem.date);
                        result.series.push(elem.count);
                    });
                    result.series = [result.series];
                    return result;
                },
                options: Object.assign({}, this.chartLineOptions)
            },
            {
                type: "Bar",
                title: "Oddział: dostępność",
                key: "departmentAvailability",
                transform: (data) => {
                    let result = this.getProtoChart();
                    result.labels = [
                        `do 2 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.up_to_two * 100 / data.stats.aggregated.total) : 0}%)`,
                        `3 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.plus_three * 100 / data.stats.aggregated.total) : 0}%)`,
                        `powyżej 3 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.more_than_three * 100 / data.stats.aggregated.total) : 0}%)`
                    ]
                    result.series = [
                        [
                            data.stats.aggregated.up_to_two || 0,
                            data.stats.aggregated.plus_three || 0,
                            data.stats.aggregated.more_than_three || 0
                        ]
                    ];
                    return result;
                },
                options: Object.assign({}, this.chartBarOptions),
                buttons: (
                    <Button disabled={this.state.excelIsGenerating} bsStyle="default" pullRight round onClick={() => { this.generateLink(`/admin/order/availability`) }}>
                        <span className="btn-label">
                            <i className="fa fa-download" /> Pobierz .xlsx
                        </span>
                    </Button>
                )
            },
            {
                type: "Line",
                title: "Oddział: punktualność",
                key: "departmentPunctuality",
                transform: (data) => {
                    let result = this.getProtoChart();
                    let series0 = [];
                    let series1 = [];
                    for (const [key, value] of Object.entries(data.stats.by_date)) {
                        result.labels.push(moment(key).format("DD.MM"));
                        series0.push({ "meta": `Wszystkie zlecenia`, "value": value.total });
                        series1.push({ "meta": `Dostarczone na czas (${value.total ? Math.round(value.on_time * 100 / value.total) : 0}%)`, "value": value.on_time });
                    }
                    result.series = [series0, series1];
                    return result;
                },
                options: Object.assign({}, this.chartLineOptions),
                buttons: (
                    <Button disabled={this.state.excelIsGenerating} bsStyle="default" pullRight round onClick={() => { this.generateLink(`/admin/order/punctuality`) }}>
                        <span className="btn-label">
                            <i className="fa fa-download" /> Pobierz .xlsx
                        </span>
                    </Button>
                )
            },
            {
                type: "Bar",
                title: "Oddział: statusy",
                key: "departmentStatuses",
                transform: (data) => {
                    let result = this.getProtoChart();
                    result.labels = [
                        `Dostarczone (${data.stats.aggregated.total ? Math.round((data.stats.aggregated[$STATUS2_DOSTARCZONE] || 0) * 100 / data.stats.aggregated.total) : 0}%)`,
                        `Niedostarczone (${data.stats.aggregated.total ? Math.round((data.stats.aggregated[$STATUS2_NIEZREALIZOWANE] || 0) * 100 / data.stats.aggregated.total) : 0}%)`,
                        `Zgłoszono reklamację (${data.stats.aggregated.total ? Math.round((data.stats.aggregated[$STATUS2_ZGLOSZONOREKLAMACJE] || 0) * 100 / data.stats.aggregated.total) : 0}%)`,
                    ]
                    result.series = [
                        [
                            data.stats.aggregated[$STATUS2_DOSTARCZONE] || 0,
                            data.stats.aggregated[$STATUS2_NIEZREALIZOWANE] || 0,
                            data.stats.aggregated[$STATUS2_ZGLOSZONOREKLAMACJE] || 0
                        ]
                    ];
                    return result;
                },
                options: Object.assign({}, this.chartBarOptions)
            },
            {
                type: "Line",
                title: "Firma: zlecenia",
                key: "companyOrder",
                transform: (data) => {
                    let result = this.getProtoChart();
                    data.orderScheduled.forEach(elem => {
                        result.labels.push(elem.date);
                        result.series.push(elem.count);
                    });
                    result.series = [result.series];
                    return result;
                },
                options: Object.assign({}, this.chartLineOptions)
            },
            {
                type: "Bar",
                title: "Firma: dostępność",
                key: "companyAvailability",
                transform: (data) => {
                    let result = this.getProtoChart();
                    result.labels = [
                        `do 2 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.up_to_two * 100 / data.stats.aggregated.total) : 0}%)`,
                        `3 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.plus_three * 100 / data.stats.aggregated.total) : 0}%)`,
                        `pow. 3 dni (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.more_than_three * 100 / data.stats.aggregated.total) : 0}%)`,
                        `na życz. (${data.stats.aggregated.total ? Math.round(data.stats.aggregated.delay_request * 100 / data.stats.aggregated.total) : 0}%)`,
                    ]
                    result.series = [
                        [
                            data.stats.aggregated.up_to_two || 0,
                            data.stats.aggregated.plus_three || 0,
                            data.stats.aggregated.more_than_three || 0,
                            data.stats.aggregated.delay_request || 0
                        ]
                    ];
                    return result;
                },
                options: Object.assign({}, this.chartBarOptions)
            },
            {
                type: "Line",
                title: "Firma: punktualność",
                key: "companyPunctuality",
                transform: (data) => {
                    let result = this.getProtoChart();
                    let series0 = [];
                    let series1 = [];
                    for (const [key, value] of Object.entries(data.stats.by_date)) {
                        result.labels.push(key);
                        series0.push({ "meta": `Wszystkie zlecenia`, "value": value.total });
                        series1.push({ "meta": `Dostarczone na czas (${value.total ? Math.round(value.on_time * 100 / value.total) : 0}%)`, "value": value.on_time });
                    }
                    result.series = [series0, series1];
                    return result;
                },
                options: Object.assign({}, this.chartLineOptions)
            },
            {
                type: "Bar",
                title: "Firma: statusy",
                key: "companyStatuses",
                transform: (data) => {
                    let result = this.getProtoChart();
                    result.labels = [
                        `Dostarczone (${data.stats.aggregated.total ? Math.round(data.stats.aggregated[$STATUS2_DOSTARCZONE] * 100 / data.stats.aggregated.total) : 0}%)`,
                        `Zgłoszono reklamację (${data.stats.aggregated.total ? Math.round(data.stats.aggregated[$STATUS2_ZGLOSZONOREKLAMACJE] * 100 / data.stats.aggregated.total) : 0}%)`,
                    ]
                    result.series = [
                        [
                            data.stats.aggregated[$STATUS2_DOSTARCZONE] || 0,
                            data.stats.aggregated[$STATUS2_ZGLOSZONOREKLAMACJE] || 0
                        ]
                    ];
                    return result;
                },
                options: Object.assign({}, this.chartBarOptions)
            },
        ];
    }

    componentDidMount() {
        this.loadList('department', `/admin/general/departments`,
            () => {
                let selected = this.state.departmentItems.filter(itm => itm.value === this.props.user.user.department.uuid)[0];
                let departmentItems = this.state.departmentItems;
                if (this.props.userHasRole(['expertManager'])) {
                    departmentItems = this.state.departmentItems.filter((elem) => { return elem.region === selected.region });
                }
                this.setDepartment(selected, departmentItems)
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
    }

    setDepartment(value, departmentItems) {
        if (typeof departmentItems === "undefined") {
            departmentItems = this.state.departmentItems;
        }
        this.setState(
            {
                department: value,
                departmentItems: departmentItems,
                isRefreshing: true,
                allOrder: this.getProtoChart(),
                allAvailability: this.getProtoChart(),
                allPunctuality: this.getProtoChart(),
                allStatuses: this.getProtoChart(),
                departmentOrder: this.getProtoChart(),
                departmentAvailability: this.getProtoChart(),
                departmentPunctuality: this.getProtoChart(),
                departmentStatuses: this.getProtoChart()
            },
            () => {
                this.setState({ isRefreshing: false });
                this.loadList('fleet', `/admin/general/fleet/department/${this.state.department ? this.state.department.value : ""}`, () => { });
                this.loadList('company', `/admin/general/companies/${this.state.department ? this.state.department.value : ""}`, () => { });
                if (this.state.department) {
                    this.loadChart('departmentOrder', `/statistica/department-scheduled-orders/${this.state.department.value}`);
                    this.loadChart('departmentAvailability', `/statistica/department-availability/${this.state.department.value}`);
                    this.loadChart('departmentPunctuality', `/statistica/department-punctuality/${this.state.department.value}`);
                    this.loadChart('departmentStatuses', `/statistica/department-statuses/${this.state.department.value}`);
                } else {
                    this.loadChart('allOrder', `/statistica/all-scheduled-orders`);
                    this.loadChart('allAvailability', `/statistica/all-availability`);
                    this.loadChart('allPunctuality', `/statistica/all-punctuality`);
                    this.loadChart('allStatuses', `/statistica/all-statuses`);
                }
            }
        );
    }

    setFleet(value) {
        this.setState({ fleet: value, isRefreshing: true }, () => { this.setState({ isRefreshing: false }) });
    }

    setCompany(value) {
        this.setState(
            {
                company: value,
                isRefreshing: true,
                companyOrder: this.getProtoChart(),
                companyAvailability: this.getProtoChart(),
                companyPunctuality: this.getProtoChart(),
                companyStatuses: this.getProtoChart()
            },
            () => {
                this.setState({ isRefreshing: false });
                if (this.state.company && this.state.company.value) {
                    this.loadChart('companyOrder', `/statistica/company-scheduled-orders/${this.state.company.value}`);
                    this.loadChart('companyAvailability', `/statistica/company-availability/${this.state.company.value}`);
                    this.loadChart('companyPunctuality', `/statistica/company-punctuality/${this.state.company.value}`);
                    this.loadChart('companyStatuses', `/statistica/company-statuses/${this.state.company.value}`);
                }
            }
        );
    }

    setDateFrom(value) {
        this.setState(
            {
                dateFrom: value,
                isRefreshing: true
            },
            () => {
                this.setState({ isRefreshing: false });
                this.setDepartment(this.state.department || null);
                this.setCompany(this.state.company || null);
            }
        );
    }

    setDateTo(value) {
        this.setState(
            {
                dateTo: value,
                isRefreshing: true
            },
            () => {
                this.setState({ isRefreshing: false });
                this.setDepartment(this.state.department || null);
                this.setCompany(this.state.company || null);
            }
        );
    }

    loadList(name, endpoint, callback) {
        let self = this;
        self.setState({ [name]: null, [name + 'Items']: [], [name + 'Loading']: true });
        _api.request(endpoint, {},
            (data) => {
                if (!data) {
                    data = [];
                }
                let items = [];
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name, "region": data[i].region || null });
                }
                if (items.length) {
                    self.setState({ [name]: items[0].value, [name + 'Items']: items, [name + 'Loading']: false }, () => { callback(); });
                } else {
                    self.setState({ [name + 'Loading']: false }, () => { callback(); });
                }
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    loadChart(name, endpoint) {
        let self = this;
        let dateFrom = moment(self.state.dateFrom).format("YYYY-MM-DD");
        let dateTo = moment(self.state.dateTo).format("YYYY-MM-DD");
        _api.request(
            `${endpoint}?from=${dateFrom}&to=${dateTo}`,
            {},
            (data) => {
                let chartData = [];
                self.charts = self.charts.map(itm => {
                    if (itm.key === name) {
                        chartData = itm.transform(data);
                    }
                    return itm;
                });
                self.setState({
                    [`${name}Data`]: chartData,
                    updated: moment()
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    generateLink(endpoint) {
        let self = this;
        self.setState({ excelIsGenerating: true });
        let type = self.state.type ? self.state.type.value : "xlsx";
        endpoint = endpoint || `/admin/order/report/${type}`;
        _api.request(endpoint,
            {
                department: self.state.department ? self.state.department.value : null,
                fleet: self.state.fleet ? self.state.fleet.value : null,
                company: self.state.company ? self.state.company.value : null,
                dateFrom: moment(self.state.dateFrom).format("YYYY-MM-DD"),
                dateTo: moment(self.state.dateTo).format("YYYY-MM-DD")
            },
            (data) => {
                self.setState({ excelIsGenerating: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ excelIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    getBadge = (status) => {
        switch (status) {
            case 1:
                return (<Badge pullRight bsStyle="danger">1/5</Badge>);
            case 3:
                return (<Badge pullRight bsStyle="warning">3/5</Badge>);
            case 5:
                return (<Badge pullRight bsStyle="success">5/5</Badge>);
            default:
                return (<Badge pullRight bsStyle="secondary">-/-</Badge>);
        }
    }

    getFilesList = () => {
        let filesList = [];
        let allFiles = [];
        for (let i = 0; i < this.state.additionalFiles.length; i++) {
            allFiles.push({ name: this.state.additionalFiles[i].name });
        }
        for (let i = 0; i < allFiles.length; i++) {
            let elem = (<><i className="fa fa-file" /> {allFiles[i].name}</>);
            if (allFiles[i].url) {
                elem = (<a href={allFiles[i].url} target="_blank" rel="noopener noreferrer">{elem}</a>);
            }
            filesList.push(<p key={"file" + i}>{elem}</p>);
        }
        return filesList;
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        if (!self.state.additionalFiles) {
            self.props.showNoty('tr', 'error', 'Wybierz plik(i)');
            return;
        }
        self.props.showNoty('tr', 'info', 'Wgrywanie pliku..');
        _api.request(`/admin/import/kilometers`,
            {
                "files": self.state.additionalFiles,
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Plik został przeliczony');
                this.setState({ uploading: false });
                window.location.href = data[0].url;
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas odczytu pliku');
                this.setState({ uploading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const filesList = this.getFilesList();
        const uploading = this.state.uploading;

        const loader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Raport
                                        </span>
                                        {
                                            this.state.excelIsGenerating ?
                                                (
                                                    <Button bsStyle="default" pullRight round disabled>
                                                        <span className="btn-label">
                                                            <i className="fa fa-download" /> Generowanie…
                                                        </span>
                                                    </Button>
                                                )
                                                :
                                                (
                                                    <Button bsStyle="default" pullRight round onClick={() => { this.generateLink() }}>
                                                        <span className="btn-label">
                                                            <i className="fa fa-download" /> Pobierz .xlsx
                                                        </span>
                                                    </Button>
                                                )
                                        }
                                    </div>
                                }
                                content={
                                    <div>
                                        <Row>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>Wybrany oddział: </ControlLabel>
                                                    <Col>
                                                        <Select
                                                            isClearable={this.props.userHasRole('admin')}
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={this.state.department}
                                                            placeholder="Wybierz oddział"
                                                            onChange={value =>
                                                                this.setDepartment(value)
                                                            }
                                                            options={this.state.departmentItems}
                                                            isDisabled={!this.props.userHasRole(['admin', 'expertManager'])}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>Zakres dostawy od: </ControlLabel>
                                                    <Datetime
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: "Wybierz datę" }}
                                                        value={moment(this.state.dateFrom)}
                                                        defaultValue={moment(this.state.dateFrom)}
                                                        onChange={value =>
                                                            this.setDateFrom(value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={4}>
                                                <FormGroup>
                                                    <ControlLabel>Zares dostawy do: </ControlLabel>
                                                    <Datetime
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: "Wybierz datę" }}
                                                        value={moment(this.state.dateTo)}
                                                        defaultValue={moment(this.state.dateTo)}
                                                        onChange={value =>
                                                            this.setDateTo(value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Wybrany Klient: </ControlLabel>
                                                    <Col>
                                                        <Select
                                                            isClearable
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={this.state.company}
                                                            placeholder="Wybierz firmę"
                                                            onChange={value =>
                                                                this.setCompany(value)
                                                            }
                                                            options={this.state.companyItems}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Wybrana Flota: </ControlLabel>
                                                    <Col>
                                                        <Select
                                                            isClearable
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={this.state.fleet}
                                                            placeholder="Wybierz flotę"
                                                            onChange={value =>
                                                                this.setFleet(value)
                                                            }
                                                            options={this.state.fleetItems}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <FormGroup>
                                                    <ControlLabel>Format raportu: </ControlLabel>
                                                    <Col>
                                                        <Select
                                                            isClearable
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={this.state.type}
                                                            placeholder="Wybierz format"
                                                            onChange={value =>
                                                                this.setState({ type: value })
                                                            }
                                                            options={this.state.availableTypes}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.department ?
                                                (
                                                    <></>
                                                ) : (!this.state.departmentLoading && !this.state.fleetLoading && !this.state.companyLoading && !this.state.isRefreshing) ?
                                                    (
                                                        <Datatable
                                                            data={this.state.data}
                                                            pages={this.state.pages}
                                                            loading={this.state.loading}
                                                            manual
                                                            filterable
                                                            columns={[
                                                                {
                                                                    Header: "Numer zlecenia",
                                                                    accessor: "ident"
                                                                },
                                                                {
                                                                    Header: "Nadawca",
                                                                    accessor: "sender"
                                                                },
                                                                {
                                                                    Header: "Odbiorca",
                                                                    accessor: "recipient"
                                                                },
                                                                {
                                                                    Header: "Status",
                                                                    accessor: "status"
                                                                },
                                                                {
                                                                    Header: "CSAT",
                                                                    accessor: "csat",
                                                                    width: 50
                                                                },
                                                            ]}
                                                            defaultSorted={[
                                                                {
                                                                    id: "ident",
                                                                    desc: true
                                                                }
                                                            ]}
                                                            onFetchData={(state, instance) => {
                                                                let self = this;
                                                                self.setState({ loading: true });
                                                                _api.request("/admin/order/report/table", {
                                                                    page: state.page,
                                                                    pageSize: state.pageSize,
                                                                    sorted: state.sorted,
                                                                    filtered: state.filtered,
                                                                    department: self.state.department ? self.state.department.value : null,
                                                                    fleet: self.state.fleet ? self.state.fleet.value : null,
                                                                    company: self.state.company ? self.state.company.value : null,
                                                                    dateFrom: moment(self.state.dateFrom).format("YYYY-MM-DD"),
                                                                    dateTo: moment(self.state.dateTo).format("YYYY-MM-DD")
                                                                }, (res) => {
                                                                    self.setState({
                                                                        data: res.rows.map((prop) => {
                                                                            prop.csat = self.getBadge(prop.csat);
                                                                            return prop;
                                                                        }),
                                                                        pages: res.pages,
                                                                        loading: false
                                                                    });
                                                                }, (error) => {
                                                                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                                    self.setState({ loading: false });
                                                                }, (progress) => {
                                                                    self.props.setProgress(progress);
                                                                });
                                                            }}
                                                            defaultPageSize={5}
                                                            showPaginationBottom
                                                            className="-striped -highlight"
                                                            previousText="Poprzednia"
                                                            nextText="Następna"
                                                            noDataText="Brak danych"
                                                            loadingText="Pobieranie danych"
                                                            pageText="Strona"
                                                            ofText=" z "
                                                            rowsText="wierszy"
                                                        />
                                                    )
                                                    :
                                                    loader
                                        }
                                    </div>
                                }
                            />
                        </Col>
                    </Row>

                    <Row>
                        {
                            this.charts.map((chart) => {
                                if (chart.key.includes('all') && (this.state.department)) {
                                    return null;
                                }
                                if (chart.key.includes('department') && (!this.state.department || !this.state.department.value)) {
                                    return null;
                                }
                                if (chart.key.includes('company') && (!this.state.company || !this.state.company.value)) {
                                    return null;
                                }
                                return (
                                    <Col md={12} key={chart.key}>
                                        <Card
                                            title={
                                                <div>
                                                    <span>
                                                        {chart.title}
                                                    </span>
                                                    {chart.buttons}
                                                </div>
                                            }
                                            content={
                                                <ChartistGraph
                                                    style={{ height: 350 }}
                                                    data={this.state[`${chart.key}Data`]}
                                                    options={chart.options}
                                                    type={chart.type}
                                                    responsiveOptions={this.chartResponsive}
                                                />
                                            }
                                        />
                                    </Col>
                                )
                            })
                        }

                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card
                                title={"Przeliczanie kilometrów"}
                                content={
                                    <Row>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <ExpertUploadComponent onSelectedFiles={files => { this.setState({ additionalFiles: files }) }} />
                                            </FormGroup>
                                            <FormGroup>
                                                {filesList}
                                            </FormGroup>
                                        </Col>
                                        <Col sm={6}>
                                            <FormGroup>
                                                <Button type="submit" disabled={uploading} className="btn-fill btn-wd btn-icon btn btn-success" onClick={!uploading ? this.handleSubmit.bind(this) : null}>
                                                    <i className="fa fa-save" />  {uploading ? 'Ładowanie…' : 'Wgraj plik CSV'}
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                }
                            />
                        </Col>
                    </Row>

                </Grid>
            </div>
        );
    }
}

export default ExpertAdminReport;
