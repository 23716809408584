import React, { Component } from "react";

class ExpertUploadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedFiles: []
        }
    }

    onChangeHandler = event => {
        this.setState({
            selectedFiles: event.target.files,
        }, () => this.props.onSelectedFiles(this.state.selectedFiles))
    }

    render() {
        return (
            <div className="custom-file" style={this.props.style || {}}>
                <input type="file" multiple className="custom-file-input" onChange={this.onChangeHandler} id="customFile" />
                <label className="custom-file-label" htmlFor="customFile">{this.state.selectedFiles.length ? "Pliki do przesłania: " + this.state.selectedFiles.length : "Wybierz pliki"}</label>
            </div>
        )
    }

}

export default ExpertUploadComponent;