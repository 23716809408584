import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'react-bootstrap';

export class Dnd extends Component {

    constructor(props) {
        super(props)
        this.state = {
            droppableId: this.props.droppableId,
            items: this.props.items || []
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        nextState.items = nextProps.items || [];
        return true;
    }

    getItemStyle = (isDragging, draggableStyle) => {
        const fixTopOffset = document.querySelector('.modal-lg').getBoundingClientRect().top;
        const fixLeftOffset = document.querySelector('.modal-lg').getBoundingClientRect().left;
        return {
            userSelect: 'none',
            padding: `8px`,
            margin: isDragging ? `-${fixTopOffset}px 0 8px -${fixLeftOffset}px` : `0 0 8px 0`,
            border: `1px solid #e3e3e3`,
            backgroundColor: isDragging ? '#6addf4' : 'white',
            borderColor: isDragging ? '#6addf4' : '#e3e3e3',
            color: isDragging ? '#fff' : '#333',
            transition: `background-color 0.5s ease-out`,
            ...draggableStyle
        }
    };

    getListStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? '#e3e3e3' : 'white',
        transition: `background-color 0.3s ease-out`,
    });

    onDragEnd(result) {
        const { source, destination } = result;
        if (!source || !destination) {
            return;
        }

        let newItems = this.state.items;
        for (let i = 0; i < newItems.length; i++) {
            if (i === source.index) {
                newItems[i].position = destination.index;
            } else if (i < source.index) {
                newItems[i].position = i + 1;
            } else if (i > source.index) {
                newItems[i].position = i - 1;
            }
        }

        let fixedPosition = newItems
            .sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0)
            .map(
                ({ ...itm }, index) => ({
                    ...itm,
                    position: index
                })
            );

        this.props.onChange(fixedPosition);
    }

    render() {
        const items = this.props.filterByTrash ? this.state.items.filter(item => item.trash === false) : this.state.items;
        const itemKey = this.props.itemKey || "uuid";
        return (
            <DragDropContext onDragEnd={(e) => { this.onDragEnd(e) }}>
                <Droppable droppableId={this.state.droppableId} direction={this.props.direction || "vertical"}>
                    {(provided, snapshot) => (
                        <div
                            ref={provided.innerRef}
                            style={this.getListStyle(snapshot.isDraggingOver)}
                            className={this.props.containerClass || ""}
                        >
                            {this.props.firstItem}
                            {
                                items.sort((a, b) => (a.position > b.position) ? 1 : (a.position < b.position) ? -1 : 0).map((item, index) => (
                                    <Draggable
                                        key={item[itemKey]}
                                        draggableId={item[itemKey]}
                                        index={index}>
                                        {(providedItem, snapshotItem) => (
                                            <div
                                                ref={providedItem.innerRef}
                                                {...providedItem.draggableProps}
                                                className={this.props.itemClass || ""}
                                                style={this.getItemStyle(
                                                    snapshotItem.isDragging,
                                                    providedItem.draggableProps.style
                                                )}>
                                                {
                                                    this.props.renderItem(item, index, providedItem.dragHandleProps)
                                                }
                                            </div>
                                        )}
                                    </Draggable>
                                ))
                            }
                            {this.props.lastItem}
                            {provided.placeholder}
                            {
                                this.state.items.length === 0 && !this.props.lastItem && !this.props.firstItem && this.props.placeholder
                                    ?
                                    (
                                        <Row>
                                            <Col sm={12}>
                                                <p
                                                    className="lead text-muted"
                                                    style={{
                                                        border: `1px dashed #e3e3d3`,
                                                        textAlign: `center`,
                                                        padding: `8px`,
                                                        lineHeight: `48px`,
                                                        margin: `0 8px`
                                                    }}
                                                >
                                                    {this.props.placeholder}
                                                </p>
                                            </Col>
                                        </Row>
                                    )
                                    :
                                    <></>
                            }
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        )
    }
}

export default Dnd