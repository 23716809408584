/* global _api */
import React, { Component, Fragment } from "react";
import Card from "../../../../components/Card/Card.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import Datatable from "../../../../components/Datatable";
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from "moment";
import "moment/min/locales";
import Select from "react-select";
import Checkbox from "../../../../components/CustomCheckbox/CustomCheckbox.jsx";
import {
    Grid,
    Row,
    Col,
    FormControl,
    Table,
    Glyphicon,
    FormGroup,
    Modal,
    ControlLabel,
    ListGroup,
    ListGroupItem,
} from "react-bootstrap";
import ExpertStatusBadge from "../../../../components/Expert/StatusBadge.jsx";

moment.locale('pl');

class ExpertAdminHubWarehouse extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRefreshing: false,
            alert: null,
            data: [],
            pages: -1,
            loading: true,
            barcodeReading: false,
            mainScannerInput: "",
            popupScannerInput: "",
            scannedSchedules: [],
            scannedPackages: [],

            myWarehouseUuid: "",
            myWarehouses: [],

            showScheduleModal: false,
            schedules: {},
            beeper: false,

            printPackagesIdents: [],
            printAllPackagesIdents: false,
            labelPageWidth: localStorage.getItem('labelPageWidth') || 150,
            labelPageHeight: localStorage.getItem('labelPageHeight') || 100,
            showElementsModal: false,

            isSaving: false,
            excelIsGenerating: false,
        };
        this.handleScannerInput = this.handleScannerInput.bind(this);
        this.bufferScannerInput = [];
        this.bufferLastKeyTime = 0;
        this.bufferCheckInterval = undefined;
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();

        this.onChangeFormatCallback = () => { console.log("ok") };
    }

    setStateAndStore(key, value) {
        localStorage.setItem(key, value);
        this.setState({ [key]: value });
    }

    beep() {
        if (!this.state.beeper) {
            return;
        }
        var oscillator = this.audioCtx.createOscillator();
        var gainNode = this.audioCtx.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(this.audioCtx.destination);

        gainNode.gain.value = 1.0;
        oscillator.frequency.value = 675;
        oscillator.type = 'square';

        oscillator.start();

        setTimeout(
            function () {
                oscillator.stop();
            },
            200
        );
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleScannerInput, false);
        this.bufferCheckInterval = setInterval((self) => {
            const currentTime = Date.now();
            if (currentTime - self.bufferLastKeyTime > 1000) {
                if (self.bufferScannerInput.length) {
                    self.bufferScannerInput = [];
                    self.setState({ barcodeReading: false });
                }
            }
        }, 1000, this);
        _api.request(
            `/admin/transfer/list-last-mile-warehouses`,
            {},
            (data) => {
                this.setState({ myWarehouses: data || [] })
            }
        );
    }

    componentWillUnmount() {
        clearInterval(this.bufferCheckInterval);
        document.removeEventListener("keydown", this.handleScannerInput, false);
        _api.abort();
    }

    handleScannerInput(event) {
        if (event.target.tagName.toUpperCase() === "INPUT") {
            return;
        }

        if (event.key === 'Shift') {
            return;
        }

        const key = event.key;
        const currentTime = Date.now();

        if (currentTime - this.bufferLastKeyTime > 1000) {
            this.bufferScannerInput = [];
        }

        this.bufferLastKeyTime = currentTime;

        if (event.key === 'Enter') {
            const str = this.bufferScannerInput.join('');
            this.bufferScannerInput = [];
            this.setState({ barcodeReading: false });
            if (this.state.showScheduleModal) {
                this.addCodeToList('scannedPackages', str);
            } else {
                this.addCodeToList('scannedSchedules', str);
            }
        } else {
            if (!this.bufferScannerInput.length) {
                this.setState({ barcodeReading: true });
            }
            this.bufferScannerInput.push(key);
        }
    }

    onMainScannerInput(event) {
        this.setState({ mainScannerInput: event.target.value });
    }

    onPopupScannerInput(event) {
        this.setState({ popupScannerInput: event.target.value });
    }

    addCodeToList(key, code) {
        this.beep();
        let newCodes = this.state[key];
        if (!newCodes.includes(code)) {
            newCodes.push(code);
        }
        this.setState({ mainScannerInput: "", popupScannerInput: "", [key]: newCodes });
    }

    removeCodeFromList(key, code) {
        const filteredOrders = this.state[key].filter(value => value !== code);
        this.setState({ [key]: filteredOrders });
    }

    loadSchedule(schedulesArray) {
        this.props.showNoty('tr', 'info', 'Pobieranie danych..');
        this.setState({ scannedSchedules: schedulesArray }, () => {
            this.handleScannedSchedules();
        })
    }

    handleScannedSchedules() {
        let self = this;
        this.setState({ isSaving: true });
        _api.request(`/admin/transfer/get-schedules`,
            {
                transfer: {
                    idents: this.state.scannedSchedules
                },
                warehouse: {
                    uuid: this.state.myWarehouseUuid
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Dane zostały pobrane');
                self.setState({ isSaving: false, showScheduleModal: true, schedules: data, scannedPackages: [], isRefreshing: true }, () => { this.setState({ isRefreshing: false }) });
            },
            (error) => {
                if (error.E_SCHEDULE_NOT_FOUND) {
                    self.props.showNoty('tr', 'error', 'Harmonogram nie został znaleziony: ' + error.E_SCHEDULE_NOT_FOUND);
                } else {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                }
                this.setState({ isSaving: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    getPackages() {
        let packages = [];
        Object.keys(this.state.schedules).forEach(scheduleIdent => {
            const theSchedule = this.state.schedules[scheduleIdent];
            theSchedule.orders.forEach(theOrder => {
                theOrder.elements.forEach(theElement => {
                    theElement.packages.forEach(thePackage => {
                        packages.push({
                            ...thePackage,
                            orderIdent: theOrder.ident,
                            orderIdentExternal: theOrder.identExternal,
                            scheduleIdent: scheduleIdent,
                            element: theElement
                        })
                    });
                })
            })
        });
        return packages;
    }

    getScanners() {
        let scanners = [];
        Object.keys(this.state.schedules).forEach(scheduleIdent => {
            const theSchedule = this.state.schedules[scheduleIdent];
            theSchedule.orders.forEach(theOrder => {
                theOrder.elements.forEach(theElement => {
                    scanners = scanners.concat(theElement.scanner || []);
                })
            })
        });
        return scanners;
    }

    handleScannedPackages(doWhat) {
        let packages = this.getPackages();
        let preparedPackages = [];
        let hasError = false;
        this.props.showNoty('tr', 'info', 'Sprawdzanie danych..');
        this.state.scannedPackages.forEach(scannedPackageIdent => {
            let found = false;
            let realPackageIdent = scannedPackageIdent;
            packages.forEach(realPackage => {
                if (realPackage.ident === scannedPackageIdent) {
                    realPackageIdent = realPackage.ident;
                    found = { ...realPackage };
                }
                if (realPackage.orderIdentExternal === scannedPackageIdent) {
                    realPackageIdent = realPackage.ident;
                    found = { ...realPackage };
                }
            });
            if (found) {
                if (doWhat === "release") {
                    let foundCollect = false;
                    found.element?.scanner?.forEach(scannerAction => {
                        if (scannerAction.ident === scannedPackageIdent) {
                            if (scannerAction.action === 'collect') {
                                if (scannerAction.warehouseUuid === this.state.myWarehouseUuid) {
                                    foundCollect = true;
                                }
                            }
                        }
                    });
                    if (!foundCollect) {
                        hasError = true;
                        this.props.showNoty('tr', 'error', 'Paczka nie została przyjęta: ' + scannedPackageIdent);
                        return;
                    }
                }
                preparedPackages.push({
                    ident: realPackageIdent,
                    action: doWhat,
                    warehouseUuid: this.state.myWarehouseUuid,
                    scheduleIdent: found.scheduleIdent,
                    orderIdent: found.orderIdent,
                    ts: moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
                })
            } else {
                hasError = true;
                this.props.showNoty('tr', 'error', 'Paczka nie została znaleziona: ' + scannedPackageIdent);
                return;
            }
        });
        if (hasError) {
            return;
        }
        this.sendScannedPackages(preparedPackages, doWhat);
    }

    sendScannedPackages(packages, action) {
        let self = this;
        if (this.state.isSaving) {
            return;
        }
        this.setState({ isSaving: true }, () => {
            _api.request(`/admin/transfer/collect-release-packages/${action}`,
                {
                    packages: packages,
                    warehouse: {
                        uuid: this.state.myWarehouseUuid
                    }
                },
                (data) => {
                    self.handleScannedSchedules();
                    this.props.showNoty('tr', 'success', 'Dane zostały zapisane');
                },
                (error) => {
                    this.setState({ isSaving: false });
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                },
                (progress) => {
                    self.props.setProgress(progress);
                }
            );
        });
    }

    getPackageStatus(packageIdent) {
        let scanners = this.getScanners();
        let status = 'BRAK';
        scanners.forEach(theScan => {
            if (theScan.ident !== packageIdent) {
                return;
            }
            if (theScan.warehouseUuid !== this.state.myWarehouseUuid) {
                return;
            }
            if (theScan.action === 'collect') {
                status = 'PRZYJĘTE';
            }
            if (theScan.action === 'release') {
                status = 'WYDANE';
            }
            if (theScan.action === 'damage') {
                status = 'USZKODZONE';
            }
        });
        return status;
    }

    printPackagesIdentsToggle(packageIdent, selectDeselect) {
        if (packageIdent) {
            let printPackagesIdents = this.state.printPackagesIdents;
            if (printPackagesIdents.includes(packageIdent)) {
                this.setState({ printPackagesIdents: printPackagesIdents.filter(itm => itm !== packageIdent) })
            } else {
                this.setState({ printPackagesIdents: printPackagesIdents.concat([packageIdent]) })
            }
        } else {
            if (selectDeselect === false) {
                this.setState({ printPackagesIdents: [] })
            } else {
                let newPrintPackagesIdents = [];
                Object.keys(this.state.schedules).forEach(scheduleIdent => {
                    const theSchedule = this.state.schedules[scheduleIdent];
                    theSchedule.orders.map(theOrder => {
                        theOrder.elements.map(theElement => {
                            theElement.packages.map(thePackage => {
                                newPrintPackagesIdents.push(thePackage.ident)
                            })
                        })
                    })
                });
                this.setState({ printPackagesIdents: newPrintPackagesIdents })
            }
        }
    }

    printSelectedPackages() {
        let self = this;
        let orders = [];

        if (self.state.printPackagesIdents.length === 0) {
            self.props.showNoty('tr', 'error', 'Wybierz przynajmniej jeden element');
            return;
        }

        Object.keys(this.state.schedules).forEach(scheduleIdent => {
            const theSchedule = this.state.schedules[scheduleIdent];
            theSchedule.orders.map(theOrder => {
                theOrder.elements.map(theElement => {
                    theElement.packages.map(thePackage => {
                        if (this.state.printPackagesIdents.includes(thePackage.ident)) {
                            orders.push({ "uuid": theOrder.uuid });
                        }
                    })
                })
            })
        });

        self.props.showNoty('tr', 'info', 'Generowanie dokumentów..');
        self.setState({ pdfIsGenerating: true });
        _api.request(`/admin/order/pdf-elements`,
            {
                mode: "ordersList",
                orders: orders,
                elements: this.state.printPackagesIdents,
                width: self.state.labelPageWidth,
                height: self.state.labelPageHeight,
            },
            (data) => {
                self.setState({ pdfIsGenerating: false, showElementsModal: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ pdfIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    getReport() {
        this.setState({ 'excelIsGenerating': true });
        _api.request(
            `/admin/transfer/report/transfers-rosebrock-warehouse`,
            {
                overlayed: {
                    warehouses: [this.state.myWarehouseUuid]
                }
            },
            (data) => {
                this.setState({ 'excelIsGenerating': false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                this.setState({ 'excelIsGenerating': false });
                this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                this.props.setProgress(progress);
            }
        );
    }

    printSelectedSchedules() {
        this.setState({ pdfIsGenerating: true }, () => {
            _api.request(`/admin/transfer/print-schedules`,
                {
                    transfer: {
                        idents: Object.keys(this.state.schedules) || [],
                    }
                },
                (data) => {
                    this.setState({ pdfIsGenerating: false });
                    if (data.url) {
                        window.open(data.url);
                    }
                },
                (error) => {
                    this.setState({ pdfIsGenerating: false });
                    this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                },
                (progress) => {
                    this.props.setProgress(progress);
                }
            );
        });

    }

    render() {

        const loader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const modalElements = (
            <Modal show={this.state.showElementsModal} onHide={() => { this.setState({ showElementsModal: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Wydruk zleceń</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <ControlLabel>Szerokość (mm):</ControlLabel>
                                <FormControl
                                    type="number"
                                    min="50"
                                    max="210"
                                    value={this.state.labelPageWidth}
                                    onChange={event =>
                                        this.setStateAndStore('labelPageWidth', event.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <ControlLabel>Wysokość (mm):</ControlLabel>
                                <FormControl
                                    type="number"
                                    min="50"
                                    max="210"
                                    value={this.state.labelPageHeight}
                                    onChange={event =>
                                        this.setStateAndStore('labelPageHeight', event.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Button
                                onClick={() => { this.printSelectedPackages() }}
                                bsStyle="default"
                                block
                                round
                                icon>
                                <i className="fa fa-print" />
                                Drukuj etykiety
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )

        return (
            <div className="main-content">
                {this.state.alert}
                {modalElements}

                <Modal bsSize="lg" show={this.state.showScheduleModal} onHide={() => this.setState({ showScheduleModal: false, printPackagesIdents: [], printAllPackagesIdents: false, })} style={{ paddingLeft: 0 }} backdrop="static">
                    <Modal.Header closeButton>
                        <Button
                            onClick={(e) => { e.preventDefault(); this.printSelectedSchedules(); }}
                            bsStyle="default"
                            style={{ marginRight: 5 }}
                            icon>
                            <i className="fa fa-print" />
                        </Button>
                        Harmonogram
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col sm={6}>
                                <Card
                                    content={
                                        <>
                                            <Row>
                                                <Col sm={12}>
                                                    <ControlLabel bsClass="text-left">Skaner</ControlLabel>
                                                    <FormControl
                                                        type="text"
                                                        onChange={(e) => { this.onPopupScannerInput(e) }}
                                                        onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.addCodeToList('scannedPackages', this.state.popupScannerInput) } }}
                                                        value={this.state.popupScannerInput}
                                                        placeholder="Skanuj kod, lub wpisz i zatwierdź klawiszem [Enter]"
                                                        autoComplete="off"
                                                        name="code"
                                                    />
                                                </Col>
                                                <Col sm={12}>
                                                    <Table style={{ marginTop: 10 }}>
                                                        <thead>
                                                            <tr>
                                                                <th>Wprowadzony kod</th>
                                                                <th style={{ width: 80 }}>Opcje</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.scannedPackages.length
                                                                    ?
                                                                    this.state.scannedPackages.map((elem, index) => {
                                                                        return (
                                                                            <tr key={index}>
                                                                                <td>{elem}</td>
                                                                                <td>
                                                                                    <Button
                                                                                        onClick={() => { this.removeCodeFromList('scannedPackages', elem) }}
                                                                                        bsStyle="default"
                                                                                        simple
                                                                                        icon>
                                                                                        <i className="fa fa-times" />
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    (
                                                                        <tr>
                                                                            <td colSpan="2" style={{ textAlign: "center" }}>
                                                                                Lista jest pusta.
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6} lg={4}>
                                                    <Button style={{ marginBottom: 10, padding: 10 }} bsStyle="info" bsSize="large" block disabled={this.state.scannedPackages.length === 0 || this.state.isSaving} onClick={() => { this.handleScannedPackages('collect') }}>
                                                        <Glyphicon glyph="arrow-down" /> Przyjmij
                                                    </Button>
                                                </Col>
                                                <Col md={6} lg={4}>
                                                    <Button style={{ marginBottom: 10, padding: 10 }} bsStyle="success" bsSize="large" block disabled={this.state.scannedPackages.length === 0 || this.state.isSaving} onClick={() => { this.handleScannedPackages('release') }}>
                                                        <Glyphicon glyph="arrow-up" /> Wydaj
                                                    </Button>
                                                </Col>
                                                <Col md={12} lg={4}>
                                                    <Button style={{ marginBottom: 10, padding: 10 }} bsStyle="danger" bsSize="large" block disabled={this.state.scannedPackages.length === 0 || this.state.isSaving} onClick={() => { this.handleScannedPackages('damage') }}>
                                                        <Glyphicon glyph="certificate" /> Uszkodzenie
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </>
                                    }
                                />
                            </Col>
                            <Col sm={6}>
                                <div className="mb-10">
                                    <Button bsStyle="link" onClick={() => { this.setState({ printAllPackagesIdents: !this.state.printAllPackagesIdents }); this.printPackagesIdentsToggle(null, !this.state.printAllPackagesIdents) }}>Zaznacz wszystkie</Button>
                                    <Button bsStyle="link" style={{ marginLeft: 10 }} onClick={() => { this.setState({ showElementsModal: true }) }}>Drukuj zaznaczone etykiety</Button>
                                </div>
                                <Card
                                    content={
                                        Object.keys(this.state.schedules).map(scheduleIdent => {
                                            const theSchedule = this.state.schedules[scheduleIdent];
                                            return (
                                                <Fragment key={scheduleIdent}>
                                                    <ControlLabel bsClass="text-left">{scheduleIdent || "/ BRAK NUMERU ENET /"}</ControlLabel>
                                                    <ListGroup>
                                                        {
                                                            theSchedule.orders.map(theOrder => {
                                                                return theOrder.elements.map(theElement => {
                                                                    return theElement.packages.map(thePackage => {
                                                                        return (
                                                                            <Fragment key={thePackage.ident}>
                                                                                <ListGroupItem>
                                                                                    <Row>
                                                                                        <Col sm={12} md={6}>
                                                                                            Zlecenie: {theOrder.ident}
                                                                                            <br />Nr wew.:{theOrder.identExternal}
                                                                                            <br />Towar: {theElement.name}
                                                                                            <br />Paczka: {thePackage.index} z {thePackage.total}
                                                                                            <br />Kod: {thePackage.ident}
                                                                                            <br />
                                                                                            <Checkbox
                                                                                                label="Drukuj"
                                                                                                number={thePackage.ident}
                                                                                                checked={this.state.printPackagesIdents.includes(thePackage.ident)}
                                                                                                onChange={event => { this.printPackagesIdentsToggle(thePackage.ident) }}
                                                                                            />
                                                                                        </Col>
                                                                                        <Col sm={12} md={6} style={{ textAlign: "right" }}>
                                                                                            <strong>{this.getPackageStatus(thePackage.ident)}</strong>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </ListGroupItem>
                                                                            </Fragment>
                                                                        )
                                                                    })
                                                                })
                                                            })
                                                        }
                                                    </ListGroup>
                                                </Fragment>
                                            )
                                        })
                                    }
                                />
                            </Col>
                        </Row>

                    </Modal.Body>
                </Modal>

                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Skaner
                                        </span>
                                    </div>
                                }
                                content={
                                    <>
                                        <Row>
                                            <Col sm={12}>
                                                <Select
                                                    className="react-select primary"
                                                    classNamePrefix="react-select"
                                                    value={this.state.myWarehouseUuid ? this.state.myWarehouses.find(itm => itm.value === this.state.myWarehouseUuid) : null}
                                                    placeholder="Wybierz magazyn.."
                                                    onChange={(value) => { this.setState({ isRefreshing: true, myWarehouseUuid: value ? value.value : null }, () => { this.setState({ isRefreshing: false }) }) }}
                                                    options={this.state.myWarehouses}
                                                    isClearable={true}
                                                />
                                            </Col>
                                        </Row>
                                        <Row style={{ marginTop: 10 }}>
                                            <Col sm={12}>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onMainScannerInput(e) }}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.addCodeToList('scannedSchedules', this.state.mainScannerInput) } }}
                                                    value={this.state.mainScannerInput}
                                                    placeholder="Skanuj kod, lub wpisz i zatwierdź klawiszem [Enter]"
                                                    autoComplete="off"
                                                    name="code"
                                                />
                                                <Checkbox
                                                    label="Skanuj z dźwiękiem"
                                                    number={`beeper`}
                                                    checked={this.state.beeper}
                                                    onChange={event => {
                                                        this.setState({ beeper: event.target.checked });
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={12}>
                                                <Table style={{ marginTop: 10 }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Wprowadzony kod</th>
                                                            <th style={{ width: 80 }}>Opcje</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.scannedSchedules.length
                                                                ?
                                                                this.state.scannedSchedules.map((elem, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{elem}</td>
                                                                            <td>
                                                                                <Button
                                                                                    onClick={() => { this.removeCodeFromList('scannedSchedules', elem) }}
                                                                                    bsStyle="default"
                                                                                    simple
                                                                                    icon>
                                                                                    <i className="fa fa-times" />
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                (
                                                                    <tr>
                                                                        <td colSpan="2" style={{ textAlign: "center" }}>
                                                                            Lista jest pusta.
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>
                                                <Button style={{ marginBottom: 10 }} bsStyle="info" bsSize="large" block disabled={!this.state.myWarehouseUuid || this.state.scannedSchedules.length === 0} onClick={() => { this.handleScannedSchedules() }}>
                                                    Przetwórz harmonogram
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                    {
                        this.state.myWarehouseUuid && (
                            <Row>
                                <Col md={12}>
                                    <Card
                                        title={
                                            <>
                                                <span>
                                                    Lista
                                                </span>
                                                <Button bsStyle="default" style={{ marginLeft: 10 }} pullRight round onClick={() => { this.getReport() }} disabled={this.state.excelIsGenerating}>
                                                    <span className="btn-label">
                                                        <i className="fa fa-download" /> Pobierz .xlsx (RB)
                                                    </span>
                                                </Button>
                                            </>
                                        }
                                        content={
                                            <div>
                                                {(!this.state.isRefreshing) ?
                                                    (
                                                        <Datatable
                                                            data={this.state.data}
                                                            pages={this.state.pages}
                                                            loading={this.state.loading}
                                                            manual
                                                            filterable
                                                            columns={[
                                                                {
                                                                    Header: "Harmo. ENET",
                                                                    accessor: "transferIdent",
                                                                },
                                                                {
                                                                    Header: "Harmo. LAST MILE",
                                                                    accessor: "scheduleIdent",
                                                                },
                                                                {
                                                                    Header: "Aktualny magazyn",
                                                                    accessor: "currentWarehouseName"
                                                                },
                                                                {
                                                                    Header: "Następny magazyn",
                                                                    accessor: "nextWarehouseName"
                                                                },
                                                                {
                                                                    Header: "Zamówienie",
                                                                    accessor: "orderIdent"
                                                                },
                                                                {
                                                                    Header: "Dodane",
                                                                    accessor: "transferCreated"
                                                                },
                                                                {
                                                                    Header: "Dostawa",
                                                                    accessor: "transferDeliveryDayIdent",
                                                                    showInSummary: true,
                                                                },
                                                                {
                                                                    Header: "Przyjęcie",
                                                                    accessor: "transferCollected"
                                                                },
                                                                {
                                                                    Header: "Wydanie",
                                                                    accessor: "transferReleased"
                                                                },
                                                                {
                                                                    Header: "Status",
                                                                    accessor: "statusName"
                                                                },
                                                                {
                                                                    Header: "",
                                                                    accessor: "actions",
                                                                    sortable: false,
                                                                    filterable: false,
                                                                    width: 20
                                                                }
                                                            ]}
                                                            defaultSorted={[
                                                                {
                                                                    id: "orderIdent",
                                                                    desc: true
                                                                }
                                                            ]}
                                                            onFetchData={(state, instance) => {
                                                                let self = this;
                                                                self.setState({ loading: true });
                                                                _api.request("/admin/transfer/list-warehouse-elements", {
                                                                    page: state.page,
                                                                    pageSize: state.pageSize,
                                                                    sorted: state.sorted,
                                                                    filtered: state.filtered,
                                                                    warehouseUuid: this.state.myWarehouseUuid
                                                                }, (res) => {
                                                                    self.setState({
                                                                        data: res.rows.map((prop) => {
                                                                            if (!prop.hasAutoBadge) {
                                                                                prop.statusName = <ExpertStatusBadge status={prop.statusName} />
                                                                                prop.hasAutoBadge = true;
                                                                            }
                                                                            if (!prop.hasScheduleButton) {
                                                                                const ti = prop.transferIdent;
                                                                                prop.transferIdent = (
                                                                                    <button style={{ border: 0, padding: 0, textAlign: 'left', textDecoration: 'underline', background: 'transparent' }} onClick={() => this.loadSchedule([ti])}>{ti}</button>
                                                                                )
                                                                                const si = prop.scheduleIdent;
                                                                                prop.scheduleIdent = (
                                                                                    <button style={{ border: 0, padding: 0, textAlign: 'left', textDecoration: 'underline', background: 'transparent' }} onClick={() => this.loadSchedule([si])}>{si}</button>
                                                                                )
                                                                                prop.hasScheduleButton = true;
                                                                            }
                                                                            return prop;
                                                                        }),
                                                                        pages: res.pages,
                                                                        loading: false
                                                                    });
                                                                }, (error) => {
                                                                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                                    self.setState({ loading: false });
                                                                }, (progress) => {
                                                                    self.props.setProgress(progress);
                                                                });
                                                            }}
                                                            defaultPageSize={10}
                                                            showPaginationBottom
                                                            className="-striped -highlight"
                                                            previousText="Poprzednia"
                                                            nextText="Następna"
                                                            noDataText="Brak danych"
                                                            loadingText="Pobieranie danych"
                                                            pageText="Strona"
                                                            ofText=" z "
                                                            rowsText="wierszy"
                                                        />
                                                    )
                                                    :
                                                    loader
                                                }
                                            </div>
                                        }
                                    />
                                </Col>
                            </Row>
                        )
                    }
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminHubWarehouse;
