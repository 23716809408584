import { Component } from "react";

class BaseComponent extends Component {

    componentDidMount() {
        if (this.props.title && this.props.setNavTitle) {
            this.props.setNavTitle(this.props.title);
        }
    }

    getFieldEventValue(e, explicit) {
        return explicit ? e : e.target.value;
    }

    onChange(field, e, explicit) {
        let value = this.getFieldEventValue(e, explicit);
        this.setState({ [field]: value });
    }

    onChangeFloat(field, e, explicit) {
        let value = this.getFieldEventValue(e, explicit).replace(',', '.');
        this.setState({ [field]: value });
    }

    parseFloatFixed(value) {
        if (isNaN(parseFloat(value))) {
            return 0;
        }
        return parseFloat(value);
    }

}

export default BaseComponent;