/* global _api */
import React, { Component } from "react";
import Datetime from "react-datetime";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import moment from "moment";
import "moment/min/locales";
import Select from "react-select";
import Card from "../../../components/Card/Card.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import ExpertClientOrder from "../Client/Order";
import MarkerMap from "../../../components/Map/MarkerMap.jsx";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SweetAlert from "react-bootstrap-sweetalert";
import ScheduleComponent from "../../../components/Expert/ScheduleComponent.jsx";
import OrderPrinterComponent from "../../../components/Expert/OrderPrinterComponent.jsx";
import {
    Grid,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    FormGroup,
    Modal,
    ControlLabel
} from "react-bootstrap";
import Datatable from "../../../components/Datatable";

moment.locale('pl');

class ExpertAdminSchedule extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataTableShow: false,
            dataTable: [],
            pagesTable: -1,
            pageTable: 0,
            loadingTable: true,
            alert: null,
            department: null,
            departmentLoading: true,
            departments: [],
            selectedDate: moment().format("YYYY-MM-DD"),
            ordersAll: [],
            ordersLoading: true,
            selectedScheduleName: null,
            selectedScheduleUuid: null,
            showScheduleModal: false,
            showPrinterModal: false,
            orderUuid: null
        };
        this.refMap = React.createRef();
    }

    componentDidMount() {
        let self = this;

        let scheduleData = self.props.location.pathname.replace("/admin/schedule/", "").split('/');
        let departmentGuid = null;
        let selectedDate = moment().format("YYYY-MM-DD");

        if (scheduleData.length >= 2) {
            departmentGuid = scheduleData[0];
            if (!_api.isGuid(departmentGuid)) {
                departmentGuid = null;
            }
            if (moment(scheduleData[1]).isValid()) {
                selectedDate = moment(scheduleData[1]).format("YYYY-MM-DD");
            }
        }

        if (!self.props.userHasRole('admin')) {
            departmentGuid = self.props.user.user.department.uuid;
        }

        _api.request(`/admin/general/departments`, {},
            (data) => {
                let items = [];
                let selected = null;
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name });
                    if (departmentGuid && data[i].uuid === departmentGuid) {
                        selected = items[i];
                    }
                }
                if (items.length) {
                    if (!self.state.department) {
                        if (selected) {
                            self.setDepartment(selected, items, selectedDate);
                        } else {
                            self.setDepartment(items[0], items, selectedDate);
                        }
                    }
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
    }

    setDepartment(currentDepartment, allDepartments, selectedDate) {
        let self = this;

        let callback = () => {
            self.setDate(selectedDate);
        }

        if (allDepartments) {
            self.setState({ department: currentDepartment, departments: allDepartments, departmentLoading: true }, callback);
        } else {
            self.setState({ department: currentDepartment, departmentLoading: true }, callback);
        }

    }

    setDate(date) {
        let self = this;

        let callback = () => {
            self.props.history.push('/admin/schedule/' + self.state.department.value + '/' + self.state.selectedDate);
            self.getOrders();
        }

        self.setState({ selectedDate: moment(date).format("YYYY-MM-DD"), dataTableShow: false }, callback)
    }

    getOrders() {
        let self = this;
        let fromDate = self.state.selectedDate;
        self.setState({ dataTableShow: true, ordersLoading: true, ordersAll: [], ordersUnassigned: [], ordersAssigned: [] });

        _api.request(`/admin/slot/details/${self.state.department.value}/${fromDate}`, {},
            (data) => {

                if (!data) {
                    data = {
                        'slot': [],
                        'order': []
                    };
                }

                let ordersAll = data.order;

                let colours = this.getRainbow(ordersAll.length);
                ordersAll.map((item, index) => {
                    let background = colours[index].background;
                    let foreground = colours[index].foreground;
                    item.background = background;
                    item.sender.address.background = background;
                    item.recipient.address.background = background;
                    item.sender.address.foreground = foreground;
                    item.recipient.address.foreground = foreground;
                    return item;
                });

                self.setState({ ordersAll: ordersAll });

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    saveOrdersInOrder() {
        let self = this;
        self.setState({ dataTableShow: false });
        const filteredOrders = this.state.ordersAll.map((order, index) => {
            return {
                uuid: order.uuid,
                position: order.position,
                schedule: {
                    assigned: order.schedule.assigned,
                    uuid: order.schedule.uuid
                }
            };
        });
        _api.request(`/admin/schedule/orders`,
            {
                orders: filteredOrders
            },
            (data) => {
                self.props.showNoty('tr', 'info', 'Zmiany zostały zapisane');
                window.scrollTo(0, self.refMap.current.parentElement.offsetTop);
                self.setState({ dataTableShow: true });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    showOrderModal(orderUuid) {
        this.setState({ showOrderModal: true, orderUuid: orderUuid });
    }

    hideOrderModal() {
        this.setState({ showOrderModal: false, orderUuid: null });
    }

    refreshCurrentView() {
        this.setState({ dataTableShow: false }, () => { this.getOrders(); });
    }

    onOrderSaved() {
        this.refreshCurrentView();
    }

    onOrderSavedAndHide() {
        this.refreshCurrentView();
        this.hideOrderModal();
    }

    getOrderStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: `8px`,
        margin: `0 0 8px 0`,
        border: `1px solid #e3e3e3`,
        backgroundColor: isDragging ? '#6addf4' : 'white',
        borderColor: isDragging ? '#6addf4' : '#e3e3e3',
        color: isDragging ? '#fff' : '#333',
        transition: `background-color 0.5s ease-out`,
        ...draggableStyle
    });

    getOrdersListStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? '#e3e3e3' : 'white',
        transition: `background-color 0.3s ease-out`,
    });

    onDragEnd(result) {
        const selectedScheduleUuid = this.state.selectedScheduleUuid;
        const selectedScheduleName = this.state.selectedScheduleName;
        const { source, destination } = result;
        if (!destination || !source) {
            return;
        }
        const uuid = result.draggableId;
        const isAssigned = destination.droppableId === "ordersAssigned" ? true : false;
        const newOrders = this.state.ordersAll.map((order, index) => {
            if (order.uuid !== uuid) return order;
            let updatedOrder = { ...order };
            updatedOrder.position = destination.index;
            updatedOrder.schedule.assigned = isAssigned;
            updatedOrder.schedule.uuid = isAssigned ? selectedScheduleUuid : null;
            updatedOrder.schedule.name = isAssigned ? selectedScheduleName : null;
            return updatedOrder;
        });
        this.setState({ ordersAll: newOrders }, () => { this.saveOrdersInOrder() });
    }

    sinToHex = (i, phase, size) => {
        let sin = Math.sin(Math.PI / size * 2 * i + phase);
        let int = Math.floor(sin * 127) + 128;
        let hex = int.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    getRainbow(size) {
        let rainbow = new Array(size);
        for (let i = 0; i < size; i++) {
            let red = this.sinToHex(i, 0 * Math.PI * 2 / 3, size); // 0   deg
            let blue = this.sinToHex(i, 1 * Math.PI * 2 / 3, size); // 120 deg
            let green = this.sinToHex(i, 2 * Math.PI * 2 / 3, size); // 240 deg
            let luma = ((0.299 * parseInt(red, 16)) + (0.587 * parseInt(green, 16)) + (0.114 * parseInt(blue, 16))) / 255;
            let background = "#" + red + green + blue;
            // Return black for bright colors, white for dark colors
            let foreground = luma > 0.5 ? '#000' : '#fff';
            rainbow[i] = {
                background: background,
                foreground: foreground
            }
        }
        return rainbow;
    }

    popupScheduleSaved() {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Harmonogram został zapisany"
                    confirmBtnText="Zamknij"
                    onConfirm={() => { this.setState({ alert: null, showScheduleModal: false, dataTableShow: true }); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            ),
            dataTableShow: false
        });
    }

    popupScheduleError() {
        this.setState({
            alert: (
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Wystąpił błąd podczas zapisu danych"
                    confirmBtnText="Zamknij"
                    confirmBtnBsStyle="default"
                    onConfirm={() => this.setState({ alert: null })}>
                </SweetAlert>
            )
        });
    }

    moveToTrash(uuid) {
        let self = this;
        self.setState({ dataTableShow: false }, () => {
            _api.request(`/admin/trash/recursive/schedule/${uuid}`, {},
                (data) => {
                    self.props.showNoty('tr', 'info', 'Harmonogram został usunięty');
                    self.setState({ dataTableShow: true });
                    self.getOrders();
                },
                (error) => {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
                },
                (progress) => {
                    self.props.setProgress(progress);
                }
            );
        }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń element"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    showScheduleModal(scheduleUuid) {
        this.setState({ showScheduleModal: true, selectedScheduleUuid: scheduleUuid });
    }

    hideScheduleModal() {
        this.setState({ showScheduleModal: false });
    }

    showPrinterModal(scheduleUuid) {
        this.setState({ showPrinterModal: true, selectedScheduleUuid: scheduleUuid });
    }

    hidePrinterModal() {
        this.setState({ showPrinterModal: false });
    }

    showScheduleMap(scheduleName, scheduleUuid) {
        this.setState({
            selectedScheduleName: scheduleName,
            selectedScheduleUuid: scheduleUuid,
        });
        window.scrollTo(0, this.refMap.current.parentElement.offsetTop);
    }

    render() {

        const oEdit = <Tooltip id="oEdit">Edycja harmonogramu</Tooltip>;
        const oMap = <Tooltip id="oMap">Przypisz zlecenia</Tooltip>;
        const oPrint = <Tooltip id="oPrint">Wydrukuj zlecenia</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;

        const loader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const modal = (
            <Modal show={this.state.showOrderModal} onHide={this.hideOrderModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja zamówienia</Modal.Header>
                <Modal.Body>
                    <ExpertClientOrder
                        {...this.props}
                        uuid={this.state.orderUuid}
                        selectedDay={this.state.slotsDate ? moment(this.state.slotsDate).subtract('1', 'day') : null}
                        includeUnavailableSlots={true}
                        nextDaySpan={14}
                        isAdminView={true}
                        department={this.state.department ? this.state.department.value : null}
                        onSaved={this.onOrderSaved.bind(this)}
                        onSavedAndHide={this.onOrderSavedAndHide.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const scheduleModal = (
            <Modal show={this.state.showScheduleModal} onHide={this.hideScheduleModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja harmonogramu</Modal.Header>
                <Modal.Body>
                    <ScheduleComponent
                        uuid={this.state.selectedScheduleUuid}
                        department={this.state.department ? this.state.department.value : null}
                        date={this.state.selectedDate}
                        showNoty={this.props.showNoty}
                        setProgress={this.props.setProgress}
                        onSaved={this.popupScheduleSaved.bind(this)}
                        onError={this.popupScheduleError.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const printerModal = (
            <Modal bsSize="small" show={this.state.showPrinterModal} onHide={this.hidePrinterModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Wydruk harmonogramu</Modal.Header>
                <Modal.Body>
                    <OrderPrinterComponent
                        schedule={this.state.selectedScheduleUuid}
                        showNoty={this.props.showNoty}
                        setProgress={this.props.setProgress}
                        user={this.props.user}
                    />
                </Modal.Body>
            </Modal>
        )

        const placeholderSelectFleet = (
            <p className="lead text-muted" style={{ border: `1px dashed #e3e3d3`, textAlign: `center`, padding: `8px`, lineHeight: `48px`, margin: 0 }}>
                wybierz harmonogram z górnej tabelki lub
                <Button bsStyle="default" round wd style={{ marginLeft: "5px" }} onClick={() => { this.showScheduleModal(null) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    stwórz nowy
                </Button>
            </p>
        )

        const placeholderFleet = (<p className="lead text-muted" style={{ border: `1px dashed #e3e3d3`, textAlign: `center`, padding: `8px`, lineHeight: `48px`, margin: 0 }}>przeciągnij zlecenia</p>)
        const placeholderDay = (<p className="lead text-muted" style={{ border: `1px dashed #e3e3d3`, textAlign: `center`, padding: `8px`, lineHeight: `48px`, margin: 0 }}>wszystko gotowe :)</p>)

        let ordersAssigned = [];
        let markersAssigned = [];
        let ordersUnassigned = [];
        let markersUnassigned = [];

        this.state.ordersAll.sort((a, b) => (a.position > b.position) ? 1 : -1).map((item, index) => {
            if (item.schedule.assigned) {
                if (item.schedule.uuid === this.state.selectedScheduleUuid) {
                    ordersAssigned.push(item);
                    // markersAssigned.push(item.sender.address);
                    markersAssigned.push(item.recipient.address);
                }
            } else {
                ordersUnassigned.push(item);
                // markersUnassigned.push(item.sender.address);
                markersUnassigned.push(item.recipient.address);
            }
            return item;
        });

        return (
            <div className="main-content">
                {modal}
                {scheduleModal}
                {printerModal}
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<div>
                                    Harmonogram
                                    <Button bsStyle="default" pullRight round onClick={() => { this.showScheduleModal(null) }}>
                                        <span className="btn-label">
                                            <i className="fa fa-plus" /> utwórz
                                        </span>
                                    </Button>
                                </div>}
                                content={
                                    <div>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Wybrany oddział: </ControlLabel>
                                                    <Col>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            name="payer"
                                                            value={this.state.department}
                                                            placeholder="Wybierz oddział"
                                                            onChange={value =>
                                                                this.setDepartment(value)
                                                            }
                                                            options={this.state.departments}
                                                            isDisabled={!this.props.userHasRole('admin')}
                                                        />
                                                    </Col>
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Wybrana data: </ControlLabel>
                                                    <Datetime
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: "Wybierz datę" }}
                                                        value={moment(this.state.selectedDate)}
                                                        defaultValue={moment(this.state.selectedDate)}
                                                        onChange={value =>
                                                            this.setDate(value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {this.state.dataTableShow ? (
                                            <Datatable
                                                data={this.state.dataTable}
                                                pages={this.state.pagesTable}
                                                loading={this.state.loadingTable}
                                                manual
                                                filterable
                                                columns={[
                                                    {
                                                        Header: "Identyfikator",
                                                        accessor: "ident"
                                                    },
                                                    {
                                                        Header: "Flota",
                                                        accessor: "fleetName"
                                                    },
                                                    {
                                                        Header: "Onboarding",
                                                        accessor: "onboardingDate"
                                                    },
                                                    {
                                                        Header: "Ilość zleceń",
                                                        accessor: "numberOfOrders"
                                                    },
                                                    {
                                                        Header: "Ilość kilometrów",
                                                        accessor: "orderDistance"
                                                    },
                                                    {
                                                        Header: "Opcje",
                                                        accessor: "actions",
                                                        sortable: false,
                                                        filterable: false,
                                                        width: 150
                                                    }
                                                ]}
                                                defaultSorted={[
                                                    {
                                                        id: "ident",
                                                        desc: true
                                                    }
                                                ]}
                                                onFetchData={(state, instance) => {
                                                    let self = this;
                                                    self.setState({ loadingTable: true });
                                                    _api.request(`/schedule/list/${self.state.department.value}/${self.state.selectedDate}/${self.state.selectedDate}`, {
                                                        page: state.page,
                                                        pageSize: state.pageSize,
                                                        sorted: state.sorted,
                                                        filtered: state.filtered
                                                    }, (res) => {
                                                        self.setState({
                                                            dataTable: res.rows.map((prop, key) => {
                                                                let identString = prop.ident;
                                                                prop.ident = (
                                                                    <Button style={{ padding: 0 }} onClick={() => { this.showScheduleMap(identString, prop.uuid) }} bsStyle="info" simple>{identString}</Button>
                                                                );
                                                                prop.actions = (
                                                                    <div className="actions-right">
                                                                        <OverlayTrigger placement="top" overlay={oEdit}>
                                                                            <Button
                                                                                onClick={() => { this.showScheduleModal(prop.uuid); this.showScheduleMap(identString, prop.uuid) }}
                                                                                bsStyle="default"
                                                                                simple
                                                                                icon>
                                                                                <i className="fa fa-edit" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement="top" overlay={oPrint}>
                                                                            <Button
                                                                                onClick={() => { this.showPrinterModal(prop.uuid); this.showScheduleMap(identString, prop.uuid) }}
                                                                                bsStyle="default"
                                                                                simple
                                                                                icon>
                                                                                <i className="fa fa-print" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement="top" overlay={oMap}>
                                                                            <Button
                                                                                onClick={() => { this.showScheduleMap(identString, prop.uuid) }}
                                                                                bsStyle="default"
                                                                                simple
                                                                                icon>
                                                                                <i className="fa fa-map" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                        <OverlayTrigger placement="top" overlay={oTrash}>
                                                                            <Button
                                                                                onClick={() => { this.popupTrash(prop.uuid) }}
                                                                                bsStyle="danger"
                                                                                simple
                                                                                icon>
                                                                                <i className="fa fa-times" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                )
                                                                return prop;
                                                            }),
                                                            pageTable: res.page,
                                                            pagesTable: res.pages,
                                                            pageSizeTable: res.pageSize,
                                                            sortedTable: res.sorted,
                                                            filteredTable: res.filtered,
                                                            loadingTable: false
                                                        });
                                                    }, (error) => {
                                                        self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                        self.setState({ loadingTable: false });
                                                    }, (progress) => {
                                                        self.props.setProgress(progress);
                                                    });
                                                }}
                                                defaultPageSize={5}
                                                showPaginationBottom
                                                className="-striped -highlight"
                                                previousText="Poprzednia"
                                                nextText="Następna"
                                                noDataText="Brak danych"
                                                loadingText="Pobieranie danych"
                                                pageText="Strona"
                                                ofText=" z "
                                                rowsText="wierszy"
                                            />
                                        ) : (
                                                <>{loader}</>
                                            )
                                        }
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <DragDropContext onDragEnd={this.onDragEnd.bind(this)}>
                            <Col md={6}>
                                <h4 className="title" ref={this.refMap}>
                                    Harmonogram {this.state.selectedScheduleName}
                                </h4>
                                <div style={{ marginBottom: `30px` }}>
                                    <MarkerMap
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `280px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        defaultCenter={{ lat: parseFloat(this.props.user.user.company.address.geoLat) || 52.237049, lng: parseFloat(this.props.user.user.company.address.geoLng) || 21.017532 }}
                                        markers={markersAssigned}
                                    />
                                </div>
                                <Card
                                    content={
                                        this.state.selectedScheduleName ?
                                            (
                                                <Droppable droppableId="ordersAssigned">
                                                    {(provided, snapshot) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            style={this.getOrdersListStyle(snapshot.isDraggingOver)}>
                                                            {ordersAssigned.map((item, index) => (
                                                                <Draggable
                                                                    key={item.uuid}
                                                                    draggableId={item.uuid}
                                                                    index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            style={this.getOrderStyle(
                                                                                snapshot.isDragging,
                                                                                provided.draggableProps.style
                                                                            )}>
                                                                            <p className={item.trash ? "trash-button" : ""}><i className="fa fa-circle" style={{ color: item.background }} /> <Button style={{ padding: 0 }} onClick={() => { this.showOrderModal(item.uuid) }} bsStyle="info" simple>Zlecenie {item.ident}</Button> - {item.sender.name}</p>
                                                                            <p><i className="fa fa-clock-o" /> {item.dayPart}</p>
                                                                            <p><i className="fa fa-dot-circle-o" /> {item.sender.address.streetAddress}</p>
                                                                            <p><i className="fa fa-check-circle-o" /> {item.recipient.address.streetAddress} {item.recipient.address.streetNumber}, {item.recipient.address.cityName}</p>
                                                                            <p><i className="fa fa-info-circle" /> {item.comment || '---'}</p>
                                                                            <p><i className="fa fa-wrench" /> {item.service ? item.service.map((service) => { return service.name }).join(", ") : '---'} </p>
                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            ))}
                                                            {provided.placeholder}
                                                            {ordersAssigned.length === 0 && placeholderFleet}
                                                        </div>
                                                    )}
                                                </Droppable>
                                            ) : (
                                                <div>{placeholderSelectFleet}</div>
                                            )
                                    }
                                />
                            </Col>
                            <Col md={6}>
                                <h4 className="title">
                                    Zlecenia z dnia {this.state.selectedDate}
                                </h4>
                                <div style={{ marginBottom: `30px` }}>
                                    <MarkerMap
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `280px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                        defaultCenter={{ lat: parseFloat(this.props.user.user.company.address.geoLat) || 52.237049, lng: parseFloat(this.props.user.user.company.address.geoLng) || 21.017532 }}
                                        markers={markersUnassigned}
                                    />
                                </div>
                                <Card
                                    content={
                                        <Droppable droppableId="ordersUnassigned">
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    style={this.getOrdersListStyle(snapshot.isDraggingOver)}>
                                                    {ordersUnassigned.map((item, index) => (
                                                        <Draggable
                                                            key={item.uuid}
                                                            draggableId={item.uuid}
                                                            index={index}>
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    style={this.getOrderStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}>
                                                                    <p className={item.trash ? "trash-button" : ""}><i className="fa fa-circle" style={{ color: item.background }} /> <Button style={{ padding: 0 }} onClick={() => { this.showOrderModal(item.uuid) }} bsStyle="info" simple>Zlecenie {item.ident}</Button> - {item.sender.name}</p>
                                                                    <p><i className="fa fa-clock-o" /> {item.dayPart}</p>
                                                                    <p><i className="fa fa-dot-circle-o" /> {item.sender.address.streetAddress}</p>
                                                                    <p><i className="fa fa-check-circle-o" /> {item.recipient.address.streetAddress} {item.recipient.address.streetNumber}, {item.recipient.address.cityName}</p>
                                                                    <p><i className="fa fa-info-circle" /> {item.comment || '---'}</p>
                                                                    <p><i className="fa fa-wrench" /> {item.service ? item.service.map((service) => { return service.name }).join(", ") : '---'} </p>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}
                                                    {provided.placeholder}
                                                    {ordersUnassigned.length === 0 && placeholderDay}
                                                </div>
                                            )}
                                        </Droppable>
                                    }
                                />
                            </Col>
                        </DragDropContext>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminSchedule;
