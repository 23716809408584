/* global _api */
import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import NotificationSystem from "react-notification-system";
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import AdminNavbar from "../components/Navbars/AdminNavbar.jsx";
import Footer from "../components/Footer/Footer.jsx";
import LoadingBar from 'react-top-loading-bar';
import image from "../assets/img/expert/cover-sidebar.jpg";
import routes from "../routes.js";
import { style } from "../variables/Variables.jsx";

class Panel extends Component {


    constructor(props) {
        super(props);
        this.sessionTimer = undefined;
        this.state = {
            _notificationSystem: null,
            image: image,
            color: "black",
            hasImage: true,
            navbar: false,
            mini: false,
            fixedClasses: "dropdown show-dropdown open",
            sessionChecker: true,
            loadingBarProgress: 0,
            userSet: false,
            navVisible: true,
            navTitle: "",
            user: {
                "token": {
                    "uuid": null,
                    "expires": {
                        "date": null,
                        "timezone_type": null,
                        "timezone": null
                    }
                },
                "user": {
                    "uuid": null,
                    "role": null,
                    "firstname": null,
                    "lastname": null,
                    "email": null,
                    "releaseElements": null,
                    "mobilephone": null,
                    "updated": {
                        "date": null,
                        "timezone_type": null,
                        "timezone": null
                    }
                }
            }
        };
        this.onSetUrl = this.onSetUrl.bind(this);
    }

    onSetUrl(e) {
        if (e.detail.url) {
            this.props.history.push(e.detail.url);
        }
    }

    componentDidMount() {
        this.setState({ _notificationSystem: this.refs.notificationSystem, sessionChecker: true });
        this.checkSession(0);
        window.addEventListener("SET_URL", this.onSetUrl, false);
    }

    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
        }
        if (
            window.innerWidth < 993 &&
            e.history.action === "PUSH" &&
            document.documentElement.className.indexOf("nav-open") !== -1
        ) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    componentWillMount() {
        if (document.documentElement.className.indexOf("nav-open") !== -1) {
            document.documentElement.classList.toggle("nav-open");
        }
    }

    componentWillUnmount() {
        clearTimeout(this.sessionTimer);
        this.setState({ sessionChecker: false });
        window.removeEventListener("SET_URL", this.onSetUrl, false);
    }

    checkSession(tOut) {
        let self = this;
        self.sessionTimer = setTimeout(
            function () {
                _api.request("/user/session", {},
                    (data) => {
                        if (self.state.sessionChecker) {
                            if (!self.state.userSet || (data.user.updated && (data.user.updated.date !== self.state.user.user.updated.date))) {
                                self.setState({ user: data, userSet: true, loadingBarProgress: 100 });
                            }
                            self.checkSession(30 * 30 * 1000);
                        }
                    },
                    (error) => {
                        if (self.state.sessionChecker) {
                            _api.sessionClear();
                            self.props.history.push('/auth/login');
                            setTimeout(() => {
                                window.location.reload()
                            }, 500);
                        }
                    }
                );
            },
            tOut
        );
    }

    showNoty = (position, level, content) => {
        this.state._notificationSystem.addNotification({
            title: <span data-notify="icon" className="pe-7s-info" />,
            message: (<div>{content}</div>),
            level: level,
            position: position,
            autoDismiss: 10
        });
    };

    setProgress = (value) => {
        this.setState({ loadingBarProgress: value });
    }

    userHasRole = (value) => {
        if (Array.isArray(value)) {
            return value.includes(this.state.user.user.role);
        }
        return this.state.user.user.role === value;
    }

    setNavTitle = (value) => {
        this.setState({ navTitle: value });
    }

    setNavVisible = (value) => {
        this.setState({ navVisible: value });
    }

    getNavTitle = () => {
        return this.state.navTitle;
    }

    handleMiniClick = () => {
        this.setState({ mini: !this.state.mini });
        document.body.classList.toggle("sidebar-mini");
        window.dispatchEvent(new CustomEvent('SIDEBAR_TOGGLE', { 'detail': {} }), true);
    };

    forceMiniSidebar = () => {
        this.setState({ mini: true });
        if (!document.body.classList.contains("sidebar-mini")) {
            document.body.classList.add("sidebar-mini");
        }
        window.dispatchEvent(new CustomEvent('SIDEBAR_TOGGLE', { 'detail': {} }), true);
    };

    getRoutes = routes => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.roles && !this.userHasRole(prop.roles)) {
                return null;
            }
            return (
                <Route
                    path={prop.layout + prop.path}
                    key={key}
                    render={routeProps => (
                        <prop.component
                            {...routeProps}
                            showNoty={this.showNoty}
                            setProgress={this.setProgress}
                            setNavTitle={this.setNavTitle}
                            getNavTitle={this.getNavTitle}
                            setNavVisible={this.setNavVisible}
                            forceMiniSidebar={this.forceMiniSidebar}
                            user={this.state.user}
                            userHasRole={this.userHasRole}
                        />
                    )}
                />
            );
        });
    };

    render() {

        let loadingBar = (
            <div>
                <LoadingBar
                    progress={this.state.loadingBarProgress}
                    height={3}
                    color='#df9125'
                />
            </div>
        );

        let notySystem = (
            <NotificationSystem ref="notificationSystem" style={style} />
        );

        let content = (
            <div>
                <Sidebar
                    {...this.props}
                    image={this.state.image}
                    color={this.state.color}
                    hasImage={this.state.hasImage}
                    mini={this.state.mini}
                    user={this.state.user}
                    userHasRole={this.userHasRole}
                />
                <div
                    className="main-panel"
                    ref="mainPanel"
                >
                    {
                        this.state.navVisible ?
                            (
                                <AdminNavbar
                                    {...this.props}
                                    handleMiniClick={this.handleMiniClick}
                                    forceMiniSidebar={this.forceMiniSidebar}
                                    navbar={this.state.navbar}
                                    user={this.state.user}
                                    title={this.state.navTitle}
                                    userHasRole={this.userHasRole}
                                />
                            )
                            :
                            <></>
                    }
                    <Switch>{this.getRoutes(routes)}</Switch>
                    {this.state.navVisible && (<Footer fluid />)}
                </div>
            </div>
        );

        if (!this.state.user.user.uuid) {
            content = "";
        }

        return (
            <div className="wrapper">
                {loadingBar}
                {notySystem}
                {content}
            </div>
        );
    }
}

export default Panel;
