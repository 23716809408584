import React, { Component } from "react";
import Button from "../CustomButton/CustomButton.jsx";
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import ExpertUploadComponent from "./UploadComponent";
import {
    Table,
    FormControl,
    Modal,
    FormGroup,
    ControlLabel,
    Col,
    Row
} from "react-bootstrap";

class ExpertServicesListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            services: this.props.services || [],
            skills: this.props.skills || [],

            importShow: false,
            importFiles: [],
            importLoading: false,
            forceVisibe: false
        };
        this.servicePrototype = {
            "uuid": "",
            "name": "",
            "basePriceClient": "",
            "basePriceFleet": "",
            "basePriceReal": "",
            "points": "",
            "duration": "",

            "size": "",
            "weight": "",
            "autoRequired": false,
            "fallbackPriceReal": "",

            "skills": [],
            "trash": false
        };
    }

    handleAddService = (service) => {
        this.setState({ services: this.state.services.concat([service]) }, () => { this.props.onServicesChange(this.state.services) });
    };

    handleServiceChange = (idx, field, newValue) => evt => {
        const newServices = this.state.services.map((service, sidx) => {
            if (idx !== sidx) return service;
            let updatedService = { ...service };
            updatedService[field] = typeof newValue === "undefined" ? evt ? evt.value || evt.target.value : null : newValue;
            return updatedService;
        });
        this.setState({ services: newServices }, () => { this.props.onServicesChange(this.state.services) });
    };

    handleTrashService = idx => () => {
        const newServices = this.state.services.map((service, sidx) => {
            if (idx !== sidx) return service;
            return { ...service, trash: true };
        });
        this.setState({ services: newServices }, () => { this.props.onServicesChange(this.state.services) });
    };

    handleServiceHasSkill(serviceUuid, skillUuid, shouldHave) {
        const newServices = this.state.services.map((service) => {
            if (service.uuid !== serviceUuid) return service;
            let newSkills = service.skills || [];
            if (!shouldHave) {
                newSkills = newSkills.filter(function (skill) {
                    return skill.uuid !== skillUuid
                })
            } else {
                newSkills.push(skillUuid);
            }
            return { ...service, skills: newSkills };
        });
        this.setState({ services: newServices }, () => { this.props.onServicesChange(this.state.services) });
    }

    importSubmit(event) {
        event.preventDefault();
        let self = this;
        if (!self.state.importFiles.length) {
            self.props.showNoty('tr', 'error', 'Wybierz plik(i)');
            return;
        }
        self.setState({ importLoading: true });
        self.props.showNoty('tr', 'info', 'Wgrywanie pliku..');
        _api.request(`/admin/import/excel-to-json/services`,
            {
                "files": self.state.importFiles
            },
            (data) => {
                if (document.getElementById("upForm")) {
                    document.getElementById("upForm").reset();
                }
                self.props.showNoty('tr', 'success', 'Plik został zaimportowany');

                let newServices = self.state.services.map(service => { let newService = { ...service }; newService.trash = true; return newService }) || [];
                newServices = newServices.concat(data);
                self.setState({ importFiles: [], importLoading: false, importShow: false, services: newServices }, () => { this.props.onServicesChange(this.state.services) });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas odczytu pliku');
                self.setState({ importLoading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        let importFilesList = [];
        for (var i = 0; i < this.state.importFiles.length; i++) {
            importFilesList.push(<p key={"file" + i}><i className="fa fa-file" /> {this.state.importFiles[i].name}</p>);
        }

        const importModal = (
            <Modal show={this.state.importShow} onHide={() => { this.setState({ importShow: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Import</Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.importSubmit.bind(this)} id="upForm">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>Wybierz plik do importu: </ControlLabel>
                                    <ExpertUploadComponent onSelectedFiles={files => { this.setState({ importFiles: files }) }} />
                                </FormGroup>
                                <FormGroup>
                                    {importFilesList}
                                </FormGroup>
                                <Button type="submit" disabled={this.state.importLoading} className="pull-right btn-fill btn-wd btn-icon btn btn-success" onClick={!this.state.importLoading ? this.importSubmit.bind(this) : null}>
                                    <i className="fa fa-save" />  {this.state.importLoading ? 'Ładowanie…' : 'Wgraj plik'}
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        )

        return (
            <div>
                {importModal}
                <Button bsStyle="info" round onClick={() => { this.handleAddService(this.servicePrototype) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    dodaj usługę
                </Button>
                {` `}
                <Button bsStyle="info" round onClick={() => { this.setState({ importShow: true }) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    wczytaj usługi (zakresy kg i m3)
                </Button>
                {
                    this.state.services.length > 50 && !this.state.forceVisibe ?
                        (
                            <>
                                {` `}
                                <Button bsStyle="danger" round onClick={() => { this.setState({ forceVisibe: true }) }}>
                                    duża liczba danych. Załadować mimo to?
                                </Button>
                            </>
                        ) : (
                            <>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ borderRight: "1px solid #e3e3e3" }} colSpan={6}>Dane podstawowe</th>
                                            <th style={{ borderRight: "1px solid #e3e3e3" }} colSpan={4}>E-commerce</th>
                                            {
                                                this.state.skills.length > 0 && (
                                                    <th style={{ borderRight: "1px solid #e3e3e3" }} colSpan={this.state.skills.length}>Umiejętności</th>
                                                )
                                            }
                                            <th>Opcje</th>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: 200 }}>Nazwa</th>
                                            <th style={{ minWidth: 100 }}>Punkty</th>
                                            <th style={{ minWidth: 100 }}>Czas<br />(minuty)</th>
                                            <th style={{ minWidth: 100 }}>Cena<br />(Klient)</th>
                                            <th style={{ minWidth: 100 }}>Cena<br />(Flota)</th>
                                            <th style={{ minWidth: 100 }}>Cena<br />(Expert)</th>
                                            <th style={{ minWidth: 100 }}>Wybieraj automatycznie</th>
                                            <th style={{ minWidth: 100 }}>Zakres&nbsp;kg</th>
                                            <th style={{ minWidth: 100 }}>Zakres&nbsp;m3</th>
                                            <th style={{ minWidth: 100 }}>Cena<br />(bez wniesienia)</th>
                                            {this.state.skills.map(skill =>
                                                <th key={`h-${skill.uuid}`}>
                                                    {skill.name}
                                                </th>
                                            )}
                                            <th className="text-right">Opcje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!(this.state.services.length) ? <tr><td colSpan={9 + (this.state.skills ? this.state.skills.length : 0)} className="text-center text-muted">- brak danych -</td></tr> :
                                            this.state.services.map((service, idx) => {
                                                if (service.trash) {
                                                    return (<></>)
                                                }
                                                return (
                                                    <tr key={idx}>
                                                        <td>
                                                            <FormControl
                                                                type="text"
                                                                placeholder={`...`}
                                                                value={service.name || ""}
                                                                onChange={this.handleServiceChange(idx, 'name')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="1"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.points || ""}
                                                                onChange={this.handleServiceChange(idx, 'points')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="1"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.duration || ""}
                                                                onChange={this.handleServiceChange(idx, 'duration')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="any"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.basePriceClient || ""}
                                                                onChange={this.handleServiceChange(idx, 'basePriceClient')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="any"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.basePriceFleet || ""}
                                                                onChange={this.handleServiceChange(idx, 'basePriceFleet')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="any"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.basePriceReal || ""}
                                                                onChange={this.handleServiceChange(idx, 'basePriceReal')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Checkbox
                                                                number={`cb-${idx}-auto`}
                                                                checked={service.autoRequired || false}
                                                                onChange={this.handleServiceChange(idx, 'autoRequired', !service.autoRequired)}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="any"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.weight || ""}
                                                                onChange={this.handleServiceChange(idx, 'weight')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="any"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.size || ""}
                                                                onChange={this.handleServiceChange(idx, 'size')}
                                                            />
                                                        </td>
                                                        <td>
                                                            <FormControl
                                                                type="number"
                                                                step="any"
                                                                lang="en"
                                                                placeholder={`...`}
                                                                value={service.fallbackPriceReal || ""}
                                                                onChange={this.handleServiceChange(idx, 'fallbackPriceReal')}
                                                            />
                                                        </td>
                                                        {this.state.skills.map(skill =>
                                                            <td key={`td-${skill.uuid}-${service.uuid}`}>
                                                                <Checkbox
                                                                    number={`cb-${skill.uuid}-${service.uuid}`}
                                                                    checked={service.skills.includes(skill.uuid)}
                                                                    onChange={event => {
                                                                        this.handleServiceHasSkill(service.uuid, skill.uuid, event.target.checked);
                                                                    }}
                                                                />
                                                            </td>
                                                        )}
                                                        <td>
                                                            <Button
                                                                onClick={this.handleTrashService(idx)}
                                                                bsStyle="default"
                                                                simple
                                                                icon>
                                                                <i className="fa fa-times" />
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                </Table>
                            </>
                        )
                }
            </div>
        );
    }

}

export default ExpertServicesListComponent;