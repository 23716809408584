import React, { Component } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import "moment/min/locales";
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import _ from "lodash";
import {
    Table
} from "react-bootstrap";

moment.locale('pl');

class ExpertCutoffListComponent extends Component {

    constructor(props) {
        super(props);
        this.default = [
            {
                "day": 0,
                "dayName": "Sunday",
                "cutoff": "17:00",
                "availableForDelivery": false
            },
            {
                "day": 1,
                "dayName": "Monday",
                "cutoff": "17:00",
                "availableForDelivery": true
            },
            {
                "day": 2,
                "dayName": "Tuesday",
                "cutoff": "17:00",
                "availableForDelivery": true
            },
            {
                "day": 3,
                "dayName": "Wednesday",
                "cutoff": "17:00",
                "availableForDelivery": true
            },
            {
                "day": 4,
                "dayName": "Thursday",
                "cutoff": "17:00",
                "availableForDelivery": true
            },
            {
                "day": 5,
                "dayName": "Friday",
                "cutoff": "17:00",
                "availableForDelivery": true
            },
            {
                "day": 6,
                "dayName": "Saturday",
                "cutoff": "17:00",
                "availableForDelivery": true
            }
        ]
        this.state = {
            cutoff: _.unionBy(this.props.cutoff, this.default, "day") || this.default
        };

    }

    setHour(dayIndex, newValue) {
        let newCutoff = this.state.cutoff;
        newCutoff.map(itm => {
            if (itm.day === dayIndex) {
                itm.cutoff = moment(newValue).format('HH:mm');
            }
            return itm;
        });
        this.setState({ cutoff: newCutoff }, () => { this.props.onCutoffChange(this.state.cutoff) });
    }

    setAvailableForDelivery(dayIndex, newValue) {
        let newCutoff = this.state.cutoff;
        newCutoff.map(itm => {
            if (itm.day === dayIndex) {
                itm.availableForDelivery = newValue;
            }
            return itm;
        });
        this.setState({ cutoff: newCutoff }, () => { this.props.onCutoffChange(this.state.cutoff) });
    }

    render() {
        return (
            <div>
                <Table>
                    <thead>
                        <tr>
                            <th>Opcja</th>
                            <th>Niedziela</th>
                            <th>Poniedziałek</th>
                            <th>Wtorek</th>
                            <th>Środa</th>
                            <th>Czwartek</th>
                            <th>Piątek</th>
                            <th>Sobota</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td style={{ width: 180 }}>
                                Obsługa zamówień
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={0}
                                    checked={this.state.cutoff.filter(itm => itm.day === 0)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(0, event.target.checked);
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={1}
                                    checked={this.state.cutoff.filter(itm => itm.day === 1)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(1, event.target.checked);
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={2}
                                    checked={this.state.cutoff.filter(itm => itm.day === 2)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(2, event.target.checked);
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={3}
                                    checked={this.state.cutoff.filter(itm => itm.day === 3)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(3, event.target.checked);
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={4}
                                    checked={this.state.cutoff.filter(itm => itm.day === 4)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(4, event.target.checked);
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={5}
                                    checked={this.state.cutoff.filter(itm => itm.day === 5)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(5, event.target.checked);
                                    }}
                                />
                            </td>
                            <td className="text-center">
                                <Checkbox
                                    number={6}
                                    checked={this.state.cutoff.filter(itm => itm.day === 6)[0].availableForDelivery}
                                    onChange={event => {
                                        this.setAvailableForDelivery(6, event.target.checked);
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Zamówienia do godziny
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 0)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(0, value)
                                    }
                                />
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 1)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(1, value)
                                    }
                                />
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 2)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(2, value)
                                    }
                                />
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 3)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(3, value)
                                    }
                                />
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 4)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(4, value)
                                    }
                                />
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 5)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(5, value)
                                    }
                                />
                            </td>
                            <td>
                                <Datetime
                                    dateFormat={false}
                                    inputProps={{ placeholder: "..." }}
                                    defaultValue={this.state.cutoff.filter(itm => itm.day === 6)[0].cutoff}
                                    onChange={value =>
                                        this.setHour(6, value)
                                    }
                                />
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div >
        );
    }

}

export default ExpertCutoffListComponent;