import React, { Component } from "react";
import moment from "moment";
import "moment/min/locales";
import {
    Grid,
    Row,
    Col,
    Button,
} from "react-bootstrap";
import Card from "../../../../components/Card/Card";

moment.locale('pl');

class ExpertHubConfirm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            uuid: this.props.location.pathname.replace("/enet/", ""),
            found: null
        };
    }

    componentDidMount() {
        this.getTransfer();
    }

    getTransfer() {
        const { uuid } = this.state;
        this.setState({ isLoading: true }, () => {
            _api.request(`/enet/get-transfer`,
                {
                    transfer: {
                        uuid: uuid
                    },
                },
                (data) => {
                    this.setState({ found: data.transfer, isLoading: false })
                },
                (error) => {
                    this.setState({ isLoading: false })
                    this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                }
            );
        })
    }

    setTransferConfirmation(text) {
        const { uuid } = this.state;
        this.setState({ isLoading: true }, () => {
            _api.request(`/enet/set-transfer-confirmation`,
                {
                    transfer: {
                        uuid: uuid,
                        confirmation: text,
                    },
                },
                (data) => {
                    this.setState({ found: data.transfer, isLoading: false })
                    this.props.showNoty('tr', 'success', 'Dane został zapisane poprawnie');
                },
                (error) => {
                    this.setState({ isLoading: false })
                    this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                }
            );
        })
    }

    openFile() {
        const { found } = this.state;
        if (found?.file?.link) {
            window.open(found.file.link);
        } else {
            this.props.showNoty('tr', 'error', 'Brak pliku harmonogramu!');
        }
    }

    render() {
        const { found, isLoading } = this.state;
        const whenFound = (
            <div className="main-content">
                <Grid>
                    <Row>
                        <Col md={6} sm={6} mdOffset={3} smOffset={3}>
                            <Card
                                content={
                                    <div style={{ maxWidth: "80%", margin: "0 auto" }}>
                                        <h3 style={{ marginBottom: 15 }}>{found?.ident}</h3>
                                        <Row>
                                            <Col md={12} style={{ marginBottom: 15 }}>
                                                <Button block bsStyle="default" bsSize="large" disabled={isLoading} onClick={() => { this.openFile() }}>
                                                    Zobacz
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6} style={{ marginBottom: 15 }}>
                                                <Button block bsStyle="info" bsSize="large" className={`${found?.confirmation === 'Przyjęty' ? 'btn-fill' : ''}`} disabled={isLoading} onClick={() => { this.setTransferConfirmation('Przyjęty') }}>
                                                    Przyjmij
                                                </Button>
                                            </Col>
                                            <Col lg={6} style={{ marginBottom: 15 }}>
                                                <Button block bsStyle="danger" bsSize="large" className={`${found?.confirmation === 'Odrzucony' ? 'btn-fill' : ''}`} disabled={isLoading} onClick={() => { this.setTransferConfirmation('Odrzucony') }}>
                                                    Odrzuć
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );

        return found ? whenFound : null;
    }

}

export default ExpertHubConfirm;
