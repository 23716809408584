/* global _api */
import React, { Component } from "react";
import Datetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import "moment/min/locales";
import PropagateLoader from 'react-spinners/PropagateLoader';
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import {
    FormGroup,
    ControlLabel,
    FormControl,
    Row,
    Col,
    Button
} from "react-bootstrap";

moment.locale('pl');

class ExpertScheduleComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: true,
            uuid: this.props.uuid || "",
            ident: this.props.ident || "",
            date: this.props.date || moment(),
            onboarding: this.props.onboarding || moment().add(1, 'day').hours(8).startOf('hour'),
            department: this.props.department || "",
            fleet: this.props.fleet || "",
            driver: this.props.driver || "",
            helper: this.props.helper || "",
            onboardingSendSms: false,
            availableFleet: [],
            availableUsers: []
        };
    }

    componentDidMount() {
        this.loadFleet(() => {
            this.loadUsers(() => {
                if (this.props.uuid) {
                    this.loadSchedule(this.props.uuid);
                } else {
                    this.setState({ isLoading: false })
                }
            });
        });
    }

    loadSchedule(scheduleUuid) {
        let self = this;
        self.setState({ uuid: scheduleUuid, isLoading: true });
        _api.request(`/admin/schedule/details/${scheduleUuid}`, {},
            (data) => {
                self.setState({
                    isLoading: false,
                    uuid: data.schedule.uuid,
                    ident: data.schedule.ident,
                    date: moment(data.schedule.dayIdent.date),
                    onboarding: moment(data.schedule.onboardingDate.date),
                    department: data.department.uuid,
                    fleet: self.state.availableFleet.filter(itm => itm.value === data.fleet.uuid)[0],
                    driver: self.state.availableUsers.filter(itm => itm.value === data.driver.uuid)[0],
                    helper: self.state.availableUsers.filter(itm => itm.value === data.helper.uuid)[0],
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    loadFleet(callback) {
        let self = this;
        _api.request(`/admin/general/fleet/region/${this.props.department}`, {},
            (data) => {
                let tempArray = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        tempArray.push({ "value": data[i].uuid, "label": `${data[i].name} (${data[i].department.region} / ${data[i].department.name})` });
                    }
                }
                self.setState({ availableFleet: tempArray }, () => { callback() });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    loadUsers(callback) {
        let self = this;
        _api.request(`/admin/user/region/department/${this.props.department}/expertFleet`, {
            page: 0,
            pageSize: 999
        },
            (data) => {
                let tempArray = [];
                if (data && data.rows) {
                    for (let i = 0; i < data.rows.length; i++) {
                        tempArray.push({ "value": data.rows[i].uuid, "label": `${data.rows[i].firstname} ${data.rows[i].lastname} (${data.rows[i].department.region} / ${data.rows[i].department.name})` });
                    }
                }
                self.setState({ availableUsers: tempArray }, () => { callback() });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleSubmit(event) {
        event.preventDefault();

        let self = this;
        let endpoint = this.state.uuid ? `/admin/schedule/update/${this.state.uuid}` : `/admin/schedule/create`

        self.setState({ isSaving: true });
        self.props.setProgress(0);
        _api.request(endpoint,
            {
                "schedule": {
                    "uuid": self.state.uuid,
                    "dayIdent": moment(self.state.date).format("YYYY-MM-DD"),
                    "onboardingDate": moment(self.state.onboarding).format("YYYY-MM-DD HH:mm:ss"),
                    "onboardingSendSms": self.state.onboardingSendSms
                },
                "department": {
                    "uuid": self.state.department,
                },
                "fleet": {
                    "uuid": self.state.fleet ? self.state.fleet.value : null
                },
                "driver": {
                    "uuid": self.state.driver ? self.state.driver.value : null
                },
                "helper": {
                    "uuid": self.state.helper ? self.state.helper.value : null
                }
            },
            (data) => {
                if (typeof self.props.onSaved === "function") {
                    self.props.onSaved();
                } else {
                    self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                }
                self.setState({ isSaving: false });
                self.props.setProgress(100);
            },
            (error) => {
                if (typeof self.props.onError === "function") {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                } else {
                    self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                }
                self.setState({ isSaving: false });
                self.props.setProgress(100);
            }
        );
    }

    setOnboarding(date) {
        this.setState({ onboarding: moment(date) });
    }

    render() {

        const scheduleLoading = (
            <div className="main-content">
                <div style={{ "paddingBottom": "26px" }}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        css={{
                            "display": "block",
                            "margin": "10px auto",
                            "width": "1px"
                        }}
                        color={'#777'}
                        loading={true}
                    />
                </div>
            </div>
        );

        const scheduleLoaded = (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Identyfikator: </ControlLabel>
                            <FormControl placeholder="(zostanie nadany automatycznie)" readOnly="readonly" value={this.state.ident} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Flota:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="fleet"
                                    value={this.state.fleet}
                                    placeholder="Wybierz.."
                                    onChange={value =>
                                        this.setState({ fleet: value })
                                    }
                                    options={this.state.availableFleet}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Kierowca:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="driver"
                                    value={this.state.driver}
                                    placeholder="Wybierz.."
                                    onChange={value =>
                                        this.setState({ driver: value })
                                    }
                                    options={this.state.availableUsers}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Pomocnik:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="helper"
                                    value={this.state.helper}
                                    placeholder="Wybierz.."
                                    onChange={value =>
                                        this.setState({ helper: value })
                                    }
                                    options={this.state.availableUsers}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Onboarding: </ControlLabel>
                            <Datetime
                                inputProps={{ placeholder: "Wybierz datę i godzinę" }}
                                defaultValue={this.state.onboarding}
                                onChange={value =>
                                    this.setOnboarding(value)
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Powiadomienia:</ControlLabel>
                            <Col>
                                <Checkbox
                                    number="1"
                                    label="Wyślij onboarding SMSem"
                                    checked={this.state.onboardingSendSms}
                                    onChange={event =>
                                        this.setState({ onboardingSendSms: event.target.checked })
                                    }
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Button type="submit" disabled={this.state.isSaving} bsStyle="default" onClick={!this.state.isSaving ? this.handleSubmit.bind(this) : null}>
                            {this.state.isSaving ? 'Zapisywanie…' : 'Zapisz dane'}
                        </Button>
                    </Col>
                </Row>
            </form>
        );

        return (
            <div>
                {this.state.isLoading ? scheduleLoading : scheduleLoaded}
            </div>
        )

    }

}

export default ExpertScheduleComponent;
