import React, { Component } from "react";
import _ from "lodash";
import Dnd from "../Dnd/dnd";
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import {
    Row,
    Col,
    FormControl
} from "react-bootstrap";

class ExpertColumnsListComponent extends Component {

    constructor(props) {
        super(props);
        this.default = [
            {
                Header: "Oddział",
                accessor: "department",
                active: false,
                position: 0
            },
            {
                Header: "Sklep",
                accessor: "company",
                active: false,
                position: 1
            },
            {
                Header: "Nr zlecenia",
                accessor: "ident",
                active: true,
                position: 2
            },
            {
                Header: "Nazwa odbiorcy",
                accessor: "name",
                active: true,
                position: 3
            },
            {
                Header: "Adres odbiorcy",
                accessor: "streetAddress",
                active: true,
                position: 4
            },
            {
                Header: "Data utworzenia",
                accessor: "created",
                active: true,
                position: 5
            },
            {
                Header: "Telefon",
                accessor: "mobilePhone",
                active: true,
                position: 6
            },
            {
                Header: "Data dostawy",
                accessor: "date",
                active: true,
                position: 7
            },
            {
                Header: "Nr dok. wew.",
                accessor: "identExternal",
                active: true,
                position: 8
            },
            {
                Header: "Magazyn",
                accessor: "warehouse",
                active: false,
                position: 9
            },
            {
                Header: "COD",
                accessor: "cod",
                active: false,
                position: 10
            },
            {
                Header: "Rozliczenie",
                accessor: "settled",
                active: false,
                position: 11
            },
            {
                Header: "Express",
                accessor: "express",
                active: false,
                position: 12
            },
            {
                Header: "Ocena satysfakcji",
                accessor: "csat",
                active: false,
                position: 13
            },
            {
                Header: "Kierowca",
                accessor: "driverName",
                active: false,
                position: 14
            },
            {
                Header: "Pomocnik",
                accessor: "helperName",
                active: false,
                position: 15
            },
            {
                Header: "Data dost. mag. last mile",
                accessor: "lastMileWarehouseDeliveryDayIdent",
                active: false,
                position: 16
            }
        ];

        this.state = {
            items: props.items.length ? props.items : _.cloneDeep(this.default)
        };

    }

    shouldComponentUpdate(nextProps, nextState) {
        nextState.items = nextProps.items.length ? nextProps.items : _.cloneDeep(this.default)
        return true;
    }

    getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: 'none',
        padding: `8px 8px 0 8px`,
        margin: `0 0 8px 0`,
        border: `1px solid #e3e3e3`,
        backgroundColor: isDragging ? '#6addf4' : 'white',
        borderColor: isDragging ? '#6addf4' : '#e3e3e3',
        color: isDragging ? '#fff' : '#333',
        transition: `background-color 0.5s ease-out`,
        ...draggableStyle
    });

    getGroupStyle = isDraggingOver => ({
        backgroundColor: isDraggingOver ? '#e3e3e3' : 'white',
        transition: `background-color 0.3s ease-out`,
    });

    handleElementAdd(list, element) {
        this.setState({ [list]: this.state[list].concat([element]) });
    };

    getFieldEventValue(e, explicit) {
        return explicit ? e : e.target.type === "checkbox" ? e.target.checked : e.target.value;
    }

    handleElementChange(list, currentElement, field, e, explicit) {
        const newElements = this.state[list].map((element) => {
            if (element.accessor !== currentElement.accessor) return element;
            let updatedElement = { ...element };
            let newValue = this.getFieldEventValue(e, explicit);
            updatedElement[field] = newValue;
            return updatedElement;
        });
        this.setState({ [list]: newElements });
        this.props.onChange(newElements);
    };

    render() {
        return (
            <div>
                <Dnd
                    filterByTrash={false}
                    itemKey={`accessor`}
                    items={this.state.items}
                    onChange={(items) => { this.props.onChange(items) }}
                    droppableId="items"
                    placeholder="- brak elementów -"
                    renderItem={(item, index, dragProps) => {
                        return (
                            <Row key={item.accessor}>
                                <Col sm={8}>
                                    <FormControl
                                        type="text"
                                        value={item.Header}
                                        placeholder={item.accessor}
                                        onChange={(e) => {
                                            this.handleElementChange(`items`, item, 'Header', e)
                                        }}
                                    />
                                </Col>
                                <Col sm={2} className="text-center">
                                    <Checkbox
                                        number={item.accessor}
                                        checked={item.active}
                                        onChange={(e) => {
                                            this.handleElementChange(`items`, item, 'active', e)
                                        }}
                                    />
                                </Col>
                                <Col sm={2} className="text-center">
                                    <span
                                        style={{ cursor: "move" }}
                                        {...dragProps}
                                    >
                                        <i
                                            className="fa fa-bars"
                                            style={{ width: 35, fontSize: 20, padding: 9, color: '#999' }}
                                        />
                                    </span>
                                </Col>
                            </Row>
                        )
                    }}
                />
            </div>
        );
    }

}

export default ExpertColumnsListComponent;