/* global _api */
import React from "react";
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from "moment";
import "moment/min/locales";
import { Grid, Row, Col, Modal, FormGroup, ControlLabel, Badge } from "react-bootstrap";
import Switch from "react-bootstrap-switch";
import BaseComponent from "../../../../utils/BaseComponent.jsx";
import Card from "../../../../components/Card/Card.jsx";
import Datatable from "../../../../components/Datatable";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import Checkbox from "../../../../components/CustomCheckbox/CustomCheckbox.jsx";
import ExpertTransferModalComponent from "../../../../components/Expert/TransferModalComponent.jsx";
import ExpertTransferModalOrderDetailsComponent from "../../../../components/Expert/TransferModalOrderDetailsComponent.jsx";
import ExpertTrackerComponent from "../../../../components/Expert/TrackerComponent.jsx";
import ExpertTrackerTransferComponent from "../../../../components/Expert/TrackerTransferComponent.jsx";
import ExpertAdminHubFilter from "./Filter.jsx";
import ExpertStatusBadge from "../../../../components/Expert/StatusBadge.jsx";

moment.locale('pl');

class ExpertAdminHubTransfer extends BaseComponent {

    constructor(props) {
        super(props);
        this.defaults = {
            elements: [],
            pickupDate: moment(),
            deliveryDate: moment().add(1, 'days'),
            deliveryWarehouseUuid: "",
            firstname: "",
            lastname: "",
            companyName: "",
            vehicleNumber: "",
            vehicleIdent: "",
            comment: "",
            mobileNumber: "",
            totalSize: 0,
            totalWeight: 0,
            singleOrder: {},
            checkAll: false,
        }
        this.state = {
            data: [],
            pages: -1,
            loading: true,
            isSaving: false,
            isRefreshing: false,

            showTrackerModal: false,
            orderTracker: false,
            orderIdent: false,
            showOrderModal: false,
            showModalTransferNewSchedule: false,
            orderUuid: false,
            transferStatuses: [],
            deliveryWarehouses: [],

            filters: {
                excelIsGenerating: false,
                transferMaxFirstMilePickupDayIdentFrom: moment().subtract(2, 'weeks'),
                transferMaxFirstMilePickupDayIdentTo: moment().add(2, 'weeks'),
                slotDayIdentFrom: null,
                slotDayIdentTo: null,

                fromWarehouse: null,
                toWarehouse: null,
                fromCompany: null,
                driver: null,
                status: null,

                warehouses: [],
                companies: [],
                statuses: [],
            },

            ...this.defaults,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        _api.request(
            `/admin/transfer/list-last-mile-warehouses`,
            {},
            (data) => {
                this.setState({ deliveryWarehouses: data || [] })
            }
        );
        _api.request(
            `/admin/transfer/list-filters`,
            {},
            (data) => {
                this.setState({ filters: { ...this.state.filters, ...data || {} } })
            }
        );
    }

    manageTransfer(itemUuid, checked) {
        let totalSize = 0;
        let totalWeight = 0;
        let elements = [];
        const newItems = this.state.data.map((item) => {
            let updatedItem = { ...item };
            if (itemUuid !== null && item.uuid === itemUuid) {
                updatedItem.checked = checked;
            }
            if (updatedItem.checked) {
                totalSize += this.parseFloatFixed(item.calculatedSize, 10);
                totalWeight += this.parseFloatFixed(item.calculatedWeight, 10);
                elements.push({ ...item, checkbox: null });
            }
            return updatedItem;
        });
        this.setState({
            data: this.reMapData(newItems),
            totalSize: totalSize,
            totalWeight: totalWeight,
            elements: elements
        });
    }

    reMapData(data) {
        return data.map((prop, key) => {
            prop.checked = prop.hasOwnProperty('checked') ? prop.checked : false;
            prop.checkbox = (
                <Checkbox
                    key={prop.uuid}
                    number={key}
                    checked={prop.checked}
                    onChange={event => {
                        this.manageTransfer(prop.uuid, event.target.checked);
                    }}
                />
            );
            if (!prop.hasAutoBadge) {
                prop.statusName = <ExpertStatusBadge status={prop.statusName} />
                prop.hasAutoBadge = true;
            }
            if (!prop.hasActions) {
                prop.orderIdentRaw = prop.orderIdent;
                prop.orderIdent = (
                    <span className={prop.orderTrash === "1" ? "trash" : ""}>
                        {prop.orderIdentRaw}
                    </span>
                )
                prop.transferIdentRaw = prop.transferIdent;
                prop.transferIdent = (
                    <button style={{ border: 0, padding: 0, textAlign: 'left', textDecoration: 'underline', background: 'transparent' }} onClick={() => this.getExistingTransfer(prop.transferIdentRaw)}>{prop.transferIdentRaw}</button>
                )
                prop.actions = (
                    <div className="actions-right">
                        <Button
                            onClick={() => { this.showOrderModal(prop) }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-edit" />
                        </Button>
                        <Button
                            onClick={() => { this.showTrackerModal(prop.orderTracker, prop.orderIdentRaw) }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-info-circle" />
                        </Button>
                    </div>
                )
                prop.hasActions = true;
            }
            return prop;
        });
    }

    showTrackerModal(orderTracker, orderIdent) {
        this.setState({ showTrackerModal: true, orderTracker: orderTracker, orderIdent: orderIdent });
    }

    hideTrackerModal() {
        this.setState({ showTrackerModal: false, orderTracker: null, orderIdent: null });
    }

    showOrderModal(orderData) {
        this.setState({ showOrderModal: true, singleOrder: orderData });
    }

    hideOrderModal() {
        this.setState({ showOrderModal: false, singleOrder: {} });
    }

    fetchTable = (state, instance, callback) => {
        let self = this;
        self.setState({ loading: true });
        _api.request("/admin/transfer/list-transfers", {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            overlayed: this.getOverlayed(),
        }, (res) => {
            self.setState({
                data: self.reMapData(res.rows),
                pages: res.pages,
                loading: false,
                page: res.page,
                pageSize: res.pageSize,
                sorted: res.sorted,
                filtered: res.filtered
            },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loading: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    onSubmitNewTransfer(e) {
        if (e) {
            e.preventDefault();
        }

        let self = this;
        self.setState({ loading: true });
        _api.request(`/admin/transfer/create-transfer`,
            {
                transfer: {
                    elements: this.state.elements.map(itm => itm.uuid),
                    pickupDate: moment(self.state.pickupDate).format("YYYY-MM-DD"),
                    deliveryDate: moment(self.state.deliveryDate).format("YYYY-MM-DD"),
                    deliveryWarehouseUuid: this.state.deliveryWarehouseUuid,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    companyName: this.state.companyName,
                    vehicleNumber: this.state.vehicleNumber,
                    vehicleIdent: this.state.vehicleIdent,
                    comment: this.state.comment,
                    mobileNumber: this.state.mobileNumber,
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', `Transfer został zapisany (${data.ident})`);
                window.prompt("Identyfikator nowego transferu", data.ident);
                self.setState({ loading: false, showModalTransferNewSchedule: false, ...this.defaults }, () => {
                    self.fetchTable(this.state)
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                self.setState({ loading: false });
            }
        );
    }

    checkAll(checked) {
        const checkAll = checked;
        const newItems = this.state.data.map((item) => {
            let updatedItem = { ...item };
            updatedItem.checked = checkAll;
            return updatedItem;
        });
        this.setState({
            data: this.reMapData(newItems),
            checkAll
        }, () => {
            this.manageTransfer(null, checkAll);
        });
    }

    setFilters(e, c) {
        this.setState({ filters: { ...this.state.filters, [e]: c } }, () => {
            this.fetchTable(this.state);
        })
    }

    getOverlayed() {
        const self = this;
        return {
            transferMaxFirstMilePickupDayIdentFrom: self.state.filters.transferMaxFirstMilePickupDayIdentFrom ? moment(self.state.filters.transferMaxFirstMilePickupDayIdentFrom).format("YYYY-MM-DD") : null,
            transferMaxFirstMilePickupDayIdentTo: self.state.filters.transferMaxFirstMilePickupDayIdentTo ? moment(self.state.filters.transferMaxFirstMilePickupDayIdentTo).format("YYYY-MM-DD") : null,
            slotDayIdentFrom: self.state.filters.slotDayIdentFrom ? moment(self.state.filters.slotDayIdentFrom).format("YYYY-MM-DD") : null,
            slotDayIdentTo: self.state.filters.slotDayIdentTo ? moment(self.state.filters.slotDayIdentTo).format("YYYY-MM-DD") : null,
            fromWarehouse: self.state.filters.fromWarehouse,
            toWarehouse: self.state.filters.toWarehouse,
            fromCompany: self.state.filters.fromCompany,
            driver: self.state.filters.driver,
            status: self.state.filters.status,
        };
    }

    getReport(ofType) {
        if (!ofType) {
            ofType = 'transfers'
        }
        this.setFilters('excelIsGenerating', true);
        _api.request(
            `/admin/transfer/report/${ofType}`,
            { overlayed: this.getOverlayed() },
            (data) => {
                this.setFilters('excelIsGenerating', false);
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                this.setFilters('excelIsGenerating', false);
                this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                this.props.setProgress(progress);
            }
        );
    }

    getExistingTransfer(scheduleIdent) {
        let self = this;
        _api.request(`/admin/transfer/get-schedules`,
            {
                transfer: {
                    idents: [scheduleIdent]
                }
            },
            (data) => {
                let rawData = data[scheduleIdent];
                let deliveryWarehouseName = "";
                let elements = rawData.orders?.map(order => {
                    order.currentWarehouseName = order.fromWarehouse;
                    order.orderIdent = order.ident,
                        order.companyName = order.client,
                        deliveryWarehouseName = order.toWarehouse;
                    return order;
                });
                let scheduleData = {
                    ...rawData.transfer,
                    ident: scheduleIdent,
                    elements: elements,
                    pickupDate: rawData.transfer.pickupDayIdent,
                    deliveryDate: rawData.transfer.deliveryDayIdent,
                    deliveryWarehouseName: deliveryWarehouseName,
                }
                self.setState({ scheduleData: scheduleData, showModalTransferSchedule: true });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    onSubmitExistingTransfer() {
        let self = this;
        _api.request(`/admin/transfer/set-transfer`,
            { transfer: self.state.scheduleData },
            (data) => {
                this.props.showNoty('tr', 'success', 'Dane zostały zapisane');
                this.setState({ isRefreshing: true }, () => { this.setState({ isRefreshing: false }) });
                this.getExistingTransfer(data['ident']);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    onUpdateExistingTransfer(newData, callback) {
        let newSchedule = { ...this.state.scheduleData, ...newData };
        this.setState({ scheduleData: newSchedule }, callback);
    }

    render() {

        let cols = [
            {
                Header: (
                    <>
                        <Checkbox
                            key="check-all"
                            number={99999}
                            checked={this.state.checkAll}
                            className="mb-0"
                            onChange={event => {
                                this.checkAll(event.target.checked);
                            }}
                        />
                    </>
                ),
                accessor: "checkbox",
                sortable: false,
                filterable: false,
                width: 50,
            },
            {
                Header: "Kontrahent",
                accessor: "companyName",
                showInSummary: true,
            },
            {
                Header: "Nr zlecenia",
                accessor: "orderIdent",
                showInSummary: true,
            },
            {
                Header: "Nr dok. wew.",
                accessor: "orderIdentExternal",
                showInSummary: true,
            },
            {
                Header: "Typ odbioru",
                accessor: "orderWarehouseType",
                showInSummary: true,
            },
            {
                Header: "Miejsce odbioru",
                accessor: "orderWarehouseName",
                showInSummary: true,
            },
            {
                Header: "Data odbioru",
                accessor: "transferMaxFirstMilePickupDayIdent",
                showInSummary: true,
            },
            {
                Header: "Harmonogram",
                accessor: "transferIdent",
                showInSummary: true,
            },
            {
                Header: "Potwierdzenie",
                accessor: "transferConfirmation",
                showInSummary: true,
            },
            {
                Header: "Aktualny mag.",
                accessor: "currentWarehouseName",
                showInSummary: true,
            },
            {
                Header: "Data odbioru mag.",
                accessor: "transferPickupDayIdent",
                showInSummary: true,
            },
            {
                Header: "Następny mag.",
                accessor: "nextWarehouseName",
                showInSummary: true,
            },
            {
                Header: "Data dostawy mag.",
                accessor: "transferDeliveryDayIdent",
                showInSummary: true,
            },
            {
                Header: "Mag. last mile",
                accessor: "lastMileWarehouseName",
                showInSummary: true,
            },
            {
                Header: "Data dostawy last mile",
                accessor: "slotDayIdent",
                showInSummary: true,
            },
            {
                Header: "Kubatura",
                accessor: "calculatedSize",
                showInSummary: true,
                filterable: false,
            },
            {
                Header: "Waga",
                accessor: "calculatedWeight",
                showInSummary: true,
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "statusName"
            },
            {
                Header: "",
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 80
            }
        ];

        const contentLoader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        return (
            <div className="main-content">

                <Modal show={this.state.showTrackerModal} onHide={this.hideTrackerModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                    <Modal.Header closeButton>Podgląd statusu {this.state.orderIdentRaw}</Modal.Header>
                    <Modal.Body>
                        <form>
                            <FormGroup>
                                <ControlLabel style={{ width: "100%" }}>
                                    <Switch name="showLastMileTracker" onText="" offText="" value={this.state.showLastMileTracker} defaultValue={false} onChange={(el, state) => { this.setState({ showLastMileTracker: state }) }} />
                                    <span style={{ marginLeft: 10 }}>Pokaż szczegóły i statusy last mile</span>
                                </ControlLabel>
                            </FormGroup>
                        </form>
                        {
                            this.state.showLastMileTracker ? (
                                <ExpertTrackerComponent
                                    {...this.props}
                                    headless={true}
                                    withDetails={true}
                                    orderTracker={this.state.orderTracker}
                                    hideStatus={[]}
                                />
                            ) : (
                                <ExpertTrackerTransferComponent
                                    {...this.props}
                                    headless={true}
                                    withDetails={true}
                                    orderTracker={this.state.orderTracker}
                                />
                            )
                        }
                    </Modal.Body>
                </Modal>

                <ExpertTransferModalComponent
                    show={this.state.showModalTransferNewSchedule}
                    onHide={() => this.setState({ showModalTransferNewSchedule: false })}
                    onSubmit={(e) => this.onSubmitNewTransfer(e)}
                    setUpperState={(e, c) => { this.setState(e, c) }}
                    upperState={{ ...this.state }}
                />

                <ExpertTransferModalComponent
                    show={this.state.showModalTransferSchedule}
                    onHide={() => this.setState({ showModalTransferSchedule: false })}
                    onSubmit={(e) => this.onSubmitExistingTransfer(e)}
                    setUpperState={(e, c) => { this.onUpdateExistingTransfer(e, c) }}
                    upperState={this.state.scheduleData}
                    editOnlyBasics={true}
                    confirmCreate="Czy chcesz zaktualizować ten harmonogram?"
                />

                <ExpertTransferModalOrderDetailsComponent
                    show={this.state.showOrderModal}
                    onHide={() => this.setState({ showOrderModal: false })}
                    upperState={{ ...this.state }}
                />

                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <ExpertAdminHubFilter
                                withRosebrockReport='transfers-rosebrock'
                                getReport={(ofType) => { this.getReport(ofType) }}
                                setFilters={(e, c) => { this.setFilters(e, c) }}
                                filters={{ ...this.state.filters }}
                            />
                        </Col>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Transfery - <strong>{this.state.totalSize.toFixed(2)} m<sup>3</sup> / {this.state.totalWeight.toFixed(2)} kg</strong>
                                        </span>

                                        <Button
                                            bsStyle="default"
                                            round
                                            icon
                                            pullRight
                                            style={{ marginRight: 10 }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ showModalTransferNewSchedule: true })
                                            }}
                                        >
                                            <span className="btn-label">
                                                <i className="fa fa-location-arrow" /> Przekaż do transferu
                                            </span>
                                        </Button>
                                    </div>
                                }
                                content={
                                    <>
                                        {
                                            !this.state.isRefreshing
                                                ?
                                                (
                                                    <Datatable
                                                        data={this.state.data}
                                                        pages={this.state.pages}
                                                        loading={this.state.loading}
                                                        manual
                                                        filterable
                                                        columns={cols}
                                                        defaultSorted={[
                                                            {
                                                                id: "ident",
                                                                desc: true
                                                            }
                                                        ]}
                                                        onFetchData={this.fetchTable}
                                                        defaultPageSize={25}
                                                        pageSizeOptions={[25, 50, 100, 150, 200]}
                                                        showPaginationBottom
                                                        className="-striped -highlight"
                                                        previousText="Poprzednia"
                                                        nextText="Następna"
                                                        noDataText="Brak danych"
                                                        loadingText="Pobieranie danych"
                                                        pageText="Strona"
                                                        ofText=" z "
                                                        rowsText="wierszy"
                                                    />
                                                )
                                                :
                                                contentLoader
                                        }
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminHubTransfer;
