/* global _api */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import LocationSearchInput from "../../../components/Map/LocationSearchInput.jsx";
import ExpertUsersListComponent from "../../../components/Expert/UsersListComponent.jsx";
import ExpertServicesListComponent from "../../../components/Expert/ServicesListComponent.jsx";
import ExpertZonesListComponent from "../../../components/Expert/ZonesListComponent.jsx";
import ExpertCutoffListComponent from "../../../components/Expert/CutoffListComponent.jsx";
import ExpertColumnsListComponent from "../../../components/Expert/ColumnsListComponent.jsx";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Tab,
    Nav,
    NavItem,
    Modal,
    OverlayTrigger,
    Tooltip,
    NavDropdown
} from "react-bootstrap";
import ExpertWarehousesList from "../../../components/Expert/WarehousesListComponent";
import Datatable from "../../../components/Datatable";

class ExpertAdminCompanies extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            tab: "basic",
            tabDefault: "basic",
            showModal: false,
            showTable: true,
            dataTable: [],
            pagesTable: -1,
            pageTable: 0,
            defaultPageSizeTable: 10,
            loadingTable: true,
            departments: [],
            companySelected: false,
            companyIsLoading: false,
            allowEmptyDate: false,
            forceEmptyDate: false,
            allowTransferable: false,
            isTransferable: false,
            allowNoBringing: false,
            overrideColumns: false,
            companyUuid: null,
            companyName: "",
            streetAddress: "",
            apartmentNumber: "",
            taxId: "",
            complaintEmailAddress: "",
            baseOrderDuration: "",
            geoLat: "",
            geoLng: "",
            numberOfSpans: "",
            numberOfSpansOptions: [
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" }
            ],
            industry: "",
            industryOptions: [
                { value: "furniture", label: "MEBLE (OGÓLNY)" },
                { value: "mmkt", label: "MEDIA MARKT" },
                { value: "agata", label: "AGATA MEBLE" },
                { value: "jysk", label: "JYSK" },
                { value: "expert", label: "EXPERT" },
                { value: "ecomm", label: "ECOMMERCE" },
                { value: "brw", label: "BRW" }
            ],
            services: [],
            servicesChanged: false,
            zones: [],
            zonesChanged: false,
            zonesMode: "perimeter",
            cutoff: [],
            refreshConfiguration: false,
        };
    }

    componentDidMount() {
        let self = this;
        _api.request(`/admin/general/departments`, {},
            (data) => {
                let items = [];
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name });
                }
                if (items.length) {
                    self.setState({ departments: items });
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
    }

    onAddressChange = (data) => {
        this.setState({ streetAddress: data.address, geoLat: data.addressLatLng.lat, geoLng: data.addressLatLng.lng });
    }

    onAddressNotFound = (data) => {
        this.props.showNoty('tr', 'error', 'Podany adres nie został znaleziony');
        this.setState({ streetAddress: '', geoLat: '', geoLng: '' });
    }

    onServicesChange = (services) => {
        this.setState({ services: services, servicesChanged: true });
    }

    onZonesChange = (zones) => {
        this.setState({ zones: zones, zonesChanged: true });
    }

    onCutoffChange = (cutoff) => {
        this.setState({ cutoff: cutoff });
    }

    onColumnsChange = (columns) => {
        this.setState({ columns: columns });
    }

    editCompany = (uuid) => {
        let self = this;

        self.setState({ companyUuid: uuid, companySelected: true, companyIsLoading: true, showModal: true });

        if (!uuid) {
            self.setState({
                tab: self.state.tabDefault,
                companyUuid: null,
                companyName: "",
                numberOfSpans: null,
                industry: null,
                streetAddress: "",
                apartmentFloor: "",
                allowEmptyDate: false,
                forceEmptyDate: false,
                allowTransferable: false,
                isTransferable: false,
                taxId: "",
                allowNoBringing: false,
                overrideColumns: false,
                geoLat: null,
                geoLng: null,
                department: null,
                services: null,
                servicesChanged: false,
                zones: null,
                zonesChanged: false,
                cutoff: null,
                columns: null,
                companySelected: true,
                companyIsLoading: false
            });
            return;
        }

        _api.request(`/admin/company/details/${uuid}`, {},
            (data) => {
                self.setState({
                    companyUuid: data.company.uuid,
                    companyName: data.company.name || "",
                    complaintEmailAddress: data.company.complaintEmailAddress || "",
                    baseOrderDuration: data.company.baseOrderDuration || "",
                    numberOfSpans: self.state.numberOfSpansOptions.filter(itm => itm.value === data.company.numberOfSpans)[0],
                    industry: self.state.industryOptions.filter(itm => itm.value === data.company.industry)[0],
                    streetAddress: data.address.streetAddress || "",
                    apartmentFloor: data.address.apartmentFloor || "",
                    apartmentNumber: data.address.apartmentNumber || "",
                    taxId: data.company.taxId || "",
                    geoLat: data.address.geoLat,
                    geoLng: data.address.geoLng,
                    department: self.state.departments.filter(itm => itm.value === data.department.uuid)[0],
                    services: data.services,
                    skills: data.skills,
                    zones: data.zones,
                    zonesMode: data.company.configuration.zones.mode,
                    cutoff: data.company.configuration && data.company.configuration.cutoff ? data.company.configuration.cutoff : [],
                    overrideColumns: data.company.configuration?.orders?.overrideColumns || false,
                    columns: data.company.configuration && data.company.configuration.orders?.columns ? data.company.configuration.orders.columns : [],
                    allowEmptyDate: data.company.configuration?.allowEmptyDate || false,
                    forceEmptyDate: data.company.configuration?.forceEmptyDate || false,
                    allowTransferable: data.company.configuration?.allowTransferable || false,
                    isTransferable: data.company.isTransferable || false,
                    allowNoBringing: data.company.configuration?.allowNoBringing || false,
                    companyIsLoading: false,
                    zonesChanged: false,
                    servicesChanged: false
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleCompanySubmit(event) {
        event.preventDefault();

        let self = this;

        let validationRules = {
            "isValid": true,
            "items": [
                { "field": "companyName", "rules": { "empty": "Uzupełnij nazwę firmy" } },
                { "field": "streetAddress", "rules": { "empty": "Uzupełnij ulicę, numer domu i miasto" } },
                { "field": "department", "rules": { "empty": "Wybierz oddział Expert" } },
                { "field": "numberOfSpans", "rules": { "empty": "Określ ilość przedziałów czasowych" } },
                { "field": "industry", "rules": { "empty": "Określ branżę" } },
                { "field": "taxId", "rules": { "empty": "Uzupełnij NIP" } }
            ]
        };

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = self.state[element.field];
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
            }
        });

        if (!validationRules.isValid) {
            return;
        }

        self.setState({ isLoading: true, showTable: false });
        self.props.setProgress(0);
        let endpoint = this.state.companyUuid ? `/admin/company/update/${this.state.companyUuid}` : `/admin/company/create`;
        _api.request(endpoint,
            {
                "company": {
                    "name": self.state.companyName,
                    "numberOfSpans": self.state.numberOfSpans.value,
                    "industry": self.state.industry.value,
                    "complaintEmailAddress": self.state.complaintEmailAddress,
                    "baseOrderDuration": self.state.baseOrderDuration,
                    "isTransferable": self.state.isTransferable,
                    "taxId": self.state.taxId,
                    "configuration": {
                        "cutoff": self.state.cutoff,
                        "allowEmptyDate": self.state.allowEmptyDate,
                        "forceEmptyDate": self.state.forceEmptyDate,
                        "allowTransferable": self.state.allowTransferable,
                        "allowNoBringing": self.state.allowNoBringing,
                        "orders": {
                            "overrideColumns": self.state.overrideColumns,
                            "columns": self.state.columns
                        }
                    },
                    "refreshConfiguration": self.state.refreshConfiguration
                },
                "department": {
                    "uuid": self.state.department.value
                },
                "address": {
                    "streetAddress": self.state.streetAddress,
                    "apartmentFloor": self.state.apartmentFloor,
                    "apartmentNumber": self.state.apartmentNumber,
                    "geoLat": self.state.geoLat,
                    "geoLng": self.state.geoLng
                },
                "services": self.state.services,
                "servicesChanged": self.state.servicesChanged,
                "zones": self.state.zones,
                "zonesChanged": self.state.zonesChanged,
            },
            (data) => {
                self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                self.setState({ isLoading: false, companyUuid: data, showTable: true }, () => {
                    self.editCompany(data);
                });
                self.props.setProgress(100);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                self.setState({ isLoading: false, showTable: true });
                self.props.setProgress(100);
            }
        );
    }

    hideModal() {
        this.setState({ showModal: false });
    }

    handleTabSelect(key) {
        this.setState({ tab: key });
    }

    moveToTrash(uuid) {
        let self = this;
        self.setState({ showTable: false }, () => {
            _api.request(`/admin/trash/recursive/company/${uuid}`, {},
                (data) => {
                    self.props.showNoty('tr', 'info', 'Firma została usunięta');
                    self.setState({ showTable: true });
                },
                (error) => {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
                },
                (progress) => {
                    self.props.setProgress(progress);
                }
            );
        }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń element"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    render() {

        const oEdit = <Tooltip id="oEdit">Edycja danych</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;

        const editCompanyForm = (
            <Row className="clearfix">
                <Col md={6}>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Oddział Expert:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="payer"
                                    value={this.state.department}
                                    placeholder="Wybierz oddział"
                                    onChange={value =>
                                        this.setState({ department: value })
                                    }
                                    options={this.state.departments}
                                />
                            </Col>
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Nazwa firmy:</ControlLabel>
                            <FormControl
                                type="text"
                                name="companyName"
                                value={this.state.companyName}
                                onChange={event =>
                                    this.setState({ companyName: event.target.value })
                                }
                            />
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Przedziały czasowe:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="numberOfSpans"
                                    value={this.state.numberOfSpans}
                                    placeholder="Wybierz.."
                                    onChange={value =>
                                        this.setState({ numberOfSpans: value })
                                    }
                                    options={this.state.numberOfSpansOptions}
                                />
                            </Col>
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Branża:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="industry"
                                    value={this.state.industry}
                                    placeholder="Wybierz.."
                                    onChange={value =>
                                        this.setState({ industry: value })
                                    }
                                    options={this.state.industryOptions}
                                />
                            </Col>
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Ulica, numer domu i miasto:</ControlLabel>
                            <LocationSearchInput
                                value={this.state.streetAddress}
                                onAddressNotFound={this.onAddressNotFound}
                                onAddressChange={this.onAddressChange} />
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Numer lokalu:</ControlLabel>
                            <FormControl
                                type="text"
                                name="apartmentNumber"
                                placeholder="(opcjonalnie)"
                                value={this.state.apartmentNumber}
                                onChange={event =>
                                    this.setState({ apartmentNumber: event.target.value })
                                }
                            />
                        </FormGroup>
                    </fieldset>
                </Col>
                <Col md={6}>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>NIP:</ControlLabel>
                            <FormControl
                                type="text"
                                name="taxId"
                                placeholder="(obowiązkowy)"
                                value={this.state.taxId}
                                onChange={event =>
                                    this.setState({ taxId: event.target.value })
                                }
                            />
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Powiadomienia dot. reklamacji (e-mail):</ControlLabel>
                            <FormControl
                                type="text"
                                name="complaintEmailAddress"
                                placeholder="(opcjonalnie, oddzielane przecinkami)"
                                value={this.state.complaintEmailAddress}
                                onChange={event =>
                                    this.setState({ complaintEmailAddress: event.target.value })
                                }
                            />
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Średni czas realizacji zlecenia, bez usług i dojazdu:</ControlLabel>
                            <FormControl
                                type="number"
                                name="baseOrderDuration"
                                placeholder="(w minutach)"
                                value={this.state.baseOrderDuration}
                                onChange={event =>
                                    this.setState({ baseOrderDuration: event.target.value })
                                }
                            />
                        </FormGroup>
                    </fieldset>
                    <fieldset>
                        <FormGroup>
                            <ControlLabel>Opcje dodatkowe:</ControlLabel>
                            <Checkbox
                                label="Możliwość umówienia zlecenia bez daty"
                                number={`allowEmptyDate`}
                                checked={this.state.allowEmptyDate}
                                onChange={event => {
                                    this.setState({ allowEmptyDate: event.target.checked });
                                }}
                            />
                            <ControlLabel>Opcje e-commerce:</ControlLabel>
                            <Checkbox
                                label="Konieczność umówienia zlecenia bez daty"
                                number={`forceEmptyDate`}
                                checked={this.state.forceEmptyDate}
                                onChange={event => {
                                    this.setState({ forceEmptyDate: event.target.checked });
                                }}
                            />
                            <Checkbox
                                label="Możliwość przekazania zlecenia do transferu"
                                number={`allowTransferable`}
                                checked={this.state.allowTransferable}
                                onChange={event => {
                                    this.setState({ allowTransferable: event.target.checked });
                                }}
                            />
                            <Checkbox
                                label="Możliwość odebrania zlecenia do transferu"
                                number={`isTransferable`}
                                checked={this.state.isTransferable}
                                onChange={event => {
                                    this.setState({ isTransferable: event.target.checked });
                                }}
                            />
                            <Checkbox
                                label="Możliwość umówienia zlecenia bez wniesienia"
                                number={`allowNoBringing`}
                                checked={this.state.allowNoBringing}
                                onChange={event => {
                                    this.setState({ allowNoBringing: event.target.checked });
                                }}
                            />
                        </FormGroup>
                    </fieldset>
                </Col>
            </Row>
        );

        const servicesList = (
            <ExpertServicesListComponent
                services={this.state.services ? this.state.services : []}
                skills={this.state.skills ? this.state.skills : []}
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
                onServicesChange={this.onServicesChange}
            />
        );

        const zonesList = (
            <ExpertZonesListComponent
                allowTransferable={this.state.allowTransferable || false}
                zones={this.state.zones ? this.state.zones : []}
                mode={this.state.zonesMode}
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
                onZonesChange={this.onZonesChange}
            />
        );

        const usersList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/clientEmployee`}
                endpointProfileCreate="/admin/user/create/clientEmployee"
                endpointProfileUpdate="/admin/user/update/clientEmployee"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usersOverviewList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/clientOverview`}
                endpointProfileCreate="/admin/user/create/clientOverview"
                endpointProfileUpdate="/admin/user/update/clientOverview"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usersAdminList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/admin`}
                endpointProfileCreate="/admin/user/create/admin"
                endpointProfileUpdate="/admin/user/update/admin"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usersExpertManagerList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/expertManager`}
                endpointProfileCreate="/admin/user/create/expertManager"
                endpointProfileUpdate="/admin/user/update/expertManager"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usersIndustryManagerList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/industryManager`}
                endpointProfileCreate="/admin/user/create/industryManager"
                endpointProfileUpdate="/admin/user/update/industryManager"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usersRosebrockManagerList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/rosebrockManager`}
                endpointProfileCreate="/admin/user/create/rosebrockManager"
                endpointProfileUpdate="/admin/user/update/rosebrockManager"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usershubWarehouseList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/hubWarehouse`}
                endpointProfileCreate="/admin/user/create/hubWarehouse"
                endpointProfileUpdate="/admin/user/update/hubWarehouse"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const usersExpertWarehousersList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.department ? this.state.department.value : null}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/user/company/${this.state.companyUuid}/expertWarehouse`}
                endpointProfileCreate="/admin/user/create/expertWarehouse"
                endpointProfileUpdate="/admin/user/update/expertWarehouse"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const warehousesList = (
            <ExpertWarehousesList
                headless={true}
                company={this.state.companyUuid ? this.state.companyUuid : null}
                endpointList={`/admin/warehouse/by-company/${this.state.companyUuid}`}
                endpointCreate="/admin/warehouse/create"
                endpointUpdate="/admin/warehouse/update"
                endpointDetails="/admin/warehouse/details"
                dialogClassName="second-level-modal-backdrop"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const cutoffList = (
            <ExpertCutoffListComponent
                cutoff={this.state.cutoff ? this.state.cutoff : []}
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
                onCutoffChange={this.onCutoffChange}
            />
        )

        const columnsList = (
            <>
                <FormGroup className="clearfix">
                    <Row className="clearfix">
                        <Col md={6}>
                            <ControlLabel>Konfiguracja kolumn:</ControlLabel>
                            <Checkbox
                                number="overrideColumns"
                                label="Nadpisz konfigurację kolumn"
                                style={{ marginTop: 8 }}
                                checked={this.state.overrideColumns}
                                onChange={event => {
                                    this.setState({ overrideColumns: event.target.checked });
                                }}
                            />
                        </Col>
                        <Col md={6} className="text-right">
                            <Button
                                onClick={() => { this.setState({ overrideColumns: false, columns: [] }) }}
                                icon>
                                <i className="fa fa-times" /> Resetuj ustawienia
                            </Button>
                        </Col>
                    </Row>
                </FormGroup>

                <ExpertColumnsListComponent
                    items={this.state.columns ? this.state.columns : []}
                    showNoty={this.props.showNoty}
                    setProgress={this.props.setProgress}
                    onChange={(newColumns) => { this.setState({ columns: newColumns }) }}
                />
            </>
        )

        const editCompany = (
            <form onSubmit={this.handleCompanySubmit.bind(this)}>
                <Tab.Container
                    id="tabs-company"
                    activeKey={this.state.tab}
                    onSelect={this.handleTabSelect.bind(this)}
                >
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Nav bsStyle="tabs">
                                <NavItem eventKey="basic">Informacje</NavItem>
                                {this.state.companyUuid ? <NavItem eventKey="services">Usługi</NavItem> : ""}
                                {this.state.companyUuid ? <NavItem eventKey="zones">Strefy</NavItem> : ""}
                                <NavDropdown title="Użytkownicy">
                                    {this.state.companyUuid ? <NavItem eventKey="users">Pracownicy</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-overview">Dział reklamacji</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-admin">Administratorzy EXPERT</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-expertManager">Managerowie EXPERT</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-industryManager">Managerowie KLIENTA (wszystkie sklepy z branży)</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-expertWarehouse">Magazynierzy EXPERT</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-rosebrockManager">Managerowie ROSEBROCK</NavItem> : ""}
                                    {this.state.companyUuid ? <NavItem eventKey="users-hubWarehouse">HUB Magazyn</NavItem> : ""}
                                </NavDropdown>
                                {this.state.companyUuid ? <NavItem eventKey="cutoff">Odcięcia</NavItem> : ""}
                                {this.state.companyUuid ? <NavItem eventKey="columns">Lista kolumn</NavItem> : ""}
                                {this.state.companyUuid ? <NavItem eventKey="warehouses">Magazyny</NavItem> : ""}
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content animation={false}>
                                <Tab.Pane eventKey="basic" style={{ padding: "15px 0px" }}>{editCompanyForm}</Tab.Pane>
                                {this.state.companyUuid ? <Tab.Pane eventKey="services" style={{ padding: "15px 0px" }}>{servicesList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="zones" style={{ padding: "15px 0px" }}>{zonesList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users" style={{ padding: "15px 0px" }}>{usersList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-overview" style={{ padding: "15px 0px" }}>{usersOverviewList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-admin" style={{ padding: "15px 0px" }}>{usersAdminList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-expertManager" style={{ padding: "15px 0px" }}>{usersExpertManagerList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-industryManager" style={{ padding: "15px 0px" }}>{usersIndustryManagerList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-expertWarehouse" style={{ padding: "15px 0px" }}>{usersExpertWarehousersList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-rosebrockManager" style={{ padding: "15px 0px" }}>{usersRosebrockManagerList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="users-hubWarehouse" style={{ padding: "15px 0px" }}>{usershubWarehouseList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="cutoff" style={{ padding: "15px 0px" }}>{cutoffList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="columns" style={{ padding: "15px 0px" }}>{columnsList}</Tab.Pane> : ""}
                                {this.state.companyUuid ? <Tab.Pane eventKey="warehouses" style={{ padding: "15px 0px" }}>{warehousesList}</Tab.Pane> : ""}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <Button type="submit" disabled={this.state.isLoading} bsStyle="default" onClick={!this.state.isLoading ? this.handleCompanySubmit.bind(this) : null}>
                    {this.state.isLoading ? 'Zapisywanie…' : 'Zapisz dane'}
                </Button>
            </form>
        );

        const editCompanyLoader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const editCompanyPlaceholder = (
            <div>
                <p className="lead text-muted">
                    <>Wybierz firmę z listy, lub</>
                    <Button bsStyle="default" round wd style={{ marginLeft: "5px" }} onClick={() => { this.editCompany(null) }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus" />
                        </span>
                        <>stwórz nową firmę</>
                    </Button>
                </p>
            </div>
        );

        const modal = (
            <Modal show={this.state.showModal} onHide={this.hideModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja danych</Modal.Header>
                <Modal.Body>
                    {this.state.companySelected ? this.state.companyIsLoading ? editCompanyLoader : editCompany : editCompanyPlaceholder}
                </Modal.Body>
            </Modal>
        )

        return (
            <div className="main-content">
                {modal}
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<div>
                                    Lista firm
                                    <Button bsStyle="default" pullRight round onClick={() => { this.editCompany(null) }}>
                                        <span className="btn-label">
                                            <i className="fa fa-plus" />
                                        </span>
                                    </Button>
                                </div>}
                                content={
                                    this.state.showTable ? <Datatable
                                        data={this.state.dataTable}
                                        pages={this.state.pagesTable}
                                        loading={this.state.loadingTable}
                                        manual
                                        filterable
                                        columns={[
                                            {
                                                Header: "Nazwa firmy",
                                                accessor: "name"
                                            },
                                            {
                                                Header: "Oddział",
                                                accessor: "department"
                                            },
                                            {
                                                Header: "Opcje",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                width: 100
                                            }
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "name",
                                                desc: false
                                            }
                                        ]}
                                        onFetchData={(state, instance) => {
                                            let self = this;
                                            self.setState({ loadingTable: true });
                                            _api.request("/admin/company/list", {
                                                page: state.page,
                                                pageSize: state.pageSize,
                                                sorted: state.sorted,
                                                filtered: state.filtered
                                            }, (res) => {
                                                self.setState({
                                                    dataTable: res.rows.map((prop, key) => {
                                                        prop.actions = (
                                                            <div className="actions-right">
                                                                <OverlayTrigger placement="top" overlay={oEdit}>
                                                                    <Button
                                                                        onClick={() => { this.editCompany(prop.uuid) }}
                                                                        bsStyle="default"
                                                                        simple
                                                                        icon>
                                                                        <i className="fa fa-edit" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={oTrash}>
                                                                    <Button
                                                                        onClick={() => { this.popupTrash(prop.uuid) }}
                                                                        bsStyle="danger"
                                                                        simple
                                                                        icon>
                                                                        <i className="fa fa-times" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )
                                                        return prop;
                                                    }),
                                                    pageTable: res.page,
                                                    pagesTable: res.pages,
                                                    pageSizeTable: res.pageSize,
                                                    sortedTable: res.sorted,
                                                    filteredTable: res.filtered,
                                                    loadingTable: false
                                                });
                                            }, (error) => {
                                                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                self.setState({ loadingTable: false });
                                            }, (progress) => {
                                                self.props.setProgress(progress);
                                            });
                                        }}
                                        defaultPageSize={this.state.defaultPageSizeTable}
                                        showPaginationBottom
                                        className="-striped -highlight"
                                        previousText="Poprzednia"
                                        nextText="Następna"
                                        noDataText="Brak danych"
                                        loadingText="Pobieranie danych"
                                        pageText=""
                                        ofText=" z "
                                        rowsText="wierszy"
                                    /> :
                                        <div style={{ "paddingBottom": "26px" }}>
                                            <PropagateLoader
                                                sizeUnit={"px"}
                                                size={15}
                                                margin={2}
                                                css={{
                                                    "display": "block",
                                                    "margin": "10px auto",
                                                    "width": "1px"
                                                }}
                                                color={'#777'}
                                                loading={true}
                                            />
                                        </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminCompanies;
