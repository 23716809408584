/* global _api */
import React, { Component } from "react";
import AsyncSelect from 'react-select/async';

class AsyncSelectWrapper extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputValue: props.value
        };
        this.customStyles = {
            control: () => ({
                width: props.width,
            }),
        }
    }

    handleInputChange = (newValue) => {
        const inputValue = newValue;
        this.setState({ inputValue });
        return inputValue;
    };

    render() {

        return (
            <>
                <AsyncSelect
                    placeholder={this.props.placeholder || "..."}
                    className="react-select primary"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => this.state.inputValue ? "brak wyników" : "wpisz frazę"}
                    loadingMessage={() => "pobieranie danych"}
                    isDisabled={this.props.disabled}
                    isSearchable={true}
                    cacheOptions={this.props.cache}
                    defaultOptions={this.props.preload}
                    value={this.props.value}
                    onInputChange={this.handleInputChange}
                    styles={this.customStyles}
                    loadOptions={
                        (input, cb) => {
                            if (input.length < 3) {
                                return;
                            }
                            let self = this;
                            _api.request(
                                this.props.endpoint,
                                Object.assign({}, { search: input }, this.props.params || {}),
                                (data) => {
                                    cb(data);
                                },
                                (error) => {
                                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                }
                            );
                        }
                    }
                    onChange={
                        (value) => {
                            this.props.onChange(value);
                        }
                    }
                />
            </>
        );
    }
}

export default AsyncSelectWrapper;