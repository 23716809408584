export const $STATUS2_BRAK = 'brak';
export const $STATUS2_NOWE = 'nowe';
export const $STATUS2_ROBOCZE = 'robocze';
export const $STATUS2_NOWEDOTRANSFERU = 'nowe do transferu';
export const $STATUS2_ODBIORODNADAWCY = 'odbiór od nadawcy';
export const $STATUS2_ODEBRANEODNADAWCY = 'odebrane od nadawcy';
export const $STATUS2_AWIZACJA = 'awizacja';
export const $STATUS2_WYDANE = 'wydane';
export const $STATUS2_CZESCIOWOWYDANE = 'częściowo wydane';
export const $STATUS2_PRZYJETE = 'przyjęte';
export const $STATUS2_CZESCIOWOPRZYJETE = 'częściowo przyjęte';
export const $STATUS2_WDOSTAWIE = 'w dostawie';
export const $STATUS2_AWIZACJADOHUBLASTMILE = 'awizacja do hub last mile';
export const $STATUS2_TRANSFERDOHUBLASTMILE = 'transfer do hub last mile';
export const $STATUS2_MAGAZYNLASTMILE = 'magazyn last mile';
export const $STATUS2_WYDANEDODORECZENIA = 'wydane do doręczenia';
export const $STATUS2_DOSTARCZONE = 'dostarczone';
export const $STATUS2_NIEZREALIZOWANE = 'niezrealizowane';
export const $STATUS2_ZGLOSZONOREKLAMACJE = 'zgłoszono reklamację';
export const $STATUS2_ZWROT = 'zwrot';
export const $STATUS2_USZKODZONE = 'uszkodzone';
export const $STATUS2_CZESCIOWOUSZKODZONE = 'częściowo uszkodzone';
export const $STATUS2_ANULOWANE = 'anulowane';