/* global _api */
import React, { Component } from "react";
import Card from "../Card/Card.jsx";
import Button from "../CustomButton/CustomButton.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Modal,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import ExpertWarehouseComponent from "./WarehouseComponent";
import Datatable from "../Datatable";

class ExpertWarehousesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            header: this.props.header || "Lista magazynów",
            headless: this.props.headless || false,
            dataTable1: [],
            pagesTable1: -1,
            pageTable1: 0,
            defaultPageSizeTable1: this.props.defaultPageSize || 5,
            loadingTable1: true,
            showModal: false,
            uuid: null,
            companyUuid: this.props.company || null,
            endpoint: this.props.endpoint || null,
            showNoty: this.props.showNoty || null,
            setProgress: this.props.setProgress || null,
            endpointList: this.props.endpointList,
            endpointCreate: this.props.endpointCreate,
            endpointUpdate: this.props.endpointUpdate,
            endpointDetails: this.props.endpointDetails
        };
    }

    showModal(uuid) {
        this.setState({ showModal: true, uuid: uuid });
    }

    hideModal() {
        this.setState({ showModal: false, uuid: null });
    }

    popupSaved() {
        this.props.showNoty('tr', 'success', 'Dane zostały zapisane');
        this.reloadList();
    }

    popupError() {
        this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisu danych');
    }

    moveToTrash(uuid) {
        let self = this;
        _api.request(`/admin/trash/recursive/warehouse/${uuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'info', 'Przeniesiono do kosza');
                self.reloadList();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    reloadList() {
        let self = this;
        this.fetchList({
            page: self.state.pageTable1,
            pageSize: self.state.pageSizeTable1,
            sorted: self.state.sortedTable1,
            filtered: self.state.filteredTable1
        });
    }

    fetchList = (state, instance) => {

        const oEdit = <Tooltip id="oEdit">Edycja danych</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;

        let self = this;

        self.setState({ loadingTable1: true });
        _api.request(this.props.endpointList, {
            page: state.page,
            pageSize: self.state.pageSizeTable1,
            sorted: state.sorted,
            filtered: state.filtered
        }, (res) => {
            self.setState({
                dataTable1: res.rows.map((prop, key) => {
                    prop.actions = (
                        <div className="actions-right">
                            <OverlayTrigger placement="top" overlay={oEdit}>
                                <Button
                                    onClick={() => { this.showModal(prop.uuid) }}
                                    bsStyle="default"
                                    simple
                                    icon>
                                    <i className="fa fa-edit" />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={oTrash}>
                                <Button
                                    onClick={() => { this.popupTrash(prop.uuid) }}
                                    bsStyle="danger"
                                    simple
                                    icon>
                                    <i className="fa fa-times" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    )
                    return prop;
                }),
                pageTable1: res.page,
                pagesTable1: res.pages,
                loadingTable1: false,
                pageSizeTable1: res.pageSize,
                sortedTable1: res.sorted,
                filteredTable1: res.filtered
            });
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loadingTable1: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    render() {

        const elementModal = (
            <Modal dialogClassName={this.props.dialogClassName || ""} show={this.state.showModal} onHide={this.hideModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja danych</Modal.Header>
                <Modal.Body>
                    <ExpertWarehouseComponent
                        isAdminView={true}
                        uuid={this.state.uuid}
                        companyUuid={this.state.companyUuid}
                        endpointCreate={this.state.endpointCreate}
                        endpointUpdate={this.state.endpointUpdate}
                        endpointDetails={this.state.endpointDetails}
                        showNoty={this.props.showNoty}
                        setProgress={this.props.setProgress}
                        onSaved={this.popupSaved.bind(this)}
                        onError={this.popupError.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const content = (
            <Datatable
                data={this.state.dataTable1}
                pages={this.state.pagesTable1}
                loading={this.state.loadingTable1}
                manual
                filterable
                columns={[
                    {
                        Header: "Pozycja",
                        accessor: "position",
                        width: 100
                    },
                    {
                        Header: "Nazwa",
                        accessor: "name"
                    },
                    {
                        Header: "Śr. czas załadunku (min.)",
                        accessor: "avgLoadingTime"
                    },
                    {
                        Header: "Opcje",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 100
                    }
                ]}
                defaultSorted={[
                    {
                        id: "name",
                        desc: false
                    }
                ]}
                onFetchData={this.fetchList}
                defaultPageSize={this.state.defaultPageSizeTable1}
                showPaginationBottom
                className="-striped -highlight"
                previousText="Poprzednia"
                nextText="Następna"
                noDataText="Brak danych"
                loadingText="Pobieranie danych"
                pageText=""
                ofText=" z "
                rowsText="wierszy"
            />
        )

        const viewAsCard = (
            <Card
                title={<div>
                    {this.state.header}
                    <Button bsStyle="info" round onClick={() => { this.showModal(null) }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus" />
                        </span>
                        dodaj
                    </Button>
                </div>}
                content={content}
            />
        )

        const viewAsHeadless = (
            <div>
                <Button bsStyle="info" round onClick={() => { this.showModal(null) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    dodaj
                </Button>
                {content}
            </div>
        )

        const usersList = this.state.headless ? viewAsHeadless : viewAsCard;

        return (
            <div>
                {this.state.alert}
                {elementModal}
                {usersList}
            </div>
        )

    }

}


export default ExpertWarehousesList;