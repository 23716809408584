/* global _api */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import ExpertUsersListComponent from "../../../components/Expert/UsersListComponent.jsx";
import LocationSearchInput from "../../../components/Map/LocationSearchInput.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Tab,
    Nav,
    NavItem,
    Modal,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Datatable from "../../../components/Datatable";

class ExpertAdminDepartments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            tab: "basic",
            tabDefault: "basic",
            showModal: false,
            showTable: true,
            dataTable: [],
            pagesTable: -1,
            pageTable: 0,
            defaultPageSizeTable: 10,
            loadingTable: true,
            departmentSelected: false,
            departmentIsLoading: false,
            departmentUuid: null,
            departmentName: "",
            departmentCapacity: "20",
            departmentRegion: "",
            departmentScanPath: "",
            streetAddress: "",
            geoLat: "",
            geoLng: "",
        };
    }

    componentWillUnmount() {
        _api.abort();
    }

    editDepartment = (uuid) => {
        let self = this;

        self.setState({ departmentUuid: uuid, departmentSelected: true, departmentIsLoading: true, showModal: true });

        if (!uuid) {
            self.setState({
                tab: self.state.tabDefault,
                departmentUuid: null,
                departmentName: "",
                departmentCapacity: "20",
                departmentSelected: true,
                departmentIsLoading: false,
                departmentRegion: "",
                departmentScanPath: "",
                streetAddress: "",
                geoLat: "",
                geoLng: ""
            });
            return;
        }

        _api.request(`/admin/department/details/${uuid}`, {},
            (data) => {
                self.setState({
                    departmentUuid: data.department.uuid,
                    departmentName: data.department.name || "",
                    departmentCapacity: data.department.capacity || "",
                    departmentRegion: data.department.region || "",
                    departmentScanPath: data.department.scanPath || "",
                    streetAddress: data.address.streetAddress || "",
                    geoLat: data.address.geoLat,
                    geoLng: data.address.geoLng,
                    departmentIsLoading: false
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleFleetSubmit(event) {
        event.preventDefault();

        let self = this;

        let validationRules = {
            "isValid": true,
            "items": [
                { "field": "departmentName", "rules": { "empty": "Uzupełnij nazwę" } },
                { "field": "streetAddress", "rules": { "empty": "Uzupełnij ulicę, numer domu i miasto" } },
            ]
        };

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = self.state[element.field];
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
            }
        });

        if (!validationRules.isValid) {
            return;
        }

        self.setState({ isLoading: true, showTable: false });
        self.props.setProgress(0);
        let endpoint = this.state.departmentUuid ? `/admin/department/update/${this.state.departmentUuid}` : `/admin/department/create`;
        _api.request(endpoint,
            {
                "department": {
                    "name": self.state.departmentName,
                    "capacity": self.state.departmentCapacity,
                    "region": self.state.departmentRegion,
                    "scanPath": self.state.departmentScanPath
                },
                "address": {
                    "streetAddress": self.state.streetAddress,
                    "geoLat": self.state.geoLat,
                    "geoLng": self.state.geoLng
                }
            },
            (data) => {
                self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                self.setState({ isLoading: false, departmentUuid: data, showTable: true });
                self.props.setProgress(100);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                self.setState({ isLoading: false, showTable: true });
                self.props.setProgress(100);
            }
        );
    }

    hideModal() {
        this.setState({ showModal: false });
    }

    handleTabSelect(key) {
        this.setState({ tab: key });
    }

    onAddressChange = (data) => {
        this.setState({ streetAddress: data.address, geoLat: data.addressLatLng.lat, geoLng: data.addressLatLng.lng });
    }

    onAddressNotFound = (data) => {
        this.props.showNoty('tr', 'error', 'Podany adres nie został znaleziony');
        this.setState({ streetAddress: '', geoLat: '', geoLng: '' });
    }

    moveToTrash(uuid) {
        let self = this;
        self.setState({ showTable: false }, () => {
            _api.request(`/admin/trash/recursive/department/${uuid}`, {},
                (data) => {
                    self.props.showNoty('tr', 'info', 'Oddział został usunięty');
                    self.setState({ showTable: true });
                },
                (error) => {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
                },
                (progress) => {
                    self.props.setProgress(progress);
                }
            );
        }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń element"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    render() {

        const oEdit = <Tooltip id="oEdit">Edycja danych</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;

        const managersList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.departmentUuid}
                endpointList={`/admin/user/department/${this.state.departmentUuid}`}
                endpointProfileCreate="/admin/user/create/expertManager"
                endpointProfileUpdate="/admin/user/update/expertManager"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const fleetList = (
            <ExpertUsersListComponent
                headless={true}
                department={this.state.departmentUuid}
                endpointList={`/admin/user/department/${this.state.departmentUuid}/expertFleet`}
                endpointProfileCreate="/admin/user/create/expertFleet"
                endpointProfileUpdate="/admin/user/update/expertFleet"
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
            />
        );

        const editDepartmentForm = (
            <div>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Nazwa oddziału:</ControlLabel>
                        <FormControl
                            type="text"
                            name="departmentName"
                            value={this.state.departmentName}
                            onChange={event =>
                                this.setState({ departmentName: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Region:</ControlLabel>
                        <FormControl
                            type="text"
                            name="departmentRegion"
                            placeholder="np. GDA"
                            value={this.state.departmentRegion}
                            onChange={event =>
                                this.setState({ departmentRegion: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Adres e-mail skanera:</ControlLabel>
                        <FormControl
                            type="text"
                            name="departmentScanPath"
                            placeholder={`np. oddzial@sg.zlecenia.expert`}
                            value={this.state.departmentScanPath}
                            onChange={event =>
                                this.setState({ departmentScanPath: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Domyślne capacity:</ControlLabel>
                        <FormControl
                            type="number"
                            name="departmentCapacity"
                            value={this.state.departmentCapacity}
                            onChange={event =>
                                this.setState({ departmentCapacity: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Ulica, numer domu i miasto siedziby:</ControlLabel>
                        <LocationSearchInput
                            value={this.state.streetAddress}
                            onAddressNotFound={this.onAddressNotFound}
                            onAddressChange={this.onAddressChange} />
                    </FormGroup>
                </fieldset>
            </div>
        )

        const editDepartment = (
            <form onSubmit={this.handleFleetSubmit.bind(this)}>
                <Tab.Container
                    id="tabs-fleet"
                    activeKey={this.state.tab}
                    onSelect={this.handleTabSelect.bind(this)}
                >
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Nav bsStyle="tabs">
                                <NavItem eventKey="basic">Informacje</NavItem>
                                {this.state.departmentUuid ? <NavItem eventKey="managers">Managerowie</NavItem> : ""}
                                {this.state.departmentUuid ? <NavItem eventKey="fleet">Kierowcy i pomocnicy</NavItem> : ""}
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content animation={false}>
                                <Tab.Pane eventKey="basic" style={{ padding: "15px 0px" }}>{editDepartmentForm}</Tab.Pane>
                                {this.state.departmentUuid ? <Tab.Pane eventKey="managers" style={{ padding: "15px 0px" }}>{managersList}</Tab.Pane> : ""}
                                {this.state.departmentUuid ? <Tab.Pane eventKey="fleet" style={{ padding: "15px 0px" }}>{fleetList}</Tab.Pane> : ""}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <Button type="submit" disabled={this.state.isLoading} bsStyle="default" onClick={!this.state.isLoading ? this.handleFleetSubmit.bind(this) : null}>
                    {this.state.isLoading ? 'Zapisywanie…' : 'Zapisz dane'}
                </Button>
            </form>
        );

        const editDepartmentLoader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const editDepartmentPlaceholder = (
            <div>
                <p className="lead text-muted">
                    <>Wybierz z listy, lub</>
                    <Button bsStyle="default" round wd style={{ marginLeft: "5px" }} onClick={() => { this.editDepartment(null) }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus" />
                        </span>
                        <>stwórz nowy oddział</>
                    </Button>
                </p>
            </div>
        );

        const modal = (
            <Modal show={this.state.showModal} onHide={this.hideModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static" size="large">
                <Modal.Header closeButton>Edycja danych</Modal.Header>
                <Modal.Body>
                    {this.state.departmentSelected ? this.state.departmentIsLoading ? editDepartmentLoader : editDepartment : editDepartmentPlaceholder}
                </Modal.Body>
            </Modal>
        )

        return (
            <div className="main-content">
                {modal}
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<div>
                                    Lista oddziałów
                                    <Button bsStyle="default" pullRight round onClick={() => { this.editDepartment(null) }}>
                                        <span className="btn-label">
                                            <i className="fa fa-plus" />
                                        </span>
                                    </Button>
                                </div>}
                                content={
                                    this.state.showTable ? <Datatable
                                        data={this.state.dataTable}
                                        pages={this.state.pagesTable}
                                        loading={this.state.loadingTable}
                                        manual
                                        filterable
                                        columns={[
                                            {
                                                Header: "Oddział Expert",
                                                accessor: "name"
                                            },
                                            {
                                                Header: "Region",
                                                accessor: "region"
                                            },
                                            {
                                                Header: "Opcje",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                width: 100
                                            }
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "name",
                                                desc: false
                                            }
                                        ]}
                                        onFetchData={(state, instance) => {
                                            let self = this;
                                            self.setState({ loadingTable: true });
                                            _api.request("/admin/department/list", {
                                                page: state.page,
                                                pageSize: state.pageSize,
                                                sorted: state.sorted,
                                                filtered: state.filtered
                                            }, (res) => {
                                                self.setState({
                                                    dataTable: res.rows.map((prop, key) => {
                                                        prop.actions = (
                                                            <div className="actions-right">
                                                                <OverlayTrigger placement="top" overlay={oEdit}>
                                                                    <Button
                                                                        onClick={() => { this.editDepartment(prop.uuid) }}
                                                                        bsStyle="default"
                                                                        simple
                                                                        icon>
                                                                        <i className="fa fa-edit" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={oTrash}>
                                                                    <Button
                                                                        disabled={!this.props.userHasRole(['admin'])}
                                                                        onClick={() => { this.popupTrash(prop.uuid) }}
                                                                        bsStyle="danger"
                                                                        simple
                                                                        icon>
                                                                        <i className="fa fa-times" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )
                                                        return prop;
                                                    }),
                                                    pageTable: res.page,
                                                    pagesTable: res.pages,
                                                    pageSizeTable: res.pageSize,
                                                    sortedTable: res.sorted,
                                                    filteredTable: res.filtered,
                                                    loadingTable: false
                                                });
                                            }, (error) => {
                                                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                self.setState({ loadingTable: false });
                                            }, (progress) => {
                                                self.props.setProgress(progress);
                                            });
                                        }}
                                        defaultPageSize={this.state.defaultPageSizeTable}
                                        showPaginationBottom
                                        className="-striped -highlight"
                                        previousText="Poprzednia"
                                        nextText="Następna"
                                        noDataText="Brak danych"
                                        loadingText="Pobieranie danych"
                                        pageText=""
                                        ofText=" z "
                                        rowsText="wierszy"
                                    />
                                        :
                                        <div style={{ "paddingBottom": "26px" }}>
                                            <PropagateLoader
                                                sizeUnit={"px"}
                                                size={15}
                                                margin={2}
                                                css={{
                                                    "display": "block",
                                                    "margin": "10px auto",
                                                    "width": "1px"
                                                }}
                                                color={'#777'}
                                                loading={true}
                                            />
                                        </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminDepartments;
