/* global _api */
import React, { Component } from "react";
import Datetime from "react-datetime";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import moment from "moment";
import "moment/min/locales";
import Select from "react-select";
import Card from "../../../components/Card/Card.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import ExpertClientOrder from "../Client/Order";
import ScheduleMap from "../../../components/Map/ScheduleMap.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import ScheduleComponent from "../../../components/Expert/ScheduleComponent.jsx";
import OrderPrinterComponent from "../../../components/Expert/OrderPrinterComponent.jsx";
import {
    Grid,
    Row,
    Col,
    OverlayTrigger,
    Tooltip,
    FormGroup,
    Modal,
    ControlLabel,
    FormControl,
    Tab,
    Nav,
    NavItem
} from "react-bootstrap";
import VRouter from "../../../components/VRouter";

moment.locale('pl');

class ExpertAdminScheduleV2 extends Component {

    constructor(props) {
        super(props);

        this.spans = [
            { value: 1, label: "I cz. dnia" },
            { value: 2, label: "II cz. dnia" }
        ];

        this.showOptions = [
            { value: "all", label: "wszystkie zlecenia" },
            { value: "open", label: "niezrealizowane zlecenia" },
            { value: "closed", label: "zrealizowane zlecenia" }
        ]

        this.state = {
            alert: null,
            department: null,
            departmentLoading: true,
            departments: [],
            selectedDate: moment().format("YYYY-MM-DD"),
            depot: null,
            ordersAll: [],
            schedulesAll: [],
            ordersLoading: true,
            schedulesLoading: true,
            selectedScheduleName: null,
            selectedScheduleUuid: null,
            showScheduleModal: false,
            showPrinterModal: false,
            orderUuid: null,
            tab: "schedules",
            firstRun: true,
            filterSpan: null,
            filterSearch: "",
            filterSchedule: null,
            distance: "",
            duration: "",
            refs: [],
            show: this.showOptions[0],
            isLoading: false,
        };

        this.refMap = React.createRef();
    }

    componentDidMount() {
        let self = this;

        this.props.forceMiniSidebar();

        let scheduleData = self.props.location.pathname.replace("/admin/v2-schedule/", "").split('/');
        let departmentGuid = null;
        let selectedDate = moment().format("YYYY-MM-DD");

        if (scheduleData.length >= 2) {
            departmentGuid = scheduleData[0];
            if (!_api.isGuid(departmentGuid)) {
                departmentGuid = null;
            }
            if (moment(scheduleData[1], "YYYY-MM-DD").isValid()) {
                selectedDate = moment(scheduleData[1], "YYYY-MM-DD").format("YYYY-MM-DD");
            }
        }

        if (!self.props.userHasRole('admin')) {
            departmentGuid = self.props.user.user.department.uuid;
        }

        _api.request(`/admin/general/departments`, {},
            (data) => {
                let items = [];
                let selected = null;
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name, "data": data[i], "region": data[i].region });
                    if (departmentGuid && data[i].uuid === departmentGuid) {
                        selected = items[i];
                    }
                }

                if (self.props.userHasRole(['expertManager'])) {
                    items = items.filter((elem) => { return elem.region === selected.region });
                }

                if (items.length) {
                    if (!self.state.department) {
                        if (selected) {
                            self.setDepartment(selected, items, selectedDate);
                        } else {
                            self.setDepartment(items[0], items, selectedDate);
                        }
                    }
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );

        this.props.setNavVisible(false);
    }

    componentWillUnmount() {
        _api.abort();
        this.props.setNavVisible(true);
    }

    setDepartment(currentDepartment, allDepartments, selectedDate) {
        let self = this;

        let callback = () => {
            self.setDate(selectedDate);
        }

        if (allDepartments) {
            self.setState({ department: currentDepartment, departments: allDepartments, departmentLoading: true }, callback);
        } else {
            self.setState({ department: currentDepartment, departmentLoading: true }, callback);
        }

    }

    setDate(date) {
        let self = this;

        let callback = () => {
            self.props.history.push('/admin/v2-schedule/' + self.state.department.value + '/' + self.state.selectedDate);
            self.getOrders();
        }

        self.setState({ selectedDate: moment(date).format("YYYY-MM-DD") }, callback)
    }

    setShow(data) {
        let self = this;

        let callback = () => {
            self.getOrders();
        }

        self.setState({ show: data }, callback)
    }

    getOrders() {
        let self = this;
        let fromDate = self.state.selectedDate;
        let showValue = this.state.show ? this.state.show.value : "";

        self.setState({ ordersLoading: self.state.firstRun });

        _api.request(`/admin/slot/details/${self.state.department.value}/${fromDate}`,
            {
                "filter": showValue
            },
            (data) => {
                if (!data) {
                    data = {
                        'slot': [],
                        'order': [],
                        'depot': null
                    };
                }
                let ordersAll = data.order;
                self.setState({ ordersLoading: false, firstRun: false, depot: data.depot }, () => this.getSchedules(ordersAll));
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );

    }

    getSchedules(ordersAll) {
        let self = this;
        self.setState({ schedulesLoading: self.state.firstRun });

        _api.request(`/schedule/list/${self.state.department.value}/${self.state.selectedDate}/${self.state.selectedDate}`,
            {
                page: 0,
                pageSize: 999,
                sorted: null,
                filtered: null
            },
            (data) => {
                let schedules = data.rows;
                let colours = this.getRainbow(schedules.length);
                let refs = {};

                schedules.map((item, index) => {
                    item.background = colours[index].background;
                    item.foreground = colours[index].foreground;
                    item.label = item.ident + ` (${item.driverName})`;
                    item.value = item.uuid;
                    return item;
                });

                ordersAll.map((item, index) => {
                    if (item.schedule.assigned) {
                        schedules.forEach(schedule => {
                            if (item.schedule.uuid === schedule.uuid) {
                                item.background = schedule.background;
                                item.foreground = schedule.foreground;
                            }
                        });
                    }
                    refs[item.uuid] = React.createRef();
                    return item;
                });

                self.setState({ schedulesLoading: false, firstRun: false, schedulesAll: schedules, ordersAll: ordersAll, refs: refs });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    saveOrdersInOrder() {
        let self = this;
        const filteredOrders = this.state.ordersAll.map((order, index) => {
            return {
                uuid: order.uuid,
                position: order.position,
                schedule: {
                    assigned: order.schedule.assigned,
                    uuid: order.schedule.uuid
                }
            };
        });
        this.setState({ isLoading: true });
        _api.request(`/admin/schedule/orders`,
            {
                orders: filteredOrders
            },
            (data) => {
                self.props.showNoty('tr', 'info', 'Zmiany zostały zapisane');
                self.getOrders();
                this.setState({ isLoading: false });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                this.setState({ isLoading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    bulkSave() {
        let self = this;
        _api.request(`/admin/schedule/bulk-save`,
            {
                orders: this.state.ordersAll,
                schedules: this.state.schedulesAll
            },
            (data) => {
                self.props.showNoty('tr', 'info', 'Zmiany zostały zapisane');
                self.getOrders();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    saveDistanceAndTime(scheduleUuid, distance, time) {
        let self = this;
        _api.request(`/admin/schedule/distance-time/${scheduleUuid}`,
            {
                schedule: {
                    distance: distance,
                    time: time
                }
            },
            (data) => {
                self.props.showNoty('tr', 'info', 'Zmiany zostały zapisane');
                self.getOrders();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    showOrderModal(orderUuid) {
        this.setState({ showOrderModal: true, orderUuid: orderUuid });
    }

    hideOrderModal() {
        this.setState({ showOrderModal: false, orderUuid: null });
    }

    refreshCurrentView() {
        this.getOrders();
    }

    onOrderSaved() {
        this.refreshCurrentView();
    }

    onOrderSavedAndHide() {
        this.refreshCurrentView();
        this.hideOrderModal();
    }

    sinToHex = (i, phase, size) => {
        let sin = Math.sin(Math.PI / size * 2 * i + phase);
        let int = Math.floor(sin * 127) + 128;
        let hex = int.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }

    getRainbow(size) {
        let rainbow = new Array(size);
        for (let i = 0; i < size; i++) {
            let red = this.sinToHex(i, 0 * Math.PI * 2 / 3, size); // 0   deg
            let blue = this.sinToHex(i, 1 * Math.PI * 2 / 3, size); // 120 deg
            let green = this.sinToHex(i, 2 * Math.PI * 2 / 3, size); // 240 deg
            let luma = ((0.299 * parseInt(red, 16)) + (0.587 * parseInt(green, 16)) + (0.114 * parseInt(blue, 16))) / 255;
            let background = "#" + red + green + blue;
            // Return black for bright colors, white for dark colors
            let foreground = luma > 0.5 ? '#000' : '#fff';
            rainbow[i] = {
                background: background,
                foreground: foreground
            }
        }
        return rainbow;
    }

    popupScheduleSaved() {
        this.setState({
            alert: (
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Harmonogram został zapisany"
                    confirmBtnText="Zamknij"
                    onConfirm={() => { this.setState({ alert: null, showScheduleModal: false }); this.getOrders(); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });
    }

    popupScheduleError() {
        this.setState({
            alert: (
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Wystąpił błąd podczas zapisu danych"
                    confirmBtnText="Zamknij"
                    confirmBtnBsStyle="default"
                    onConfirm={() => this.setState({ alert: null })}>
                </SweetAlert>
            )
        });
    }

    moveToTrash(uuid) {
        let self = this;
        _api.request(`/admin/trash/recursive/schedule/${uuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'info', 'Harmonogram został usunięty');
                self.getOrders();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń element"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    showScheduleModal(scheduleUuid) {
        this.setState({ showScheduleModal: true, selectedScheduleUuid: scheduleUuid });
    }

    hideScheduleModal() {
        this.setState({ showScheduleModal: false });
    }

    showPrinterModal(scheduleUuid) {
        this.setState({ showPrinterModal: true, selectedScheduleUuid: scheduleUuid });
    }

    hidePrinterModal() {
        this.setState({ showPrinterModal: false });
    }

    showScheduleMap(scheduleName, scheduleUuid) {
        this.setState({
            selectedScheduleName: scheduleName,
            selectedScheduleUuid: scheduleUuid,
        });
        window.scrollTo(0, this.refMap.current.parentElement.offsetTop);
    }

    handleTabSelect(key) {
        this.setState({ tab: key });
    }

    bindOrderWithSchedule(orderUuid, scheduleUuid) {
        let newPosition = 0;
        let isAssigned = false;
        let selectedSchedule = null;

        this.setState({ isLoading: true });

        if (scheduleUuid) {
            selectedSchedule = this.state.schedulesAll.filter(itm => itm.uuid === scheduleUuid)[0];
            if (selectedSchedule) {
                isAssigned = true;
            }
        }

        if (isAssigned) {
            newPosition = this.state.ordersAll.filter((order) => {
                return order.schedule.uuid === scheduleUuid;
            }).length + 1;
        }

        const newOrders = this.state.ordersAll.map((order, index) => {
            if (order.uuid !== orderUuid) return order;
            let updatedOrder = { ...order };
            updatedOrder.position = newPosition;
            updatedOrder.schedule.assigned = isAssigned;
            updatedOrder.schedule.uuid = isAssigned ? selectedSchedule.uuid : null;
            updatedOrder.schedule.name = isAssigned ? selectedSchedule.name : null;
            updatedOrder.background = isAssigned ? selectedSchedule.background : "#000";
            updatedOrder.foreground = isAssigned ? selectedSchedule.foreground : "#fff";
            return updatedOrder;
        });

        this.setState({ ordersAll: newOrders, isLoading: false }, () => { this.saveOrdersInOrder() });
    }

    bindOrderWithPosition(orderUuid, position) {
        const newOrders = this.state.ordersAll.map((order, index) => {
            if (order.uuid !== orderUuid) return order;
            let updatedOrder = { ...order };
            updatedOrder.position = position;
            return updatedOrder;
        });
        this.setState({ ordersAll: newOrders }, () => {
            this.state.refs[orderUuid].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        });
    }

    moveDown(orderUuid) {
        this.bindOrderWithPosition(orderUuid, -1);
    }

    moveUp(orderUuid) {
        this.bindOrderWithPosition(orderUuid, 1);
    }

    onDirectionsChange = (data) => {
        this.setState({ distance: data.distance, duration: moment.utc(data.duration * 1000).format('HH:mm:ss') });
    }

    onDirectionsNotFound = (data) => {
        this.props.showNoty('tr', 'error', 'Wystąpił problem z ustaleniem trasy');
        this.setState({ distance: '', duration: '' });
    }

    render() {

        const oPrint = <Tooltip id="oPrint">Wydrukuj zlecenia</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;
        const noDataPlaceholder = (<p className="lead text-muted" style={{ border: `1px dashed #e3e3d3`, textAlign: `center`, padding: `8px`, lineHeight: `48px`, margin: `-10px -10px -10px -12px` }}>brak danych</p>)

        const loader = (
            <div style={{ "padding": "20px 0", marginBottom: "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const modal = (
            <Modal show={this.state.showOrderModal} onHide={this.hideOrderModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja zamówienia</Modal.Header>
                <Modal.Body>
                    <ExpertClientOrder
                        {...this.props}
                        uuid={this.state.orderUuid}
                        selectedDay={this.state.slotsDate ? moment(this.state.slotsDate).subtract('1', 'day') : null}
                        includeUnavailableSlots={true}
                        nextDaySpan={14}
                        isAdminView={true}
                        department={this.state.department ? this.state.department.value : null}
                        onSaved={this.onOrderSaved.bind(this)}
                        onSavedAndHide={this.onOrderSavedAndHide.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const scheduleModal = (
            <Modal show={this.state.showScheduleModal} onHide={this.hideScheduleModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja harmonogramu</Modal.Header>
                <Modal.Body>
                    <ScheduleComponent
                        uuid={this.state.selectedScheduleUuid}
                        department={this.state.department ? this.state.department.value : null}
                        date={this.state.selectedDate}
                        showNoty={this.props.showNoty}
                        setProgress={this.props.setProgress}
                        onSaved={this.popupScheduleSaved.bind(this)}
                        onError={this.popupScheduleError.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const printerModal = (
            <Modal bsSize="small" show={this.state.showPrinterModal} onHide={this.hidePrinterModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Wydruk harmonogramu</Modal.Header>
                <Modal.Body>
                    <OrderPrinterComponent
                        schedule={this.state.selectedScheduleUuid}
                        showNoty={this.props.showNoty}
                        setProgress={this.props.setProgress}
                        user={this.props.user}
                    />
                </Modal.Body>
            </Modal>
        )

        const ordersFiltered = this.state.ordersAll.filter((item, index) => {
            if (this.state.filterSpan) {
                if (item.dayPartIdent !== this.state.filterSpan.value) {
                    return null;
                }
            }
            if (this.state.filterSearch) {
                let containsString = JSON.stringify(item)
                    .toString()
                    .toLowerCase()
                    .includes(this.state.filterSearch.toLowerCase());
                if (!containsString) {
                    return null;
                }
            }
            if (this.state.filterSchedule) {
                if (item.schedule.uuid !== this.state.filterSchedule.value) {
                    return null;
                }
            }
            return item;
        }).sort((a, b) => (a.position > b.position) ? 1 : ((b.position > a.position) ? -1 : 0));

        let orders = (
            <Col style={{ minHeight: "130px", maxHeight: "60vh", overflowY: "scroll", paddingTop: "6px" }}>

                <Row style={{ margin: 0 }}>
                    <Col md={6} style={{ padding: "0 5px" }}>
                        <FormGroup>
                            <ControlLabel>Przedział: </ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="payer"
                                    value={this.state.filterSpan}
                                    placeholder="..."
                                    onChange={value =>
                                        this.setState({ filterSpan: value })
                                    }
                                    options={this.spans}
                                    isClearable={true}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={6} style={{ padding: "0 5px" }}>
                        <FormGroup>
                            <ControlLabel>Szukaj: </ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.filterSearch}
                                placeholder="..."
                                onChange={e =>
                                    this.setState({ filterSearch: e.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={12} style={{ padding: "0 5px" }}>
                        <FormGroup>
                            <ControlLabel>Harmonogram: </ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    placeholder="Harmonogram"
                                    isClearable={true}
                                    options={this.state.schedulesAll}
                                    value={this.state.filterSchedule}
                                    onChange={value =>
                                        this.setState({ filterSchedule: value, distance: '' })
                                    }
                                    styles={{
                                        menu: provided => ({ ...provided, zIndex: 9999 })
                                    }}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col md={12} style={{ padding: "0 5px" }}>
                        <FormGroup>
                            <ControlLabel>Długość trasy:&nbsp;</ControlLabel>
                            <ControlLabel>
                                {this.state.filterSchedule ? `${this.state.distance || '...'} km / ${this.state.duration || '...'}` : "wybierz harmonogram"}
                                {this.state.filterSchedule && (
                                    <Button bsStyle="info" simple onClick={() => { this.saveDistanceAndTime(this.state.filterSchedule.value, this.state.distance, this.state.duration) }} style={{ padding: 0, marginLeft: "5px" }}>
                                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                                            <i className="fa fa-save" />
                                        </span>
                                    </Button>
                                )}
                            </ControlLabel>
                        </FormGroup>
                    </Col>
                    <Col md={12} style={{ padding: "0 5px" }}>
                        <FormGroup>
                            <div>
                                <Button bsStyle="info" block wd onClick={() => { this.saveOrdersInOrder() }}>
                                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                                        <i className="fa fa-save" />
                                    </span>
                                    <>zapisz kolejność</>
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>

                {this.state.ordersLoading ? loader : ordersFiltered.map((item, index) => {

                    return (
                        <div
                            key={item.uuid}
                            ref={this.state.refs[item.uuid]}
                            index={index}
                            className="card"
                            style={{ margin: "0 6px 10px 3px" }}
                        >
                            <div className="content">
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }} className={item.trash ? "trash-button" : ""}>
                                    <i className="fa fa-circle" style={{ color: item.background }} />
                                    <Button style={{ padding: 0 }} onClick={() => { this.showOrderModal(item.uuid) }} bsStyle="info" simple>Zlecenie {item.ident}</Button>
                                    <FormControl
                                        className="form-control float-right mini-input-right"
                                        minLength={0}
                                        type="number"
                                        value={item.position}
                                        onChange={event => {
                                            this.bindOrderWithPosition(item.uuid, parseInt(event.target.value, 10))
                                        }}
                                    />
                                </p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2", textOverflow: "ellipsis" }}><i className="fa fa-user-o" /> {item.sender.name}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-clock-o" /> {item.dayPart}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-dot-circle-o" /> {item.warehouse.name ? `Magazyn: ${item.warehouse.name} - ${item.warehouse.address.streetAddress} ${item.warehouse.address.streetNumber}, ${item.warehouse.address.cityName}` : `Nadawca: ${item.sender.address.streetAddress}`}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-check-circle-o" /> Odbiorca: {item.recipient.address.streetAddress} {item.recipient.address.streetNumber}, {item.recipient.address.cityName}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-info-circle" /> {item.comment || '---'}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-wrench" /> {item.service && item.service.length ? item.service.map((service) => { return service.name }).join(", ") : '---'} </p>
                            </div>
                        </div>
                    )
                })}
                {!ordersFiltered.length && !this.state.ordersLoading && (
                    <div className="content">
                        {noDataPlaceholder}
                    </div>
                )}
            </Col>
        )

        let schedules = (
            <Col style={{ minHeight: "130px", maxHeight: "60vh", overflowY: "scroll", paddingTop: "6px" }}>

                <div style={{ padding: "5px 5px 5px 3px" }}>
                    <Button bsStyle="success" block wd onClick={() => { this.showScheduleModal(null) }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus" />
                        </span>
                        <>stwórz harmonogram</>
                    </Button>
                </div>

                <div style={{ padding: "5px 5px 5px 3px" }}>
                    <VRouter
                        upperState={this.state}
                        setUpperState={(state, callback) => this.setState(state, callback)}
                        bulkSave={() => this.bulkSave()}
                    />
                </div>

                {this.state.schedulesLoading ? loader : this.state.schedulesAll.map((item, index) => (
                    <div
                        key={item.uuid}
                        index={index}
                        className="card"
                        style={{ margin: "0 6px 10px 3px" }}
                    >
                        <div className="content">
                            <div style={{ fontSize: "14px", lineHeight: "1.2" }} className={item.trash ? "trash-button" : ""}>
                                <i className="fa fa-circle" style={{ color: item.background }} />
                                <Button style={{ padding: 0 }} onClick={() => { this.showScheduleModal(item.uuid) }} bsStyle="info" simple>{item.ident}</Button>
                                <div className="actions-right" style={{ display: 'inline', float: 'right' }}>
                                    <OverlayTrigger placement="top" overlay={oPrint}>
                                        <Button
                                            onClick={() => { this.showPrinterModal(item.uuid); }}
                                            bsStyle="default"
                                            simple
                                            icon
                                            style={{ padding: '0 5px 8px', lineHeight: '1.2' }}>
                                            <i className="fa fa-print" />
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger placement="top" overlay={oTrash}>
                                        <Button
                                            onClick={() => { this.popupTrash(item.uuid) }}
                                            bsStyle="danger"
                                            simple
                                            icon
                                            style={{ padding: '0 5px 8px', lineHeight: '1.2' }}>
                                            <i className="fa fa-times" />
                                        </Button>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            <div style={{ clear: "both" }}></div>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-user" /> {item.driverName || "[brak kierowcy]"}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-phone" /> {item.driverMobilePhone || "[brak kierowcy]"}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-money" /> {item.driverIncome || "[0.00]"} / {item.realIncome || "[0.00]"}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-clock-o" /> {item.onboardingDate || "[brak daty]"}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-road" /> {item.numberOfOrders} zlec. {item.distance ? ` / ${item.distance} km` : ""} {item.time ? ` / ${item.time}` : ""}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-balance-scale" /> {item.orderWeight} kg</p>
                            {
                                this.state.department?.label.toLowerCase() === "klose"
                                    ?
                                    (
                                        <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-expand" /> {item.orderSize} m<sup>3</sup></p>
                                    )
                                    :
                                    (
                                        <></>
                                    )
                            }
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-tasks" /> {Object.keys(item.statuses).map(status => `${status}: ${item.statuses[status]}`).join(', ')}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-bell" />SMS (1. cz.): {item.notifyDayPart1 || "[brak]"}</p>
                            <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-bell" />SMS (2. cz.): {item.notifyDayPart2 || "[brak]"}</p>
                        </div>
                    </div>
                ))
                }
                {
                    !this.state.schedulesAll.length && !this.state.ordersLoading && (
                        <div className="content">
                            {noDataPlaceholder}
                        </div>
                    )
                }
            </Col>
        )

        let overlay = (
            <Grid>
                <Row style={{ position: "absolute", zIndex: 999, top: "25px", right: 0, left: "15px", margin: 0, pointerEvents: "none" }}>
                    <Col xs={6} md={4}>
                        <Card
                            style={{ backgroundColor: "rgba(255,255,255,.9)", maxHeight: "100vh" }}
                            content={
                                <div style={{ pointerEvents: "auto" }}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel>Oddział: </ControlLabel>
                                                <Col>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="payer"
                                                        value={this.state.department}
                                                        placeholder="Wybierz"
                                                        onChange={value =>
                                                            this.setDepartment(value)
                                                        }
                                                        options={this.state.departments}
                                                        isDisabled={!this.props.userHasRole(['admin', 'expertManager'])}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel>Data: </ControlLabel>
                                                <Datetime
                                                    timeFormat={false}
                                                    inputProps={{ placeholder: "Wybierz datę" }}
                                                    value={moment(this.state.selectedDate)}
                                                    defaultValue={moment(this.state.selectedDate)}
                                                    onChange={value =>
                                                        this.setDate(value)
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={12}>
                                            <FormGroup>
                                                <ControlLabel>Pokaż: </ControlLabel>
                                                <Col>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="payer"
                                                        value={this.state.show}
                                                        placeholder="Wybierz"
                                                        onChange={value =>
                                                            this.setShow(value)
                                                        }
                                                        options={this.showOptions}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Tab.Container
                                        id="tabs-company"
                                        activeKey={this.state.tab}
                                        onSelect={this.handleTabSelect.bind(this)}
                                    >
                                        <Row className="clearfix">
                                            <Col sm={12}>
                                                <Nav bsStyle="tabs">
                                                    <NavItem eventKey="schedules">Harmonogramy</NavItem>
                                                    <NavItem eventKey="orders">Zlecenia</NavItem>
                                                </Nav>
                                            </Col>
                                            <Col sm={12}>
                                                <Tab.Content animation={false}>
                                                    <Tab.Pane eventKey="schedules" style={{ padding: "0px" }}>{schedules}</Tab.Pane>
                                                    <Tab.Pane eventKey="orders" style={{ padding: "0px" }}>{orders}</Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>

                                    </Tab.Container>
                                </div>
                            }
                        />
                    </Col>
                </Row>
            </Grid>
        );

        let centerLat = parseFloat(this.props.user.user.company.address.geoLat) || 52.237049;
        let centerLng = parseFloat(this.props.user.user.company.address.geoLng) || 21.017532;
        if (this.state.department) {
            let currentDepartmentData = this.state.departments.filter(elem => { return elem.value === this.state.department.value })[0];
            if (currentDepartmentData) {
                centerLat = parseFloat(currentDepartmentData.data.company.address.geoLat);
                centerLng = parseFloat(currentDepartmentData.data.company.address.geoLng);
            }
        }

        return (
            <>
                {modal}
                {scheduleModal}
                {printerModal}
                {this.state.alert}
                <ScheduleMap
                    {...this.props}
                    isLoading={this.state.isLoading}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `calc(100vh - 3px)` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    defaultCenter={{ lat: centerLat, lng: centerLng }}
                    drawRouteForSchedule={this.state.filterSchedule ? this.state.filterSchedule.value : null}
                    markers={ordersFiltered}
                    schedules={this.state.schedulesAll}
                    overlay={overlay}
                    bindOrderWithSchedule={(orderUuid, scheduleUuid) => { this.bindOrderWithSchedule(orderUuid, scheduleUuid); }}
                    onDirectionsChange={this.onDirectionsChange}
                    onDirectionsNotFound={this.onDirectionsNotFound}
                />
            </>
        );
    }
}

export default ExpertAdminScheduleV2;
