import ExpertAuthLogin from "./views/Expert/Auth/Login.jsx";
import ExpertProfileMe from "./views/Expert/Profile/Me.jsx";
import ExpertDashboard from "./views/Expert/Profile/Dashboard.jsx";
import ExpertClientOrder from "./views/Expert/Client/Order.jsx";
import ExpertClientOrders from "./views/Expert/Client/Orders.jsx";
import ExpertAdminCalendar from "./views/Expert/Admin/Calendar.jsx";
import ExpertAdminCompanies from "./views/Expert/Admin/Companies.jsx";
import ExpertTracker from "./views/Expert/Tracker/Tracker.jsx";
import ExpertAdminFleet from "./views/Expert/Admin/Fleet.jsx";
import ExpertAdminSchedule from "./views/Expert/Admin/Schedule.jsx";
import ExpertAdminScheduleV2 from "./views/Expert/Admin/ScheduleV2.jsx";
import ExpertAdminReport from "./views/Expert/Admin/Report.jsx";
import ExpertAdminDepartments from "./views/Expert/Admin/Departments.jsx";
import ExpertAdminPreorders from "./views/Expert/Admin/Preorders.jsx";
import ExpertAdminDocuments from "./views/Expert/Admin/Documents.jsx";
import ExpertAdminHubTransfer from "./views/Expert/Admin/Hub/Transfers.jsx";
import ExpertAdminHubSchedule from "./views/Expert/Admin/Hub/Schedules.jsx";
import ExpertAdminHubWarehouse from "./views/Expert/Admin/Hub/Warehouse.jsx";
import ExpertAdminKloseOrders from "./views/Expert/Admin/Klose/Orders.jsx";
import ExpertAdminKloseWarehouse from "./views/Expert/Admin/Klose/Warehouse.jsx";
import ExpertClientRelease from "./views/Expert/Client/Release";
import ExpertHubConfirm from "./views/Expert/Admin/Hub/Confirm.jsx";

var routes = [
    {
        layout: "/profile",
        path: "/me",
        name: "Mój profil",
        hideInMenu: true,
        component: ExpertProfileMe
    },
    {
        layout: "/auth",
        path: "/login",
        name: "Logowanie",
        hideInMenu: true,
        component: ExpertAuthLogin
    },
    {
        layout: "/track",
        path: "/",
        name: "Śledź przesyłkę",
        hideInMenu: true,
        component: ExpertTracker
    },
    {
        layout: "/enet",
        path: "/",
        name: "Potwierdź harmonogram",
        hideInMenu: true,
        component: ExpertHubConfirm
    },
    {
        layout: "/profile",
        path: "/dashboard",
        name: "Dashboard",
        icon: "pe-7s-keypad",
        component: ExpertDashboard
    },
    {
        layout: "/client",
        path: "/create-order",
        name: "Nowe zlecenie",
        icon: "pe-7s-plus",
        component: ExpertClientOrder,
        roles: ["admin", "clientEmployee", "expertManager"]
    },
    {
        layout: "/client",
        path: "/edit-order",
        name: "Edytuj zlecenie",
        icon: "pe-7s-edit",
        component: ExpertClientOrder,
        hideInMenu: true,
        roles: ["admin", "clientEmployee", "industryManager", "industryRegionalManager", "expertManager"]
    },
    {
        layout: "/client",
        path: "/orders",
        name: "Lista zleceń",
        icon: "pe-7s-note2",
        component: ExpertClientOrders,
        roles: ["admin", "clientEmployee", "clientOverview", "expertManager", "expertWarehouse", "industryManager", "industryRegionalManager", "rosebrockManager"]
    },
    {
        layout: "/client",
        path: "/release",
        name: "Wydanie towaru",
        icon: "pe-7s-box1",
        component: ExpertClientRelease,
        permissions: ["releaseElements"],
        roles: ["admin", "clientEmployee", "clientOverview", "expertManager", "expertWarehouse", "industryManager", "industryRegionalManager"]
    },
    {
        layout: "/admin",
        path: "/calendar",
        name: "Kalendarz zleceń",
        icon: "pe-7s-date",
        component: ExpertAdminCalendar,
        roles: ["admin", "expertManager"]
    },
    {
        layout: "/admin",
        path: "/schedule",
        name: "Planowanie",
        icon: "pe-7s-wristwatch",
        component: ExpertAdminSchedule,
        hideInMenu: true,
        roles: ["admin", "expertManager"]
    },
    {
        layout: "/admin",
        path: "/preorders",
        name: "Zlecenia do realizacji",
        icon: "pe-7s-hourglass",
        component: ExpertAdminPreorders,
        roles: ["admin", "expertManager"]
    },
    {
        layout: "/admin",
        path: "/documents",
        name: "Import skanów",
        icon: "pe-7s-print",
        component: ExpertAdminDocuments,
        roles: ["admin", "expertManager"]
    },
    {
        layout: "/admin",
        path: "/v2-schedule",
        name: "Planowanie",
        icon: "pe-7s-wristwatch",
        component: ExpertAdminScheduleV2,
        roles: ["admin", "expertManager"]
    },
    {
        collapse: true,
        path: "/admin/hub",
        name: "Expert Net",
        state: "openHub",
        icon: "pe-7s-share",
        hideInMenu: false,
        roles: ["admin", "expertManager", "expertWarehouse", "rosebrockManager", "hubWarehouse"],
        views: [
            {
                layout: "/admin",
                path: "/hub/transfers",
                name: "Transfery",
                mini: "TRA",
                component: ExpertAdminHubTransfer,
                roles: ["admin", "expertManager", "rosebrockManager"],
            },
            {
                layout: "/admin",
                path: "/hub/schedules",
                name: "Harmonogramy",
                mini: "HAR",
                component: ExpertAdminHubSchedule,
                roles: ["admin", "expertManager", "rosebrockManager", "hubWarehouse"],
            },
            {
                layout: "/admin",
                path: "/hub/warehouse",
                name: "Magazyn",
                mini: "MAG",
                component: ExpertAdminHubWarehouse
            }
        ]
    },
    {
        collapse: true,
        path: "/admin/klose",
        name: "HUB KLOSE",
        state: "openKlose",
        icon: "pe-7s-repeat",
        hideInMenu: false,
        roles: ["admin", "expertManager", "expertWarehouse", "rosebrockManager"],
        users: ["kamil@expert-transport.pl", "magazyn@zlecenia.expert", "klose@zlecenia.expert", "sebastian.stasiak@expert-transport.pl"],
        alwaysAllowRoles: ["rosebrockManager"],
        views: [
            {
                layout: "/admin",
                path: "/klose/orders",
                name: "Preawizacja",
                mini: "PRE",
                component: ExpertAdminKloseOrders
            },
            {
                layout: "/admin",
                path: "/klose/warehouse",
                name: "Magazyn",
                mini: "MAG",
                component: ExpertAdminKloseWarehouse,
                roles: ["admin", "expertManager", "expertWarehouse"],
            }
        ]
    },
    {
        layout: "/admin",
        path: "/companies",
        name: "Zarządzanie firmami",
        icon: "pe-7s-portfolio",
        component: ExpertAdminCompanies,
        roles: ["admin"]
    },
    {
        layout: "/admin",
        path: "/fleet",
        name: "Zarządzanie flotą",
        icon: "pe-7s-car",
        component: ExpertAdminFleet,
        roles: ["admin"]
    },
    {
        layout: "/admin",
        path: "/departments",
        name: "Zarządzanie oddziałami",
        icon: "pe-7s-map",
        component: ExpertAdminDepartments,
        roles: ["admin"]
    },
    {
        layout: "/admin",
        path: "/report",
        name: "Raporty",
        icon: "pe-7s-calculator",
        component: ExpertAdminReport,
        roles: ["admin", "expertManager"]
    }
];
export default routes;
