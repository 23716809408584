import React, { Component } from "react";

class ExpertUploadMultipleComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {}
        this.maxFiles = 40;
        for (let i = 0; i < this.maxFiles; i++) {
            this.state[`selectedFiles${i}`] = [];
        }
    }

    onChangeHandler = (event, index) => {
        this.setState(
            {
                ["selectedFiles" + index]: event.target.files,
            },
            () => {
                let finalList = [];
                Object.values(this.state).filter(file => file.length > 0).forEach(files => {
                    for (let fileIndex = 0; fileIndex < files.length; fileIndex++) {
                        finalList.push(files[fileIndex]);
                    }
                });
                this.props.onSelectedFiles(finalList);
            }
        );
    }

    getNames(ofWhat) {
        if (!ofWhat.length) {
            return '';
        }
        var names = [];
        for (var i = 0; i < ofWhat.length; i++) {
            names.push(ofWhat[i].name);
        }
        return names.join(', ');
    }

    render() {

        let list = [];
        let howMuch = this.props.howMuch ? Math.min(this.props.howMuch, this.maxFiles) : 5;
        for (let i = 0; i < howMuch; i++) {
            list.push(
                <div className="custom-file" key={"fileUpload" + i} style={{ marginBottom: "15px" }}>
                    <input multiple="multiple" type="file" className="custom-file-input" onChange={(event) => { this.onChangeHandler(event, i) }} id={"customFile" + i} />
                    <label className="custom-file-label" htmlFor={"customFile" + i}>{this.state[`selectedFiles${i}`].length ? this.getNames(this.state[`selectedFiles${i}`]) : "Wybierz plik"}</label>
                </div>
            );
        }

        return list;
    }

}

export default ExpertUploadMultipleComponent;