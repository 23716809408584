/*global google*/
import _ from "lodash";
import React, { Component } from "react";
import "moment/min/locales";
import Select from "react-select";
import {
    Col,
    FormGroup,
} from "react-bootstrap";
import {
    withGoogleMap,
    GoogleMap,
    Marker,
    InfoWindow,
    DirectionsRenderer
} from "react-google-maps";

class ScheduleMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultCenter: props.defaultCenter,
            markers: props.markers || [],
            directionsMarkers: [],
            schedules: props.schedules || [],
            showingInfoWindow: false,
            positionInfoWindow: null,
            dataInfoWindow: null,
            directions: null,
            distance: null,
            duration: null,
            origin: null,
            destination: null,
            drawRouteForSchedule: null
        };
        this.directionsService = new google.maps.DirectionsService();
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onMapClick = this.onMapClick.bind(this);
        this.refMap = React.createRef();
        this.onGoogleMapLoad = this.onGoogleMapLoad.bind(this);
    }

    onGoogleMapLoad() {
        this.setBounds();
    }

    setBounds() {
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(this.state.defaultCenter);
        this.state.markers.forEach(place => {
            bounds.extend(place.pos)
        });
        if (this.state.markers.length) {
            const distance = google.maps.geometry.spherical.computeDistanceBetween(bounds.getNorthEast(), bounds.getSouthWest()) / 1000;
            if (distance > 100) {
                this.refMap.fitBounds(bounds);
            }
        }
    }

    componentWillReceiveProps(nextProps) {

        let currentMarkers = this.state.markers;
        // let currentCenter = this.state.defaultCenter;

        this.setState({
            markers: nextProps.markers || [],
            schedules: nextProps.schedules || [],
            defaultCenter: nextProps.defaultCenter,
            drawRouteForSchedule: nextProps.drawRouteForSchedule
        }, () => { this.setBounds(); });

        if (!nextProps.drawRouteForSchedule) {
            this.setState({
                directions: null,
                directionsMarkers: [],
                distance: null,
                duration: null,
                origin: null,
                destination: null
            });
            return;
        }

        if (_.isEqual(nextProps.markers, currentMarkers) && this.state.directionsMarkers.length) {
            return;
        }

        let waypoints = [];
        for (let i = 0; i < nextProps.markers.length; i++) {
            let marker = nextProps.markers[i];
            waypoints.push({
                location: new google.maps.LatLng(parseFloat(marker.recipient.address.geoLat), parseFloat(marker.recipient.address.geoLng)),
                stopover: true
            });
        }

        this.directionsService.route(
            {
                origin: nextProps.defaultCenter,
                destination: nextProps.defaultCenter,
                waypoints: waypoints,
                travelMode: google.maps.TravelMode.DRIVING,
                optimizeWaypoints: false
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    let distance = 0;
                    let duration = 0;
                    let directionsMarkers = [];

                    for (let j = 0; j < result.routes[0].legs.length; j++) {
                        distance += result.routes[0].legs[j].distance.value / 1000; // kilometers
                        duration += result.routes[0].legs[j].duration.value; // seconds
                    }

                    for (let i = 0; i < result.routes[0].legs.length; i++) {
                        directionsMarkers.push({
                            position: result.routes[0].legs[i].start_location,
                            label: "" + i
                        });
                    }

                    let output = {
                        directions: result,
                        distance: distance.toFixed(2),
                        duration: duration,
                        directionsMarkers: directionsMarkers
                    };

                    this.setState(output);

                    this.props.onDirectionsChange(output);

                } else {
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    markerImage(bgColor, type, isTransferable) {

        let carIcon = `<g transform="translate(7,6) scale(.8)" fill="white"><rect height="13" width="15" x="1" y="3"/><polygon points="16 8 20 8 23 11 23 16 16 16 16 8"/><circle cx="5.5" cy="18.5" r="2.5"/><circle cx="18.5" cy="18.5" r="2.5"/></g>`;
        let wrenchIcon = `<g transform="translate(6,6) scale(.4)" fill="white"><path d="M46.46,8.434l-6.19,6.19l-6.992-0.707l-0.785-7.071l6.148-6.148c-4.398-1.508-9.47-0.585-12.943,2.889  c-3.565,3.565-4.432,8.829-3,13.054L0.524,38.813c-1.22,1.221-0.778,3.17,0.442,4.391l2.947,2.945  c1.22,1.221,3.169,1.662,4.39,0.442l22.172-22.172c4.227,1.431,9.516,0.591,13.08-2.975C47.063,17.936,48.01,12.838,46.46,8.434z" /></g>`
        let transferableIcon = `<g transform="translate(7,6) scale(1.2)" fill="white"><path d="M11.3,10c-0.8,0-1.49,0.48-1.81,1.16L5.91,8.57C5.96,8.39,6,8.2,6,8S5.96,7.61,5.91,7.43l3.58-2.59   C9.81,5.52,10.5,6,11.3,6c1.1,0,2-0.9,2-2s-0.9-2-2-2c-1.01,0-1.84,0.75-1.97,1.72L5.4,6.57C5.03,6.22,4.54,6,4,6C2.9,6,2,6.9,2,8   s0.9,2,2,2c0.54,0,1.03-0.22,1.4-0.57l3.93,2.85C9.46,13.25,10.29,14,11.3,14c1.1,0,2-0.9,2-2S12.4,10,11.3,10z M11.3,3   c0.55,0,1,0.45,1,1s-0.45,1-1,1s-1-0.45-1-1S10.75,3,11.3,3z M4,9C3.45,9,3,8.55,3,8s0.45-1,1-1s1,0.45,1,1S4.55,9,4,9z M11.3,13   c-0.55,0-1-0.45-1-1s0.45-1,1-1s1,0.45,1,1S11.85,13,11.3,13z"/></g>`;
        let selectedIcon = carIcon;

        if (type === 'assembly') {
            selectedIcon = wrenchIcon;
        } else if (isTransferable) {
            selectedIcon = transferableIcon;
        }

        return encodeURIComponent(`
            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <linearGradient id="myGradient" gradientUnits="userSpaceOnUse" x1="5" y1="16" x2="27" y2="16" gradientTransform="rotate(90 16 16)">
                        <stop offset="0" stop-color="white" />
                        <stop offset="1" stop-color="black" />
                    </linearGradient>
                    <filter id="dropshadow">
                        <feGaussianBlur in="SourceAlpha" stdDeviation="1" />
                        <feOffset dx="0" dy="0" result="offsetblur" />
                        <feFlood flood-color="#000" flood-opacity=".25" />
                        <feComposite in2="offsetblur" operator="in" />
                        <feMerge>
                            <feMergeNode />
                            <feMergeNode in="SourceGraphic" />
                        </feMerge>
                    </filter>
                </defs>
                <path fill="${bgColor}" stroke="white" stroke-width="1.5" style="filter:url(#dropshadow)" d="M3.5 3.5h25v25h-25z" />
                <path opacity=".15" fill="url(#myGradient)" d="M5 5h22v22H5z" />
                ${selectedIcon}
                />
            </svg>
        `);
    }

    onMarkerClick = (marker, data, position) => {
        this.setState({
            showingInfoWindow: true,
            dataInfoWindow: data,
            positionInfoWindow: position
        });
    }

    onMapClick = (props) => {
        if (this.state.showingInfoWindow) {
            this.setState({
                showingInfoWindow: false,
                dataInfoWindow: null,
                positionInfoWindow: null
            });
        }
    }

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    render() {

        let dir = (<></>);
        if (this.state.directions) {
            dir = <DirectionsRenderer
                directions={this.state.directions}
                options={{ suppressMarkers: true }}
            />;
        }

        let usedLatLng = [];

        let markers = this.state.markers && this.state.markers.length ? this.state.markers.map((place, index) => {
            const pos = { lat: parseFloat(place.recipient.address.geoLat), lng: parseFloat(place.recipient.address.geoLng) };
            let used = usedLatLng.filter(obj => obj.lat === pos.lat && obj.lng === pos.lng);
            if (used.length) {
                pos.lat += this.randomInteger(50, 80) * 0.0000089;
                pos.lng += this.randomInteger(50, 80) * 0.0000089;
            }
            usedLatLng.push(pos);
            place.pos = pos;
            return place;
        }) : [];

        return (
            <GoogleMap
                ref={(ref) => { this.refMap = ref; }}
                onLoad={this.onGoogleMapLoad}
                defaultCenter={this.state.defaultCenter}
                center={this.state.defaultCenter}
                onClick={this.onMapClick}
                defaultZoom={this.props.defaultZoom || 9}
                defaultOptions={{
                    mapTypeControl: false
                }}
            >

                {
                    markers.map((place, index) => {
                        const icon = {
                            url: 'data:image/svg+xml;utf-8,' + this.markerImage(place.background, place.type, place.transferable),
                            anchor: new google.maps.Point(16, 16),
                            scaledSize: new google.maps.Size(32, 32)
                        }
                        if (place.trash) {
                            return null
                        }
                        return (
                            <Marker
                                key={`marker-place-${index}`}
                                icon={icon}
                                position={place.pos}
                                onClick={(evt) => { this.onMarkerClick(evt, place, place.pos); }}
                            ></Marker>
                        )
                    })
                }

                {
                    this.state.directionsMarkers.length
                        ?
                        this.state.directionsMarkers.map((place, index) => {
                            return (
                                <Marker
                                    key={`direction-place-${index}`}
                                    label={place.label}
                                    position={place.position}
                                ></Marker>
                            )
                        })
                        :
                        <></>
                }

                {dir}
                {this.props.overlay}
                {this.state.showingInfoWindow &&
                    (
                        <InfoWindow
                            position={this.state.positionInfoWindow}
                            visible={this.state.showingInfoWindow}
                            onCloseClick={(e) => { this.setState({ showingInfoWindow: false }) }}
                        >
                            <div className="content" style={{ padding: "0 15px 0 0", width: '300px' }}>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-circle" style={{ color: this.state.dataInfoWindow.background }} /> Zlecenie {this.state.dataInfoWindow.ident}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-user-o" /> {this.state.dataInfoWindow.sender.name}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-clock-o" /> {this.state.dataInfoWindow.dayPart}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-dot-circle-o" /> {this.state.dataInfoWindow.warehouse.name ? `Magazyn: ${this.state.dataInfoWindow.warehouse.name} - ${this.state.dataInfoWindow.warehouse.address.streetAddress} ${this.state.dataInfoWindow.warehouse.address.streetNumber}, ${this.state.dataInfoWindow.warehouse.address.cityName}` : `Nadawca: ${this.state.dataInfoWindow.sender.address.streetAddress}`}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-check-circle-o" /> Odbiorca: {this.state.dataInfoWindow.recipient.address.streetAddress} {this.state.dataInfoWindow.recipient.address.streetNumber}, {this.state.dataInfoWindow.recipient.address.cityName}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-info-circle" /> {this.state.dataInfoWindow.comment || '---'}</p>
                                <p style={{ fontSize: "14px", lineHeight: "1.2" }}><i className="fa fa-wrench" /> {this.state.dataInfoWindow.service && this.state.dataInfoWindow.service.length ? this.state.dataInfoWindow.service.map((service) => { return service.name }).join(", ") : '---'} </p>

                                <FormGroup>
                                    <Col style={{ marginBottom: "5px" }}>
                                        <Select
                                            isDisabled={this.props.isLoading}
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            placeholder="Harmonogram"
                                            options={this.state.schedules}
                                            isClearable={true}
                                            value={this.state.schedules.filter(itm => itm.value === this.state.dataInfoWindow.schedule.uuid)[0]}
                                            onChange={(itm) => {
                                                this.props.bindOrderWithSchedule(this.state.dataInfoWindow.uuid, itm ? itm.value : null)
                                            }}
                                            styles={{
                                                menu: provided => ({ ...provided, zIndex: 9999 })
                                            }}
                                        />
                                    </Col>
                                </FormGroup>

                            </div>
                        </InfoWindow>
                    )
                }
            </GoogleMap>
        );
    }
}

export default withGoogleMap(ScheduleMap);