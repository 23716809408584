import React, { Component } from "react";
import { $STATUS2_ANULOWANE, $STATUS2_AWIZACJA, $STATUS2_AWIZACJADOHUBLASTMILE, $STATUS2_BRAK, $STATUS2_DOSTARCZONE, $STATUS2_MAGAZYNLASTMILE, $STATUS2_NIEZREALIZOWANE, $STATUS2_NOWE, $STATUS2_NOWEDOTRANSFERU, $STATUS2_ODBIORODNADAWCY, $STATUS2_ODEBRANEODNADAWCY, $STATUS2_PRZYJETE, $STATUS2_TRANSFERDOHUBLASTMILE, $STATUS2_WDOSTAWIE, $STATUS2_WYDANE, $STATUS2_WYDANEDODORECZENIA, $STATUS2_ZGLOSZONOREKLAMACJE, $STATUS2_ZWROT } from "../../constants";

class ExpertStatusBadge extends Component {

    constructor(props) {
        super(props);
        this.match = {}

        this.match[$STATUS2_BRAK] = { variant: 'badge-secondary' };
        this.match[$STATUS2_NOWE] = { variant: 'badge-primary' };
        this.match[$STATUS2_WDOSTAWIE] = { variant: 'badge-info' };
        this.match[$STATUS2_DOSTARCZONE] = { variant: 'badge-success' };
        this.match[$STATUS2_NIEZREALIZOWANE] = { variant: 'badge-danger' };
        this.match[$STATUS2_ZGLOSZONOREKLAMACJE] = { variant: 'badge-danger' };
        this.match[$STATUS2_ZWROT] = { variant: 'badge-danger' };
        this.match[$STATUS2_ANULOWANE] = { variant: 'badge-danger' };

        this.match[$STATUS2_NOWEDOTRANSFERU] = { style: { color: '#fff', backgroundColor: '#a60000' } };
        this.match[$STATUS2_ODBIORODNADAWCY] = { style: { color: '#fff', backgroundColor: '#127c07' } };
        this.match[$STATUS2_ODEBRANEODNADAWCY] = { style: { color: '#000', backgroundColor: '#00ffff' } };
        this.match[$STATUS2_AWIZACJA] = { style: { color: '#fff', backgroundColor: '#127c07' } };
        this.match[$STATUS2_WYDANE] = { style: { color: '#fff', backgroundColor: '#ffaa00' } };
        this.match[$STATUS2_PRZYJETE] = { style: { color: '#fff', backgroundColor: '#193e8c' } };
        this.match[$STATUS2_AWIZACJADOHUBLASTMILE] = { style: { color: '#fff', backgroundColor: '#b23f79' } };
        this.match[$STATUS2_TRANSFERDOHUBLASTMILE] = { style: { color: '#fff', backgroundColor: '#000000' } };
        this.match[$STATUS2_MAGAZYNLASTMILE] = { style: { color: '#fff', backgroundColor: '#366fdf' } };
        this.match[$STATUS2_WYDANEDODORECZENIA] = { style: { color: '#fff', backgroundColor: '#127c07' } };
    }

    render() {
        const statusName = new String(this.props.status).toLowerCase().trim();
        const statusStyle = this.match[statusName]?.style || this.match[$STATUS2_BRAK].style || null;
        const statusVariant = this.match[statusName]?.variant || this.match[$STATUS2_BRAK].variant || null;
        return (
            <>
                <span className={`badge ${statusVariant}`} style={statusStyle}>{statusName}</span>
            </>
        )
    }
}

export default ExpertStatusBadge;