/* global _api */
/*eslint-disable*/
import React, { Component } from "react";
import { Grid, Row, Col, Badge, Modal, FormGroup, ControlLabel, FormControl } from "react-bootstrap";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import ExpertTrackerComponent from "../../../components/Expert/TrackerComponent.jsx";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import ExpertClientOrder from "../Client/Order";
import SweetAlert from "react-bootstrap-sweetalert";
import Datatable from "../../../components/Datatable";
import ExpertStatusBadge from "../../../components/Expert/StatusBadge.jsx";
import { $STATUS2_AWIZACJA, $STATUS2_ODBIORODNADAWCY, $STATUS2_PRZYJETE, $STATUS2_WYDANE } from "../../../constants.js";

class ExpertClientOrders extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pages: -1,
            loading: false,
            showTrackerModal: false,
            showPrinterModal: false,
            showOrderModal: false,
            showElementsModal: false,
            orderTracker: null,
            orderUuid: null,
            checkAll: false,
            askAbout: null,
            labelPageWidth: localStorage.getItem('labelPageWidth') || 150,
            labelPageHeight: localStorage.getItem('labelPageHeight') || 100,
        };
    }

    componentWillUnmount() {
        _api.abort();
    }

    setStateAndStore(key, value) {
        localStorage.setItem(key, value);
        this.setState({ [key]: value });
    }

    getBadgeStatus = (status) => {
        return <ExpertStatusBadge status={status} />
    }

    getBadgeExpress = (status) => {
        status = parseInt(status, 10);
        switch (status) {
            case 1:
                return (<Badge bsStyle="primary">EXPRESS</Badge>);
            default:
                return (<Badge bsStyle="light">Standard</Badge>);
        }
    }

    getBadgeSettled = (status) => {
        status = parseInt(status, 10);
        switch (status) {
            case 1:
                return (<Badge bsStyle="success">Rozliczone</Badge>);
            default:
                return (<Badge bsStyle="light">Oczekujące</Badge>);
        }
    }

    getBadgeCsat = (status) => {
        status = parseInt(status, 10);
        switch (status) {
            case 1:
                return (<Badge bsStyle="danger">1/5</Badge>);
            case 3:
                return (<Badge bsStyle="warning">3/5</Badge>);
            case 5:
                return (<Badge bsStyle="success">5/5</Badge>);
            default:
                return (<Badge bsStyle="light">-/-</Badge>);
        }
    }

    showTrackerModal(orderTracker, orderIdent) {
        this.setState({ showTrackerModal: true, orderTracker: orderTracker, orderIdent: orderIdent });
    }

    hideTrackerModal() {
        this.setState({ showTrackerModal: false, orderTracker: null, orderIdent: null });
    }

    showOrderModal(orderUuid, orderIdent) {
        this.setState({ showOrderModal: true, orderUuid: orderUuid, orderIdent: orderIdent });
    }

    hideOrderModal() {
        this.setState({ showOrderModal: false, orderUuid: null, orderIdent: null });
    }

    showComplainsModal(orderIdent) {
        const w = 700;
        const h = 480;
        const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2);
        const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2);
        const params = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`;
        window.open(`https://reklamacje.wiecejnizdostawa.pl/?orderIdent=${orderIdent}`, 'complains', params);
    }

    onOrderSaved(callback) {
        let self = this;
        self.fetchTable(
            {
                page: self.state.page,
                pageSize: self.state.pageSize,
                sorted: self.state.sorted,
                filtered: self.state.filtered
            },
            null,
            () => {
                if (typeof callback === "function") {
                    callback();
                }
            }
        );
    }

    onOrderSavedAndHide() {
        this.onOrderSaved(
            () => {
                this.setState({ showOrderModal: false, orderUuid: null, orderIdent: null })
            }
        );
    }

    manageOrder(orderUuid, checked) {
        const newOrders = this.state.data.map((order) => {
            if (order.uuid !== orderUuid) return order;
            let updatedOrder = { ...order };
            updatedOrder.checked = checked;
            return updatedOrder;
        });
        this.setState({
            data: this.reMapData(newOrders)
        });
    }

    duplicateOrderPopup(orderUuid, orderIdent) {
        this.setState({
            askAbout: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz ponowić to zlecenie?"
                    confirmBtnText="Ponów zlecenie"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ askAbout: null }); this.duplicateOrder(orderUuid, orderIdent); }}
                    onCancel={() => { this.setState({ askAbout: null }); }}
                    confirmBtnBsStyle="info">
                    Zostanie wykonany duplikat zlecenia, bez przypisanego slotu czasowego.
                </SweetAlert>
            )
        });
    }

    duplicateOrder(orderUuid, orderIdent) {
        let self = this;
        self.props.showNoty('tr', 'info', `Kopiowanie zlecenia ${orderIdent}`);
        _api.request(`/order/duplicate/${orderUuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'success', `Numer nowego zlecenia: ${data.ident}`);
                self.onOrderSaved();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    reMapData(data) {
        let self = this;
        return data.map((prop, key) => {
            prop.checked = prop.hasOwnProperty('checked') ? prop.checked : false;

            if (!prop.hasBadge) {
                prop.status = self.getBadgeStatus(prop.status);
                prop.express = self.getBadgeExpress(prop.express);
                prop.settled = self.getBadgeSettled(prop.settled);
                prop.csat = self.getBadgeCsat(prop.csat);
                prop.hasBadge = true;
            }

            prop.checkbox = (
                <Checkbox
                    key={prop.uuid}
                    number={key}
                    checked={prop.checked}
                    onChange={event => {
                        this.manageOrder(prop.uuid, event.target.checked);
                    }}
                />
            );

            prop.date = prop.date ? prop.date : <Badge bsStyle="warning">brak daty</Badge>;

            prop.actions = (
                <div className="actions-right">
                    {!this.props.userHasRole(['clientOverview']) && (
                        <Button
                            onClick={() => { this.showOrderModal(prop.uuid, prop.ident) }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-edit" />
                        </Button>
                    )}
                    <Button
                        onClick={() => { this.showTrackerModal(prop.tracker, prop.ident) }}
                        bsStyle="default"
                        simple
                        icon>
                        <i className="fa fa-info-circle" />
                    </Button>
                    <Button
                        onClick={() => { this.showComplainsModal(prop.ident) }}
                        bsStyle="warning"
                        simple
                        icon>
                        <i className="fa fa-exclamation-circle" />
                    </Button>
                    {!this.props.userHasRole(['clientOverview', 'industryManager', 'industryRegionalManager']) && (
                        <Button
                            onClick={() => { this.duplicateOrderPopup(prop.uuid, prop.ident) }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-copy" />
                        </Button>
                    )}
                </div>
            )
            return prop;
        });
    }

    fetchTable = (state, instance, callback) => {
        let self = this;
        self.setState({ loading: true });
        _api.request("/order/list", {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }, (res) => {
            self.setState({
                data: self.reMapData(res.rows),
                pages: res.pages,
                loading: false,
                page: res.page,
                pageSize: res.pageSize,
                sorted: res.sorted,
                filtered: res.filtered
            },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loading: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    generatePrinterLink(apiUrl) {
        let self = this;
        let uuids = [];

        self.state.data.forEach((element) => {
            if (!element.checked) {
                return;
            }
            uuids.push({ uuid: element.uuid });
        });

        if (uuids.length === 0) {
            self.props.showNoty('tr', 'error', 'Wybierz przynajmniej jedno zlecenie');
            return;
        }

        self.props.showNoty('tr', 'info', 'Generowanie dokumentów..');
        self.setState({ pdfIsGenerating: true });
        _api.request(apiUrl,
            {
                mode: "ordersList",
                orders: uuids,
                width: self.state.labelPageWidth,
                height: self.state.labelPageHeight,
            },
            (data) => {
                self.setState({ pdfIsGenerating: false, showPrinterModal: false, showElementsModal: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ pdfIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const modalTracker = (
            <Modal show={this.state.showTrackerModal} onHide={this.hideTrackerModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Podgląd statusu {this.state.orderIdent}</Modal.Header>
                <Modal.Body>
                    <ExpertTrackerComponent
                        {...this.props}
                        headless={true}
                        withDetails={true}
                        orderTracker={this.state.orderTracker}
                        hideStatus={[
                            $STATUS2_ODBIORODNADAWCY,
                            $STATUS2_AWIZACJA,
                            $STATUS2_WYDANE,
                            $STATUS2_PRZYJETE,
                        ]}
                    />
                </Modal.Body>
            </Modal>
        )


        const modalPrinter = (
            <Modal show={this.state.showPrinterModal} onHide={() => { this.setState({ showPrinterModal: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Wydruk zleceń</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <Button
                                onClick={() => { this.generatePrinterLink(`/admin/order/pdf`) }}
                                bsStyle="info"
                                round
                                block
                                icon>
                                <i className="fa fa-print" />
                                Protokół dostaw
                            </Button>
                        </Col>
                        <Col sm={6}>
                            <Button
                                onClick={() => { this.setState({ showPrinterModal: false, showElementsModal: true }) }}
                                bsStyle="default"
                                block
                                round
                                icon>
                                <i className="fa fa-print" />
                                Etykiety
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )

        const modalElements = (
            <Modal show={this.state.showElementsModal} onHide={() => { this.setState({ showElementsModal: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Wydruk zleceń</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col sm={6}>
                            <FormGroup>
                                <ControlLabel>Szerokość (mm):</ControlLabel>
                                <FormControl
                                    type="number"
                                    min="50"
                                    max="210"
                                    value={this.state.labelPageWidth}
                                    onChange={event =>
                                        this.setStateAndStore('labelPageWidth', event.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col sm={6}>
                            <FormGroup>
                                <ControlLabel>Wysokość (mm):</ControlLabel>
                                <FormControl
                                    type="number"
                                    min="50"
                                    max="210"
                                    value={this.state.labelPageHeight}
                                    onChange={event =>
                                        this.setStateAndStore('labelPageHeight', event.target.value)
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Button
                                onClick={() => { this.generatePrinterLink(`/admin/order/pdf-elements`) }}
                                bsStyle="default"
                                block
                                round
                                icon>
                                <i className="fa fa-print" />
                                Drukuj etykiety
                            </Button>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        )

        const modalOrder = (
            <Modal show={this.state.showOrderModal} onHide={this.hideOrderModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja zamówienia {this.state.orderIdent}</Modal.Header>
                <Modal.Body>
                    <ExpertClientOrder
                        {...this.props}
                        uuid={this.state.orderUuid}
                        department={null}
                        selectedDay={null}
                        nextDaySpan={14}
                        includeUnavailableSlots={this.props.userHasRole(['admin', 'expertManager'])}
                        isAdminView={this.props.userHasRole(['admin', 'expertManager'])}
                        isPreviewOnly={!this.props.userHasRole(['admin', 'expertManager'])}
                        onSaved={this.onOrderSaved.bind(this)}
                        onSavedAndHide={this.onOrderSavedAndHide.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const firstColumns = [
            {
                Header: (
                    <Button
                        onClick={() => { this.setState({ showPrinterModal: true }) }}
                        bsStyle="default"
                        round
                        icon>
                        <i className="fa fa-print" />
                    </Button>
                ),
                accessor: "checkbox",
                sortable: false,
                filterable: false,
                width: 50,
            }
        ];

        const lastColumns = [
            {
                Header: "Status",
                accessor: "status",
                width: 100
            },
            {
                Header: "Opcje",
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 160
            }
        ];

        const defaultColumns = [
            {
                Header: "Nr zlecenia",
                accessor: "ident"
            },
            {
                Header: "Nazwa odbiorcy",
                accessor: "name"
            },
            {
                Header: "Adres odbiorcy",
                accessor: "streetAddress"
            },
            {
                Header: "Data dostawy",
                accessor: "date"
            }
        ];

        let cols = [].concat(firstColumns, defaultColumns, lastColumns);

        if (this.props.user.user.company.configuration.orders.overrideColumns) {
            cols = [].concat(firstColumns, this.props.user.user.company.configuration.orders.columns.filter(column => column.active), lastColumns);
        } else {

            if (this.props.userHasRole(['admin', 'expertManager'])) {
                cols.splice(1, 0,
                    {
                        Header: "Oddział",
                        accessor: "department"
                    },
                    {
                        Header: "Kontrahent",
                        accessor: "company"
                    }
                );
                cols.splice(4, 0,
                    {
                        Header: "Nr dok. wew.",
                        accessor: "identExternal"
                    }
                );
                cols.splice(8, 0,
                    {
                        Header: "COD",
                        accessor: "cod",
                        width: 100,
                    }
                );
            } else if (this.props.userHasRole(['industryManager', 'industryRegionalManager'])) {
                cols.splice(1, 0,
                    {
                        Header: "Oddział",
                        accessor: "department"
                    },
                    {
                        Header: "Sklep",
                        accessor: "company"
                    }
                );
                cols.splice(4, 0,
                    {
                        Header: "Nr dok. wew.",
                        accessor: "identExternal"
                    }
                );
            } else if (this.props.userHasRole(['expertWarehouse'])) {
                cols.splice(1, 0,
                    {
                        Header: "Kontrahent",
                        accessor: "company"
                    },
                    {
                        Header: "Magazyn",
                        accessor: "warehouse"
                    }
                );
                cols.splice(4, 0,
                    {
                        Header: "Nr dok. wew.",
                        accessor: "identExternal"
                    }
                );
            } else {
                this.props.user.user.company.configuration.orders.additionalColumns.forEach(col => {
                    cols.splice(col.position, 0, col);
                });
            }
        }

        cols = cols.filter(col => col.accessor !== 'csat');

        return (
            <div className="main-content">
                {modalTracker}
                {modalOrder}
                {modalPrinter}
                {modalElements}
                {this.state.askAbout}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                content={
                                    <Datatable
                                        data={this.state.data}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        manual
                                        filterable
                                        columns={cols}
                                        defaultSorted={[
                                            {
                                                id: "ident",
                                                desc: true
                                            }
                                        ]}
                                        onFetchData={this.fetchTable}
                                        defaultPageSize={50}
                                        pageSizeOptions={[25, 50, 100, 150, 200]}
                                        showPaginationBottom
                                        className="-striped -highlight"
                                        previousText="Poprzednia"
                                        nextText="Następna"
                                        noDataText="Brak danych"
                                        loadingText="Pobieranie danych"
                                        pageText="Strona"
                                        ofText=" z "
                                        rowsText="wierszy"
                                    >
                                        {(state, makeTable, instance) => {
                                            let recordsInfoText = "";
                                            const { filtered, pageRows, pageSize, sortedData, page } = state;
                                            if (sortedData && sortedData.length > 0) {
                                                let isFiltered = filtered.length > 0;
                                                let totalRecords = sortedData.length;
                                                let recordsCountFrom = page * pageSize + 1;
                                                let recordsCountTo = recordsCountFrom + pageRows.length - 1;
                                                if (isFiltered)
                                                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} z ${totalRecords} rekordów`;
                                                else
                                                    recordsInfoText = `${recordsCountFrom}-${recordsCountTo} z ${totalRecords} rekordów`;
                                            } else recordsInfoText = "Brak danych";
                                            return (
                                                <div>
                                                    {makeTable()}
                                                    <p className="text-muted" style={{ marginTop: "10px" }}>{recordsInfoText}</p>
                                                </div>
                                            );
                                        }}
                                    </Datatable>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div >
        );
    }
}

export default ExpertClientOrders;
