/* global _api */
import React, { Component } from "react";
import ExpertDriverDashboardComponent from "../../../components/Expert/DriverDashboardComponent.jsx";
import ChartistGraph from "react-chartist";
import Card from "../../../components/Card/Card.jsx";
import tooltip from "chartist-plugin-tooltip";
import {
    Grid,
    Row,
    Col,
    Button,
    FormGroup
} from "react-bootstrap";
import Select from "react-select";
import moment from "moment";

class ExpertDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataChart: {
                labels: [],
                series: []
            },
            company: null,
            companyItems: [],
            companyLoading: true,
            department: null,
            departmentItems: [],
            departmentLoading: true,
            updated: moment()
        }
        this.chartOptions = {
            showArea: false,
            height: "245px",
            axisX: {
                showGrid: false
            },
            onlyInteger: true,
            lineSmooth: true,
            showLine: true,
            showPoint: true,
            fullWidth: true,
            chartPadding: {
                right: 50
            },
            plugins: [tooltip()]
        };
        this.chartResponsive = [
            [
                "screen and (max-width: 640px)",
                {
                    axisX: {
                        labelInterpolationFnc: function (value) {
                            return value[0];
                        }
                    }
                }
            ]
        ];
    }

    componentDidMount() {

        if (["expertFleet"].includes(this.props.user.user.role)) {
            return;
        }

        this.loadChart(`/statistica/created-orders`);

        if (["admin", "expertManager"].includes(this.props.user.user.role)) {
            let departmentGuid = "";
            if (!this.props.userHasRole('admin')) {
                departmentGuid = this.props.user.user.department.uuid;
            } else {
                this.loadList('department', `/admin/general/departments`, () => { });
            }
            this.loadList('company', `/admin/general/companies/${departmentGuid}`, () => { });
        }

    }

    componentWillUnmount() {
        _api.abort();
    }

    loadChart(endpoint) {
        let self = this;
        _api.request(endpoint, {},
            (data) => {
                let labels = [];
                let series = [];
                data.orderCreated.forEach(elem => {
                    labels.push(elem.date);
                    series.push(elem.count);
                });
                self.setState({
                    dataChart: {
                        labels: labels,
                        series: [series]
                    },
                    updated: moment()
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    loadList(name, endpoint, callback) {
        let self = this;
        self.setState({ [name]: null, [name + 'Items']: [], [name + 'Loading']: true });
        _api.request(endpoint, {},
            (data) => {
                if (!data) {
                    data = [];
                }
                let items = [];
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name });
                }
                if (items.length) {
                    self.setState({ [name]: items[0].value, [name + 'Items']: items, [name + 'Loading']: false }, () => { callback(); });
                } else {
                    self.setState({ [name + 'Loading']: false }, () => { callback(); });
                }
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    setDepartment(value, doNotLoad) {
        this.setState({ department: value },
            () => {
                if (doNotLoad) {
                    return;
                }
                if (value) {
                    this.loadList('company', `/admin/general/companies/${this.state.department.value}`, () => { });
                    this.loadChart(`/statistica/department-created-orders/${this.state.department.value}`)
                } else {
                    this.loadChart(`/statistica/created-orders`);
                }
            }
        );
    }

    setCompany(value, doNotLoad) {
        this.setState({ company: value },
            () => {
                if (doNotLoad) {
                    return;
                }
                if (value) {
                    this.loadChart(`/statistica/company-created-orders/${this.state.company.value}`)
                } else {
                    this.loadChart(`/statistica/created-orders`);
                }
            }
        );
    }

    render() {

        let driverDashboard = (
            <ExpertDriverDashboardComponent
                {...this.props} />
        )

        let userDashboard = (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<>Witaj, {this.props.user.user.firstname}!</>}
                                category="Złóż nowe zamówienie, lub sprawdź status swoich poprzednich zamówień"
                                content={
                                    <div className="buttons-with-right-margin">
                                        {!this.props.userHasRole(['clientOverview', 'industryManager', 'industryRegionalManager']) && (
                                            <Button
                                                onClick={() => { window.location.href = '/#/client/create-order'; }}
                                                bsSize="lg"
                                                className="btn-wd btn btn-default">
                                                <span className="btn-label" style={{ paddingRight: "10px" }}>
                                                    <i className="fa fa-plus" />
                                                </span> <>Nowe Zlecenie</>
                                            </Button>
                                        )}
                                        <Button
                                            onClick={() => { window.location.href = '/#/client/orders'; }}
                                            bsSize="lg"
                                            className="btn-wd btn btn-default">
                                            <span className="btn-label" style={{ paddingRight: "10px" }}>
                                                <i className="fa fa-list-alt" />
                                            </span> <>Lista zleceń</>
                                        </Button>
                                    </div>
                                }
                            />

                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>
                            <Card
                                title="Statystyki zleceń"
                                category={
                                    <>
                                        <p>Przegląd ostatnich 14 dni.</p>
                                        {
                                            (["admin"].includes(this.props.user.user.role))
                                                ?
                                                (
                                                    <FormGroup>
                                                        <Col>
                                                            <Select
                                                                isClearable
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                value={this.state.department}
                                                                placeholder="Wybierz oddział"
                                                                onChange={
                                                                    (value) => {
                                                                        this.setDepartment(value);
                                                                        this.setCompany(null, true);
                                                                    }
                                                                }
                                                                options={this.state.departmentItems}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                        {
                                            (["admin", "expertManager"].includes(this.props.user.user.role))
                                                ?
                                                (
                                                    <FormGroup>
                                                        <Col>
                                                            <Select
                                                                isClearable
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                value={this.state.company}
                                                                placeholder="Wybierz firmę"
                                                                onChange={
                                                                    (value) => {
                                                                        this.setCompany(value);
                                                                        this.setDepartment(null, true);
                                                                    }
                                                                }
                                                                options={this.state.companyItems}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )
                                                :
                                                (
                                                    <></>
                                                )
                                        }
                                    </>
                                }
                                content={
                                    <ChartistGraph
                                        data={this.state.dataChart}
                                        type="Line"
                                        options={this.chartOptions}
                                        responsiveOptions={this.chartResponsive}
                                    />
                                }
                                legend={
                                    <div>
                                        <i className="fa fa-circle text-info" /> Zlecenia przyjęte
                                    </div>
                                }
                                stats={
                                    <div>
                                        <i className="fa fa-history" /> Aktualizacja: {moment(this.state.updated).format("YYYY-MM-DD HH:mm:ss")}
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );

        if (["expertFleet"].includes(this.props.user.user.role)) {
            return driverDashboard;
        }

        return userDashboard;

    }

}

export default ExpertDashboard;