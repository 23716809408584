/* global _api */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel,
    Tab,
    Nav,
    NavItem,
    Modal,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Datatable from "../../../components/Datatable";

class ExpertAdminFleet extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            tab: "basic",
            tabDefault: "basic",
            showModal: false,
            showTable: true,
            dataTable: [],
            pagesTable: -1,
            pageTable: 0,
            defaultPageSizeTable: 10,
            loadingTable: true,
            departments: [],
            fleetSelected: false,
            fleetIsLoading: false,
            fleetUuid: null,
            fleetName: "",
            vehicleMake: "",
            vehicleModel: "",
            vehicleNumber: ""
        };
    }

    componentDidMount() {
        let self = this;
        _api.request(`/admin/general/departments`, {},
            (data) => {
                let items = [];
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name });
                }
                if (items.length) {
                    self.setState({ departments: items });
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
    }

    editFleet = (uuid) => {
        let self = this;

        self.setState({ fleetUuid: uuid, fleetSelected: true, fleetIsLoading: true, showModal: true });

        if (!uuid) {
            self.setState({
                tab: self.state.tabDefault,
                fleetUuid: null,
                fleetName: "",
                vehicleMake: "",
                vehicleModel: "",
                vehicleNumber: "",
                department: null,
                fleetSelected: true,
                fleetIsLoading: false
            });
            return;
        }

        _api.request(`/admin/fleet/details/${uuid}`, {},
            (data) => {
                self.setState({
                    fleetUuid: data.fleet.uuid,
                    fleetName: data.fleet.name,
                    vehicleMake: data.fleet.vehicleMake,
                    vehicleModel: data.fleet.vehicleModel,
                    vehicleNumber: data.fleet.vehicleNumber,
                    department: self.state.departments.filter(itm => itm.value === data.department.uuid)[0],
                    fleetIsLoading: false
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleFleetSubmit(event) {
        event.preventDefault();

        let self = this;

        let validationRules = {
            "isValid": true,
            "items": [
                { "field": "fleetName", "rules": { "empty": "Uzupełnij nazwę" } },
            ]
        };

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = self.state[element.field];
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
            }
        });

        if (!validationRules.isValid) {
            return;
        }

        self.setState({ isLoading: true, showTable: false });
        self.props.setProgress(0);
        let endpoint = this.state.fleetUuid ? `/admin/fleet/update/${this.state.fleetUuid}` : `/admin/fleet/create`;
        _api.request(endpoint,
            {
                "fleet": {
                    "name": self.state.fleetName,
                    "vehicleMake": self.state.vehicleMake,
                    "vehicleModel": self.state.vehicleModel,
                    "vehicleNumber": self.state.vehicleNumber,
                },
                "department": {
                    "uuid": self.state.department.value
                }
            },
            (data) => {
                self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                self.setState({ isLoading: false, fleetUuid: data, showTable: true });
                self.props.setProgress(100);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                self.setState({ isLoading: false, showTable: true });
                self.props.setProgress(100);
            }
        );
    }

    hideModal() {
        this.setState({ showModal: false });
    }

    handleTabSelect(key) {
        this.setState({ tab: key });
    }

    moveToTrash(uuid) {
        let self = this;
        self.setState({ showTable: false }, () => {
            _api.request(`/admin/trash/recursive/fleet/${uuid}`, {},
                (data) => {
                    self.props.showNoty('tr', 'info', 'Flota została usunięta');
                    self.setState({ showTable: true });
                },
                (error) => {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
                },
                (progress) => {
                    self.props.setProgress(progress);
                }
            );
        }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń element"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    render() {

        const oEdit = <Tooltip id="oEdit">Edycja danych</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;

        const editFleetForm = (
            <div>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Oddział Expert:</ControlLabel>
                        <Col>
                            <Select
                                className="react-select primary"
                                classNamePrefix="react-select"
                                name="payer"
                                value={this.state.department}
                                placeholder="Wybierz oddział"
                                onChange={value =>
                                    this.setState({ department: value })
                                }
                                options={this.state.departments}
                            />
                        </Col>
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Nazwa floty:</ControlLabel>
                        <FormControl
                            type="text"
                            name="fleetName"
                            value={this.state.fleetName}
                            onChange={event =>
                                this.setState({ fleetName: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Marka pojazdu:</ControlLabel>
                        <FormControl
                            type="text"
                            name="vehicleMake"
                            value={this.state.vehicleMake || ""}
                            onChange={event =>
                                this.setState({ vehicleMake: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Model pojazdu:</ControlLabel>
                        <FormControl
                            type="text"
                            name="vehicleModel"
                            value={this.state.vehicleModel || ""}
                            onChange={event =>
                                this.setState({ vehicleModel: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Numer pojazdu:</ControlLabel>
                        <FormControl
                            type="text"
                            name="vehicleNumber"
                            value={this.state.vehicleNumber || ""}
                            onChange={event =>
                                this.setState({ vehicleNumber: event.target.value })
                            }
                        />
                    </FormGroup>
                </fieldset>
            </div>
        )

        const editFleet = (
            <form onSubmit={this.handleFleetSubmit.bind(this)}>
                <Tab.Container
                    id="tabs-fleet"
                    activeKey={this.state.tab}
                    onSelect={this.handleTabSelect.bind(this)}
                >
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Nav bsStyle="tabs">
                                <NavItem eventKey="basic">Informacje</NavItem>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content animation={false}>
                                <Tab.Pane eventKey="basic" style={{ padding: "15px 0px" }}>{editFleetForm}</Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <Button type="submit" disabled={this.state.isLoading} bsStyle="default" onClick={!this.state.isLoading ? this.handleFleetSubmit.bind(this) : null}>
                    {this.state.isLoading ? 'Zapisywanie…' : 'Zapisz dane'}
                </Button>
            </form>
        );

        const editFleetLoader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const editFleetPlaceholder = (
            <div>
                <p className="lead text-muted">
                    <>Wybierz z listy, lub</>
                    <Button bsStyle="default" round wd style={{ marginLeft: "5px" }} onClick={() => { this.editFleet(null) }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus" />
                        </span>
                        <>dodaj pojazd</>
                    </Button>
                </p>
            </div>
        );

        const modal = (
            <Modal show={this.state.showModal} onHide={this.hideModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja danych</Modal.Header>
                <Modal.Body>
                    {this.state.fleetSelected ? this.state.fleetIsLoading ? editFleetLoader : editFleet : editFleetPlaceholder}
                </Modal.Body>
            </Modal>
        )

        return (
            <div className="main-content">
                {modal}
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={<div>
                                    Przegląd Floty
                                    <Button bsStyle="default" pullRight round onClick={() => { this.editFleet(null) }}>
                                        <span className="btn-label">
                                            <i className="fa fa-plus" />
                                        </span>
                                    </Button>
                                </div>}
                                content={
                                    this.state.showTable ? <Datatable
                                        data={this.state.dataTable}
                                        pages={this.state.pagesTable}
                                        loading={this.state.loadingTable}
                                        manual
                                        filterable
                                        columns={[
                                            {
                                                Header: "Nazwa",
                                                accessor: "name"
                                            },
                                            {
                                                Header: "Numer pojazdu",
                                                accessor: "vehicleNumber"
                                            },
                                            {
                                                Header: "Oddział Expert",
                                                accessor: "department"
                                            },
                                            {
                                                Header: "Opcje",
                                                accessor: "actions",
                                                sortable: false,
                                                filterable: false,
                                                width: 100
                                            }
                                        ]}
                                        defaultSorted={[
                                            {
                                                id: "name",
                                                desc: false
                                            }
                                        ]}
                                        onFetchData={(state, instance) => {
                                            let self = this;
                                            self.setState({ loadingTable: true });
                                            _api.request("/admin/fleet/list", {
                                                page: state.page,
                                                pageSize: state.pageSize,
                                                sorted: state.sorted,
                                                filtered: state.filtered
                                            }, (res) => {
                                                self.setState({
                                                    dataTable: res.rows.map((prop, key) => {
                                                        prop.actions = (
                                                            <div className="actions-right">
                                                                <OverlayTrigger placement="top" overlay={oEdit}>
                                                                    <Button
                                                                        onClick={() => { this.editFleet(prop.uuid) }}
                                                                        bsStyle="default"
                                                                        simple
                                                                        icon>
                                                                        <i className="fa fa-edit" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                                <OverlayTrigger placement="top" overlay={oTrash}>
                                                                    <Button
                                                                        onClick={() => { this.popupTrash(prop.uuid) }}
                                                                        bsStyle="danger"
                                                                        simple
                                                                        icon>
                                                                        <i className="fa fa-times" />
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                        )
                                                        return prop;
                                                    }),
                                                    pageTable: res.page,
                                                    pagesTable: res.pages,
                                                    pageSizeTable: res.pageSize,
                                                    sortedTable: res.sorted,
                                                    filteredTable: res.filtered,
                                                    loadingTable: false
                                                });
                                            }, (error) => {
                                                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                self.setState({ loadingTable: false });
                                            }, (progress) => {
                                                self.props.setProgress(progress);
                                            });
                                        }}
                                        defaultPageSize={this.state.defaultPageSizeTable}
                                        showPaginationBottom
                                        className="-striped -highlight"
                                        previousText="Poprzednia"
                                        nextText="Następna"
                                        noDataText="Brak danych"
                                        loadingText="Pobieranie danych"
                                        pageText=""
                                        ofText=" z "
                                        rowsText="wierszy"
                                    />
                                        :
                                        <div style={{ "paddingBottom": "26px" }}>
                                            <PropagateLoader
                                                sizeUnit={"px"}
                                                size={15}
                                                margin={2}
                                                css={{
                                                    "display": "block",
                                                    "margin": "10px auto",
                                                    "width": "1px"
                                                }}
                                                color={'#777'}
                                                loading={true}
                                            />
                                        </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminFleet;
