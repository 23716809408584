/* global _api */
import _ from "lodash";
import React, { Component } from "react";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import BigCalendar from "react-big-calendar";
import moment from "moment";
import "moment/min/locales";
import Select from "react-select";
import Card from "../../../components/Card/Card.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import ExpertClientOrder from "../Client/Order";
import {
    Grid,
    Row,
    Col,
    Table,
    OverlayTrigger,
    Tooltip,
    FormGroup,
    FormControl,
    Badge,
    Modal
} from "react-bootstrap";

moment.locale('pl');
const localizer = BigCalendar.momentLocalizer(moment);

class ExpertAdminCalendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            alert: null,
            department: null,
            departmentLoading: true,
            departments: [],
            dayLoading: false,
            slots0915: [],
            capacity1: "20",
            slots1520: [],
            capacity2: "20",
            slotsDate: null,
            slotsLoading: true,
            showOrderModal: false,
            selectedOrder: null,
            calendarDate: null
        };
    }

    componentDidMount() {
        let self = this;

        let departmentGuid = self.props.location.pathname.replace("/admin/calendar/", "");
        if (!_api.isGuid(departmentGuid)) {
            departmentGuid = null;
        }

        if (!self.props.userHasRole('admin')) {
            departmentGuid = self.props.user.user.department.uuid;
        }

        _api.request(`/admin/general/departments`, {},
            (data) => {
                let items = [];
                let selected = null;
                for (let i = 0; i < data.length; i++) {
                    items.push({ "value": data[i].uuid, "label": data[i].name, "region": data[i].region });
                    if (departmentGuid && data[i].uuid === departmentGuid) {
                        selected = items[i];
                    }
                }

                if (self.props.userHasRole(['expertManager'])) {
                    items = items.filter((elem) => { return elem.region === selected.region });
                }

                if (items.length) {
                    if (!self.state.department) {
                        if (selected) {
                            self.setDepartment(selected, items);
                        } else {
                            self.setDepartment(items[0], items);
                        }
                    }
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    componentWillUnmount() {
        _api.abort();
    }

    setDepartment(currentDepartment, allDepartments) {
        let self = this;

        let callback = () => {
            self.props.history.push('/admin/calendar/' + currentDepartment.value);
            self.loadMonth(moment());
        }

        if (allDepartments) {
            self.setState({ department: currentDepartment, departments: allDepartments, departmentLoading: true, slotsLoading: true, slots0915: [], slots1520: [] }, callback);
        } else {
            self.setState({ department: currentDepartment, departmentLoading: true, slotsLoading: true, slots0915: [], slots1520: [] }, callback);
        }

    }

    loadMonth(date) {
        let self = this;
        let fromDate = moment(date).startOf('month').format("YYYY-MM-DD");
        let tillDate = moment(date).endOf('month').add(1, 'days').format("YYYY-MM-DD");

        self.setState({ events: [], calendarDate: date });
        _api.request(`/admin/slot/overview/${self.state.department.value}/${fromDate}/${tillDate}`, {},
            (data) => {
                let events = [];
                let dayPartGroups = [];
                for (let i = 0; i < data.length; i++) {

                    if (_.includes(dayPartGroups, data[i].dayPartGroup)) {
                        continue;
                    } else {
                        dayPartGroups.push(data[i].dayPartGroup);
                    }

                    let totalUsed = data[i].size - data[i].free;
                    let hour = data[i].dayPart.split(' - ');
                    let capacity = parseInt(totalUsed, 10) / parseInt(data[i].size, 10);
                    let color = capacity > 0.0 ? capacity > 0.5 ? capacity > 0.9 ? "red" : "orange" : "blue" : "green";
                    events.push({
                        title: totalUsed + " / " + data[i].size,
                        start: moment(data[i].dayIdent + ` ${hour[0]}`, 'YYYY-MM-DD HH:mm'),
                        end: moment(data[i].dayIdent + ` ${hour[1]}`, 'YYYY-MM-DD HH:mm'),
                        allDay: false,
                        color: color
                    });
                }
                self.setState({ events: events, departmentLoading: false, slotsLoading: false });
                if (!self.state.slotsDate) {
                    self.getSlots(moment());
                }
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    selectedEvent(event) {
        if (event.start) {
            this.getSlots(moment(event.start));
        }
    }

    selectedSlot(data) {
        if (data.slots) {
            this.getSlots(moment(data.slots[0]));
        }
    }

    getSlots(date) {

        let self = this;
        let fromDate = date.format("YYYY-MM-DD");
        self.setState({ dayLoading: true, slotsLoading: true, slots: [], slotsDate: fromDate });

        _api.request(`/admin/slot/details/${self.state.department.value}/${fromDate}`, {},
            (data) => {

                if (!data) {
                    data = {
                        slot: [],
                        order: []
                    };
                }

                let capacity1 = 20;
                let capacity2 = 20;

                for (let i = 0; i < data.slot.length; i++) {
                    if (data.slot[i].dayPartIdent === 1) {
                        capacity1 = data.slot[i].size;
                    }
                    if (data.slot[i].dayPartIdent === 2) {
                        capacity2 = data.slot[i].size;
                    }
                }

                let slots0915 = [];
                let slots1520 = [];

                for (let i = 0; i < data.order.length; i++) {
                    switch (data.order[i].dayPartIdent) {
                        case 1:
                            slots0915.push(data.order[i]);
                            break;

                        case 2:
                            slots1520.push(data.order[i]);
                            break;

                        default:
                            break;
                    }
                }

                self.setState({ dayLoading: false, slotsLoading: false, slots0915: slots0915, slots1520: slots1520, capacity1: capacity1, capacity2: capacity2 });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    selectedMonth(targetDate, viewName, actionName) {
        this.loadMonth(moment(targetDate));
    }

    eventColors(event, start, end, isSelected) {
        var backgroundColor = "rbc-event-";
        event.color
            ? (backgroundColor = backgroundColor + event.color)
            : (backgroundColor = backgroundColor + "default");
        return {
            className: backgroundColor
        };
    }

    showOrderModal(orderUuid) {
        this.setState({ showOrderModal: true, orderUuid: orderUuid });
    }

    hideOrderModal() {
        this.setState({ showOrderModal: false, orderUuid: null });
    }

    refreshCurrentView() {
        this.getSlots(moment(this.state.slotsDate));
        this.loadMonth(moment(this.state.calendarDate));
    }

    onOrderSaved() {
        this.refreshCurrentView();
    }

    onOrderSavedAndHide() {
        this.refreshCurrentView();
        this.hideOrderModal();
    }

    saveCapacity() {
        let self = this;
        let fromDate = self.state.slotsDate;
        self.props.showNoty('tr', 'info', 'Zapisywanie danych..');
        _api.request(`/admin/slot/size/${self.state.department.value}/${fromDate}`,
            {
                "size": {
                    1: self.state.capacity1,
                    2: self.state.capacity2
                }
            },
            (data) => {
                self.setState({ departmentLoading: true });
                self.props.showNoty('tr', 'success', 'Capacity zostało zapisane');
                self.loadMonth(fromDate);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const view = <Tooltip id="view">Edycja zamówienia</Tooltip>;
        const capacity = <Tooltip id="capacity">Capacity</Tooltip>;

        const loader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const modal = (
            <Modal show={this.state.showOrderModal} onHide={this.hideOrderModal.bind(this)} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja zamówienia</Modal.Header>
                <Modal.Body>
                    <ExpertClientOrder
                        {...this.props}
                        uuid={this.state.orderUuid}
                        selectedDay={this.state.slotsDate ? moment(this.state.slotsDate).subtract('1', 'day') : null}
                        includeUnavailableSlots={true}
                        nextDaySpan={14}
                        isAdminView={true}
                        department={this.state.department ? this.state.department.value : null}
                        onSaved={this.onOrderSaved.bind(this)}
                        onSavedAndHide={this.onOrderSavedAndHide.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        return (
            <div className="main-content">
                {modal}
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col sm={12} md={6}>
                            <Card
                                title="Wybór oddziału"
                                content={
                                    <div>
                                        <fieldset>
                                            <FormGroup>
                                                <Col style={{ "paddingTop": "10px" }}>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="payer"
                                                        value={this.state.department}
                                                        placeholder="Wybierz oddział"
                                                        onChange={value =>
                                                            this.setDepartment(value)
                                                        }
                                                        options={this.state.departments}
                                                        isDisabled={!this.props.userHasRole(['admin', 'expertManager'])}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </fieldset>
                                    </div>
                                }
                            />
                            {!this.state.departmentLoading ? (
                                <Card
                                    className="overflowAuto"
                                    calendar
                                    content={
                                        <BigCalendar
                                            selectable
                                            localizer={localizer}
                                            events={this.state.events}
                                            views={['month']}
                                            defaultView="month"
                                            scrollToTime={new Date(1970, 1, 1, 6)}
                                            defaultDate={new Date()}
                                            onSelectEvent={event => this.selectedEvent(event)}
                                            onSelectSlot={slotInfo => this.selectedSlot(slotInfo)}
                                            onNavigate={(targetDate, viewName, actionName) => this.selectedMonth(targetDate, viewName, actionName)}
                                            eventPropGetter={this.eventColors}
                                            messages={{ "today": "Dzisiaj", "previous": "Poprzedni", "next": "Następny" }}
                                        />
                                    }
                                />
                            ) : (
                                    <Card
                                        content={loader}
                                    />
                                )
                            }
                        </Col>
                        <Col sm={12} md={6}>
                            {!this.state.slotsLoading ? (
                                <div>
                                    <Card
                                        title={
                                            <div>
                                                {moment(this.state.slotsDate).format('dddd, DD MMMM YYYY') + ", I część dnia"}
                                                <div className="pull-right" style={{ width: "60px", textAlign: "center" }}>
                                                    <OverlayTrigger placement="top" overlay={capacity}>
                                                        <FormControl
                                                            type="number"
                                                            placeholder={`...`}
                                                            value={this.state.capacity1}
                                                            onChange={event =>
                                                                this.setState({ capacity1: event.target.value })
                                                            }
                                                            onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.saveCapacity()
                                                                }
                                                            }}
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        }
                                        tableFullWidth
                                        content={
                                            <Table hover responsive>
                                                <thead>
                                                    <tr>
                                                        <th>Numer</th>
                                                        <th>Klient</th>
                                                        <th>Odbiorca</th>
                                                        <th>Grafik</th>
                                                        <th className="text-center" style={{ "minWidth": "110px" }}>Godzina</th>
                                                        <th className="text-center" style={{ "minWidth": "110px" }}>Opcje</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.slots0915.length) ? (
                                                        this.state.slots0915.map((prop, key) => {
                                                            let scheduleStatus = prop.schedule.assigned ? <span>{prop.schedule.ident}</span> : <Badge bsStyle="warning">brak</Badge>
                                                            return (
                                                                <tr key={prop.uuid} className={prop.trash ? "trash" : ""}>
                                                                    <td>{prop.ident}</td>
                                                                    <td>{prop.sender.name}</td>
                                                                    <td>{prop.recipient.address.streetAddress}</td>
                                                                    <td>{scheduleStatus}</td>
                                                                    <td className="text-center">{prop.dayPart}</td>
                                                                    <td className="td-actions text-center">
                                                                        <OverlayTrigger placement="top" overlay={view}>
                                                                            <Button simple bsStyle="info" bsSize="xs" onClick={() => this.showOrderModal(prop.uuid)}>
                                                                                <i className="fa fa-edit" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (<tr><td className="text-center text-muted" colSpan={5}>- brak danych -</td></tr>)
                                                    }
                                                </tbody>
                                            </Table>
                                        }
                                    />
                                    <Card
                                        title={
                                            <div>
                                                {moment(this.state.slotsDate).format('dddd, DD MMMM YYYY') + ", II część dnia"}
                                                <div className="pull-right" style={{ width: "60px", textAlign: "center" }}>
                                                    <OverlayTrigger placement="top" overlay={capacity}>
                                                        <FormControl
                                                            type="number"
                                                            placeholder={`...`}
                                                            value={this.state.capacity2}
                                                            onChange={event =>
                                                                this.setState({ capacity2: event.target.value })
                                                            }
                                                            onKeyPress={event => {
                                                                if (event.key === 'Enter') {
                                                                    this.saveCapacity()
                                                                }
                                                            }}
                                                        />
                                                    </OverlayTrigger>
                                                </div>
                                            </div>
                                        }
                                        tableFullWidth
                                        content={
                                            <Table hover={true} responsive={true} condensed={true}>
                                                <thead>
                                                    <tr>
                                                        <th>Numer</th>
                                                        <th>Klient</th>
                                                        <th>Odbiorca</th>
                                                        <th>Grafik</th>
                                                        <th className="text-center" style={{ "minWidth": "110px" }}>Godzina</th>
                                                        <th className="text-center" style={{ "minWidth": "110px" }}>Opcje</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(this.state.slots1520.length) ? (
                                                        this.state.slots1520.map((prop, key) => {
                                                            let scheduleStatus = prop.schedule.assigned ? <span>{prop.schedule.ident}</span> : <Badge bsStyle="warning">brak</Badge>
                                                            return (
                                                                <tr key={prop.uuid} className={prop.trash ? "trash" : ""}>
                                                                    <td>{prop.ident}</td>
                                                                    <td>{prop.sender.name}</td>
                                                                    <td>{prop.recipient.address.streetAddress}</td>
                                                                    <td>{scheduleStatus}</td>
                                                                    <td className="text-center">{prop.dayPart}</td>
                                                                    <td className="td-actions text-center">
                                                                        <OverlayTrigger placement="top" overlay={view}>
                                                                            <Button simple bsStyle="info" bsSize="xs" onClick={() => this.showOrderModal(prop.uuid)}>
                                                                                <i className="fa fa-edit" />
                                                                            </Button>
                                                                        </OverlayTrigger>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (<tr><td className="text-center text-muted" colSpan={5}>- brak danych -</td></tr>)
                                                    }
                                                </tbody>
                                            </Table>
                                        }
                                    />
                                </div>
                            ) : (
                                    <div>
                                        <Card
                                            content={loader}
                                        />
                                        <Card
                                            content={loader}
                                        />
                                    </div>
                                )
                            }
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminCalendar;
