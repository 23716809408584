/* global _api */
import React from "react";
import Card from "../../../../components/Card/Card.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import ExpertUploadComponent from "../../../../components/Expert/UploadComponent.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import SweetAlert from "react-bootstrap-sweetalert";
import BaseComponent from "../../../../utils/BaseComponent.jsx";
import HubMap from "../../../../components/Map/HubMap.jsx";
import Datetime from "react-datetime";
import Select from "react-select";
import moment from "moment";
import "moment/min/locales";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    OverlayTrigger,
    Tooltip,
    Modal,
    ControlLabel,
    FormControl,
    Tab,
    Nav,
    NavItem,
    Table
} from "react-bootstrap";
import Datatable from "../../../../components/Datatable";

moment.locale('pl');

class ExpertAdminKloseOrders extends BaseComponent {

    constructor(props) {
        super(props);

        this.dataFormatKlose = [{ "value": "klose", "label": "KLOSE" }];
        this.dataFormatExpert = [{ "value": "expert", "label": "EXPERT" }];
        this.availableStatuses = [
            { "value": "Przyjęte", "label": "Przyjęte" },
            { "value": "W awizacji", "label": "W awizacji" },
            { "value": "Wydane", "label": "Wydane" },
            { "value": "Gotowe do rozliczenia", "label": "Gotowe do rozliczenia" },
            { "value": "Rozliczone", "label": "Rozliczone" }
        ];
        this.dataFormats = [this.dataFormatKlose[0], this.dataFormatExpert[0]];

        this.state = {
            data: [],
            pages: -1,
            loading: true,
            isRefreshing: false,
            checkAll: false,
            askAbout: null,
            showModal: false,
            showModalImport: false,
            showModalFormat: false,
            showModalMap: false,

            tab: "basic",
            tabDefault: "basic",
            additionalFiles: [],

            dataFormat: this.dataFormats[0],
            dataFormats: this.dataFormatKlose,

            dateFrom: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
            dateTo: moment().add(1, 'months').endOf('month').format("YYYY-MM-DD"),

            orderUuid: "",
            orderYear: "",
            orderIdent: "",
            orderStatus: "",
            orderNewStatus: "",
            orderElements: [],
            orderFiles: [],

            orderDeliveryDateFrom: "",
            orderDeliveryDateTo: "",
            orderDeliveryMethod: "",

            orderDealerIdent: "",
            orderDealerName: "",
            orderDealerCountryCode: "",
            orderDealerPostalCode: "",
            orderDealerCityName: "",
            orderDealerStreetAddress: "",
            orderDealerStreetNumber: "",
            orderDealerIdentTax: "",
            orderDealerEmailAddress: "",
            orderDealerMobilePhone: "",

            orderBuyerIdent: "",
            orderBuyerName: "",
            orderBuyerCountryCode: "",
            orderBuyerPostalCode: "",
            orderBuyerCityName: "",
            orderBuyerStreetAddress: "",
            orderBuyerStreetNumber: "",
            orderBuyerIdentTax: "",
            orderBuyerEmailAddress: "",
            orderBuyerMobilePhone: "",

            orderRecipientIdent: "",
            orderRecipientName: "",
            orderRecipientCountryCode: "",
            orderRecipientPostalCode: "",
            orderRecipientCityName: "",
            orderRecipientStreetAddress: "",
            orderRecipientStreetNumber: "",
            orderRecipientIdentTax: "",
            orderRecipientEmailAddress: "",
            orderRecipientMobilePhone: "",
        };

        this.onChangeFormatCallback = () => { console.log("ok") };

    }

    componentWillUnmount() {
        _api.abort();
    }

    openOrder(uuid, event) {
        if (event) {
            event.preventDefault();
        }
        const url = `/#/client/edit-order/${uuid}`;
        window.open(url);
    }

    openSchedule(prop, event) {
        if (event) {
            event.preventDefault();
        }
        const url = `/#/admin/v2-schedule/${prop.departmentUuid}/${prop.scheduleDayIdent}`;
        window.open(url);
    }

    reloadTable(callback) {
        let self = this;
        self.fetchTable(
            {
                page: self.state.page,
                pageSize: self.state.pageSize,
                sorted: self.state.sorted,
                filtered: self.state.filtered,
                dateFrom: moment(self.state.dateFrom).format("YYYY-MM-DD"),
                dateTo: moment(self.state.dateTo).format("YYYY-MM-DD")
            },
            null,
            () => {
                if (typeof callback === "function") {
                    callback();
                }
            }
        );
    }

    createOrderFromPreorder(preorderUuid) {
        let self = this;
        self.props.showNoty('tr', 'info', `Tworzenie nowego zlecenia`);
        _api.request(`/admin/klose/create-order/${preorderUuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'success', `Numer nowego zlecenia: ${data.ident}`);
                self.reloadTable();
                setTimeout(() => { self.openOrder(data.uuid); }, 600);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    removeItem(uuid) {
        let self = this;
        self.props.showNoty('tr', 'info', `Usuwanie preawizacji`);
        _api.request(`/admin/trash/recursive/hubOrder/${uuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'success', `Zlecenie zostało usunięte`);
                this.reloadTable();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    removeItemPopup(preorderUuid) {
        this.setState({
            askAbout: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz usunąć to zlecenie?"
                    confirmBtnText="Usuń"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ askAbout: null }); this.removeItem(preorderUuid); }}
                    onCancel={() => { this.setState({ askAbout: null }); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });
    }

    removeElement(uuid) {
        let self = this;
        self.props.showNoty('tr', 'info', `Usuwanie elementu zlecenia`);
        _api.request(`/admin/trash/recursive/hubElement/${uuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'success', `Element został usunięty`);
                this.reloadTable();
                this.loadOrderData(this.state.orderUuid);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    removeElementPopup(hubElementUuid) {
        this.setState({
            askAbout: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz usunąć ten element?"
                    confirmBtnText="Usuń"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ askAbout: null }); this.removeElement(hubElementUuid); }}
                    onCancel={() => { this.setState({ askAbout: null }); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });
    }

    reMapData(data) {
        let self = this;
        const oPrint = <Tooltip id="oPrint">Drukuj</Tooltip>;
        const oEdit = <Tooltip id="oEdit">Edycja danych</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;
        return data.map((prop, key) => {
            prop.orderIdent = prop.orderUuid
                ? <Button style={{ padding: 0, minWidth: "auto" }} onClick={(e) => { self.openOrder(prop.orderUuid, e) }} bsStyle="info" round wd simple>{prop.orderIdent}</Button>
                : <Button style={{ padding: 0, minWidth: "auto" }} onClick={(e) => { self.createOrderFromPreorder(prop.uuid, e) }} bsStyle="primary" round wd simple>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    stwórz
                </Button>;
            prop.scheduleIdent = prop.scheduleIdent
                ? <Button style={{ padding: 0, minWidth: "auto" }} onClick={(e) => { self.openSchedule(prop, e) }} bsStyle="info" round wd simple>{prop.scheduleIdent}</Button>
                : <></>
            prop.actions = (
                <div className="actions-right">
                    <OverlayTrigger placement="top" overlay={oPrint}>
                        <Button
                            onClick={() => {
                                this.onChangeFormatCallback = () => { this.handlePdfNotice(prop.uuid) }
                                this.setState({ showModalFormat: true, dataFormats: this.dataFormatKlose, dataFormat: this.dataFormatKlose[0] });
                            }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-print" />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={oEdit}>
                        <Button
                            onClick={() => { this.loadOrderData(prop.uuid) }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-edit" />
                        </Button>
                    </OverlayTrigger>
                    <OverlayTrigger placement="top" overlay={oTrash}>
                        <Button
                            onClick={() => { this.removeItemPopup(prop.uuid) }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-times" />
                        </Button>
                    </OverlayTrigger>
                </div>
            )
            return prop;
        });
    }

    fetchTable = (state, instance, callback) => {
        let self = this;
        self.setState({ loading: true });
        _api.request("/admin/klose/list", {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            dateFrom: moment(self.state.dateFrom).format("YYYY-MM-DD"),
            dateTo: moment(self.state.dateTo).format("YYYY-MM-DD")
        }, (res) => {
            self.setState({
                data: self.reMapData(res.rows),
                pages: res.pages,
                loading: false,
                page: res.page,
                pageSize: res.pageSize,
                sorted: res.sorted,
                filtered: res.filtered
            },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loading: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    getFilesList = () => {
        let filesList = [];
        let allFiles = [];
        for (let i = 0; i < this.state.additionalFiles.length; i++) {
            allFiles.push({ name: this.state.additionalFiles[i].name });
        }
        for (let i = 0; i < allFiles.length; i++) {
            let elem = (<><i className="fa fa-file" /> {allFiles[i].name}</>);
            if (allFiles[i].url) {
                elem = (<a href={allFiles[i].url} target="_blank" rel="noopener noreferrer">{elem}</a>);
            }
            filesList.push(<p key={"file" + i}>{elem}</p>);
        }
        return filesList;
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        if (!self.state.additionalFiles.length) {
            self.props.showNoty('tr', 'error', 'Wybierz plik(i)');
            return;
        }
        self.setState({ loading: true });
        self.props.showNoty('tr', 'info', 'Wgrywanie pliku..');
        _api.request(`/admin/import/preorders/${this.state.dataFormat.value}`,
            {
                "files": self.state.additionalFiles
            },
            (data) => {
                if (document.getElementById("upForm")) {
                    document.getElementById("upForm").reset();
                }
                self.props.showNoty('tr', 'success', 'Plik został zaimportowany');
                self.setState({ additionalFiles: [], loading: false, showModalImport: false });
                self.reloadTable();
            },
            (error) => {
                if (error === "E_ORDER_EXISTS") {
                    self.props.showNoty('tr', 'error', 'Zlecenie o tym numerze zostało już dodane');
                } else {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas odczytu pliku');
                }
                self.setState({ loading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handlePdfNotice(uuid) {
        let self = this;
        self.setState({ loading: true });
        self.props.showNoty('tr', 'info', 'Generowanie pliku..');
        _api.request(`/admin/klose/pdf-notice/${self.state.dataFormat.value}/${uuid}`,
            {},
            (data) => {
                self.setState({ loading: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ loading: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleCsv(uuids) {
        let self = this;
        self.setState({ loading: true });
        self.props.showNoty('tr', 'info', 'Generowanie pliku..');
        _api.request(`/admin/klose/csv/${self.state.dataFormat.value}`,
            {
                uuid: uuids
            },
            (data) => {
                self.setState({ loading: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ loading: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    generateReport() {
        let self = this;
        self.setState({ excelIsGenerating: true });
        _api.request(`/admin/klose/report/${self.state.dataFormat.value}`,
            {
                dateFrom: moment(self.state.dateFrom).format("YYYY-MM-DD"),
                dateTo: moment(self.state.dateTo).format("YYYY-MM-DD")
            },
            (data) => {
                self.setState({ excelIsGenerating: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ excelIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    setDateFrom(value) {
        this.setState({ dateFrom: value, isRefreshing: true }, () => { this.setState({ isRefreshing: false }) });
    }

    setDateTo(value) {
        this.setState({ dateTo: value, isRefreshing: true }, () => { this.setState({ isRefreshing: false }) });
    }

    handleOrderSubmit(event) {
        event.preventDefault();
        let self = this;
        self.props.showNoty('tr', 'info', 'Zapisywanie danych..');
        _api.request(`/admin/klose/update/${self.state.orderUuid}`,
            {
                "order": {
                    "year": self.state.orderYear,
                    "ident": self.state.orderIdent,
                    "supplyDateFrom": self.state.orderSupplyDateFrom,
                    "supplyDateTo": self.state.orderSupplyDateTo,
                    "deliveryDateFrom": self.state.orderDeliveryDateFrom,
                    "deliveryDateTo": self.state.orderDeliveryDateTo,
                    "deliveryMethod": self.state.orderDeliveryMethod,
                    "newStatus": self.state.orderNewStatus ? self.state.orderNewStatus.value : null
                },
                "address": {
                    "dealer": {
                        "identExternal": self.state.orderDealerIdent,
                        "name": self.state.orderDealerIdent,
                        "countryCode": self.state.orderDealerCountryCode,
                        "postalCode": self.state.orderDealerPostalCode,
                        "cityName": self.state.orderDealerCityName,
                        "streetAddress": self.state.orderDealerStreetAddress,
                        "streetNumber": self.state.orderDealerStreetNumber,
                        "identTax": self.state.orderDealerIdentTax,
                        "emailAddress": self.state.orderDealerEmailAddress,
                        "mobilePhone": self.state.orderDealerMobilePhone
                    },
                    "buyer": {
                        "identExternal": self.state.orderBuyerIdent,
                        "name": self.state.orderBuyerIdent,
                        "countryCode": self.state.orderBuyerCountryCode,
                        "postalCode": self.state.orderBuyerPostalCode,
                        "cityName": self.state.orderBuyerCityName,
                        "streetAddress": self.state.orderBuyerStreetAddress,
                        "streetNumber": self.state.orderBuyerStreetNumber,
                        "identTax": self.state.orderBuyerIdentTax,
                        "emailAddress": self.state.orderBuyerEmailAddress,
                        "mobilePhone": self.state.orderBuyerMobilePhone
                    },
                    "recipient": {
                        "identExternal": self.state.orderRecipientIdent,
                        "name": self.state.orderRecipientIdent,
                        "countryCode": self.state.orderRecipientCountryCode,
                        "postalCode": self.state.orderRecipientPostalCode,
                        "cityName": self.state.orderRecipientCityName,
                        "streetAddress": self.state.orderRecipientStreetAddress,
                        "streetNumber": self.state.orderRecipientStreetNumber,
                        "identTax": self.state.orderRecipientIdentTax,
                        "emailAddress": self.state.orderRecipientEmailAddress,
                        "mobilePhone": self.state.orderRecipientMobilePhone
                    }
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Dane zostały zapisane');
                self.reloadTable();
                self.loadOrderData(self.state.orderUuid);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisu');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    loadOrderData(uuid) {
        let self = this;
        self.setState({ showModal: true, orderUuid: uuid, loading: true })
        _api.request(`/admin/klose/details/${uuid}`, {},
            (data) => {
                self.setState({
                    loading: false,

                    orderElements: data.elements,
                    orderFiles: data.files,

                    orderUuid: data.order.uuid,
                    orderYear: data.order.year,
                    orderIdent: data.order.ident,
                    orderStatus: data.order.status,
                    orderNewStatus: "",

                    orderSupplyDateFrom: data.order.supplyDateFrom,
                    orderSupplyDateTo: data.order.supplyDateTo,
                    orderDeliveryDateFrom: data.order.deliveryDateFrom,
                    orderDeliveryDateTo: data.order.deliveryDateTo,
                    orderDeliveryMethod: data.order.deliveryMethod,

                    orderDealerIdent: data.address.dealer.identExternal,
                    orderDealerName: data.address.dealer.name,
                    orderDealerCountryCode: data.address.dealer.countryCode,
                    orderDealerPostalCode: data.address.dealer.postalCode,
                    orderDealerCityName: data.address.dealer.cityName,
                    orderDealerStreetAddress: data.address.dealer.streetAddress,
                    orderDealerStreetNumber: data.address.dealer.streetNumber,
                    orderDealerIdentTax: data.address.dealer.identTax,
                    orderDealerEmailAddress: data.address.dealer.emailAddress,
                    orderDealerMobilePhone: data.address.dealer.mobilePhone,

                    orderBuyerIdent: data.address.buyer.identExternal,
                    orderBuyerName: data.address.buyer.name,
                    orderBuyerCountryCode: data.address.buyer.countryCode,
                    orderBuyerPostalCode: data.address.buyer.postalCode,
                    orderBuyerCityName: data.address.buyer.cityName,
                    orderBuyerStreetAddress: data.address.buyer.streetAddress,
                    orderBuyerStreetNumber: data.address.buyer.streetNumber,
                    orderBuyerIdentTax: data.address.buyer.identTax,
                    orderBuyerEmailAddress: data.address.buyer.emailAddress,
                    orderBuyerMobilePhone: data.address.buyer.mobilePhone,

                    orderRecipientIdent: data.address.recipient.identExternal,
                    orderRecipientName: data.address.recipient.name,
                    orderRecipientCountryCode: data.address.recipient.countryCode,
                    orderRecipientPostalCode: data.address.recipient.postalCode,
                    orderRecipientCityName: data.address.recipient.cityName,
                    orderRecipientStreetAddress: data.address.recipient.streetAddress,
                    orderRecipientStreetNumber: data.address.recipient.streetNumber,
                    orderRecipientIdentTax: data.address.recipient.identTax,
                    orderRecipientEmailAddress: data.address.recipient.emailAddress,
                    orderRecipientMobilePhone: data.address.recipient.mobilePhone,
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleFormatChange(event) {
        event.preventDefault();
        this.setState({ showModalFormat: false });
        this.onChangeFormatCallback();
    }

    render() {

        let cols = [
            {
                Header: "Zlecenie",
                accessor: "orderIdent",
            },
            {
                Header: "Harmonogram",
                accessor: "scheduleIdent"
            },
            {
                Header: "ZK",
                accessor: "ident"
            },
            {
                Header: "Odbiorca",
                accessor: "addressRecipientName"
            },
            {
                Header: "Dostawa OD",
                accessor: "deliveryDateFrom"
            },
            {
                Header: "Dostawa DO",
                accessor: "deliveryDateTo"
            },
            {
                Header: "Sposób odbioru",
                accessor: "deliveryMethod"
            },
            {
                Header: "Status",
                accessor: "status"
            },
            {
                Header: "",
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 120
            }
        ];

        const filesList = this.getFilesList();

        const modalAddressList = [
            [
                { "key": "orderDealer", "value": "Sprzedawca" },
                { "key": "orderBuyer", "value": "Nabywca" },
                { "key": "orderRecipient", "value": "Odbiorca" }
            ],
            [
                { "key": "Ident", "value": "Identyfikator" },
                { "key": "Name", "value": "Nazwa" },
                { "key": "CountryCode", "value": "Kraj" },
                { "key": "PostalCode", "value": "Kod pocztowy" },
                { "key": "CityName", "value": "Miasto" },
                { "key": "StreetAddress", "value": "Ulica" },
                { "key": "StreetNumber", "value": "Numer" },
                { "key": "IdentTax", "value": "NIP" },
                { "key": "EmailAddress", "value": "E-mail" },
                { "key": "MobilePhone", "value": "Telefon" }
            ]
        ];

        const modalContentLoader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        let totalWeight = 0;
        let totalCapacity = 0;

        const modalContent = (
            <form onSubmit={this.handleOrderSubmit.bind(this)}>
                <Tab.Container
                    id="tabs-order"
                    activeKey={this.state.tab}
                    onSelect={(key) => { this.setState({ tab: key }) }}
                >
                    <Row className="clearfix">
                        <Col sm={12}>
                            <Nav bsStyle="tabs">
                                <NavItem eventKey="basic">Informacje</NavItem>
                                <NavItem eventKey="address">Adresy</NavItem>
                                <NavItem eventKey="elements">Elementy</NavItem>
                            </Nav>
                        </Col>
                        <Col sm={12}>
                            <Tab.Content animation={false}>
                                <Tab.Pane eventKey="basic" style={{ padding: "15px 0px" }}>
                                    <Row>
                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel>Rok:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderYear', e) }}
                                                    value={this.state['orderYear']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Zaopatrzenie od:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderSupplyDateFrom', e) }}
                                                    value={this.state['orderSupplyDateFrom']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Zaopatrzenie do:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderSupplyDateTo', e) }}
                                                    value={this.state['orderSupplyDateTo']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Aktualny status:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderStatus', e) }}
                                                    value={this.state['orderStatus']}
                                                    placeholder="..."
                                                    readOnly={true}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Nowy status:</ControlLabel>
                                                <Col>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        name="orderNewStatus"
                                                        value={this.state.orderNewStatus}
                                                        placeholder="Wybierz.."
                                                        onChange={value =>
                                                            this.setState({ orderNewStatus: value })
                                                        }
                                                        options={this.availableStatuses}
                                                        isClearable={true}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </Col>

                                        <Col md={6}>
                                            <FormGroup>
                                                <ControlLabel>Identyfikator:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderIdent', e) }}
                                                    value={this.state['orderIdent']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Dostawa od:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderDeliveryDateFrom', e) }}
                                                    value={this.state['orderDeliveryDateFrom']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Dostawa do:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderDeliveryDateTo', e) }}
                                                    value={this.state['orderDeliveryDateTo']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Sposób odbioru/transportu:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onChange('orderDeliveryMethod', e) }}
                                                    value={this.state['orderDeliveryMethod']}
                                                    placeholder="..."
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Dołączone pliki:</ControlLabel>
                                                <ul>
                                                    {this.state.orderFiles.length ? this.state.orderFiles.map(orderFile => {
                                                        return (
                                                            <li key={orderFile.uuid}><a href={orderFile.url}>{orderFile.name}</a></li>
                                                        )
                                                    }) : (
                                                        <li>[brak]</li>
                                                    )}
                                                </ul>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="address" style={{ padding: "15px 0px" }}>
                                    <Row>
                                        {modalAddressList[0].map((section) => {
                                            return (
                                                <Col md={4} key={section.key}>
                                                    <p className="lead">{section.value}</p>
                                                    {modalAddressList[1].map((field) => {
                                                        let ident = section.key + field.key;
                                                        return (
                                                            <FormGroup key={ident}>
                                                                <ControlLabel>{field.value}:</ControlLabel>
                                                                <FormControl
                                                                    type="text"
                                                                    onChange={(e) => { this.onChange(ident, e) }}
                                                                    value={this.state[ident] || ""}
                                                                    placeholder={field.value}
                                                                />
                                                            </FormGroup>
                                                        );
                                                    }
                                                    )}
                                                </Col>
                                            )
                                        })}
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="elements" style={{ padding: "15px 0px" }}>
                                    <Row>
                                        <Col md={12}>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Nazwa</th>
                                                        <th>Waga</th>
                                                        <th>Objętość</th>
                                                        <th>Kod</th>
                                                        <th>Status</th>
                                                        <th style={{ textAlign: "center" }}>Opcje</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.orderElements.map((primaryElement) => {
                                                            totalWeight += parseFloat(primaryElement.weight);
                                                            totalCapacity += parseFloat(primaryElement.capacity);
                                                            return (
                                                                <React.Fragment key={primaryElement.uuid}>
                                                                    <tr>
                                                                        <td style={{ fontWeight: "bold" }}>{primaryElement.name}</td>
                                                                        <td style={{ fontWeight: "bold" }}>{primaryElement.weight}</td>
                                                                        <td style={{ fontWeight: "bold" }}>{primaryElement.capacity}</td>
                                                                        <td style={{ fontWeight: "bold" }}>{primaryElement.identIndex}/{primaryElement.identCombination}</td>
                                                                        <td style={{ fontWeight: "bold" }}>{primaryElement.status}</td>
                                                                        <td style={{ textAlign: "center" }}>
                                                                            <Button
                                                                                onClick={() => { this.removeElementPopup(primaryElement.uuid) }}
                                                                                bsStyle="default"
                                                                                simple
                                                                                icon>
                                                                                <i className="fa fa-times" />
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                    {primaryElement.elements.map((childElement) => {
                                                                        return (
                                                                            <tr key={childElement.uuid}>
                                                                                <td>{childElement.position}. {childElement.name}</td>
                                                                                <td>{childElement.weight}</td>
                                                                                <td>{childElement.capacity}</td>
                                                                                <td>{childElement.codeEan}</td>
                                                                                <td>{childElement.status}</td>
                                                                                <td style={{ textAlign: "center" }}>
                                                                                    <Button
                                                                                        onClick={() => { this.removeElementPopup(childElement.uuid) }}
                                                                                        bsStyle="default"
                                                                                        simple
                                                                                        icon>
                                                                                        <i className="fa fa-times" />
                                                                                    </Button>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </React.Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                                <tfoot>
                                                    <tr>
                                                        <th style={{ fontWeight: "bold" }}>Razem</th>
                                                        <th>{totalWeight}</th>
                                                        <th>{totalCapacity}</th>
                                                        <th>-</th>
                                                        <th>
                                                            <Button
                                                                disabled={this.state.loading}
                                                                bsSize="xsmall"
                                                                bsStyle="link"
                                                                onClick={() => {
                                                                    this.onChangeFormatCallback = () => {
                                                                        this.handleCsv([this.state.orderUuid])
                                                                    }
                                                                    this.setState({ showModalFormat: true, dataFormats: this.dataFormatKlose, dataFormat: this.dataFormatKlose[0] });
                                                                }}
                                                            >
                                                                {this.state.loading ? 'Ładowanie…' : 'Pobierz .csv'}
                                                            </Button>
                                                        </th>
                                                        <th style={{ textAlign: "center" }}>-</th>
                                                    </tr>
                                                </tfoot>
                                            </Table>
                                        </Col>
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
                <Button type="submit" disabled={this.state.loading} bsStyle="default" onClick={!this.state.loading ? this.handleOrderSubmit.bind(this) : null}>
                    {this.state.loading ? 'Ładowanie…' : 'Zapisz dane'}
                </Button>
            </form>
        )

        const modal = (
            <Modal show={this.state.showModal} onHide={() => { this.setState({ showModal: false }) }} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja danych</Modal.Header>
                <Modal.Body>
                    {this.state.loading ? modalContentLoader : modalContent}
                </Modal.Body>
            </Modal>
        )

        const modalImportContent = (
            <form onSubmit={this.handleSubmit.bind(this)} id="upForm">
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <ControlLabel>Wybierz plik do importu: </ControlLabel>
                            <ExpertUploadComponent onSelectedFiles={files => { this.setState({ additionalFiles: files }) }} />
                        </FormGroup>
                        <FormGroup>
                            {filesList}
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Format pliku: </ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={this.state.dataFormat}
                                    placeholder="Wybierz format"
                                    onChange={
                                        (value) => {
                                            this.setState({ dataFormat: value });
                                        }
                                    }
                                    options={this.state.dataFormats}
                                />
                            </Col>
                        </FormGroup>
                        <Button type="submit" disabled={this.state.loading} className="pull-right btn-fill btn-wd btn-icon btn btn-success" onClick={!this.state.loading ? this.handleSubmit.bind(this) : null}>
                            <i className="fa fa-save" />  {this.state.loading ? 'Ładowanie…' : 'Wgraj plik'}
                        </Button>
                    </Col>
                </Row>
            </form>
        )

        const modalImport = (
            <Modal show={this.state.showModalImport} onHide={() => { this.setState({ showModalImport: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Import danych</Modal.Header>
                <Modal.Body>
                    {this.state.loading ? modalContentLoader : modalImportContent}
                </Modal.Body>
            </Modal>
        );

        const modalFormat = (
            <Modal show={this.state.showModalFormat} onHide={() => { this.setState({ showModalFormat: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Format danych</Modal.Header>
                <Modal.Body>
                    <form onSubmit={this.handleSubmit.bind(this)} id="upForm">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>Format danych: </ControlLabel>
                                    <Col>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={this.state.dataFormat}
                                            placeholder="Wybierz format"
                                            onChange={
                                                (value) => {
                                                    this.setState({ dataFormat: value });
                                                }
                                            }
                                            options={this.state.dataFormats}
                                        />
                                    </Col>
                                </FormGroup>
                                <Button type="submit" className="pull-right btn-fill btn-wd btn-icon btn btn-success" onClick={this.handleFormatChange.bind(this)}>
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );

        const modalMap = (
            <Modal show={this.state.showModalMap} onHide={() => { this.setState({ showModalMap: false }); this.reloadTable() }} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Planowanie</Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <HubMap
                                {...this.props}
                                dateFrom={moment(this.state.dateFrom)}
                                dateTo={moment(this.state.dateTo)}
                                loadingElement={<div style={{ height: `100%` }} />}
                                containerElement={<div style={{ height: `calc(80vh)` }} />}
                                mapElement={<div style={{ height: `100%` }} />}
                                defaultCenter={{ lat: 51.9194, lng: 19.1451 }}
                                defaultZoom={6}
                            />
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );

        return (
            <div className="main-content">
                {modal}
                {modalImport}
                {modalFormat}
                {modalMap}
                {this.state.askAbout}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Preawizacja
                                        </span>

                                        <Button
                                            bsStyle="default"
                                            disabled={this.state.excelIsGenerating}
                                            pullRight
                                            round
                                            onClick={() => {
                                                this.onChangeFormatCallback = () => {
                                                    this.generateReport()
                                                }
                                                this.setState({ showModalFormat: true, dataFormats: this.dataFormats, dataFormat: this.dataFormats[0] });
                                            }}
                                        >
                                            <span className="btn-label">
                                                <i className="fa fa-download" /> Eksport danych
                                            </span>
                                        </Button>

                                        <Button bsStyle="default" pullRight round onClick={() => { this.setState({ showModalImport: true }) }} style={{ marginRight: 10 }}>
                                            <span className="btn-label">
                                                <i className="fa fa-upload" /> Import danych
                                            </span>
                                        </Button>

                                        <Button bsStyle="default" pullRight round onClick={() => { this.setState({ showModalMap: true }) }} style={{ marginRight: 10 }}>
                                            <span className="btn-label">
                                                <i className="fa fa-map" /> Planowanie
                                            </span>
                                        </Button>
                                    </div>
                                }
                                content={
                                    <>
                                        <Row>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Zakres dostawy od: </ControlLabel>
                                                    <Datetime
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: "Wybierz datę" }}
                                                        value={moment(this.state.dateFrom)}
                                                        defaultValue={moment(this.state.dateFrom)}
                                                        onChange={value =>
                                                            this.setDateFrom(value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col md={6}>
                                                <FormGroup>
                                                    <ControlLabel>Zares dostawy do: </ControlLabel>
                                                    <Datetime
                                                        timeFormat={false}
                                                        inputProps={{ placeholder: "Wybierz datę" }}
                                                        value={moment(this.state.dateTo)}
                                                        defaultValue={moment(this.state.dateTo)}
                                                        onChange={value =>
                                                            this.setDateTo(value)
                                                        }
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {
                                            !this.state.isRefreshing
                                                ?
                                                (
                                                    <Datatable
                                                        data={this.state.data}
                                                        pages={this.state.pages}
                                                        loading={this.state.loading}
                                                        manual
                                                        filterable
                                                        columns={cols}
                                                        defaultSorted={[
                                                            {
                                                                id: "created",
                                                                desc: true
                                                            }
                                                        ]}
                                                        onFetchData={this.fetchTable}
                                                        defaultPageSize={25}
                                                        pageSizeOptions={[25, 50, 100, 150, 200]}
                                                        showPaginationBottom
                                                        className="-striped -highlight"
                                                        previousText="Poprzednia"
                                                        nextText="Następna"
                                                        noDataText="Brak danych"
                                                        loadingText="Pobieranie danych"
                                                        pageText="Strona"
                                                        ofText=" z "
                                                        rowsText="wierszy"
                                                    />
                                                )
                                                :
                                                modalContentLoader
                                        }
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminKloseOrders;
