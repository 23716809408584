

/* global _api */
import React, { Component } from "react";
import "moment/min/locales";
import { Row, Col, Modal, Table, FormControl, Nav, NavItem, Tab, FormGroup, ControlLabel, ListGroup, ListGroupItem, Button } from "react-bootstrap";
import Select from "react-select";
import Datetime from "react-datetime";
import SweetAlert from "react-bootstrap-sweetalert";

class ExpertTransferModalComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            tab: 'elements',
            askAbout: null
        }
    }

    popupConfirmSubmit() {
        this.setState({
            askAbout: (
                <SweetAlert
                    info={true}
                    confirmBtnBsStyle="info"
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź nowy harmonogram"
                    confirmBtnText={"Potwierdzam"}
                    onConfirm={() => { this.setState({ askAbout: null }, () => this.props.onSubmit()); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ askAbout: null }); }}
                >
                    {this.props.confirmCreate || 'Czy na pewno chcesz stworzyć nowy harmonogram?'}
                </SweetAlert>
            )
        });
    }

    onSubmit(e) {
        if (e) {
            e.preventDefault();
        }
        const { usedCurrentWarehouses } = this.getData();
        if (usedCurrentWarehouses.length > 1 && !this.props.editOnlyBasics) {
            this.setState({
                askAbout: (
                    <SweetAlert
                        info
                        showCancel
                        style={{ display: "block", marginTop: "-300px" }}
                        title="Potwierdź operację"
                        confirmBtnText="Tak"
                        cancelBtnText="Anuluj"
                        onConfirm={() => { this.setState({ askAbout: null }); this.popupConfirmSubmit(); }}
                        onCancel={() => { this.setState({ askAbout: null }); }}
                        confirmBtnBsStyle="info">
                        Czy na pewno chcesz zaplanować transfer z różnymi punktami odbioru: {usedCurrentWarehouses.join(', ')}?
                    </SweetAlert>
                )
            });
        } else {
            this.popupConfirmSubmit();
        }
    }

    getData() {
        let usedCurrentWarehouses = [];
        let totalSize = 0;
        let totalWeight = 0;
        let totalNumberOfPackages = 0;
        let filteredCurrentWarehouses = this.props.upperState.elements.map(itm => {
            totalSize += parseFloat(itm.calculatedSize || 0);
            totalWeight += parseFloat(itm.calculatedWeight || 0);
            itm.elements.forEach((subElement, subIndex) => {
                totalNumberOfPackages += parseFloat(subElement.numberOfPackages || 0);
            });
            if (usedCurrentWarehouses.includes(itm.currentWarehouseName)) {
                return null;
            }
            usedCurrentWarehouses.push(itm.currentWarehouseName);
            return itm;
        }).filter(Boolean);
        return {
            usedCurrentWarehouses,
            totalSize,
            totalWeight,
            totalNumberOfPackages,
            filteredCurrentWarehouses,
        };
    }

    render() {

        if (!this.props.show) {
            return <></>
        }

        const {
            totalSize,
            totalWeight,
            totalNumberOfPackages,
            filteredCurrentWarehouses
        } = this.getData();

        let pickupDeliveryForm = (
            <>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Data odbioru: </ControlLabel>
                            {
                                this.props.upperState.pickupDate ? (
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={'YYYY-MM-DD'}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        defaultValue={this.props.upperState.pickupDate}
                                        onChange={value => this.props.setUpperState({ pickupDate: value })}
                                    />
                                ) : (
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={'YYYY-MM-DD'}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        onChange={value => this.props.setUpperState({ pickupDate: value })}
                                    />
                                )
                            }
                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <ControlLabel bsClass="text-left">Miejsce odbioru: </ControlLabel>
                        <ListGroup>
                            {
                                filteredCurrentWarehouses.map(itm => (
                                    <ListGroupItem key={`pickup-${itm.uuid}-${itm.currentWarehouseName}`}>
                                        {`${itm.currentWarehouseName}  → ${itm.lastMileWarehouseName ? itm.lastMileWarehouseName : `?`}`}
                                    </ListGroupItem>
                                ))
                            }
                        </ListGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Data dostawy: </ControlLabel>
                            {
                                this.props.upperState.deliveryDate ? (
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={'YYYY-MM-DD'}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        defaultValue={this.props.upperState.deliveryDate}
                                        onChange={value => this.props.setUpperState({ deliveryDate: value })}
                                    />
                                ) : (
                                    <Datetime
                                        timeFormat={false}
                                        dateFormat={'YYYY-MM-DD'}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        onChange={value => this.props.setUpperState({ deliveryDate: value })}
                                    />
                                )
                            }

                        </FormGroup>
                    </Col>
                    <Col sm={12}>
                        <ControlLabel bsClass="text-left">Miejsce dostawy: </ControlLabel>
                        {
                            this.props.upperState.deliveryWarehouseName ? (
                                <ListGroup>
                                    <ListGroupItem>{this.props.upperState.deliveryWarehouseName}</ListGroupItem>
                                </ListGroup>
                            ) : (
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    value={this.props.upperState.deliveryWarehouseUuid ? this.props.upperState.deliveryWarehouses.find(itm => itm.value === this.props.upperState.deliveryWarehouseUuid) : null}
                                    placeholder="Wybierz.."
                                    onChange={value => this.props.setUpperState({ deliveryWarehouseUuid: value ? value.value : null })}
                                    options={this.props.upperState.deliveryWarehouses}
                                    isClearable={true}
                                />
                            )
                        }
                    </Col>
                </Row>
            </>
        )

        let elementsForm = (
            <Table>
                <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Kontrahent</th>
                        <th>Nr zlecenia</th>
                        <th>Towar</th>
                        <th>Paczki</th>
                        <th style={{ textAlign: "right" }}>Kubatura (m<sup>3</sup>)</th>
                        <th style={{ textAlign: "right" }}>Waga (kg)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.upperState.elements.map((element, index) => (
                            <React.Fragment key={`modal-element-${element.uuid}-${index}-0`}>
                                <tr>
                                    <td style={{ fontWeight: "bold" }}>
                                        {index + 1}.0
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {element.companyName}
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        {element.orderIdent}
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        &nbsp;
                                    </td>
                                    <td style={{ fontWeight: "bold" }}>
                                        &nbsp;
                                    </td>
                                    <td style={{ fontWeight: "bold", textAlign: "right" }}>
                                        {parseFloat(element.calculatedSize).toFixed(3)}
                                    </td>
                                    <td style={{ fontWeight: "bold", textAlign: "right" }}>
                                        {parseFloat(element.calculatedWeight).toFixed(3)}
                                    </td>
                                </tr>
                                {element.elements.map((subElement, subIndex) => (
                                    <tr key={`modal-element-${element.uuid}-${index}-${subIndex + 1}`}>
                                        <td>
                                            {index + 1}.{subIndex + 1}
                                        </td>
                                        <td>
                                            {element.companyName}
                                        </td>
                                        <td>
                                            {element.orderIdent}
                                        </td>
                                        <td>
                                            {subElement.name}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            {subElement.numberOfPackages || <strong className="text-danger">?</strong>}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            {parseFloat(subElement.size).toFixed(3)}
                                        </td>
                                        <td style={{ textAlign: "right" }}>
                                            {parseFloat(subElement.weight).toFixed(3)}
                                        </td>
                                    </tr>
                                ))}
                            </React.Fragment>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th style={{ textAlign: "right" }}>{totalNumberOfPackages}</th>
                        <th style={{ textAlign: "right" }}>{totalSize.toFixed(3)}</th>
                        <th style={{ textAlign: "right" }}>{totalWeight.toFixed(3)}</th>
                    </tr>
                </tfoot>
            </Table>
        )

        let editDriverForm = (
            <Row>
                <Col sm={12}>
                    <div>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Imię: </ControlLabel>
                                    <FormControl placeholder="Imię" name="firstname" value={this.props.upperState.firstname || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ firstname: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Nazwisko: </ControlLabel>
                                    <FormControl placeholder="Nazwisko" name="lastname" value={this.props.upperState.lastname || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ lastname: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Nazwa firmy: </ControlLabel>
                                    <FormControl placeholder="Nazwa firmy" name="companyName" value={this.props.upperState.companyName || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ companyName: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Numer pojazdu: </ControlLabel>
                                    <FormControl placeholder="Numer pojazdu" name="vehicleNumber" value={this.props.upperState.vehicleNumber || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ vehicleNumber: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Numer dowodu: </ControlLabel>
                                    <FormControl placeholder="Numer dowodu" name="vehicleIdent" value={this.props.upperState.vehicleIdent || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ vehicleIdent: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Telefon: </ControlLabel>
                                    <FormControl placeholder="Telefon" name="mobileNumber" value={this.props.upperState.mobileNumber || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ mobileNumber: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <ControlLabel bsClass="text-left">Komentarz: </ControlLabel>
                                    <FormControl placeholder="Komentarz" name="comment" value={this.props.upperState.comment || ""}
                                        onChange={event => {
                                            this.props.setUpperState({ comment: event.target.value });
                                        }} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>
        )

        return (
            <Modal show={this.props.show} onHide={() => { this.props.onHide() }} style={{ paddingLeft: 0 }} backdrop="static" bsSize="large">
                {this.state.askAbout}
                <Modal.Header closeButton>
                    Harmonogram
                    {this.props.upperState.cancelled === "1" ? <strong className="text-danger">&nbsp;ANULOWANY</strong> : ""}
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={(e) => { this.onSubmit(e) }}>
                        <Tab.Container
                            id="tabs-transfer-modal"
                            activeKey={this.state.tab}
                            onSelect={(tab) => { this.setState({ tab: tab }) }}
                        >
                            <Row className="clearfix">
                                <Col sm={12}>
                                    <Nav bsStyle="tabs">
                                        <NavItem eventKey="elements">Zamówienia i paczki</NavItem>
                                        <NavItem eventKey="pickupDelivery">Trasa</NavItem>
                                        <NavItem eventKey="driver">Kierowca</NavItem>
                                    </Nav>
                                </Col>
                                <Col sm={12}>
                                    <Tab.Content animation={false}>
                                        <Tab.Pane eventKey="elements" style={{ padding: "15px 0px" }}>{elementsForm}</Tab.Pane>
                                        <Tab.Pane eventKey="pickupDelivery" style={{ padding: "15px 0px" }}>{pickupDeliveryForm}</Tab.Pane>
                                        <Tab.Pane eventKey="driver" style={{ padding: "15px 0px" }}>{editDriverForm}</Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <hr />
                        <Button type="submit" disabled={this.props.upperState.loading} bsStyle="default" onClick={!this.props.upperState.loading ? (e) => { this.onSubmit(e) } : null}>
                            {this.props.upperState.loading ? 'Zapisywanie…' : 'Zapisz transfer'}
                        </Button>
                    </form>
                </Modal.Body>
            </Modal >
        );
    }
}

export default ExpertTransferModalComponent;