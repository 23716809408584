import React, { Component } from "react";
import UserCard from "../../../components/Card/UserCard.jsx";
import ExpertProfileComponent from "../../../components/Expert/ProfileComponent.jsx";
import avatar from "../../../assets/img/faces/face-0.jpg";
import image from "../../../assets/img/expert/cover-sidebar.jpg";
import {
    Grid,
    Row,
    Col
} from "react-bootstrap";

class ExpertProfileMe extends Component {

    render() {
        return (
            <div className="main-content">
                <Grid fluid>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            <UserCard
                                bgImage={image}
                                avatar={avatar}
                                description={
                                    <ExpertProfileComponent
                                        isAdminView={false}
                                        uuid={this.props.user.user.uuid}
                                        emailAddress={this.props.user.user.emailAddress}
                                        firstname={this.props.user.user.firstname}
                                        lastname={this.props.user.user.lastname}
                                        mobilePhone={this.props.user.user.mobilePhone}
                                        department={this.props.user.user.department.uuid}
                                        company={this.props.user.user.company.uuid}
                                        fleet={this.props.user.user.fleet.uuid}
                                        endpoint="/user/update"
                                        showNoty={this.props.showNoty}
                                        setProgress={this.props.setProgress}
                                    />
                                }
                                socials=""
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default ExpertProfileMe;