/* global _api */
import React, { Component } from "react";
import PropagateLoader from 'react-spinners/PropagateLoader';
import {
    FormGroup,
    ControlLabel,
    FormControl,
    Row,
    Col,
    Button
} from "react-bootstrap";

class ExpertWarehouseComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: false,
            company: this.props.companyUuid || "",
            uuid: this.props.uuid || "",
            name: this.props.name || "",
            avgLoadingTime: this.props.avgLoadingTime || "",
            position: this.props.position || "",
            addressName: this.props.addressName || "",
            streetNumber: this.props.streetNumber || "",
            streetAddress: this.props.streetAddress || "",
            cityName: this.props.cityName || "",
            postalCode: this.props.postalCode || "",
            apartmentNumber: this.props.apartmentNumber || "",
            apartmentFloor: this.props.apartmentFloor || "",
            contactPerson: this.props.contactPerson || "",
            emailAddress: this.props.emailAddress || "",
            mobilePhone: this.props.mobilePhone || "",
            elevator: this.props.elevator || false
        };
    }

    componentDidMount() {
        if (this.props.uuid) {
            this.load(this.props.uuid);
        }
    }

    load(uuid) {
        let self = this;
        self.setState({ uuid: uuid, isLoading: true });
        _api.request(`${self.props.endpointDetails}/${self.state.uuid}`, {},
            (data) => {
                self.setState({
                    isLoading: false,
                    uuid: data.warehouse.uuid,
                    name: data.warehouse.name,
                    avgLoadingTime: data.warehouse.avgLoadingTime,
                    position: data.warehouse.position,
                    addressName: data.address.name,
                    streetNumber: data.address.streetNumber,
                    streetAddress: data.address.streetAddress,
                    cityName: data.address.cityName,
                    postalCode: data.address.postalCode,
                    apartmentNumber: data.address.apartmentNumber,
                    apartmentFloor: data.address.apartmentFloor,
                    contactPerson: data.address.contactPerson,
                    emailAddress: data.address.emailAddress,
                    mobilePhone: data.address.mobilePhone,
                    elevator: data.address.elevator,
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleSubmit(event) {
        event.preventDefault();

        let self = this;

        let validationRules = {
            "isValid": true,
            "items": [
                { "field": "name", "rules": { "empty": "Podaj nazwę" } }
            ]
        };

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = self.state[element.field];
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
            }
        });

        if (!validationRules.isValid) {
            return;
        }

        self.setState({ isSaving: true });
        self.props.setProgress(0);
        _api.request(
            self.state.uuid
                ?
                `${self.props.endpointUpdate}/${self.state.uuid}`
                :
                self.props.endpointCreate,
            {
                "warehouse": {
                    "uuid": self.state.uuid,
                    "name": self.state.name,
                    "avgLoadingTime": self.state.avgLoadingTime,
                    "position": self.state.position
                },
                "company": {
                    "uuid": self.state.company
                },
                "address": {
                    name: self.state.addressName,
                    streetNumber: self.state.streetNumber,
                    streetAddress: self.state.streetAddress,
                    cityName: self.state.cityName,
                    postalCode: self.state.postalCode,
                    apartmentNumber: self.state.apartmentNumber,
                    apartmentFloor: self.state.apartmentFloor,
                    contactPerson: self.state.contactPerson,
                    emailAddress: self.state.emailAddress,
                    mobilePhone: self.state.mobilePhone,
                    elevator: self.state.elevator,
                }
            },
            (data) => {
                if (typeof self.props.onSaved === "function") {
                    self.props.onSaved();
                } else {
                    self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                }
                self.setState({ isSaving: false });
                self.props.setProgress(100);
            },
            (error) => {
                if (typeof self.props.onError === "function") {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                } else {
                    self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                }
                self.setState({ isSaving: false });
                self.props.setProgress(100);
            }
        );
    }

    render() {

        const loading = (
            <div className="main-content">
                <div style={{ "paddingBottom": "26px" }}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        css={{
                            "display": "block",
                            "margin": "10px auto",
                            "width": "1px"
                        }}
                        color={'#777'}
                        loading={true}
                    />
                </div>
            </div>
        );

        const loaded = (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Nazwa: </ControlLabel>
                            <FormControl placeholder="Wprowadź nazwę" name="name" value={this.state.name || ""}
                                onChange={event => {
                                    this.setState({ name: event.target.value });
                                }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">E-mail: </ControlLabel>
                            <FormControl placeholder="Wprowadź e-mail" name="emailAddress" value={this.state.emailAddress || ""}
                                onChange={event => {
                                    this.setState({ emailAddress: event.target.value });
                                }} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>Pozycja na liście:</ControlLabel>
                            <FormControl
                                minLength={2}
                                type="text"
                                value={this.state.position || ""}
                                onChange={event =>
                                    this.setState({ position: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>Śr. czas załadunku:</ControlLabel>
                            <FormControl
                                minLength={2}
                                type="text"
                                value={this.state.avgLoadingTime || ""}
                                placeholder="(w minutach)"
                                onChange={event =>
                                    this.setState({ avgLoadingTime: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <ControlLabel>Kod pocztowy:</ControlLabel>
                            <FormControl
                                minLength={2}
                                type="text"
                                value={this.state.postalCode || ""}
                                onChange={event =>
                                    this.setState({ postalCode: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={8}>
                        <FormGroup>
                            <ControlLabel>Miasto:</ControlLabel>
                            <FormControl
                                minLength={2}
                                type="text"
                                value={this.state.cityName || ""}
                                onChange={event =>
                                    this.setState({ cityName: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <ControlLabel>Ulica:</ControlLabel>
                            <FormControl
                                minLength={2}
                                type="text"
                                value={this.state.streetAddress || ""}
                                onChange={event =>
                                    this.setState({ streetAddress: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <ControlLabel>Nr domu:</ControlLabel>
                            <FormControl
                                minLength={1}
                                type="text"
                                value={this.state.streetNumber || ""}
                                onChange={event =>
                                    this.setState({ streetNumber: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <ControlLabel>Nr lokalu:</ControlLabel>
                            <FormControl
                                type="text"
                                placeholder="(opcjonalnie)"
                                value={this.state.apartmentNumber || ""}
                                onChange={event =>
                                    this.setState({ apartmentNumber: event.target.value })
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Button type="submit" disabled={this.state.isSaving} bsStyle="default" onClick={!this.state.isSaving ? this.handleSubmit.bind(this) : null}>
                            {this.state.isSaving ? 'Zapisywanie…' : 'Zapisz dane'}
                        </Button>
                    </Col>
                </Row>
            </form>
        );

        return (
            <div>
                {this.state.isLoading ? loading : loaded}
            </div>
        )

    }

}

export default ExpertWarehouseComponent;
