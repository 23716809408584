import React, { Component } from "react";
import { Navbar } from "react-bootstrap";
import routes from "../../routes.js";
import { Helmet } from "react-helmet";

class AdminNavbar extends Component {

    getActiveRoute = routes => {
        let activeRoute = null;
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
                ) {
                    return routes[i].name;
                }
            }
        }
        return activeRoute;
    };

    mobileSidebarToggle = e => {
        document.documentElement.classList.toggle("nav-open");
    };

    render() {
        let title = this.props.title || this.getActiveRoute(routes);
        return (
            <>
                <Helmet>
                    <title>{[title, 'Expert', 'Zlecenia'].filter(n => n).join(' - ')}</title>
                </Helmet>
                <Navbar fluid className={this.props.navbar ? "navbar-fixed" : ""}>
                    <Navbar.Header>
                        <div className="navbar-minimize" style={{ display: "block" }}>
                            <button
                                id="minimizeSidebar"
                                className="btn btn-default btn-fill btn-round btn-icon hidden-sm"
                                onClick={this.props.handleMiniClick}>
                                <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
                                <i className="fa fa-navicon visible-on-sidebar-mini" />
                            </button>
                            <button
                                id="reloadPage"
                                className="btn btn-default btn-fill btn-round btn-icon btn-info"
                                onClick={() => { window.location.reload(); }}>
                                <i className="fa fa-refresh" />
                            </button>
                        </div>
                        <Navbar.Brand style={{ marginLeft: "0px" }}>
                            {title}
                        </Navbar.Brand>
                        <Navbar.Toggle onClick={this.mobileSidebarToggle} />
                    </Navbar.Header>
                </Navbar>
            </>
        );
    }

}

export default AdminNavbar;
