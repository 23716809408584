/* global _api */
/*eslint-disable*/
import React, { Component } from "react";
import { Collapse } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import avatar from "../../assets/img/faces/face-0.jpg";
import logo from "../../assets/img/expert/logo-expert-dark-1.svg";
import logoMini from "../../assets/img/expert/icon.png";
import routes from "../../routes.js";

var ps;

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...this.getCollapseStates(routes),
            openAvatar: false,
            isWindows: navigator.platform.indexOf("Win") > -1 ? true : false,
            width: window.innerWidth,
            largeLogo: !document.body.classList.contains("sidebar-mini")
        };
        this.onChangeSidebarSize = this.onChangeSidebarSize.bind(this)
    }

    onChangeSidebarSize() {
        const largeLogo = !document.body.classList.contains("sidebar-mini");
        setTimeout(() => {
            this.setState({ largeLogo: largeLogo })
        }, largeLogo ? 0 : 250);
    }

    getCollapseStates = routes => {
        let initialState = {};
        routes.map((prop, key) => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };

    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    logout() {
        _api.sessionClear();
        this.props.history.push('/auth/login');
    }

    createLinks = routes => {
        return routes.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.hideInMenu) {
                return null;
            }
            if (prop.alwaysAllowRoles && prop.alwaysAllowRoles.includes(this.props.user.user.role)) {
                // ... continue
            } else {
                if (prop.roles) {
                    if (prop.roles.includes(this.props.user.user.role) === false) {
                        return null;
                    }
                    if (this.props.user.user.emailAddress.includes('@zlecenia.expert') && (this.props.user.user.role !== 'admin')) {
                        if (prop.path === '/companies') {
                            return null;
                        }
                    }
                }
                if (prop.permissions) {
                    if (prop.permissions.includes("releaseElements") && !(this.props.user.user.releaseElements)) {
                        return null;
                    }
                }
                if (prop.users) {
                    if (prop.users.includes(this.props.user.user.emailAddress) === false) {
                        return null;
                    }
                }
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                return (
                    <li className={this.getCollapseInitialState(prop.views) ? "active" : ""} key={key}>
                        <button
                            className="link full-w"
                            onClick={e => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            <i className={prop.icon} />
                            <p>
                                {prop.name}
                                <b
                                    className={
                                        this.state[prop.state] ? "caret rotate-180" : "caret"
                                    }
                                />
                            </p>
                        </button>
                        <Collapse in={this.state[prop.state]}>
                            <ul className="nav">{this.createLinks(prop.views)}</ul>
                        </Collapse>
                    </li>
                );
            }
            return (
                <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
                    <NavLink
                        to={prop.layout + prop.path}
                        className="nav-link"
                        activeClassName="active"
                    >
                        {prop.icon ? (
                            <>
                                <i className={prop.icon} />
                                <p>{prop.name}</p>
                            </>
                        ) : (
                            <>
                                <span className="sidebar-mini">{prop.mini}</span>
                                <span className="sidebar-normal">{prop.name}</span>
                            </>
                        )}
                    </NavLink>
                </li>
            );
        });
    };

    activeRoute = routeName => {
        return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
    };

    updateDimensions() {
        this.setState({ width: window.innerWidth });
    }

    componentDidMount() {
        this.updateDimensions();
        // add event listener for windows resize
        window.addEventListener("resize", this.updateDimensions.bind(this));
        window.addEventListener("SIDEBAR_TOGGLE", this.onChangeSidebarSize, false);
        // if you are using a Windows Machine, the scrollbars will have a Mac look
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    componentWillUnmount() {
        // we need to destroy the false scrollbar when we navigate
        // to a page that doesn't have this component rendered
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
        window.removeEventListener("SIDEBAR_TOGGLE", this.onChangeSidebarSize, false);
    }

    render() {
        return (
            <div className="sidebar" data-color={this.props.color} data-image={this.props.image}>
                {this.props.hasImage ? (
                    <div
                        className="sidebar-background"
                        style={{ backgroundImage: "url(" + this.props.image + ")" }}
                    />
                ) : ("")}
                {
                    this.state.largeLogo ?
                        (
                            <div className="logo">
                                <a href="/#/profile/dashboard" className="simple-text logo-mini">
                                    <div className="logo-img">
                                        <img src={logo} alt="Expert - Zlecenia" style={{ width: 210, height: 75 }} />
                                    </div>
                                </a>
                            </div>
                        ) :
                        (
                            <div className="logo">
                                <a href="/#/profile/dashboard" className="simple-text logo-mini">
                                    <div className="logo-img">
                                        <img src={logoMini} alt="Expert - Zlecenia" style={{ maxWidth: "40px" }} />
                                    </div>
                                </a>
                                <a href="/#/profile/dashboard" className="simple-text logo-normal" style={{ paddingTop: "15px", left: "-2px" }}>
                                    Expert - Zlecenia
                                </a>
                            </div>
                        )
                }
                <div className="sidebar-wrapper" ref="sidebarWrapper">
                    <div className={!this.state.openAvatar ? "user mh-limit" : "user"}>
                        <div className="photo">
                            <img src={avatar} alt="Avatar" />
                        </div>
                        <div className="info">
                            <button className={"link no-relative"} onClick={e => { e.preventDefault(); this.setState({ openAvatar: !this.state.openAvatar }); }} style={{ paddingTop: 0 }}>
                                <span style={{ textOverflow: "ellipsis", display: "inline-block", maxWidth: 140, overflow: "hidden" }}>
                                    {
                                        this.props.userHasRole(['clientOverview', 'industryManager', 'industryRegionalManager'])
                                            ?
                                            "Konto Użytkownika"
                                            :
                                            this.props.user.user.company.name || this.props.user.user.fleet.name || "Konto Użytkownika"
                                    }
                                    <br />
                                    {this.props.user.user.firstname} {this.props.user.user.lastname}
                                </span>
                                <b className={this.state.openAvatar ? "caret rotate-180" : "caret"} style={{ display: "inline-block" }} />
                            </button>
                            <Collapse in={this.state.openAvatar}>
                                <ul className="nav">
                                    <li>
                                        <a href="/#/profile/me">
                                            <span className="sidebar-normal">Mój Profil</span>
                                        </a>
                                    </li>
                                    <li>
                                        <button className="link full-w"
                                            onClick={e => {
                                                e.preventDefault();
                                                this.logout();
                                            }}>
                                            <span className="sidebar-normal">Wyloguj się</span>
                                        </button>
                                    </li>
                                </ul>
                            </Collapse>
                        </div>
                    </div>
                    <ul className="nav">
                        {this.createLinks(routes)}
                    </ul>
                </div>
            </div>
        );
    }
}

export default Sidebar;
