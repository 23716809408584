/*global google*/
import React, { Component } from "react";
import _ from "lodash";
import {
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

class TrackerMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultCenter: props.defaultCenter,
            markerPosition: props.markerPosition
        };
        this.markerUrl = 'https://cdn.zlecenia.expert/images/truck.png';
        this.frames = [];
        this.markerAngle = -90;
        this.animationTimeout = undefined;
        this.isDuringAnimation = false;
    }

    moveMarker(index, wait) {
        if (!this.frames.length) {
            return;
        }
        if (index < this.frames.length - 1) {
            this.setState({ markerPosition: this.frames[index] });
            const marker = document.querySelector(`[src="${this.markerUrl}"]`)
            if (marker) {
                marker.style.transform = `rotate(${this.markerAngle}deg)`
            }
            this.isDuringAnimation = true;
            this.animationTimeout = setTimeout(() => {
                this.moveMarker(index + 1, wait);
            }, index, wait);
        } else {
            this.clearAnimationTimeout();
        }
    }

    clearAnimationTimeout() {
        if (this.animationTimeout) {
            clearTimeout(this.animationTimeout);
            this.isDuringAnimation = false;
        }
    }

    componentWillUnmount() {
        this.clearAnimationTimeout();
    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        let fromPoint = new google.maps.LatLng(self.state.markerPosition.lat, self.state.markerPosition.lng);
        let toPoint = new google.maps.LatLng(nextProps.markerPosition.lat, nextProps.markerPosition.lng);
        let distance = google.maps.geometry.spherical.computeDistanceBetween(fromPoint, toPoint); // meters

        if (_.isEqual(self.state.markerPosition, nextProps.markerPosition)) {
            return;
        }

        if (this.isDuringAnimation) {
            return;
        }

        if (distance < 20) {
            this.setState({ markerPosition: nextProps.markerPosition });
            return;
        }

        self.frames = [];
        self.markerAngle = google.maps.geometry.spherical.computeHeading(fromPoint, toPoint) - 90;

        for (let percent = 0; percent < 1; percent += 0.01) {
            let newPoint = google.maps.geometry.spherical.interpolate(fromPoint, toPoint, percent);
            self.frames.push({ lat: newPoint.lat(), lng: newPoint.lng() });
        }

        self.frames.push({ lat: toPoint.lat(), lng: toPoint.lng() });
        self.moveMarker(0, 20, self.state.markerPosition);
    }

    render() {

        const icon = {
            url: this.markerUrl,
            scaledSize: new google.maps.Size(40, 40),
            anchor: { x: 10, y: 10 }
        }

        return (
            <GoogleMap
                defaultCenter={this.state.defaultCenter}
                center={this.state.markerPosition}
                defaultZoom={16}
                defaultOptions={{
                    mapTypeControl: false
                }}
            >
                <Marker position={this.state.markerPosition} icon={icon} />
            </GoogleMap>
        );
    }
}

export default withGoogleMap(TrackerMap);