/* global _api */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import "moment/min/locales";
import {
    Grid,
    Row,
    Col,
} from "react-bootstrap";
import Datatable from "../../../components/Datatable";

moment.locale('pl');

class ExpertClientRelease extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isRefreshing: false,
            alert: null,
            data: [],
            pages: -1,
            loading: true,
            barcodeReading: false,
            currentInput: "",
            scannedElements: [],
            showModalFormat: false,
        };
    }

    componentWillUnmount() {
        _api.abort();
    }

    releaseConfirmation() {
        this.setState({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz wydać paczki z magazynu?"
                    confirmBtnText="Wydaj"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ alert: null }); this.handleScannedElements('release'); }}
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="success">
                    Łączna ilość paczek do wydania: {this.state.scannedElements.length}.
                </SweetAlert>
            )
        });
    }

    handleScannedElements(action) {
        let self = this;
        self.setState({ isRefreshing: true });
        self.props.showNoty('tr', 'info', `Zapisywanie danych...`);
        _api.request(`/element/${action}`, { elements: this.state.scannedElements },
            (data) => {
                self.props.showNoty('tr', 'success', `Dane zostały zapisane`);
                self.setState({ isRefreshing: false, scannedElements: [] });
            },
            (error) => {
                if (error.E_ELEMENT_NOT_FOUND) {
                    self.props.showNoty('tr', 'error', 'Paczka nie została znaleziona: ' + error.E_ELEMENT_NOT_FOUND);
                } else {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                }
                self.setState({ isRefreshing: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    setCheckData(uuid, checked) {
        const newData = this.state.data.map((order) => {
            if (order.uuid !== uuid) return order;
            let updatedOrder = { ...order };
            updatedOrder.checked = checked;
            return updatedOrder;
        });
        this.setState({
            ...this.reMapData(newData)
        });
    }

    reMapData(data) {
        let scannedElements = [];
        let newData = data.map((prop, key) => {
            prop.uuid = prop.uuid_0;
            prop.checked = prop.hasOwnProperty('checked') ? prop.checked : false;
            if (prop.checked) {
                scannedElements.push({ uuid: prop.uuid });
            }
            prop.checkbox = (
                <Checkbox
                    key={prop.uuid}
                    number={key}
                    checked={prop.checked}
                    onChange={event => {
                        this.setCheckData(prop.uuid, event.target.checked);
                    }}
                />
            );
            return prop;
        });
        return {
            scannedElements: scannedElements,
            data: newData
        }
    }

    fetchTable = (state, instance, callback) => {
        let self = this;
        self.setState({ loading: true });
        _api.request("/element/table", {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered
        }, (res) => {
            const preparedData = self.reMapData(res.rows);
            self.setState({
                ...preparedData,
                pages: res.pages,
                loading: false,
                page: res.page,
                pageSize: res.pageSize,
                sorted: res.sorted,
                filtered: res.filtered
            },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loading: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    render() {

        const loader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        return (
            <div className="main-content">
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Stan magazynowy
                                        </span>
                                    </div>
                                }
                                content={
                                    <div>
                                        {(!this.state.isRefreshing) ?
                                            (
                                                <Datatable
                                                    data={this.state.data}
                                                    pages={this.state.pages}
                                                    loading={this.state.loading}
                                                    manual
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: (
                                                                <Button
                                                                    onClick={() => { this.releaseConfirmation() }}
                                                                    bsStyle="default"
                                                                    round
                                                                    icon>
                                                                    <i className="fa fa-check" />
                                                                </Button>
                                                            ),
                                                            accessor: "checkbox",
                                                            sortable: false,
                                                            filterable: false,
                                                            width: 50,
                                                        },
                                                        {
                                                            Header: "Zamówienie",
                                                            accessor: "ident_1",
                                                            width: 100
                                                        },
                                                        {
                                                            Header: "Nr dok. wew.",
                                                            accessor: "identExternal_2"
                                                        },
                                                        {
                                                            Header: "Klient",
                                                            accessor: "name_3"
                                                        },
                                                        {
                                                            Header: "Element",
                                                            accessor: "name_4"
                                                        },
                                                        {
                                                            Header: "Kod",
                                                            accessor: "codeEan_5"
                                                        },
                                                        {
                                                            Header: "Magazyn",
                                                            accessor: "warehouse_7"
                                                        },
                                                        {
                                                            Header: "Dostawa",
                                                            accessor: "dayIdent_6",
                                                            width: 100
                                                        },
                                                        {
                                                            Header: "Wydanie",
                                                            accessor: "released_8",
                                                            width: 150
                                                        },
                                                        {
                                                            Header: "",
                                                            accessor: "actions",
                                                            sortable: false,
                                                            filterable: false,
                                                            width: 20
                                                        }
                                                    ]}
                                                    defaultSorted={[
                                                        {
                                                            id: "ident_1",
                                                            desc: true
                                                        }
                                                    ]}
                                                    onFetchData={this.fetchTable}
                                                    defaultPageSize={100}
                                                    showPaginationBottom
                                                    className="-striped -highlight"
                                                    previousText="Poprzednia"
                                                    nextText="Następna"
                                                    noDataText="Brak danych"
                                                    loadingText="Pobieranie danych"
                                                    pageText="Strona"
                                                    ofText=" z "
                                                    rowsText="wierszy"
                                                />
                                            )
                                            :
                                            loader
                                        }
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertClientRelease;
