import React, { Component } from "react";

export class UserCard extends Component {

    render() {
        return (
            <div className="card card-user">
                <div className="image">
                    <img src={this.props.bgImage} alt="..." />
                </div>
                <div className="content">
                    <div className="author">
                        <img
                            className="avatar border-gray"
                            src={this.props.avatar}
                            alt="..."
                        />
                    </div>
                    <div className="description">{this.props.description}</div>
                </div>
                <hr />
                <div className="text-center">{this.props.socials}</div>
            </div>
        );
    }

}

export default UserCard;
