/*global google*/
import React, { Component } from "react";
import {
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

class MarkerMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            defaultCenter: props.defaultCenter,
            markers: props.markers || []
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ markers: nextProps.markers || [] });
    }

    render() {
        return (
            <GoogleMap
                defaultCenter={this.state.defaultCenter}
                center={this.state.defaultCenter}
                defaultZoom={9}
                defaultOptions={{
                    mapTypeControl: false
                }}
            >
                {this.state.markers.length ?
                    this.state.markers.map((place, index) => {
                        const icon = {
                            path: "M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0",
                            fillColor: place.background,
                            fillOpacity: .6,
                            anchor: new google.maps.Point(0, 0),
                            strokeWeight: 0,
                            scale: 1
                        }
                        return (
                            <Marker
                                key={index}
                                icon={icon}
                                label={{ color: '#000', fontSize: '13px', text: place.streetAddress }}
                                position={{ lat: parseFloat(place.geoLat), lng: parseFloat(place.geoLng) }}
                                onClick={(e) => console.log(place.streetAddress)}
                            />
                        )
                    }) : (<></>)}
            </GoogleMap>
        );
    }
}

export default withGoogleMap(MarkerMap);