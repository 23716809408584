/* global _api */
import React, { Component } from "react";
import { renderToString } from 'react-dom/server'
import PropagateLoader from 'react-spinners/PropagateLoader';
import Switch from "react-bootstrap-switch";
import Card from "../Card/Card.jsx";
import Select from "react-select";
import moment from "moment";
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import 'moment-timezone';
import "moment/min/locales";
import SweetAlert from "react-bootstrap-sweetalert";
import ExpertUploadMultipleComponent from "../Expert/UploadMultipleComponent.jsx";
import recordAudio from "../../utils/RecordAudio.js";
import { FormGroup, ControlLabel, ButtonGroup, Grid, Row, Col, Button, Table, Tab, Nav, NavItem, Modal, Badge, FormControl } from "react-bootstrap";
import ExpertStatusBadge from "./StatusBadge.jsx";
import { $STATUS2_DOSTARCZONE, $STATUS2_NIEZREALIZOWANE, $STATUS2_WDOSTAWIE, $STATUS2_ZGLOSZONOREKLAMACJE, $STATUS2_ZWROT } from "../../constants.js";

moment.locale('pl');

class ExpertDriverDashboardComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recording: false,
            recorded: false,
            showAudio: false,
            audio: null,
            noDamages: true,
            howMuchFiles: 5,
            personDetails: "...",
            personGender: "m",
            numberOfPackages: "...",
            dashboardLoading: false,
            dateSelected: null,
            driverState: false,
            dayPartSelected: false,
            driverInOrder: false,
            geoIsWorking: false,
            orderLoading: false,
            orderSelected: false,
            orderDuringDelivery: false,
            orderUuid: null,
            orderIdent: null,
            orderDayPart: null,
            orderConfirmed: false,
            orderComment: null,
            orderAllowNoBringing: false,
            orderSenderName: null,
            orderSenderMobilePhone: null,
            orderSenderStreetAddress: null,
            orderRecipientName: null,
            orderRecipientMobilePhone: null,
            orderRecipientStreetAddress: null,
            orderAdditionalFiles: [],
            orderAdditionalNotes: "",
            orderAdditionalLoading: false,
            scheduleIdent: "",
            slots0915: [],
            slots1520: [],
            tabSelected: "dayPartI",
            backendSetOrderStatus: false,
            showOrderDeliveryModal: false,
            orderDeliveredStatus: null,
            orderDeliveredOptions: [
                {
                    "value": $STATUS2_DOSTARCZONE,
                    "label": $STATUS2_DOSTARCZONE,
                },
                {
                    "value": $STATUS2_NIEZREALIZOWANE,
                    "label": $STATUS2_NIEZREALIZOWANE
                },
                {
                    "value": $STATUS2_ZGLOSZONOREKLAMACJE,
                    "label": $STATUS2_ZGLOSZONOREKLAMACJE
                },
                {
                    "value": $STATUS2_ZWROT,
                    "label": $STATUS2_ZWROT
                },
            ]
        }
        this.pos = {
            lat: null,
            lng: null,
            ts: null
        };
        this.watchGeoOptions = {
            enableHighAccuracy: true,
            timeout: 5 * 1000,
            maximumAge: 5 * 60 * 1000,
            minimumAge: 1000
        };
        this.watch = undefined;
        this.tOutRefresh = undefined;
        this.audioRecorder = undefined;
    }

    componentDidMount() {
        this.initGeolocation();
    }

    componentWillUnmount() {
        if (this.watch) {
            navigator.geolocation.clearWatch(this.watch);
        }
        _api.abort();
        if (this.tOutRefresh) {
            clearTimeout(this.tOutRefresh);
        }
    }

    async startRecording() {
        this.audioRecorder = await recordAudio();
        this.audioRecorder.start();
        this.setState({
            recording: true,
            recorded: false,
            audio: null
        });
    }

    async stopRecording() {
        const audio = await this.audioRecorder.stop();
        this.setState({
            recording: false,
            recorded: true,
            audio: audio
        }, () => { this.playAudio() });
    }

    playAudio() {
        this.state.audio.play();
    }

    removeAudio() {
        this.setState({
            recording: false,
            recorded: false,
            audio: null
        });
    }

    getBadge(status) {
        return <ExpertStatusBadge status={status} />
    }

    setAndStoreState(data) {
        this.setState(data, () => { this.storeState() });
    }

    storeState() {
        let self = this;
        _api.request(`/fleet/state/store`,
            {
                frontend: {
                    dateSelected: self.state.dateSelected || null,
                    tabSelected: self.state.tabSelected || null,
                    dayPartSelected: self.state.dayPartSelected,
                    orderSelected: self.state.orderSelected,
                    orderDuringDelivery: self.state.orderDuringDelivery,
                    orderUuid: self.state.orderUuid
                },
                backend: {
                    orderSetStatus: self.state.backendSetOrderStatus,
                    orderUuid: self.state.orderUuid
                }
            },
            (data) => {
                self.setState({ backendSetOrderStatus: false });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    restoreState() {
        let self = this;
        self.setState({ dashboardLoading: true });
        _api.request(`/fleet/state/restore`,
            {
            },
            (data) => {
                data = data || {};
                if (data.dateSelected) {
                    if (moment(data.dateSelected).isBefore(moment(), 'day')) {
                        if (data.orderSelected) {
                            if (data.orderDuringDelivery) {
                                self.props.showNoty('tr', 'error', 'To zlecenie nie zostało zamknięte!!');
                            } else {
                                self.props.showNoty('tr', 'warning', 'Przeglądasz przeszłe zgłoszenie');
                            }
                        } else {
                            data = {
                                dateSelected: moment(),
                                tabSelected: "dayPartI",
                                dayPartSelected: null,
                                orderSelected: false,
                                orderUuid: null,
                                orderLoading: false
                            }
                        }
                    }
                }
                self.setState({
                    dateSelected: data.dateSelected || moment(),
                    tabSelected: data.tabSelected || "dayPartI",
                    dayPartSelected: data.dayPartSelected || null,
                    orderSelected: data.orderSelected || false,
                    orderUuid: data.orderUuid || null,
                    orderLoading: data.orderUuid ? true : false,
                    orderDuringDelivery: data.orderDuringDelivery || false
                }, () => {
                    self.loadDate(moment(data.dateSelected), (newDate) => {
                        if (!data.orderUuid) {
                            return;
                        }
                        self.loadOrder(data.orderUuid);
                    });
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych, odśwież stronę');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    updatePosition(position) {
        let self = this;
        let newPos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            acc: position.coords.accuracy,
            ts: position.timestamp
        };
        if (Math.abs(newPos.ts - self.pos.ts) < self.watchGeoOptions.minimumAge) {
            return;
        }
        if (!self.state.orderSelected || !self.state.orderDuringDelivery || !self.state.orderUuid) {
            return;
        }
        self.pos = newPos;
        _api.request(`/fleet/location/${self.state.orderUuid}`, self.pos);
    }

    initGeolocation() {
        let self = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                self.updatePosition(position);
                self.watch = navigator.geolocation.watchPosition(
                    (pos) => {
                        self.positionChangedOk(pos);
                    },
                    (err) => {
                        self.positionChangedError(err);
                    },
                    self.watchGeoOptions
                );
                self.handleLocationSuccess();
            }, function () {
                self.handleLocationError(true);
            },
                {
                    timeout: 10000
                }
            );
        } else {
            self.handleLocationError(false);
        }
    }

    positionChangedOk(newPos) {
        this.updatePosition(newPos);
    }

    positionChangedError(err) {
        console.warn('ERROR(' + err.code + '): ' + err.message);
    }

    handleLocationSuccess() {
        console.info("location ON");
        this.setState({ geoIsWorking: true }, () => { this.restoreState() });
    }

    handleLocationError(browserHasGeolocation) {
        console.error("location OFF");
        this.setState({ geoIsWorking: false });
    }

    refreshCurrentDate() {
        let self = this;
        let date = moment(self.state.dateSelected).format("YYYY-MM-DD");
        if (self.tOutRefresh) {
            clearTimeout(self.tOutRefresh);
        }
        _api.request(`/fleet/orders/${date}`, {},
            (data) => {
                if (date !== moment(self.state.dateSelected).format("YYYY-MM-DD")) {
                    return;
                }
                let slots = self.prepareSlots(data);
                self.setState(
                    {
                        slots0915: slots.slots0915,
                        slots1520: slots.slots1520
                    }
                );
            }
        );
        self.tOutRefresh = setTimeout(() => {
            self.refreshCurrentDate();
        }, 10 * 1000);
    }

    prepareSlots(data) {
        if (!data) {
            data = [];
        }

        let slots0915 = [];
        let slots1520 = [];

        for (let i = 0; i < data.length; i++) {
            switch (data[i].order.dayPartIdent) {
                case 1:
                    slots0915.push(data[i]);
                    break;

                case 2:
                    slots1520.push(data[i]);
                    break;

                default:
                    break;
            }
        }
        return {
            "slots0915": slots0915,
            "slots1520": slots1520
        }
    }

    loadDate(newDate, callback) {
        let self = this;
        let date = newDate.format("YYYY-MM-DD");
        if (self.state.dateSelected !== newDate) {
            self.setState({ dashboardLoading: true, dateSelected: newDate, slots0915: [], slots1520: [] });
        }
        _api.request(`/fleet/orders/${date}`, {},
            (data) => {
                let slots = self.prepareSlots(data);
                self.setState(
                    {
                        dashboardLoading: false,
                        dateSelected: newDate,
                        slots0915: slots.slots0915,
                        slots1520: slots.slots1520
                    },
                    () => {
                        self.storeState();
                        self.refreshCurrentDate();
                        if (typeof callback === "function") {
                            callback(newDate);
                        }
                    }
                );

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    loadOrder(orderUuid) {
        let self = this;

        if (!orderUuid) {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania identyfikatora zlecenia');
            return;
        }

        self.setState({ orderLoading: true, orderSelected: true });

        _api.request(`/fleet/order/${orderUuid}`, {},
            (data) => {
                self.setAndStoreState({
                    orderLoading: false,
                    orderSelected: true,
                    orderUuid: data.order.uuid,
                    orderIdent: data.order.ident,
                    orderConfirmed: data.order.confirmation.length ? true : false,
                    orderDayPart: data.order.dayPart,
                    orderService: data.service || [],
                    orderComment: data.order.comment,
                    orderAllowNoBringing: data.order.allowNoBringing,
                    orderSenderName: data.sender.name,
                    orderSenderMobilePhone: data.sender.mobilePhone,
                    orderSenderStreetAddress: data.sender.streetAddress,
                    orderRecipientName: data.recipient.name,
                    orderRecipientMobilePhone: data.recipient.mobilePhone,
                    orderRecipientStreetAddress: data.recipient.streetAddress + " " + data.recipient.streetNumber + ", " + data.recipient.postalCode + " " + data.recipient.cityName,
                    scheduleIdent: data.schedule.ident || ""
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                self.setState({ orderLoading: false, orderSelected: false, orderUuid: null }, () => { self.storeState(); });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    previousDate() {
        this.loadDate(this.state.dateSelected.subtract(1, 'days'));
    }

    previousDateDisabled() {
        this.props.showNoty('tr', 'error', 'Możliwy jest tylko podgląd przyszłych zleceń');
    }

    nextDate() {
        this.loadDate(this.state.dateSelected.add(1, 'days'));
    }

    todayDate() {
        this.loadDate(moment());
    }

    getDriverState() {
        if (!this.state.driverState) {
            return "nieaktywny";
        }
        if (this.state.driverInOrder) {
            return "w zleceniu";
        }
        if (this.state.dayPartSelected === 1) {
            return "I część dnia"
        }
        if (this.state.dayPartSelected === 2) {
            return "II część dnia"
        }
        return "?";
    }

    sendNotification(scope) {

        let orders = [];
        switch (scope) {
            case "dayPart1":
                orders = this.state.slots0915;
                break;

            case "dayPart2":
                orders = this.state.slots1520;
                break;

            default:
                orders = [];
                break;
        }

        if (!orders.length) {
            this.props.showNoty('tr', 'error', 'Brak zleceń, powiadomienia nie zostaną wysłane');
            return;
        }

        let self = this;
        _api.request(`/fleet/notify/${moment(self.state.dateSelected).format("YYYY-MM-DD")}/duringDelivery`,
            {
                orders: orders.map((prop) => { return { uuid: prop.order.uuid } }),
                dayPart: scope
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Powiadomienia zostały wysłane');
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                self.setState({ orderLoading: false, orderSelected: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleSwitch(elem, state) {

        let isChecked = state;
        let alertNoOrders = (
            <SweetAlert
                warning
                style={{ display: "block", marginTop: "-300px" }}
                title="Brak zleceń"
                confirmBtnText="Zamknij"
                onConfirm={() => { this.setAndStoreState({ askAbout: null, dayPartSelected: null }); }}
                confirmBtnBsStyle="warning">
                Brak zleceń w wybranym terminie!
            </SweetAlert>
        );

        let alert = null;

        switch (elem.props.name) {
            case "dayPart1":
                if (this.state.slots0915.length === 0) {
                    alert = alertNoOrders;
                } else {
                    if (isChecked) {
                        this.sendNotification(elem.props.name);
                    }
                }
                this.setAndStoreState({ askAbout: state ? alert : null, driverState: state, dayPartSelected: state ? 1 : 0 });
                break;

            case "dayPart2":
                if (this.state.slots1520.length === 0) {
                    alert = alertNoOrders;
                } else {
                    if (isChecked) {
                        this.sendNotification(elem.props.name);
                    }
                }
                this.setAndStoreState({ askAbout: state ? alert : null, driverState: state, dayPartSelected: state ? 2 : 0 });
                break;

            default:
                this.props.showNoty('tr', 'error', 'Błędne przypisanie sekcji: ' + elem.props.name);
                break;
        }
    }

    handleOrderSelect(dayPart, data) {
        this.loadOrder(data.order.uuid);
    }

    handleOrderAction(action) {

        let alertStartDelivery = (
            <SweetAlert
                info
                showCancel
                style={{ display: "block", marginTop: "-300px" }}
                title={"Rozpocząć zlecenie " + this.state.orderIdent + "?"}
                confirmBtnText="Rozpocznij"
                cancelBtnText="Powrót"
                onConfirm={() => { this.setState({ askAbout: null }); this.setAndStoreState({ orderDuringDelivery: true, backendSetOrderStatus: $STATUS2_WDOSTAWIE }); }}
                onCancel={() => { this.setState({ askAbout: null }); }}
                confirmBtnBsStyle="info">
                Rozpoczniesz realizację zlecenia {this.state.orderIdent}. Czy chcesz kontynuować?
            </SweetAlert>
        );

        switch (action) {
            case "delivery":
                this.setState({ askAbout: alertStartDelivery });
                break;

            case "delivered":
                this.showOrderDeliveryModal();
                break;

            default:
                this.props.showNoty('tr', 'error', 'Niepoprawna akcja na zleceniu');
                break;
        }
    }

    handleBackToList() {
        if (this.state.orderDuringDelivery) {
            this.props.showNoty('tr', 'error', 'Musisz najpierw zakończyć zlecenie');
            return;
        }
        this.setState({ orderSelected: false, orderUuid: null }, () => { this.loadDate(this.state.dateSelected); });
    }

    handleTabSelect(key) {
        this.setAndStoreState({ tabSelected: key });
    }

    showOrderDeliveryModal() {
        this.setState({
            showOrderDeliveryModal: true,
            orderDeliveredStatus: null,
            orderAdditionalNotes: "",
            orderAdditionalFiles: [],
            recording: false,
            recorded: false,
            showAudio: false,
            audio: null,
            noDamages: true,
            personDetails: "...",
            personGender: "m",
            numberOfPackages: "...",
        });
    }

    hideOrderDeliveryModal() {
        this.setState({ showOrderDeliveryModal: false });
    }

    saveOrderNotes() {
        if (!this.state.orderSelected || !this.state.orderUuid) {
            this.props.showNoty('tr', 'error', 'Brak wybranego zlecenia');
            return;
        }

        if (!this.state.orderDeliveredStatus || !this.state.orderDeliveredStatus.value) {
            this.props.showNoty('tr', 'error', 'Wybierz status zlecenia');
            return;
        }

        let self = this;
        self.setState({ orderAdditionalLoading: true });
        self.props.showNoty('tr', 'success', 'Zapisuję dane..');

        let numberOfPackages = null;

        let preparedFiles = self.state.orderAdditionalFiles;

        if (self.state.recorded) {
            var fileOfBlob = new File([self.state.audio.audioBlob], "VoiceSign.webm");
            preparedFiles.push(fileOfBlob);

            numberOfPackages = parseInt(self.state.numberOfPackages, 10);
            if (Number.isNaN(numberOfPackages)) {
                numberOfPackages = null;
            }
        }

        let voiceConfirmationComment = renderToString(this.getVoiceSignCopy()).replace(/<\/?[^>]+(>|$)/g, "");

        _api.request(`/fleet/status/${self.state.orderUuid}`,
            {
                notes: self.state.orderAdditionalNotes,
                files: preparedFiles,
                status: self.state.orderDeliveredStatus.value,
                voiceConfirmationSave: self.state.showAudio,
                voiceConfirmationComment: voiceConfirmationComment,
                voiceConfirmationNumberOfPackages: numberOfPackages
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Zlecenie zostało zaktualizowane');
                self.setAndStoreState({ orderDuringDelivery: false, showOrderDeliveryModal: false, orderAdditionalLoading: false });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania');
                self.setState({ orderAdditionalLoading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );

    }

    getVoiceSignCopy() {
        let today = moment().format("DD MMMM YYYY");
        return (
            <>
                <p>Ja, {this.state.personDetails}, potwierdzam odbiór {this.state.numberOfPackages} paczek dostarczonych przez firmę Expert w&nbsp;dniu {today} roku. {this.state.noDamages ? "Towar nie posiada uszkodzeń." : "Towar posiada następujące uszkodzenia: (proszę opisać uszkodzenia)."}</p>
                <p>Informuję, że {this.state.personGender === "m" ? "zostałem zaznajomiony" : "zostałam zaznajomiona"} z&nbsp;zasadami przetwarzania moich danych osobowych.</p>
            </>
        )
    }

    render() {

        let voiceSignCopy = this.getVoiceSignCopy();
        let dateIsToday = moment(this.state.dateSelected).isSame(moment(), 'day');
        // let canGoBack = moment(this.state.dateSelected).isAfter(moment(), 'day');

        let filesList = [];
        for (var i = 0; i < this.state.orderAdditionalFiles.length; i++) {
            filesList.push(<p key={"file" + i}><i className="fa fa-file" /> {this.state.orderAdditionalFiles[i].name}</p>);
        }

        const audioControls = (
            <>
                {this.state.recorded ? (
                    <>
                        <p>Nagranie zostało zapisane.</p>
                        <Button bsStyle="info" className="btn-fill btn-lg btn-block" onClick={() => this.playAudio()}>
                            <span className="btn-label">
                                Odtwórz nagranie
                            </span>
                        </Button>
                        <Button bsStyle="danger" className="btn-fill btn-lg btn-block" onClick={() => this.removeAudio()}>
                            <span className="btn-label">
                                Usuń nagranie
                            </span>
                        </Button>
                    </>
                ) : (
                    <></>
                )
                }
                {!this.state.recorded ? (
                    <>
                        <FormGroup>
                            <ControlLabel>Osoba odbierająca:</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.personDetails}
                                onChange={
                                    event => {
                                        let value = event.target.value;
                                        let lastLetter = "";
                                        if (value) {
                                            let valueArr = value.split(" ");
                                            let firstName = valueArr[0];
                                            lastLetter = firstName[firstName.length - 1];
                                        }
                                        this.setState({
                                            personDetails: value,
                                            personGender: lastLetter.toLowerCase() === "a" ? "f" : "m"
                                        });
                                    }
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Liczba paczek:</ControlLabel>
                            <FormControl
                                type="text"
                                value={this.state.numberOfPackages}
                                onChange={
                                    event => {
                                        this.setState({ numberOfPackages: event.target.value })
                                    }
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <Checkbox
                                number="noDamages"
                                label="Towar nie posiada uszkodzeń"
                                checked={this.state.noDamages}
                                disabled={this.state.isPreviewOnly}
                                onChange={(event) =>
                                    this.setState({ noDamages: event.target.checked })
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Treść klauzuli do odczytania:</ControlLabel>
                            {voiceSignCopy}
                        </FormGroup>
                    </>
                ) : (
                    <></>
                )
                }
                {!this.state.recorded ?
                    !this.state.recording ? (
                        <Button bsStyle="success" className="btn-fill btn-lg btn-block" onClick={() => this.startRecording()}>
                            <span className="btn-label">
                                Rozpocznij nagranie
                            </span>
                        </Button>
                    ) : (
                        <Button bsStyle="warning" className="btn-fill btn-lg btn-block" onClick={() => this.stopRecording()}>
                            <span className="btn-label">
                                Zakończ nagranie
                            </span>
                        </Button>
                    )
                    : <></>
                }
                <p style={{ textAlign: "center", fontSize: "11px", marginTop: "10px" }}>&copy; Voice Sign Technology<br />by Remowings</p>
            </>
        )


        const orderDeliveryModal = (
            <Modal show={this.state.showOrderDeliveryModal} onHide={this.hideOrderDeliveryModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Zakończ zlecenie</Modal.Header>
                <Modal.Body>
                    <form>
                        <FormGroup>
                            <ControlLabel>Status:</ControlLabel>
                            <Col>
                                <Select
                                    className="react-select primary"
                                    classNamePrefix="react-select"
                                    name="fleet"
                                    value={this.state.orderDeliveredStatus}
                                    placeholder="Wybierz.."
                                    onChange={value =>
                                        this.setState({ orderDeliveredStatus: value })
                                    }
                                    options={this.state.orderDeliveredOptions}
                                    isClearable={false}
                                />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Notatka:</ControlLabel>
                            <textarea
                                className="form-control"
                                value={this.state.orderAdditionalNotes}
                                rows={5}
                                onChange={event =>
                                    this.setState({ orderAdditionalNotes: event.target.value })
                                }
                            />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel style={{ width: "100%" }}>Voice Sign:<span className="pull-right"><Switch name="showAudio" onText="" offText="" value={this.state.showAudio} defaultValue={false} onChange={(el, state) => {
                                this.setState({ showAudio: state })
                            }} /></span></ControlLabel>
                            {this.state.showAudio ? audioControls : <></>}
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Załączone pliki: {filesList.length}</ControlLabel>
                            <ExpertUploadMultipleComponent howMuch={this.state.howMuchFiles} onSelectedFiles={files => { this.setState({ orderAdditionalFiles: files }) }} />
                            <Button bsStyle="default" disabled={this.state.orderAdditionalLoading} className="btn-block" onClick={() => { this.setState({ howMuchFiles: this.state.howMuchFiles + 1 }) }}>
                                <span className="btn-label">
                                    Dodaj kolejny plik
                                </span>
                            </Button>
                        </FormGroup>
                        <FormGroup>
                            <Button bsStyle="default" disabled={this.state.orderAdditionalLoading} className="btn-fill btn-lg btn-block" onClick={() => this.saveOrderNotes()}>
                                <span className="btn-label">
                                    {this.state.orderAdditionalFiles.length ? "Zapisz uwagi i pliki" : "Zapisz uwagi"}
                                </span>
                            </Button>
                        </FormGroup>
                    </form>
                </Modal.Body>
            </Modal >
        )

        const genericLoading = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        let dashboardLoading = (
            <div>
                <Card
                    textCenter
                    title={"Pobieranie danych"}
                    content={
                        <div>
                            {genericLoading}
                        </div>
                    }
                />
            </div>
        );

        const dashboardLoaded = (
            <div>

                <Card
                    textCenter
                    title={<span>{moment(this.state.dateSelected).format('dddd, DD MMMM')}</span>}
                    content={
                        <div className="text-center">
                            <ButtonGroup>

                                <Button bsStyle="default" onClick={() => this.previousDate()}>
                                    <span className="btn-label">
                                        <i className="fa fa-arrow-left" />
                                    </span>
                                </Button>

                                {
                                    dateIsToday ?
                                        (
                                            <Button bsStyle="default" className="btn-fill" onClick={() => this.todayDate()}>Dzisiaj</Button>
                                        ) : (
                                            <Button bsStyle="default" onClick={() => this.todayDate()}>Dzisiaj</Button>
                                        )
                                }
                                <Button bsStyle="default" onClick={() => this.nextDate()}>
                                    <span className="btn-label">
                                        <i className="fa fa-arrow-right" />
                                    </span>
                                </Button>
                            </ButtonGroup>
                        </div>
                    }
                />

                <Card
                    content={
                        <div>
                            <Tab.Container id="tabs-with-dropdown" onSelect={(key) => { this.handleTabSelect(key) }} activeKey={this.state.tabSelected}>
                                <Row className="clearfix">
                                    <Col sm={12}>
                                        <Nav bsStyle="tabs">
                                            <NavItem eventKey="dayPartI" style={{ width: "50%", textAlign: "center" }}>I część dnia</NavItem>
                                            <NavItem eventKey="dayPartII" style={{ width: "50%", textAlign: "center" }}>II część dnia</NavItem>
                                        </Nav>
                                    </Col>
                                    <Col sm={12}>
                                        <Tab.Content animation={false}>
                                            <Tab.Pane eventKey="dayPartI">
                                                {
                                                    dateIsToday
                                                        ? (
                                                            <h5>Aktywuj pierwszą część dnia<span className="pull-right"><Switch name="dayPart1" onText="" offText="" value={this.state.dayPartSelected === 1} defaultValue={false} onChange={(el, state) => this.handleSwitch(el, state)} /></span></h5>
                                                        ) : (
                                                            <h5>Podgląd zleceń</h5>
                                                        )
                                                }
                                                <Table striped>
                                                    <tbody>
                                                        {(this.state.slots0915.length) ? (
                                                            this.state.slots0915.map((prop, key) => {
                                                                let badge = prop.order.status && prop.order.status.length ? this.getBadge(prop.order.status[0].name) : <></>;
                                                                let badge2 = prop.order.confirmation && prop.order.confirmation.length ? this.getBadge("odbiór został potwierdzony") : <></>;
                                                                return (
                                                                    <tr key={prop.order.uuid}>
                                                                        <td>
                                                                            <h5>{prop.order.ident} {badge}{badge2}</h5>
                                                                            <p><i className="fa fa-clock-o" /> {prop.order.dayPart}</p>
                                                                            <p><i className="fa fa-dot-circle-o" /> {prop.sender.streetAddress}</p>
                                                                            <p><i className="fa fa-check-circle-o" /> {prop.recipient.streetAddress}</p>
                                                                        </td>
                                                                        <td className="text-right" style={{ paddingRight: "5px" }}>
                                                                            <Button bsSize="lg" bsStyle="default" className={this.state.dayPartSelected === 1 ? "btn-fill btn-round" : "btn-round"} onClick={() => { this.handleOrderSelect(1, prop); }}>
                                                                                <span className="btn-label">
                                                                                    <i className="fa fa-arrow-right" />
                                                                                </span>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr><td className="text-center text-muted" colSpan={2}>- brak danych -</td></tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="dayPartII">
                                                {
                                                    dateIsToday
                                                        ? (
                                                            <h5>Aktywuj drugą część dnia<span className="pull-right"><Switch name="dayPart2" onText="" offText="" value={this.state.dayPartSelected === 2} defaultValue={false} onChange={(el, state) => this.handleSwitch(el, state)} /></span></h5>
                                                        ) : (
                                                            <h5>Podgląd zleceń</h5>
                                                        )
                                                }
                                                <Table striped>
                                                    <tbody>
                                                        {(this.state.slots1520.length) ? (
                                                            this.state.slots1520.map((prop, key) => {
                                                                let badge = prop.order.status && prop.order.status.length ? this.getBadge(prop.order.status[0].name) : <></>;
                                                                let badge2 = prop.order.confirmation && prop.order.confirmation.length ? this.getBadge("confirmed") : <></>;
                                                                return (
                                                                    <tr key={prop.order.uuid}>
                                                                        <td>
                                                                            <h5>{prop.order.ident} {badge}{badge2}</h5>
                                                                            <p><i className="fa fa-clock-o" /> {prop.order.dayPart}</p>
                                                                            <p><i className="fa fa-dot-circle-o" /> {prop.sender.streetAddress}</p>
                                                                            <p><i className="fa fa-check-circle-o" /> {prop.recipient.streetAddress}</p>
                                                                        </td>
                                                                        <td className="text-right" style={{ paddingRight: "5px" }}>
                                                                            <Button bsSize="lg" bsStyle="default" className={this.state.dayPartSelected === 2 ? "btn-fill btn-round" : "btn-round"} onClick={() => { this.handleOrderSelect(2, prop); }}>
                                                                                <span className="btn-label">
                                                                                    <i className="fa fa-arrow-right" />
                                                                                </span>
                                                                            </Button>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        ) : (
                                                            <tr><td className="text-center text-muted" colSpan={2}>- brak danych -</td></tr>
                                                        )}
                                                    </tbody>
                                                </Table>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Col>
                                </Row>
                            </Tab.Container>
                        </div>
                    }
                />
            </div>
        );

        let dashboardView = (
            <div>
                {this.state.dashboardLoading ? dashboardLoading : dashboardLoaded}
            </div>
        );

        let orderLoading = (
            <div>
                <Card
                    textCenter
                    title={"Pobieranie zlecenia"}
                    content={
                        <div>
                            {genericLoading}
                        </div>
                    }
                />
            </div>
        );

        let orderDetails = (
            <div>
                {
                    this.state.dayPartSelected ? (
                        <Card
                            textCenter
                            content={
                                <div className="text-center">
                                    {
                                        !this.state.orderDuringDelivery ?
                                            (
                                                <Button bsSize="lg" bsStyle="success" className="btn-fill btn-block" onClick={() => { this.handleOrderAction("delivery"); }}>
                                                    <span className="btn-label">Rozpocznij zlecenie</span>
                                                </Button>
                                            ) : (
                                                <Button bsSize="lg" bsStyle="warning" className="btn-fill btn-block" onClick={() => { this.handleOrderAction("delivered"); }}>
                                                    <span className="btn-label">Zakończ zlecenie</span>
                                                </Button>
                                            )
                                    }
                                </div>
                            }
                        />
                    ) : (
                        <Card
                            textCenter
                            content={
                                <div className="text-center">
                                    <p className="lead">Wyświetlasz podgląd zlecenia.<br />Aby rozpocząć, musisz aktywować tę część dnia.</p>
                                </div>
                            }
                        />
                    )
                }

                <Card
                    textCenter
                    content={
                        <div className="text-center">
                            <Button bsStyle="default" className={this.state.dayPartSelected ? "btn-fill btn-block" : "btn-block"} onClick={() => { this.handleBackToList(); }}>
                                <span className="btn-label">
                                    Powrót do listy
                                </span>
                            </Button>
                        </div>
                    }
                />

                <Card
                    content={
                        <div>
                            <fieldset>
                                <legend>Zlecenie {this.state.orderIdent}{this.state.orderConfirmed ? (<Badge bsStyle="primary" style={{ marginBottom: "5px" }}>Odbiór został potwierdzony</Badge>) : <></>}</legend>
                                <FormGroup>
                                    <ControlLabel>Harmonogram: </ControlLabel>
                                    <p className="lead mb-10">{this.state.scheduleIdent}</p>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Oczekiwana godzina dostawy: </ControlLabel>
                                    <p className="lead mb-10">{this.state.orderDayPart}</p>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Uwagi: </ControlLabel>
                                    <p className="lead mb-10">{this.state.orderComment || "BRAK"}</p>
                                </FormGroup>
                                <FormGroup>
                                    <ControlLabel>Usługi dodatkowe: </ControlLabel>
                                    <ul style={{ marginBottom: "20px" }}>
                                        {
                                            this.state.allowNoBringing && (
                                                <li className="lead mb-10">[!] Zlecenie bez usługi wniesienia.</li>
                                            )
                                        }
                                        {
                                            this.state.orderService && this.state.orderService.length ?
                                                this.state.orderService.map((prop, key) => {
                                                    return (
                                                        <li key={prop.uuid} className="lead mb-10">
                                                            {prop.name}
                                                        </li>
                                                    );
                                                }) : <li className="lead mb-10">BRAK</li>
                                        }
                                    </ul>
                                </FormGroup>
                            </fieldset>

                            <fieldset>
                                <legend>Nadawca</legend>
                                <FormGroup>
                                    <ControlLabel>Nadawca: </ControlLabel>
                                    <p className="lead mb-10">{this.state.orderSenderName} <a href={"tel:" + encodeURI(this.state.orderSenderMobilePhone)}><i className="fa fa-mobile-phone" />{this.state.orderSenderMobilePhone}</a></p>
                                    <ControlLabel>Adres nadawcy: </ControlLabel>
                                    <p className="lead mb-10"><a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=" + encodeURI(this.state.orderSenderStreetAddress)}><i className="fa fa-map-marker" /> {this.state.orderSenderStreetAddress}</a></p>
                                </FormGroup>
                            </fieldset>

                            <fieldset>
                                <legend>Odbiorca</legend>
                                <FormGroup>
                                    <ControlLabel>Odbiorca: </ControlLabel>
                                    <p className="lead mb-10">{this.state.orderRecipientName} <a href={"tel:" + encodeURI(this.state.orderRecipientMobilePhone)}><i className="fa fa-mobile-phone" />{this.state.orderRecipientMobilePhone}</a></p>
                                    <ControlLabel>Adres odbiorcy: </ControlLabel>
                                    <p className="lead mb-10"><a target="_blank" rel="noopener noreferrer" href={"https://www.google.com/maps/dir/?api=1&travelmode=driving&destination=" + encodeURI(this.state.orderRecipientStreetAddress)}><i className="fa fa-map-marker" /> {this.state.orderRecipientStreetAddress}</a></p>
                                </FormGroup>
                            </fieldset>
                        </div>
                    }
                />
            </div>
        );

        let orderView = (this.state.orderLoading ? orderLoading : orderDetails);

        let geoAskView = (
            <div>
                <Card
                    textCenter
                    title="Zgoda na lokalizację"
                    content={
                        <div className="text-center">
                            <p className="lead">Aby korzystać z aplikacji, włącz udostępnianie swojej lokalizacji.</p>
                            <Button bsStyle="default" onClick={() => this.initGeolocation()}>Włącz lokalizację</Button>
                        </div>
                    }
                />
            </div>
        );

        return (
            <div className="main-content">
                {orderDeliveryModal}
                {this.state.askAbout}
                <Grid fluid>
                    <Row>
                        <Col md={8} mdOffset={2}>
                            {this.state.geoIsWorking ? this.state.orderSelected ? orderView : dashboardView : geoAskView}
                        </Col>
                    </Row>
                </Grid>
            </div>
        )

    }

}

export default ExpertDriverDashboardComponent;
