/* global _api */
import React, { Component } from "react";
import Card from "../Card/Card.jsx";
import Button from "../CustomButton/CustomButton.jsx";
import ExpertProfileComponent from "../Expert/ProfileComponent.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Modal,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import Datatable from "../Datatable";

class ExpertUsersListComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            header: this.props.header || "Lista użytkowników",
            headless: this.props.headless || false,
            dataTable1: [],
            pagesTable1: -1,
            pageTable1: 0,
            defaultPageSizeTable1: this.props.defaultPageSize || 5,
            loadingTable1: true,
            showUserModal: false,
            userUuid: null,
            isAdminView: this.props.isAdminView || true,
            departmentUuid: this.props.department || null,
            companyUuid: this.props.company || null,
            fleetUuid: this.props.fleet || null,
            endpoint: this.props.endpoint || null,
            preloadUser: this.props.preloadUser || null,
            showNoty: this.props.showNoty || null,
            setProgress: this.props.setProgress || null,
            endpointList: this.props.endpointList,
            endpointProfileCreate: this.props.endpointProfileCreate,
            endpointProfileUpdate: this.props.endpointProfileUpdate.endsWith("/") ? this.props.endpointProfileUpdate : this.props.endpointProfileUpdate + "/"
        };
    }

    showUserModal(userUuid) {
        this.setState({ showUserModal: true, userUuid: userUuid });
    }

    hideUserModal() {
        this.setState({ showUserModal: false, userUuid: null });
    }

    popupUserSaved() {
        this.props.showNoty('tr', 'success', 'Użytkownik został zapisany');
        this.reloadUsersList();
    }

    popupUserError() {
        this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisu danych');
    }

    moveToTrash(uuid) {
        let self = this;
        _api.request(`/admin/trash/recursive/user/${uuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'info', 'Użytkownik został usunięty');
                self.reloadUsersList();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas usuwania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    popupTrash(uuid) {
        this.setState({
            alert: (
                <SweetAlert
                    danger
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź usunięcie"
                    confirmBtnText="Usuń użytkownika"
                    onConfirm={() => { this.setState({ alert: null }); this.moveToTrash(uuid); }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="danger">
                </SweetAlert>
            )
        });
    }

    reloadUsersList() {
        let self = this;
        this.fetchUsersList({
            page: self.state.pageTable1,
            pageSize: self.state.pageSizeTable1,
            sorted: self.state.sortedTable1,
            filtered: self.state.filteredTable1
        });
    }

    fetchUsersList = (state, instance) => {

        const oEdit = <Tooltip id="oEdit">Edycja danych</Tooltip>;
        const oTrash = <Tooltip id="oTrash">Usuń</Tooltip>;
        
        let self = this;

        self.setState({ loadingTable1: true });
        _api.request(this.props.endpointList, {
            page: state.page,
            pageSize: self.state.defaultPageSizeTable1,
            sorted: state.sorted,
            filtered: state.filtered
        }, (res) => {
            self.setState({
                dataTable1: res.rows.map((prop, key) => {
                    prop.actions = (
                        <div className="actions-right">
                            <OverlayTrigger placement="top" overlay={oEdit}>
                                <Button
                                    onClick={() => { this.showUserModal(prop.uuid) }}
                                    bsStyle="default"
                                    simple
                                    icon>
                                    <i className="fa fa-edit" />
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="top" overlay={oTrash}>
                                <Button
                                    onClick={() => { this.popupTrash(prop.uuid) }}
                                    bsStyle="danger"
                                    simple
                                    icon>
                                    <i className="fa fa-times" />
                                </Button>
                            </OverlayTrigger>
                        </div>
                    )
                    return prop;
                }),
                pageTable1: res.page,
                pagesTable1: res.pages,
                loadingTable1: false,
                pageSizeTable1: res.pageSize,
                sortedTable1: res.sorted,
                filteredTable1: res.filtered
            });
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loadingTable1: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    render() {

        const userModal = (
            <Modal dialogClassName={this.props.dialogClassName || ""} show={this.state.showUserModal} onHide={this.hideUserModal.bind(this)} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja użytkownika</Modal.Header>
                <Modal.Body>
                    <ExpertProfileComponent
                        isAdminView={true}
                        department={this.state.departmentUuid}
                        company={this.state.companyUuid}
                        fleet={this.state.fleetUuid}
                        endpoint={this.state.userUuid ? this.state.endpointProfileUpdate + this.state.userUuid : this.state.endpointProfileCreate}
                        preloadUser={this.state.userUuid}
                        showNoty={this.props.showNoty}
                        setProgress={this.props.setProgress}
                        onSaved={this.popupUserSaved.bind(this)}
                        onError={this.popupUserError.bind(this)}
                    />
                </Modal.Body>
            </Modal>
        )

        const content = (
            <Datatable
                data={this.state.dataTable1}
                pages={this.state.pagesTable1}
                loading={this.state.loadingTable1}
                manual
                filterable
                columns={[
                    {
                        Header: "Imię",
                        accessor: "firstname"
                    },
                    {
                        Header: "Nazwisko",
                        accessor: "lastname"
                    },
                    {
                        Header: "Opcje",
                        accessor: "actions",
                        sortable: false,
                        filterable: false,
                        width: 100
                    }
                ]}
                defaultSorted={[
                    {
                        id: "lastname",
                        desc: false
                    }
                ]}
                onFetchData={this.fetchUsersList}
                defaultPageSize={this.state.defaultPageSizeTable1}
                showPaginationBottom
                className="-striped -highlight"
                previousText="Poprzednia"
                nextText="Następna"
                noDataText="Brak danych"
                loadingText="Pobieranie danych"
                pageText=""
                ofText=" z "
                rowsText="wierszy"
            />
        )

        const usersListCard = (
            <Card
                title={<div>
                    {this.state.header}
                    <Button bsStyle="info" round onClick={() => { this.showUserModal(null) }}>
                        <span className="btn-label" style={{ paddingRight: "5px" }}>
                            <i className="fa fa-plus" />
                        </span>
                        dodaj użytkownika
                    </Button>
                </div>}
                content={content}
            />
        )

        const usersListHeadless = (
            <div>
                <Button bsStyle="info" round onClick={() => { this.showUserModal(null) }}>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    dodaj użytkownika
                </Button>
                {content}
            </div>
        )

        const usersList = this.state.headless ? usersListHeadless : usersListCard;

        return (
            <div>
                {this.state.alert}
                {userModal}
                {usersList}
            </div>
        )

    }

}


export default ExpertUsersListComponent;