/* global _api */
import React, { Component } from "react";
import Card from "../../../../components/Card/Card.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import PropagateLoader from 'react-spinners/PropagateLoader';
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import Select from "react-select";
import "moment/min/locales";
import {
    Grid,
    Row,
    Col,
    FormControl,
    Table,
    Glyphicon,
    FormGroup,
    Modal,
    ControlLabel
} from "react-bootstrap";
import Datatable from "../../../../components/Datatable";

moment.locale('pl');

class ExpertAdminKloseWarehouse extends Component {

    constructor(props) {
        super(props);

        this.dataFormatExpert = [{ "value": "expert", "label": "EXPERT" }];
        this.dataFormats = [this.dataFormatExpert[0]];

        this.state = {
            isRefreshing: false,
            alert: null,
            data: [],
            pages: -1,
            loading: true,
            barcodeReading: false,
            currentInput: "",
            scannedElements: [],
            showModalFormat: false,
            dataFormat: this.dataFormats[0],
            dataFormats: this.dataFormats,
            filter: [],
        };
        this.handleScannerInput = this.handleScannerInput.bind(this);
        this.bufferScannerInput = [];
        this.bufferLastKeyTime = 0;
        this.bufferCheckInterval = undefined;
        this.audioCtx = new (window.AudioContext || window.webkitAudioContext)();

        this.onChangeFormatCallback = () => { console.log("ok") };
    }

    beep() {
        var oscillator = this.audioCtx.createOscillator();
        var gainNode = this.audioCtx.createGain();

        oscillator.connect(gainNode);
        gainNode.connect(this.audioCtx.destination);

        gainNode.gain.value = 1.0;
        oscillator.frequency.value = 675;
        oscillator.type = 'square';

        oscillator.start();

        setTimeout(
            function () {
                oscillator.stop();
            },
            200
        );
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleScannerInput, false);
        this.bufferCheckInterval = setInterval((self) => {
            const currentTime = Date.now();
            if (currentTime - self.bufferLastKeyTime > 1000) {
                if (self.bufferScannerInput.length) {
                    self.bufferScannerInput = [];
                    self.setState({ barcodeReading: false });
                }
            }
        }, 1000, this);
    }

    componentWillUnmount() {
        clearInterval(this.bufferCheckInterval);
        document.removeEventListener("keydown", this.handleScannerInput, false);
        _api.abort();
    }

    handleScannerInput(event) {
        if (event.target.tagName.toUpperCase() === "INPUT") {
            return;
        }

        if (event.key === 'Shift') {
            return;
        }

        const key = event.key;
        const currentTime = Date.now();

        if (currentTime - this.bufferLastKeyTime > 1000) {
            this.bufferScannerInput = [];
        }

        this.bufferLastKeyTime = currentTime;

        if (event.key === 'Enter') {
            const str = this.bufferScannerInput.join('');
            this.bufferScannerInput = [];
            this.setState({ barcodeReading: false });
            this.addCodeToList(str);
        } else {
            if (!this.bufferScannerInput.length) {
                this.setState({ barcodeReading: true });
            }
            this.bufferScannerInput.push(key);
        }
    }

    onCodeInput(event) {
        this.setState({ currentInput: event.target.value });
    }

    addCodeToList(code) {
        this.beep();
        let newElements = this.state.scannedElements;
        if (!newElements.includes(code)) {
            newElements.push(code);
        }
        this.setState({ currentInput: "", scannedElements: newElements });
    }

    removeCodeFromList(code) {
        const filteredElements = this.state.scannedElements.filter(value => value !== code);
        this.setState({ scannedElements: filteredElements });
    }

    collectConfirmation() {
        this.setState({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz przyjąć paczki na magazyn?"
                    confirmBtnText="Przyjmij"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ alert: null }); this.handleScannedElements('collect'); }}
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="info">
                    Łączna ilość paczek do przyjęcia: {this.state.scannedElements.length}.
                </SweetAlert>
            )
        });
    }

    releaseConfirmation() {
        this.setState({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz wydać paczki z magazynu?"
                    confirmBtnText="Wydaj"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ alert: null }); this.handleScannedElements('release'); }}
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="success">
                    Łączna ilość paczek do wydania: {this.state.scannedElements.length}.
                </SweetAlert>
            )
        });
    }

    handleScannedElements(action) {
        let self = this;
        self.setState({ isRefreshing: true });
        self.props.showNoty('tr', 'info', `Zapisywanie danych...`);
        _api.request(`/admin/klose/elements/${action}`, { elements: this.state.scannedElements },
            (data) => {
                self.props.showNoty('tr', 'success', `Dane zostały zapisane`);
                self.setState({ isRefreshing: false, scannedElements: [] });
            },
            (error) => {
                if (error.E_ELEMENT_NOT_FOUND) {
                    self.props.showNoty('tr', 'error', 'Paczka nie została znaleziona: ' + error.E_ELEMENT_NOT_FOUND);
                } else {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                }
                self.setState({ isRefreshing: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleFormatChange(event) {
        event.preventDefault();
        this.setState({ showModalFormat: false });
        this.onChangeFormatCallback();
    }

    generateReport() {
        let self = this;
        self.setState({ excelIsGenerating: true });
        _api.request(`/admin/klose/report-warehouse/${self.state.dataFormat.value}`,
            {
                filtered: self.state.filter
            },
            (data) => {
                self.setState({ excelIsGenerating: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ excelIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    render() {

        const loader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        const modalFormat = (
            <Modal show={this.state.showModalFormat} onHide={() => { this.setState({ showModalFormat: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Format danych</Modal.Header>
                <Modal.Body>
                    <form id="upForm">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>Format danych: </ControlLabel>
                                    <Col>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={this.state.dataFormat}
                                            placeholder="Wybierz format"
                                            onChange={
                                                (value) => {
                                                    this.setState({ dataFormat: value });
                                                }
                                            }
                                            options={this.state.dataFormats}
                                        />
                                    </Col>
                                </FormGroup>
                                <Button type="submit" className="pull-right btn-fill btn-wd btn-icon btn btn-success" onClick={this.handleFormatChange.bind(this)}>
                                    OK
                                </Button>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        );

        return (
            <div className="main-content">
                {modalFormat}
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Skaner
                                        </span>
                                    </div>
                                }
                                content={
                                    <>
                                        <Row>
                                            <Col sm={12}>
                                                <FormControl
                                                    type="text"
                                                    onChange={(e) => { this.onCodeInput(e) }}
                                                    onKeyPress={(e) => { if (e.key === 'Enter') { e.preventDefault(); this.addCodeToList(this.state.currentInput) } }}
                                                    value={this.state.currentInput}
                                                    placeholder="Skanuj kod, lub wpisz i zatwierdź klawiszem [Enter]"
                                                    name="code"
                                                />
                                            </Col>

                                            <Col sm={12}>
                                                <Table style={{ marginTop: 10 }}>
                                                    <thead>
                                                        <tr>
                                                            <th>Wprowadzony kod</th>
                                                            <th style={{ width: 80 }}>Opcje</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.scannedElements.length
                                                                ?
                                                                this.state.scannedElements.map((elem, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td>{elem}</td>
                                                                            <td>
                                                                                <Button
                                                                                    onClick={() => { this.removeCodeFromList(elem) }}
                                                                                    bsStyle="default"
                                                                                    simple
                                                                                    icon>
                                                                                    <i className="fa fa-times" />
                                                                                </Button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                                :
                                                                (
                                                                    <tr>
                                                                        <td colSpan="2" style={{ textAlign: "center" }}>
                                                                            Lista jest pusta.
                                                                        </td>
                                                                    </tr>
                                                                )
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <Button style={{ marginBottom: 10 }} bsStyle="info" bsSize="large" block disabled={this.state.scannedElements.length === 0} onClick={() => { this.collectConfirmation() }}>
                                                    <Glyphicon glyph="arrow-down" /> Przyjmij na magazyn <Glyphicon glyph="arrow-down" />
                                                </Button>
                                            </Col>
                                            <Col md={6}>
                                                <Button style={{ marginBottom: 10 }} bsStyle="success" bsSize="large" block disabled={this.state.scannedElements.length === 0} onClick={() => { this.releaseConfirmation() }}>
                                                    <Glyphicon glyph="arrow-up" /> Wydaj z magazynu <Glyphicon glyph="arrow-up" />
                                                </Button>
                                            </Col>
                                        </Row>
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Stan magazynowy
                                        </span>
                                        <Button
                                            bsStyle="default"
                                            disabled={this.state.excelIsGenerating}
                                            pullRight
                                            round
                                            onClick={() => {
                                                this.onChangeFormatCallback = () => {
                                                    this.generateReport()
                                                }
                                                this.setState({ showModalFormat: true, dataFormats: this.dataFormats, dataFormat: this.dataFormats[0] });
                                            }}
                                        >
                                            <span className="btn-label">
                                                <i className="fa fa-download" /> Eksport danych
                                            </span>
                                        </Button>
                                    </div>
                                }
                                content={
                                    <div>
                                        {(!this.state.isRefreshing) ?
                                            (
                                                <Datatable
                                                    data={this.state.data}
                                                    pages={this.state.pages}
                                                    loading={this.state.loading}
                                                    manual
                                                    filterable
                                                    columns={[
                                                        {
                                                            Header: "ZK",
                                                            accessor: "orderIdent",
                                                            width: 100
                                                        },
                                                        {
                                                            Header: "Wyrób",
                                                            accessor: "parentName"
                                                        },
                                                        {
                                                            Header: "Element",
                                                            accessor: "name"
                                                        },
                                                        {
                                                            Header: "Kod",
                                                            accessor: "codeEan"
                                                        },
                                                        {
                                                            Header: "Ocz. przyjęcie (od)",
                                                            accessor: "supplyDateFrom"
                                                        },
                                                        {
                                                            Header: "Ocz. przyjęcie (do)",
                                                            accessor: "supplyDateTo"
                                                        },
                                                        {
                                                            Header: "Ocz. dostawa (od)",
                                                            accessor: "deliveryDateFrom"
                                                        },
                                                        {
                                                            Header: "Ocz. dostawa (do)",
                                                            accessor: "deliveryDateTo"
                                                        },
                                                        {
                                                            Header: "Przyjęcie",
                                                            accessor: "collected"
                                                        },
                                                        {
                                                            Header: "Wydanie",
                                                            accessor: "released"
                                                        },
                                                        {
                                                            Header: "Status",
                                                            accessor: "status",
                                                            width: 100
                                                        },
                                                        {
                                                            Header: "",
                                                            accessor: "actions",
                                                            sortable: false,
                                                            filterable: false,
                                                            width: 20
                                                        }
                                                    ]}
                                                    defaultSorted={[
                                                        {
                                                            id: "codeEan",
                                                            desc: true
                                                        }
                                                    ]}
                                                    onFetchData={(state, instance) => {
                                                        let self = this;
                                                        self.setState({ loading: true });
                                                        _api.request("/admin/klose/list-warehouse", {
                                                            page: state.page,
                                                            pageSize: state.pageSize,
                                                            sorted: state.sorted,
                                                            filtered: state.filtered,
                                                        }, (res) => {
                                                            self.setState({
                                                                data: res.rows.map((prop) => {
                                                                    return prop;
                                                                }),
                                                                pages: res.pages,
                                                                loading: false,
                                                                filter: state.filtered
                                                            });
                                                        }, (error) => {
                                                            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
                                                            self.setState({ loading: false });
                                                        }, (progress) => {
                                                            self.props.setProgress(progress);
                                                        });
                                                    }}
                                                    defaultPageSize={10}
                                                    showPaginationBottom
                                                    className="-striped -highlight"
                                                    previousText="Poprzednia"
                                                    nextText="Następna"
                                                    noDataText="Brak danych"
                                                    loadingText="Pobieranie danych"
                                                    pageText="Strona"
                                                    ofText=" z "
                                                    rowsText="wierszy"
                                                />
                                            )
                                            :
                                            loader
                                        }
                                    </div>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminKloseWarehouse;
