import React, { Component } from "react";
import Footer from "../components/Footer/Footer.jsx";
import bgImage from "../assets/img/expert/cover-tracker.jpg";
import warning from "../assets/img/icon/warning.png";
import {
    Grid,
    Row,
    Col,
    Jumbotron
} from "react-bootstrap";

class Maintenance extends Component {

    render() {
        return (
            <div>
                <div className="wrapper wrapper-full-page wrapper-full-page-tracker">
                    <div
                        className={"full-page tracker-page"}
                        data-color="black"
                        data-image={bgImage}
                    >
                        <div className="content">
                            <div className="main-content text-center">
                                <Grid>
                                    <Row>
                                        <Col md={6} sm={6} mdOffset={3} smOffset={3}>
                                            <Jumbotron>
                                                <p><img src={warning} alt="" style={{ width: 64, margin: 15 }} /></p>
                                                <p>Trwają prace modernizacyjne.</p>
                                            </Jumbotron>
                                        </Col>
                                    </Row>
                                </Grid>
                            </div>
                        </div>
                        <Footer transparent />
                        <div
                            className="full-page-background"
                            style={{ backgroundImage: "url(" + bgImage + ")" }}
                        />
                    </div>
                </div>
            </div>
        );
    }

}

export default Maintenance;
