/* global _api */
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "./assets/css/common.css";
import "./assets/css/pe-icon-7-stroke.css";

import AuthLayout from "./layouts/Auth.jsx";
import PanelLayout from "./layouts/Panel.jsx";
import TrackerLayout from "./layouts/Tracker.jsx";
import MaintenanceLayout from "./layouts/Maintenance.jsx";

const maintenanceMode = typeof _api === "undefined"; // false;
const surpassMaintenanceMode = localStorage.getItem("unlock") === "true";

const normalRoutes = (
    <HashRouter>
        <Switch>
            <Route path="/me" render={props => <PanelLayout {...props} />} />
            <Route path="/client" render={props => <PanelLayout {...props} />} />
            <Route path="/profile" render={props => <PanelLayout {...props} />} />
            <Route path="/admin" render={props => <PanelLayout {...props} />} />
            <Route path="/warehouse" render={props => <PanelLayout {...props} />} />
            <Route path="/driver" render={props => <PanelLayout {...props} />} />
            <Route path="/sample" render={props => <PanelLayout {...props} />} />
            <Route path="/auth" render={props => <AuthLayout {...props} />} />
            <Route path="/track" render={props => <TrackerLayout {...props} />} />
            <Route path="/enet" render={props => <TrackerLayout {...props} />} />
            <Redirect from="/" to="/auth/login" />
        </Switch>
    </HashRouter>
)

const maintenanceModeRoutes = (
    <HashRouter>
        <Switch>
            <Route render={props => <MaintenanceLayout {...props} />} />
        </Switch>
    </HashRouter>
)

ReactDOM.render(
    (!maintenanceMode || surpassMaintenanceMode)
        ? normalRoutes
        : maintenanceModeRoutes
    ,
    document.getElementById("root")
);
