import React, { Component } from "react";
import ExpertTrackerComponent from "../../../components/Expert/TrackerComponent.jsx";
import 'react-vertical-timeline-component/style.min.css';
import moment from "moment";
import "moment/min/locales";
import { Grid, Row, Col, } from "react-bootstrap";
import { $STATUS2_AWIZACJA, $STATUS2_AWIZACJADOHUBLASTMILE, $STATUS2_ODBIORODNADAWCY, $STATUS2_PRZYJETE, $STATUS2_TRANSFERDOHUBLASTMILE, $STATUS2_WYDANE } from "../../../constants.js";

moment.locale('pl');

class ExpertTracker extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            orderTracker: this.props.location.pathname.replace("/track/", "")
        };
    }

    render() {
        return (
            <div className="main-content">
                <Grid>
                    <Row>
                        <Col md={6} sm={6} mdOffset={3} smOffset={3}>
                            <ExpertTrackerComponent
                                showPoll={true}
                                showForm={true}
                                hideStatus={[
                                    $STATUS2_ODBIORODNADAWCY,
                                    $STATUS2_AWIZACJA,
                                    $STATUS2_WYDANE,
                                    $STATUS2_PRZYJETE,
                                    $STATUS2_AWIZACJADOHUBLASTMILE,
                                    $STATUS2_TRANSFERDOHUBLASTMILE
                                ]}
                                showNoty={this.props.showNoty}
                                setProgress={this.props.setProgress}
                                orderTracker={this.state.orderTracker}
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }

}

export default ExpertTracker;
