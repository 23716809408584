// https://medium.com/@bryanjenningz/how-to-record-and-play-audio-in-javascript-faa1b2b3e49b
const recordAudio = () => {
    return new Promise(resolve => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                const audioChunks = [];

                mediaRecorder.addEventListener("dataavailable", event => {
                    audioChunks.push(event.data);
                });

                const start = () => {
                    mediaRecorder.start();
                };

                const stop = () => {
                    return new Promise(resolve => {
                        mediaRecorder.addEventListener("stop", (stream) => {
                            const audioBlob = new Blob(audioChunks);
                            const audioUrl = URL.createObjectURL(audioBlob);
                            const audioItself = new Audio(audioUrl);
                            const play = () => {
                                audioItself.play();
                            };

                            resolve({ audioBlob, audioUrl, audioItself, play });
                        });

                        mediaRecorder.stop();
                        stream.getTracks() // get all tracks from the MediaStream
                            .forEach(track => track.stop()); // stop each of them
                    });
                };

                resolve({ start, stop });
            });
    });
};

export default recordAudio;