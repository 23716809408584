import React, { Component } from "react";
import ReactTable from "react-table-6";
import { FormControl } from "react-bootstrap";
import { CSVLink } from "react-csv";

class Datatable extends Component {

    customFilter = ({ filter, onChange }) => {
        return (
            <FormControl
                placeholder={`Szukaj...`}
                onKeyPress={event => {
                    if (event.keyCode === 13 || event.which === 13) {
                        event.stopPropagation();
                        event.preventDefault();
                        onChange(event.target.value);
                    }
                }}
            />
        )
    }

    render() {

        this.props.columns.map((col) => {
            col.Filter = this.customFilter;
            return col;
        });

        return (
            <>
                <ReactTable
                    {...this.props}
                />
                <CSVLink
                    filename={"TableContent.csv"}
                    data={this.props.data}
                    className="btn btn-link"
                    style={{ border: 0, padding: 0, marginTop: 10 }}
                    separator={";"}
                >
                    &darr; CSV
                </CSVLink>
            </>
        )
    }
}

export default Datatable;