/* global _api */
import React, { Component, Fragment } from "react";
import Button from "../CustomButton/CustomButton.jsx";
import moment from "moment";
import "moment/min/locales";
import { getDistance } from 'geolib';
import PropagateLoader from 'react-spinners/PropagateLoader';
import SweetAlert from "react-bootstrap-sweetalert";
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import Select from "react-select";
import {
    Row,
    Col,
    FormGroup,
    Modal,
    ControlLabel,
    Table,
    FormControl
} from "react-bootstrap";

moment.locale('pl');

class VRouter extends Component {

    constructor(props) {
        super(props);

        this.dayParts = [
            { value: "all", label: "cały dzień" },
            { value: "1", label: "1. część dnia" },
            { value: "2", label: "2. część dnia" }
        ]

        this.zeroState = {
            dayPart: this.dayParts[0],
            schedulesChecked: [],
            showSelectModal: false,
            checkAll: false,
            maximumDuration: 8 * 60,
            averageSpeed: 50
        }

        this.defaultDuration = 15; // minutes

        this.maximumDuration = { // minutes
            "all": 8 * 60,
            "1": 4 * 60,
            "2": 4 * 60,
        }
        this.state = { ...this.zeroState }
    }

    vehicleTrackPlanningPrepare() {

        const {
            dayPart,
            maximumDuration,
            averageSpeed
        } = this.state;

        const {
            depot,
            ordersAll,
            schedulesAll
        } = this.props.upperState;

        let capitalizeFirstLetter = function (string) {
            if (!string) {
                return string;
            }
            return string[0].toUpperCase() + string.slice(1);
        }

        let orders = ordersAll
            .filter(order => !order.trash)
            .filter(order => {
                return (dayPart.value === "all") || (parseInt(order.dayPartIdent, 10) === parseInt(dayPart.value))
            })
            .map(order => {
                let newOrder = {
                    uuid: order.uuid,
                    ident: order.ident,
                    pickup: {
                        latitude: null,
                        longitude: null,
                        duration: null,
                        address: null,
                    },
                    delivery: {
                        latitude: null,
                        longitude: null,
                        duration: null,
                        address: null,
                    },
                }
                if (order.warehouse.name && order.warehouse.address && order.warehouse.address.geoLat && order.warehouse.address.geoLng) {
                    newOrder.pickup = {
                        latitude: parseFloat(order.warehouse.address.geoLat),
                        longitude: parseFloat(order.warehouse.address.geoLng),
                        duration: parseInt(order.warehouse?.avgLoadingTime || this.defaultDuration, 10),
                        address: [
                            capitalizeFirstLetter(order.warehouse.address.streetAddress),
                            capitalizeFirstLetter(order.warehouse.address.streetNumber),
                            capitalizeFirstLetter(order.warehouse.address.cityName)
                        ].filter(Boolean).join(', '),
                    };
                } else {
                    newOrder.pickup = {
                        latitude: parseFloat(order.sender.address.geoLat),
                        longitude: parseFloat(order.sender.address.geoLng),
                        duration: parseInt(order.warehouse?.avgLoadingTime || this.defaultDuration, 10),
                        address: [
                            capitalizeFirstLetter(order.sender.address.streetAddress),
                            capitalizeFirstLetter(order.sender.address.streetNumber),
                            capitalizeFirstLetter(order.sender.address.cityName)
                        ].filter(Boolean).join(', ')
                    };
                }
                newOrder.delivery = {
                    latitude: parseFloat(order.recipient.address.geoLat),
                    longitude: parseFloat(order.recipient.address.geoLng),
                    duration: parseInt(order.duration || this.defaultDuration, 10),
                    address: [
                        capitalizeFirstLetter(order.recipient.address.streetAddress),
                        capitalizeFirstLetter(order.recipient.address.streetNumber),
                        capitalizeFirstLetter(order.recipient.address.cityName)
                    ].filter(Boolean).join(', ')
                };
                return newOrder;
            });

        let schedules = schedulesAll
            .filter(sched => !sched.trash)
            .filter(sched => this.isCheckedSchedule(sched.uuid))
            .map(schedule => {
                let newSchedule = {
                    uuid: schedule.uuid,
                    ident: schedule.ident,
                    name: schedule.name,
                    background: schedule.background,
                    foreground: schedule.foreground
                }
                return newSchedule;
            });

        let num_vehicles = schedules.length;

        if (!depot) {
            this.popupVehicleTrackPlanningError('Brak punktu startowego trasy w tym oddziale.');
            return;
        }

        if (!num_vehicles) {
            this.popupVehicleTrackPlanningError('Musisz dodać przynajmniej jeden harmonogram.');
            return;
        }

        let distance_matrix = [];
        let pickups_deliveries = [];
        let duration_matrix = [];
        let depot_index = 0;
        let maximum_distance = 0;
        let total_distance = 0;
        let total_duration = parseFloat(maximumDuration);
        let avg_meters_per_minute = parseFloat(averageSpeed) * 16.667;
        let waypoints = [{
            latitude: depot.address.geoLat,
            longitude: depot.address.geoLng,
            duration: this.defaultDuration
        }];
        let aliases = [{
            "ident": "DEPOT",
            "uuid": null,
            "operation": "pickup",
            "duration": this.defaultDuration,
            "address": depot.address.streetAddress
        }];

        orders.forEach(order => {

            waypoints.push(order.pickup);
            aliases[waypoints.length - 1] = {
                "ident": order.ident,
                "uuid": order.uuid,
                "operation": "pickup",
                "duration": order.pickup.duration,
                "address": order.pickup.address
            };

            waypoints.push(order.delivery);
            aliases[waypoints.length - 1] = {
                "ident": order.ident,
                "uuid": order.uuid,
                "operation": "delivery",
                "duration": order.delivery.duration,
                "address": order.delivery.address
            };

            pickups_deliveries.push([waypoints.length - 2, waypoints.length - 1]);
        });

        aliases.push({
            "ident": "DEPOT",
            "uuid": null,
            "operation": "delivery",
            "duration": this.defaultDuration,
            "address": depot.address.streetAddress
        });

        for (let i = 0; i < waypoints.length; i++) {
            let distances = [];
            for (let j = 0; j < waypoints.length; j++) {
                let distance = getDistance(waypoints[i], waypoints[j]);
                if (distance > maximum_distance) {
                    maximum_distance = distance;
                }
                total_distance += distance;
                distances.push(distance);
            }
            distance_matrix.push(distances);
            duration_matrix.push(waypoints[i].duration);
        }

        let preparedData = {
            distance_matrix: distance_matrix,
            pickups_deliveries: pickups_deliveries,
            depot: depot_index,
            num_vehicles: num_vehicles,
            aliases: aliases,
            maximum_distance: maximum_distance,
            total_distance: total_distance,
            duration_matrix: duration_matrix,
            total_duration: total_duration,
            avg_meters_per_minute: avg_meters_per_minute,
            schedules: schedules,
            orders: orders
        }

        this.vehicleTrackPlanningExecute(preparedData);
    }

    vehicleTrackPlanningExecute(preparedData) {
        this.popupVehicleTrackPlanningProgress();
        const currentApiUrl = _api.config.url;
        _api.config.url = "https://api.zlecenia.expert/";
        _api.request(
            "/admin/schedule/v-router",
            preparedData,
            (result) => {
                _api.config.url = currentApiUrl;
                if (!result.status || result.errorMessage) {
                    this.popupVehicleTrackPlanningError('Automatyczne planowanie zakończyło się niepowodzeniem. Wykonaj operację ręcznie lub spróbuj dokonać modyfikacji parametrów.');
                    return;
                }
                this.vehicleTrackPlanningParse(preparedData, result);
            },
            (error) => {
                _api.config.url = currentApiUrl;
                this.popupVehicleTrackPlanningError('Automatyczne planowanie zakończyło się niepowodzeniem. Wykonaj operację ręcznie.');
            }
        );
    }

    vehicleTrackPlanningParse(preparedData, result) {

        let newOrders = [...this.props.upperState.ordersAll];
        let newSchedules = [...this.props.upperState.schedulesAll];

        Object.entries(result.vehicles).forEach(([vehicleIndex, vehicleData]) => {

            const selectedSchedule = preparedData.schedules[vehicleIndex];

            for (let nodeIndex = 0; nodeIndex < vehicleData.nodes.length; nodeIndex++) {
                let node = vehicleData.nodes[nodeIndex];
                let selectedOrder = preparedData.aliases[node];

                if (selectedOrder.ident === "DEPOT") {
                    continue;
                }
                if (selectedOrder.operation === "pickup") {
                    continue;
                }
                newOrders = newOrders.map((currentOrder) => {
                    if (currentOrder.uuid !== selectedOrder.uuid) {
                        return currentOrder;
                    }
                    let updatedOrder = { ...currentOrder };
                    updatedOrder.position = nodeIndex;
                    updatedOrder.schedule.assigned = true;
                    updatedOrder.schedule.uuid = selectedSchedule.uuid;
                    updatedOrder.schedule.name = selectedSchedule.name;
                    updatedOrder.background = selectedSchedule.background;
                    updatedOrder.foreground = selectedSchedule.foreground;
                    return updatedOrder;
                })
            }

            newSchedules = newSchedules.map((currentSchedule) => {
                if (currentSchedule.uuid !== selectedSchedule.uuid) {
                    return currentSchedule;
                }
                let updatedSchedule = { ...currentSchedule };
                updatedSchedule.numberOfOrders = vehicleData.nodes.length;
                updatedSchedule.distance = (vehicleData.distance / 1000).toFixed(2);
                updatedSchedule.driverIncome = "";
                updatedSchedule.realIncome = "";
                updatedSchedule.time = "";
                return updatedSchedule;
            });

        });

        this.props.setUpperState(
            {
                alert: null,
                ordersAll: newOrders,
                schedulesAll: newSchedules
            },
            () => {
                this.props.bulkSave();
            }
        );
    }

    popupVehicleTrackPlanningConfirm() {
        this.props.setUpperState({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź operację"
                    confirmBtnText="Zaplanuj trasy"
                    onConfirm={() => { this.vehicleTrackPlanningPrepare() }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.props.setUpperState({ alert: null }); }}
                    confirmBtnBsStyle="info">
                    Czy chcesz automatycznie zaplanować trasy dla wybranych harmonogramów? <strong>Zlecenia, które zostały już zaplanowane w wybranej dacie zostaną zaktualizowane.</strong>
                </SweetAlert>
            )
        });
    }

    popupVehicleTrackPlanningProgress() {
        this.props.setUpperState({
            alert: (
                <SweetAlert
                    custom
                    title={`Przetwarzanie danych`}
                    showConfirm={false}
                    showCancel={false}
                    onConfirm={() => { }}
                    style={{ display: "block", marginTop: "-196px" }}
                >
                    Ta operacja może potrwać kilka minut, proszę czekać.
                    <br />
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        css={{
                            "display": "block",
                            "margin": "10px auto",
                            "width": "1px",
                        }}
                        color={'#777'}
                        loading={true}
                    />
                    <br />
                </SweetAlert>
            )
        });
    }

    popupVehicleTrackPlanningError(content) {
        this.props.setUpperState({
            alert: (
                <SweetAlert
                    error
                    title={``}
                    style={{ display: "block", marginTop: "-196px" }}
                    showCancel={false}
                    showConfirm={true}
                    confirmBtnText="OK"
                    onConfirm={() => { this.props.setUpperState({ alert: null }); }}
                    confirmBtnBsStyle="danger"
                >
                    {content}
                    <br />
                </SweetAlert>
            )
        });
    }

    maybeCheckAllSchedules(checked) {
        const {
            schedulesAll
        } = this.props.upperState;

        if (checked) {
            let schedulesChecked = [];
            schedulesAll.forEach((element) => {
                schedulesChecked.push({ uuid: element.uuid });
            });
            this.setState({ schedulesChecked: schedulesChecked, checkAll: true });
        } else {
            this.setState({ schedulesChecked: [], checkAll: false });
        }
    }

    isCheckedSchedule(uuid) {
        return this.state.schedulesChecked.some(e => e.uuid === uuid);
    }

    maybeCheckSchedule(uuid) {
        const checked = this.isCheckedSchedule(uuid);
        if (!checked) {
            this.setState({
                schedulesChecked: this.state.schedulesChecked.concat([{ uuid: uuid }])
            });
        } else {
            this.setState({
                schedulesChecked: this.state.schedulesChecked.filter((item) => { return item.uuid !== uuid })
            });
        }
    }

    render() {

        const {
            schedulesChecked
        } = this.state;

        const {
            schedulesAll
        } = this.props.upperState;

        return (
            <Fragment>

                <Modal
                    show={this.state.showSelectModal}
                    onHide={() => { this.setState({ showSelectModal: false }) }}
                    style={{ paddingLeft: 0 }}
                    backdrop="static"
                >
                    <Modal.Header closeButton>
                        Automatyczne planowanie
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <ControlLabel>Zaplanuj zlecenia z zakresu:</ControlLabel>
                                    <Col>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            name="payer"
                                            value={this.state.dayPart}
                                            placeholder="Wybierz"
                                            onChange={value => {
                                                this.setState({
                                                    dayPart: value,
                                                    maximumDuration: this.maximumDuration[value.value]
                                                })
                                            }
                                            }
                                            options={this.dayParts}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <ControlLabel>Maksymalny czas pracy (min.):</ControlLabel>
                                            <Col>
                                                <FormControl
                                                    type="number"
                                                    placeholder={`...`}
                                                    value={this.state.maximumDuration}
                                                    onChange={evt =>
                                                        this.setState({ maximumDuration: evt.target.value })
                                                    }
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <ControlLabel>Średnia prędkość (km/h):</ControlLabel>
                                            <Col>
                                                <FormControl
                                                    type="number"
                                                    placeholder={`...`}
                                                    value={this.state.averageSpeed}
                                                    onChange={evt =>
                                                        this.setState({ averageSpeed: evt.target.value })
                                                    }
                                                />
                                            </Col>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col sm={12}>
                                <FormGroup>
                                    <ControlLabel>Następnie, przyporządkuj je do harmonogramów:</ControlLabel>
                                </FormGroup>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 60 }}>
                                                <Checkbox
                                                    key="check-all"
                                                    number={99999}
                                                    checked={this.state.checkAll}
                                                    onChange={event => {
                                                        this.maybeCheckAllSchedules(event.target.checked);
                                                    }}
                                                />
                                            </th>
                                            <th style={{ width: 140 }}>Harmonogram</th>
                                            <th>Kierowca</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!(schedulesAll.length) ? <tr><td colSpan="3" className="text-center text-muted">- brak danych -</td></tr> :
                                            schedulesAll.map((element, key) => {
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            <Checkbox
                                                                key={element.uuid}
                                                                number={key}
                                                                checked={this.isCheckedSchedule(element.uuid)}
                                                                onChange={event => {
                                                                    this.maybeCheckSchedule(element.uuid);
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{element.ident}</td>
                                                        <td>{element.driverName}</td>
                                                    </tr>
                                                )
                                            }
                                            )}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan="3" className="text-center">
                                                <button
                                                    style={{ width: "100%" }}
                                                    className="btn btn-default btn-wd"
                                                    disabled={schedulesChecked.length === 0}
                                                    onClick={
                                                        () => {
                                                            this.setState({
                                                                showSelectModal: false
                                                            }, () => {
                                                                this.popupVehicleTrackPlanningConfirm();
                                                            })
                                                        }
                                                    }>
                                                    Zaplanuj trasy
                                                    </button>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>

                <Button
                    bsStyle="info"
                    block
                    wd
                    onClick={
                        () => {
                            this.setState({ ...this.zeroState, showSelectModal: true })
                        }
                    }
                >
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-code-fork" />
                    </span>
                    <>automatyczne planowanie (beta)</>
                </Button>

            </Fragment >
        )
    }

}

export default VRouter;
