

/* global _api */
import React, { Component } from "react";
import "moment/min/locales";
import { Row, Col, Modal, Table, Nav, NavItem, Tab, FormGroup, ControlLabel, ListGroup, ListGroupItem } from "react-bootstrap";
import BaseComponent from "../../utils/BaseComponent";

class TransferModalOrderDetailsComponent extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            tab: 'details',
        }
    }

    render() {

        if (!this.props.show) {
            return <></>
        }

        let totalSize = 0;
        let totalWeight = 0;
        this.props.upperState.singleOrder.elements.map(itm => {
            totalSize += this.parseFloatFixed(itm.size, 10);
            totalWeight += this.parseFloatFixed(itm.weight, 10);
            return itm;
        }).filter(Boolean);

        let detailsForm = (
            <>
                <Row>
                    <Col sm={12}>
                        <ListGroup>
                            <ListGroupItem>
                                <div style={{ display: 'flex', justifyContent: 'between', alignItems: 'start' }}>
                                    <span>Numer zamówienia:</span>
                                    <strong style={{ marginLeft: 'auto' }}>{this.props.upperState.singleOrder.orderIdent}</strong>
                                </div>
                            </ListGroupItem>
                            <ListGroupItem>
                                <div style={{ display: 'flex', justifyContent: 'between', alignItems: 'start' }}>
                                    <span>Last mile:</span>
                                    <strong style={{ marginLeft: 'auto' }}>{this.props.upperState.singleOrder.lastMileDepartmentName}</strong>
                                </div>
                            </ListGroupItem>
                            <ListGroupItem>
                                <div style={{ display: 'flex', justifyContent: 'between', alignItems: 'start' }}>
                                    <span>Data dostawy:</span>
                                    <strong style={{ marginLeft: 'auto' }}>{this.props.upperState.singleOrder.slotDayIdent}</strong>
                                </div>
                            </ListGroupItem>
                        </ListGroup>
                    </Col>
                </Row>
            </>
        )

        let elementsForm = (
            <Table>
                <thead>
                    <tr>
                        <th>Lp.</th>
                        <th>Paczka</th>
                        <th style={{ textAlign: "right" }}>Kubatura (m<sup>3</sup>)</th>
                        <th style={{ textAlign: "right" }}>Waga (kg)</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.props.upperState.singleOrder.elements.map((element, index) => (
                            <React.Fragment key={`modal-element-${element.uuid}-${index}-0`}>
                                <tr key={`modal-element-${element.uuid}-${index}`}>
                                    <td>
                                        {index + 1}
                                    </td>
                                    <td>
                                        {element.name}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {parseFloat(element.size).toFixed(3)}
                                    </td>
                                    <td style={{ textAlign: "right" }}>
                                        {parseFloat(element.weight).toFixed(3)}
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th>&nbsp;</th>
                        <th>&nbsp;</th>
                        <th style={{ textAlign: "right" }}>{totalSize.toFixed(3)}</th>
                        <th style={{ textAlign: "right" }}>{totalWeight.toFixed(3)}</th>
                    </tr>
                </tfoot>
            </Table>
        )

        return (
            <Modal show={this.props.show} onHide={() => { this.props.onHide() }} style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Szczegóły zamówienia</Modal.Header>
                <Modal.Body>
                    <Tab.Container
                        id="tabs-transfer-modal"
                        activeKey={this.state.tab}
                        onSelect={(tab) => { this.setState({ tab: tab }) }}
                    >
                        <Row className="clearfix">
                            <Col sm={12}>
                                <Nav bsStyle="tabs">
                                    <NavItem eventKey="details">Zamówienie</NavItem>
                                    <NavItem eventKey="elements">Paczki</NavItem>
                                </Nav>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content animation={false}>
                                    <Tab.Pane eventKey="details" style={{ padding: "15px 0px" }}>{detailsForm}</Tab.Pane>
                                    <Tab.Pane eventKey="elements" style={{ padding: "15px 0px" }}>{elementsForm}</Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Modal.Body>
            </Modal >
        );
    }
}

export default TransferModalOrderDetailsComponent;