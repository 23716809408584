/*global google*/
import React, { Component } from "react";
import {
    withGoogleMap,
    GoogleMap,
    DirectionsRenderer
} from "react-google-maps";

class DirectionsMap extends Component {

    constructor(props) {
        super(props);
        this.state = {
            directions: null,
            distance: null,
            duration: null,
            origin: null,
            destination: null
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!this.state.directions) {
            return true;
        }
        if (nextProps.destination === this.state.destination) {
            return false;
        }
        return true;
    }

    componentWillReceiveProps(nextProps) {
        const directionsService = new google.maps.DirectionsService();

        if (nextProps.destination === this.state.destination) {
            return;
        }

        const origin = nextProps.origin;
        const destination = nextProps.destination;

        this.setState({ origin: origin, destination: destination, directions: null });

        if (!origin) {
            return;
        }

        if (!destination) {
            return;
        }

        directionsService.route(
            {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                optimizeWaypoints: true
            },
            (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    let distance = result.routes[0].legs[0].distance.value / 1000; // kilometers
                    let duration = result.routes[0].legs[0].duration.value; // seconds
                    let output = {
                        directions: result,
                        distance: distance,
                        duration: duration,
                        destinationGeoLat: result.routes[0].legs[0].end_location.lat(),
                        destinationGeoLng: result.routes[0].legs[0].end_location.lng(),
                    };
                    this.setState(output);
                    this.props.onDirectionsChange(output);
                } else {
                    this.props.onDirectionsNotFound(status);
                    console.error(`error fetching directions ${result}`);
                }
            }
        );
    }

    render() {
        let dir = (<></>);
        let defaultCenter = this.props.defaultCenter;

        if (this.state.directions) {
            dir = <DirectionsRenderer
                directions={this.state.directions}
            />;
        }

        return (
            <GoogleMap
                defaultCenter={defaultCenter}
                defaultZoom={11}
                defaultOptions={{
                    mapTypeControl: false
                }}
            >
                {dir}
            </GoogleMap>
        );
    }
}

export default withGoogleMap(DirectionsMap);