/* global _api */
import React from "react";
import PropagateLoader from 'react-spinners/PropagateLoader';
import moment from "moment";
import "moment/min/locales";
import { Grid, Row, Col } from "react-bootstrap";
import BaseComponent from "../../../../utils/BaseComponent.jsx";
import Card from "../../../../components/Card/Card.jsx";
import Datatable from "../../../../components/Datatable";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import Checkbox from "../../../../components/CustomCheckbox/CustomCheckbox.jsx";
import ExpertTransferModalComponent from "../../../../components/Expert/TransferModalComponent.jsx";
import ExpertAdminHubFilter from "./Filter.jsx";
import SweetAlert from "react-bootstrap-sweetalert";

moment.locale('pl');

class ExpertAdminHubSchedules extends BaseComponent {

    constructor(props) {
        super(props);
        this.state = {
            alert: null,
            data: [],
            pages: -1,
            loading: true,
            isSaving: false,
            isRefreshing: false,
            checkAll: false,
            showModalTransferSchedule: false,

            showTrackerModal: false,
            orderTracker: false,
            orderIdent: false,
            showOrderModal: false,
            orderUuid: false,
            transferStatuses: [],

            scheduleData: {},

            filters: {
                excelIsGenerating: false,
                transferMaxFirstMilePickupDayIdentFrom: moment().subtract(2, 'weeks'),
                transferMaxFirstMilePickupDayIdentTo: moment().add(2, 'weeks'),
                slotDayIdentFrom: null,
                slotDayIdentTo: null,

                fromWarehouse: null,
                toWarehouse: null,
                fromCompany: null,
                driver: null,
                status: null,

                warehouses: [],
                companies: [],
                statuses: [],
            },
        }
    }

    componentDidMount() {
        super.componentDidMount();
        _api.request(
            `/admin/transfer/list-filters`,
            {},
            (data) => {
                this.setState({ filters: { ...this.state.filters, ...data || {} } })
            }
        );
    }

    checkElement(itemUuid, checked) {
        const newItems = this.state.data.map((item) => {
            let updatedItem = { ...item };
            if (itemUuid !== null && item.uuid === itemUuid) {
                updatedItem.checked = checked;
            }
            return updatedItem;
        });
        this.setState({
            data: this.reMapData(newItems),
        });
    }

    popupCancel(transferIdent, cancelled) {
        const prop = cancelled ? { info: true, confirmBtnBsStyle: "info" } : { danger: true, confirmBtnBsStyle: "danger" }
        this.setState({
            alert: (
                <SweetAlert
                    {...prop}
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Potwierdź operację"
                    confirmBtnText={cancelled ? "Przywróć" : "Usuń"}
                    onConfirm={() => {
                        _api.request(
                            `/admin/transfer/cancel-transfer`,
                            {
                                transfer: {
                                    ident: transferIdent,
                                    cancelled: !cancelled
                                }
                            },
                            (data) => {
                                this.setState({ isRefreshing: true, alert: null }, () => { this.setState({ isRefreshing: false }) });
                            }
                        );
                    }}
                    cancelBtnText="Powrót"
                    onCancel={() => { this.setState({ alert: null }); }}
                >
                </SweetAlert>
            )
        });
    }

    reMapData(data) {
        return data.map((prop, key) => {
            prop.checked = prop.hasOwnProperty('checked') ? prop.checked : false;
            prop.checkbox = (
                <Checkbox
                    key={prop.uuid}
                    number={key}
                    checked={prop.checked}
                    onChange={event => {
                        this.checkElement(prop.uuid, event.target.checked);
                    }}
                />
            );
            if (!prop.hasActions) {
                prop.transferIdentRaw = prop.transferIdent;
                prop.transferIdent = (
                    <span className={prop.transferCancelled === "1" ? "trash" : ""}>
                        {prop.transferIdentRaw}
                    </span>
                )
                prop.actions = (
                    <div className="actions-left">
                        <Button
                            onClick={() => {
                                this.getExistingTransfer(prop.transferIdentRaw);
                            }}
                            bsStyle="default"
                            simple
                            icon>
                            <i className="fa fa-edit" />
                        </Button>
                        {
                            prop.transferCancelled === "1" ? (
                                <></>
                            ) : (
                                <Button
                                    onClick={() => { this.popupCancel(prop.transferIdentRaw, prop.transferCancelled === "1") }}
                                    bsStyle="danger"
                                    simple
                                    icon>
                                    <i className="fa fa-times" />
                                </Button>
                            )
                        }
                    </div>
                )
                prop.hasActions = true;
            }
            return prop;
        });
    }

    checkAll(checked) {
        const checkAll = checked;
        const newItems = this.state.data.map((item) => {
            let updatedItem = { ...item };
            updatedItem.checked = checkAll;
            return updatedItem;
        });
        this.setState({
            data: this.reMapData(newItems),
            checkAll
        });
    }

    fetchTable = (state, instance, callback) => {
        let self = this;
        self.setState({ loading: true });
        _api.request("/admin/transfer/list-schedules", {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            overlayed: this.getOverlayed(),
        }, (res) => {
            self.setState({
                data: self.reMapData(res.rows),
                pages: res.pages,
                loading: false,
                page: res.page,
                pageSize: res.pageSize,
                sorted: res.sorted,
                filtered: res.filtered
            },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loading: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    print() {
        let self = this;
        let idents = [];
        self.state.data.forEach((element) => {
            if (element.checked) {
                idents.push(element.transferIdentRaw);
            }
        });
        self.setState({ pdfIsGenerating: true });
        _api.request(`/admin/transfer/print-schedules`,
            {
                transfer: {
                    idents: idents,
                }
            },
            (data) => {
                self.setState({ pdfIsGenerating: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ pdfIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    getExistingTransfer(scheduleIdent) {
        let self = this;
        _api.request(`/admin/transfer/get-schedules`,
            {
                transfer: {
                    idents: [scheduleIdent]
                }
            },
            (data) => {
                let rawData = data[scheduleIdent];
                let deliveryWarehouseName = "";
                let elements = rawData.orders?.map(order => {
                    order.currentWarehouseName = order.fromWarehouse;
                    order.orderIdent = order.ident,
                        order.companyName = order.client,
                        deliveryWarehouseName = order.toWarehouse;
                    return order;
                });
                let scheduleData = {
                    ...rawData.transfer,
                    ident: scheduleIdent,
                    elements: elements,
                    pickupDate: rawData.transfer.pickupDayIdent,
                    deliveryDate: rawData.transfer.deliveryDayIdent,
                    deliveryWarehouseName: deliveryWarehouseName,
                }
                self.setState({ scheduleData: scheduleData, showModalTransferSchedule: true });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    onSubmitExistingTransfer() {
        let self = this;
        _api.request(`/admin/transfer/set-transfer`,
            { transfer: self.state.scheduleData },
            (data) => {
                this.props.showNoty('tr', 'success', 'Dane zostały zapisane');
                this.setState({ isRefreshing: true }, () => { this.setState({ isRefreshing: false }) });
                this.getExistingTransfer(data['ident']);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    onUpdateExistingTransfer(newData, callback) {
        let newSchedule = { ...this.state.scheduleData, ...newData };
        this.setState({ scheduleData: newSchedule }, callback);
    }

    setFilters(e, c) {
        this.setState({ filters: { ...this.state.filters, [e]: c } }, () => {
            this.fetchTable(this.state);
        })
    }

    getOverlayed() {
        const self = this;
        return {
            transferMaxFirstMilePickupDayIdentFrom: self.state.filters.transferMaxFirstMilePickupDayIdentFrom ? moment(self.state.filters.transferMaxFirstMilePickupDayIdentFrom).format("YYYY-MM-DD") : null,
            transferMaxFirstMilePickupDayIdentTo: self.state.filters.transferMaxFirstMilePickupDayIdentTo ? moment(self.state.filters.transferMaxFirstMilePickupDayIdentTo).format("YYYY-MM-DD") : null,
            slotDayIdentFrom: self.state.filters.slotDayIdentFrom ? moment(self.state.filters.slotDayIdentFrom).format("YYYY-MM-DD") : null,
            slotDayIdentTo: self.state.filters.slotDayIdentTo ? moment(self.state.filters.slotDayIdentTo).format("YYYY-MM-DD") : null,
            fromWarehouse: self.state.filters.fromWarehouse?.value,
            toWarehouse: self.state.filters.toWarehouse?.value,
            fromCompany: self.state.filters.fromCompany?.value,
            driver: self.state.filters.driver?.value,
            status: self.state.filters.status?.value,
        };
    }

    getReport() {
        this.setFilters('excelIsGenerating', true);
        _api.request(
            '/admin/transfer/report/schedules',
            { overlayed: this.getOverlayed() },
            (data) => {
                this.setFilters('excelIsGenerating', false);
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                this.setFilters('excelIsGenerating', false);
                this.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                this.props.setProgress(progress);
            }
        );
    }

    render() {

        let cols = [
            {
                Header: (
                    <>
                        <Checkbox
                            key="check-all"
                            number={99999}
                            checked={this.state.checkAll}
                            className="mb-0"
                            onChange={event => {
                                this.checkAll(event.target.checked);
                            }}
                        />
                    </>
                ),
                accessor: "checkbox",
                sortable: false,
                filterable: false,
                width: 50,
            },
            {
                Header: "Harmonogram",
                accessor: "transferIdent",
                width: 220,
            },
            {
                Header: "Potwierdzenie",
                accessor: "transferConfirmation",
            },
            {
                Header: "Imię",
                accessor: "transferFirstname"
            },
            {
                Header: "Nazwisko",
                accessor: "transferLastname"
            },
            {
                Header: "Firma",
                accessor: "transferCompanyName"
            },
            {
                Header: "Aktualny mag.",
                accessor: "currentWarehouseName",
            },
            {
                Header: "Data odbioru mag.",
                accessor: "transferPickupDayIdent",
                showInSummary: true,
            },
            {
                Header: "Następny mag.",
                accessor: "nextWarehouseName",
            },
            {
                Header: "Data dostawy mag.",
                accessor: "transferDeliveryDayIdent",
                showInSummary: true,
            },
            {
                Header: "Kubatura",
                accessor: "calculatedSizeTotal",
                filterable: false,
            },
            {
                Header: "Waga",
                accessor: "calculatedWeightTotal",
                filterable: false,
            },
            {
                Header: "Aktualizacja",
                accessor: "transferUpdated"
            },
            {
                Header: "",
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 80
            }
        ];

        const contentLoader = (
            <div style={{ "paddingBottom": "26px" }}>
                <PropagateLoader
                    sizeUnit={"px"}
                    size={15}
                    margin={2}
                    css={{
                        "display": "block",
                        "margin": "10px auto",
                        "width": "1px"
                    }}
                    color={'#777'}
                    loading={true}
                />
            </div>
        );

        return (
            <div className="main-content">
                {this.state.alert}
                
                <ExpertTransferModalComponent
                    show={this.state.showModalTransferSchedule}
                    onHide={() => this.setState({ showModalTransferSchedule: false })}
                    onSubmit={(e) => this.onSubmitExistingTransfer(e)}
                    setUpperState={(e, c) => { this.onUpdateExistingTransfer(e, c) }}
                    upperState={this.state.scheduleData}
                    editOnlyBasics={true}
                />

                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <ExpertAdminHubFilter
                                getReport={() => { this.getReport() }}
                                setFilters={(e, c) => { this.setFilters(e, c) }}
                                filters={{ ...this.state.filters }}
                            />
                        </Col>
                        <Col md={12}>
                            <Card
                                title={
                                    <div>
                                        <span>
                                            Harmonogramy
                                        </span>

                                        <Button
                                            bsStyle="default"
                                            round
                                            icon
                                            pullRight
                                            style={{ marginRight: 10 }}
                                            disabled={this.state.pdfIsGenerating}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                this.print()
                                            }}
                                        >
                                            <span className="btn-label">
                                                <i className="fa fa-print" /> Drukuj
                                            </span>
                                        </Button>
                                    </div>
                                }
                                content={
                                    <>
                                        {
                                            !this.state.isRefreshing
                                                ?
                                                (
                                                    <Datatable
                                                        data={this.state.data}
                                                        pages={this.state.pages}
                                                        loading={this.state.loading}
                                                        manual
                                                        filterable
                                                        columns={cols}
                                                        defaultSorted={[
                                                            {
                                                                id: "transferIdent",
                                                                desc: true
                                                            }
                                                        ]}
                                                        onFetchData={this.fetchTable}
                                                        defaultPageSize={25}
                                                        pageSizeOptions={[25, 50, 100, 150, 200]}
                                                        showPaginationBottom
                                                        className="-striped -highlight"
                                                        previousText="Poprzednia"
                                                        nextText="Następna"
                                                        noDataText="Brak danych"
                                                        loadingText="Pobieranie danych"
                                                        pageText="Strona"
                                                        ofText=" z "
                                                        rowsText="wierszy"
                                                    />
                                                )
                                                :
                                                contentLoader
                                        }
                                    </>
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        );
    }
}

export default ExpertAdminHubSchedules;
