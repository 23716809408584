/* global _api */
import _ from "lodash";
import React, { Component } from "react";
import PropagateLoader from 'react-spinners/PropagateLoader';
import Card from "../../../components/Card/Card.jsx";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import MapCard from "../../../components/Card/MapCard.jsx";
import DirectionsMap from "../../../components/Map/DirectionsMap.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import ExpertElementsListComponent from "../../../components/Expert/ElementsListComponent.jsx"
import Select from "react-select";
import { DebounceInput } from 'react-debounce-input';
import ExpertUploadComponent from "../../../components/Expert/UploadComponent.jsx";
import moment from 'moment';
import {
    Grid,
    Row,
    Col,
    FormGroup,
    FormControl,
    ControlLabel
} from "react-bootstrap";
import { $STATUS2_DOSTARCZONE, $STATUS2_NIEZREALIZOWANE, $STATUS2_NOWE, $STATUS2_NOWEDOTRANSFERU, $STATUS2_ROBOCZE, $STATUS2_WDOSTAWIE, $STATUS2_ZGLOSZONOREKLAMACJE, $STATUS2_ZWROT } from "../../../constants.js";

class ExpertClientOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            uuid: "",
            ident: "",
            userOwner: this.props.user.user || null,
            status: null,
            name: "",
            type: "",
            identExternal: "",
            identExternalVoucher: "",
            discountValue: "",
            postalCode: "",
            cityName: "",
            streetAddress: "",
            streetNumber: "",
            apartmentNumber: "",
            apartmentFloor: "",
            contactPerson: "",
            emailAddress: "",
            mobilePhone: "",
            geoLat: "",
            geoLng: "",
            payer: "",
            fullAddress: "",
            slot: "",
            comment: "",
            cod: "",
            settled: false,
            wasSettled: false,
            express: false,
            transferable: false,
            delayRequest: false,
            withoutDelivery: false,
            allowNoBringing: false,
            elevator: false,
            trash: false,
            extraBringing: false,
            extraAssembly: false,
            calculatedWeight: 0,
            calculatedSize: 0,
            calculatedZone: "",
            calculatedZoneData: {},
            calculatedDistance: "",
            calculatedPriceClient: "",
            manualPriceClient: false,
            calculatedPoints: "1",
            calculateDeliveryFromWarehouse: false,
            availableSlots: [],
            isLoading: false,
            orderLoading: true,
            isAdminView: this.props.isAdminView || false,
            isPreviewOnly: this.props.isPreviewOnly || false,
            currentDay: this.props.selectedDay || moment(),
            nextDaySpan: this.props.nextDaySpan || 31,
            calculatedSlotDelay: 0,
            payerOptions: this.props.user.user.company.configuration.order.payment.payer,
            orderService: [],
            orderElement: [],
            availableSchedule: [],
            currentFiles: [],
            additionalFiles: [],
            children: [],
            commentFleet: "",
            commentExpert: "",
            keepSlot: "",
            schedule: null,
            keepSchedule: "",
            department: this.props.department || this.props.user.user.department.uuid,
            numberOfSpans: this.props.numberOfSpans || this.props.user.user.company.numberOfSpans,
            availableServices: this.props.user.user.company.service || [],
            releaseElements: this.props.user.user.company.configuration.order.additionalFields.hasOwnProperty('releaseElements'),
            warehouse: { value: "other", label: "Inny" },
            warehousePlainText: "",
            warehouseType: "other",
            warehouses: [],
            statusOptions: [
                { value: $STATUS2_ROBOCZE, label: $STATUS2_ROBOCZE },
                { value: $STATUS2_NOWE, label: $STATUS2_NOWE },
                { value: $STATUS2_WDOSTAWIE, label: $STATUS2_WDOSTAWIE },
                { value: $STATUS2_DOSTARCZONE, label: $STATUS2_DOSTARCZONE },
                { value: $STATUS2_NIEZREALIZOWANE, label: $STATUS2_NIEZREALIZOWANE },
                { value: $STATUS2_NOWEDOTRANSFERU, label: $STATUS2_NOWEDOTRANSFERU },
                { value: $STATUS2_ZGLOSZONOREKLAMACJE, label: $STATUS2_ZGLOSZONOREKLAMACJE },
                { value: $STATUS2_ZWROT, label: $STATUS2_ZWROT },
            ],
            availableTypes: [
                { value: "delivery", label: "Dostawa" },
                { value: "assembly", label: "Montaż" }
            ],
            commonWarehouses: [
                { value: "pickup", label: "Odbiór od klienta" },
                { value: "other", label: "Inny" }
            ],

            lastMileWarehouseName: '',
            lastMileWarehouseStreetNumber: '',
            lastMileWarehouseStreetAddress: '',
            lastMileWarehouseCityName: '',
            lastMileWarehousePostalCode: '',
            lastMileWarehouseaPartmentNumber: '',
            lastMileWarehouseaPartmentFloor: '',
            lastMileWarehouseElevator: '',
            lastMileWarehouseContactPerson: '',
            lastMileWarehouseEmailAddress: '',
            lastMileWarehouseMobilePhone: '',
            lastMileWarehouseGeoLat: '',
            lastMileWarehouseGeoLng: '',

            realUserCompanyName: '',
            lastMileWarehouseCompanyUuid: '',
        };
        this.tOutSlot = undefined;
        this.onRefreshOrder = this.onRefreshOrder.bind(this);
    }

    onRefreshOrder() {
        if (this.state.orderElement) {
            this.onElementsChange(this.state.orderElement);
        }
    }

    componentDidMount() {
        let orderGuid = this.props.location.pathname.replace("/client/edit-order/", "").split('?')[0];

        if (!_api.isGuid(orderGuid)) {
            orderGuid = null;
        }

        if (this.props.uuid) {
            orderGuid = this.props.uuid;
        }

        if (orderGuid) {
            if (this.props.userHasRole(['admin', 'expertManager'])) {
                this.setState({ isAdminView: true }, () => { this.loadOrder(orderGuid); });
            } else {
                this.loadOrder(orderGuid);
            }
        } else {
            this.loadSlots(() => { });
            this.loadSchedules(() => { });

            let commonWarehouses = this.hideField("commonWarehouses") ? [] : [{ value: "pickup", label: "Odbiór od klienta" }];
            let warehouses = this.props.user.user.company.warehouses
                ? this.props.user.user.company.warehouses.map((itm) => { return { ...itm, label: itm.name, value: itm.uuid } }).concat(commonWarehouses)
                : [].concat(commonWarehouses);
            let warehouse = warehouses.length ? warehouses[0] : null;
            let warehousePlainText = warehouse ? warehouse.value : "";

            this.setState({
                orderLoading: false,
                commonWarehouses: commonWarehouses,
                warehouses: warehouses,
                warehouse: warehouse,
                warehousePlainText: warehousePlainText,
                warehouseType: warehouses.length > commonWarehouses.length ? "defined" : commonWarehouses.length ? commonWarehouses[0].value : "",
            });
        }

        window.addEventListener("ORDER_REFRESH", this.onRefreshOrder, false);
    }

    componentWillUnmount() {
        _api.abort();
        if (this.tOutSlot) {
            clearTimeout(this.tOutSlot);
        }
        this.props.setNavTitle(null);
        window.removeEventListener("ORDER_REFRESH", this.onRefreshOrder, false);
    }

    parseFloatFixed(value) {
        if (isNaN(parseFloat(value))) {
            return 0;
        }
        return parseFloat(value);
    }

    parseIntFixed(value, radix) {
        if (isNaN(parseInt(value, radix))) {
            return 0;
        }
        return parseInt(value, radix);
    }

    slotIsAvailable(slot) {
        let self = this;
        return (slot.free > 0) && (slot.free - self.state.calculatedPoints >= 0);
    }

    canEditOrder(orderCreated) {
        return true;
        /*
        let self = this;
        let nowMoment = moment();
        let orderCreatedMoment = moment(orderCreated);
        let diffDays = Math.abs(orderCreatedMoment.diff(nowMoment, 'days'));
        let currentDayIndex = moment().day();
        if (diffDays > 1) {
            return false;
        }
        let canEdit = true;
        if (self.state.userOwner.company.configuration && self.state.userOwner.company.configuration.cutoff) {
            self.state.userOwner.company.configuration.cutoff.forEach(item => {
                if (item.day === currentDayIndex) {
                    if (moment().isAfter(moment(item.cutoff, "HH:mm"))) {
                        canEdit = false;
                    }
                }
            });
        }
        return canEdit;
        */
    }

    loadSlots(callback, holdCallback) {
        let self = this;

        let nearestDay = (moment().hour() > 17) ? 2 : 1;

        let currentDayIndex = moment().day();
        let skipDays = [];
        let keepSlot = self.state.keepSlot || "null";
        let calculatedSlotDelay = self.state.calculatedSlotDelay || 0;

        if (self.state.userOwner.company.configuration && self.state.userOwner.company.configuration.cutoff) {
            self.state.userOwner.company.configuration.cutoff.forEach(item => {
                if (!item.availableForDelivery) {
                    skipDays.push(item.day);
                }
                if (item.day === currentDayIndex) {
                    if (moment().isAfter(moment(item.cutoff, "HH:mm"))) {
                        nearestDay = 2;
                    } else {
                        nearestDay = 1;
                    }
                }
            });
        }

        if (self.state.isAdminView || this.state.express) {
            nearestDay = 0;
            skipDays = [];
        } else {
            nearestDay = nearestDay + calculatedSlotDelay;
        }

        let fromToday = moment(self.state.currentDay).add(nearestDay, 'day').format("YYYY-MM-DD");
        let tillDay = moment(self.state.currentDay).add(self.state.nextDaySpan, 'day').format("YYYY-MM-DD");

        if (self.state.isAdminView) {
            if (moment(self.state.currentDay).isAfter()) {
                fromToday = moment().format("YYYY-MM-DD");
                tillDay = moment(self.state.currentDay).add(self.state.nextDaySpan, 'day').format("YYYY-MM-DD");
            }
            if (moment(self.state.currentDay).isBefore()) {
                fromToday = moment(self.state.currentDay).format("YYYY-MM-DD");
                tillDay = moment().add(self.state.nextDaySpan, 'day').format("YYYY-MM-DD");
            }
        }

        _api.request(`/slot/list/${self.state.department}/${fromToday}/${tillDay}/${keepSlot}`, {},
            (data) => {

                let slotsMapping = {};
                let availableSlots = [];

                for (let i = 0; i < data.length; i++) {
                    slotsMapping[data[i].uuid] = data[i];
                }

                let removeSelectedSlot = true;

                for (let i = 0; i < data.length; i++) {
                    let slot = data[i];
                    let labelDay = moment(slot.dayIdent).format("DD.MM.YYYY (dddd)");
                    let realUsed = slot.size - slot.free;
                    let capacity = self.state.isAdminView && !self.state.isPreviewOnly ? "(" + realUsed + " / " + slot.size + ")" : "";
                    let labelHour = slot.dayPart;
                    let includeSlot = false;

                    if (self.props.includeUnavailableSlots || self.state.isAdminView || self.state.isPreviewOnly) {
                        includeSlot = true;
                    } else if (self.state.keepSlot && self.state.keepSlot === slot.uuid) {
                        includeSlot = true;
                    } else if (skipDays.includes(moment(slot.dayIdent).day())) {
                        includeSlot = false;
                    } else if (self.slotIsAvailable(slot)) {
                        if (_.includes(self.state.userOwner.company.configuration.spans, slot.dayPart)) {
                            includeSlot = true;
                        }
                    }
                    if (includeSlot) {
                        availableSlots.push({
                            "value": slot.uuid,
                            "label": labelDay + " " + labelHour + " " + capacity,
                            "free": slot.free,
                            "day": slot.dayIdent
                        });
                        if (self.state.slot && self.state.slot.value) {
                            if (slot.uuid === self.state.slot.value) {
                                removeSelectedSlot = false;
                            }
                        }
                    }
                }

                if (self.state.slot && self.state.slot.value) {
                    if (removeSelectedSlot) {
                        self.props.showNoty('tr', 'error', 'Musisz wybrać inny termin dostawy, nastąpił cut-off');
                        self.setState({ slot: null });
                    }
                }

                self.setState({ availableSlots: availableSlots }, () => { callback() });

                if (!self.state.isAdminView && !self.state.isPreviewOnly) {
                    self.tOutSlot = setTimeout(() => {
                        if (holdCallback) {
                            self.loadSlots(() => { })
                        } else {
                            self.loadSlots(() => { callback() })
                        }
                    }, 5 * 1000);
                }

            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            }
        );
    }

    loadSchedules(callback) {
        let self = this;

        let fromToday = moment(this.state.currentDay).add(1, 'day').format("YYYY-MM-DD");
        let tillDay = moment(this.state.currentDay).add(this.state.nextDaySpan, 'day').format("YYYY-MM-DD");

        if (self.state.isAdminView) {
            if (moment(self.state.currentDay).isAfter()) {
                fromToday = moment().format("YYYY-MM-DD");
                tillDay = moment(self.state.currentDay).add(self.state.nextDaySpan, 'day').format("YYYY-MM-DD");
            }
            if (moment(self.state.currentDay).isBefore()) {
                fromToday = moment(self.state.currentDay).format("YYYY-MM-DD");
                tillDay = moment().add(self.state.nextDaySpan, 'day').format("YYYY-MM-DD");
            }
        }

        _api.request(`/schedule/list/${this.state.department}/${fromToday}/${tillDay}/true/${self.state.keepSchedule}`, {},
            (data) => {
                let tempArray = [];
                if (data) {
                    for (let i = 0; i < data.length; i++) {
                        tempArray.push({ "value": data[i].uuid, "label": data[i].ident });
                    }
                }
                self.setState({ availableSchedule: tempArray }, () => { callback() });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            }
        );
    }

    loadOrder(orderUuid) {
        let self = this;
        self.setState({ uuid: orderUuid, orderLoading: true });
        _api.request(`/order/details/${orderUuid}`, {},
            (data) => {
                let isPreviewOnly = self.state.isPreviewOnly;
                if (isPreviewOnly) {
                    if (data.slot.uuid) {
                        isPreviewOnly = true;
                    } else {
                        isPreviewOnly = false;
                    }
                }
                // odblokowanie możliwości edycji dla osób bez admina
                if (!self.state.isAdminView) {
                    isPreviewOnly = !this.canEditOrder(data.order.created);
                }
                // ---
                self.setState(
                    {
                        userOwner: data.user,
                        department: data.department.uuid || data.user.department.uuid,
                        currentDay: data.slot.dayIdent ? moment(data.slot.dayIdent) : moment(),
                        payerOptions: data.user.company.configuration.order.payment.payer,
                        isPreviewOnly: isPreviewOnly,
                        keepSlot: data.slot.uuid,
                        keepSchedule: data.schedule.uuid
                    },
                    () => {
                        self.loadSlots(
                            () => {
                                self.loadSchedules(
                                    () => {
                                        self.props.setNavTitle(`Edytuj zlecenie - ${data.order.ident}`);
                                        let payer = self.state.payerOptions.filter(itm => itm.value === data.order.payer)[0];
                                        let warehouses = data.user.company.warehouses ? data.user.company.warehouses.map((itm) => { return { ...itm, label: itm.name, value: itm.uuid } }) : [];
                                        warehouses = warehouses.concat(this.state.commonWarehouses);

                                        self.setState(
                                            {
                                                uuid: data.order.uuid,
                                                ident: data.order.ident,
                                                status: null,
                                                trash: data.order.trash || false,
                                                cod: data.order.cod || "",
                                                settled: data.order.settled || false,
                                                wasSettled: data.order.settled || false,
                                                express: data.order.express || false,
                                                transferable: data.order.transferable || false,
                                                delayRequest: data.order.delayRequest || false,
                                                withoutDelivery: data.order.withoutDelivery || false,
                                                allowNoBringing: data.order.allowNoBringing || false,
                                                releaseElements: data.order.releaseElements || false,
                                                identExternal: data.order.identExternal || "",
                                                identExternalVoucher: data.order.identExternalVoucher || "",
                                                discountValue: data.order.discountValue || "",
                                                name: data.address.name || "",
                                                streetNumber: data.address.streetNumber || "",
                                                streetAddress: data.address.streetAddress || "",
                                                cityName: data.address.cityName || "",
                                                postalCode: data.address.postalCode || "",
                                                apartmentNumber: data.address.apartmentNumber || "",
                                                apartmentFloor: data.address.apartmentFloor || "",
                                                elevator: data.address.elevator,
                                                contactPerson: data.address.contactPerson || "",
                                                emailAddress: data.address.emailAddress || "",
                                                mobilePhone: data.address.mobilePhone || "",
                                                geoLat: data.address.geoLat,
                                                geoLng: data.address.geoLng,
                                                payer: payer,
                                                paymentType: payer && payer.paymentType ? payer.paymentType.filter(itm => itm.value === data.order.paymentType)[0] : null,
                                                type: self.state.availableTypes.filter(itm => itm.value === data.order.type)[0],
                                                slot: self.state.availableSlots.filter(itm => itm.value === data.slot.uuid)[0],
                                                schedule: self.state.availableSchedule.filter(itm => itm.value === data.schedule.uuid)[0],
                                                comment: data.order.comment || "",
                                                commentFleet: data.order.commentFleet || "",
                                                commentExpert: data.order.commentExpert || "",
                                                orderService: data.service || [],
                                                orderElement: data.element || [],
                                                currentFiles: data.file || [],
                                                availableServices: data.user.company.service || [],
                                                children: data.order.children || [],
                                                calculatedZone: data.order.zone,
                                                calculatedDistance: data.order.calculatedDistance,
                                                calculatedWeight: data.order.calculatedWeight || 0,
                                                calculatedSize: data.order.calculatedSize || 0,
                                                calculatedPriceClient: data.order.calculatedPriceClient,
                                                manualPriceClient: data.order.manualPriceClient ? true : false,
                                                calculatedPriceFleet: data.order.calculatedPriceFleet,
                                                calculatedPriceAssembler: data.order.calculatedPriceAssembler,
                                                calculatedPriceReal: data.order.calculatedPriceReal,
                                                calculatedPoints: data.order.calculatedPoints,
                                                warehouses: warehouses,
                                                warehouse: warehouses.filter(itm => data.order.warehouse ? itm.value === data.order.warehouse.uuid : itm.value === data.order.warehouseType)[0],
                                                warehouseType: data.order.warehouseType || "other",
                                                warehousePlainText: data.address.warehouse || data.order.warehouse?.name || "",
                                                isLoading: false,
                                                orderLoading: false,

                                                lastMileWarehouseName: data.lastMileWarehouseAddress.name || "",
                                                lastMileWarehouseStreetNumber: data.lastMileWarehouseAddress.streetNumber || "",
                                                lastMileWarehouseStreetAddress: data.lastMileWarehouseAddress.streetAddress || "",
                                                lastMileWarehouseCityName: data.lastMileWarehouseAddress.cityName || "",
                                                lastMileWarehousePostalCode: data.lastMileWarehouseAddress.postalCode || "",
                                                lastMileWarehouseaPartmentNumber: data.lastMileWarehouseAddress.apartmentNumber || "",
                                                lastMileWarehouseaPartmentFloor: data.lastMileWarehouseAddress.apartmentFloor || "",
                                                lastMileWarehouseElevator: data.lastMileWarehouseAddress.elevator,
                                                lastMileWarehouseContactPerson: data.lastMileWarehouseAddress.contactPerson || "",
                                                lastMileWarehouseEmailAddress: data.lastMileWarehouseAddress.emailAddress || "",
                                                lastMileWarehouseMobilePhone: data.lastMileWarehouseAddress.mobilePhone || "",
                                                lastMileWarehouseGeoLat: data.lastMileWarehouseAddress.geoLat,
                                                lastMileWarehouseGeoLng: data.lastMileWarehouseAddress.geoLng,
                                                calculateDeliveryFromWarehouse: data.order.calculateDeliveryFromWarehouse,

                                                realUserCompanyName: data.realUserCompany.name,
                                                lastMileWarehouseCompanyUuid: data.lastMileWarehouseCompany.uuid
                                            },
                                            () => {
                                                self.setDistance(data.order.calculatedDistance, () => {
                                                    self.buildFullAddress(() => {
                                                        setTimeout((self) => {
                                                            self.onRefreshOrder();
                                                            window.dispatchEvent(new CustomEvent('ORDER_LOADED', { 'detail': {} }), true);
                                                        }, 250, this);
                                                    });
                                                });
                                            }
                                        );
                                    }
                                );
                            },
                            true
                        );
                    }
                );
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    buildFullAddress(callback) {

        let {
            postalCode,
            cityName,
            streetAddress,
            streetNumber,
        } = this.state;

        let allParts = (postalCode && cityName && streetAddress && streetNumber) ? true : false;
        let fullAddress = '';
        if (allParts) {
            fullAddress = `${streetAddress} ${streetNumber}, ${postalCode} ${cityName}`;
            if (!cityName?.includes(',')) {
                fullAddress = `${fullAddress}, Polska`;
            }
        }
        this.setState({ fullAddress: fullAddress }, callback);
    }

    setAddress = (data) => {
        this.setState(data, () => {
            this.buildFullAddress();
        });
    }

    onDirectionsChange = (data) => {
        this.setState({ geoLat: data.destinationGeoLat, geoLng: data.destinationGeoLng }, () => {
            this.setDistance(data.distance);
        });
    }

    onDirectionsNotFound = (data) => {
        this.props.showNoty('tr', 'error', 'Podany adres nie został znaleziony');
        this.setState({ fullAddress: '', geoLat: '', geoLng: '' });
    }

    setDistance = (distance, callback) => {
        if (!this.state.userOwner.company.zone) {
            this.props.showNoty('tr', 'error', 'Brak zdefiniowanych stref!!');
            return;
        }
        this.setState({ calculatedDistance: distance }, () => { this.calculateAll(callback); });
    }

    manageService(service, checked, autoRequired) {
        if (!this.state.calculatedDistance) {
            this.props.showNoty('tr', 'error', 'Najpierw zdefiniuj adres dostawy');
            return;
        }
        if (checked) {
            this.setState({
                orderService: this.state.orderService.concat([{ "uuid": service, "quantity": 1, "autoRequired": autoRequired }])
            }, () => { this.calculateAll(); });
        } else {
            this.setState({
                orderService: this.state.orderService.filter(item => { return item.uuid !== service })
            }, () => { this.calculateAll(); });
        }
    }

    manageServiceQuantity(serviceUuid, quantity) {
        if (!this.state.calculatedDistance) {
            this.props.showNoty('tr', 'error', 'Najpierw zdefiniuj adres dostawy');
            return;
        }
        quantity = this.parseIntFixed(quantity, 10);
        if (Number.isNaN(quantity)) {
            quantity = 1;
        }
        const newServices = this.state.orderService.map((service) => {
            if (serviceUuid !== service.uuid) return service;
            let updatedService = { ...service };
            updatedService['quantity'] = quantity;
            return updatedService;
        });
        this.setState({
            orderService: newServices
        }, () => { this.calculateAll(); });
    }

    calculateAll(callback) {

        let distanceLeft = this.state.calculatedDistance;
        let weightLeft = this.state.calculatedWeight;
        let department = this.state.department;
        let clientPrice = 0;
        let fleetPrice = 0;
        let assemblerPrice = 0;
        let realPrice = 0;
        let points = 0;
        let selectedIndex = -1;
        let selectedZone = null;
        let slotDelay = 0;

        if (!this.state.userOwner.company.zone) {
            this.props.showNoty('tr', 'error', 'Brak zdefiniowanych stref dostaw!!');
            return;
        }

        if (this.state.userOwner.company.configuration.zones.mode === "perimeter") {
            for (let i = 0; i < this.state.userOwner.company.zone.length; i++) {
                let currentZone = this.state.userOwner.company.zone[i];
                let perimeter = this.parseFloatFixed(currentZone.perimeter);
                let alreadyUsed = i ? this.parseFloatFixed(this.state.userOwner.company.zone[i - 1].perimeter) : 0;
                let basis;

                if (this.state.calculatedDistance > perimeter) {
                    distanceLeft -= (perimeter - alreadyUsed);
                    basis = (perimeter - alreadyUsed);
                } else {
                    selectedIndex = i;
                    basis = distanceLeft;
                }

                if (this.state.withoutDelivery === false) {
                    clientPrice += this.parseFloatFixed(basis) * this.parseFloatFixed(currentZone.perimeterPriceClient);
                    fleetPrice += this.parseFloatFixed(basis) * this.parseFloatFixed(currentZone.perimeterPriceFleet);
                    assemblerPrice += this.parseFloatFixed(basis) * this.parseFloatFixed(currentZone.perimeterPriceAssembler);
                    realPrice += this.parseFloatFixed(basis) * this.parseFloatFixed(currentZone.perimeterPriceReal);
                }

                if (selectedIndex !== -1) {
                    break;
                }
            }
        }

        else if (this.state.userOwner.company.configuration.zones.mode === "weightPostalCode") {
            for (let i = 0; i < this.state.userOwner.company.zone.length; i++) {
                let currentZone = this.state.userOwner.company.zone[i];
                let maxWeight = this.parseFloatFixed(currentZone.weight);

                if (weightLeft <= maxWeight) {
                    if (currentZone.postalCode.includes(this.state.postalCode)) {
                        selectedIndex = i;
                    }
                }

                if (selectedIndex !== -1) {
                    break;
                }
            }
        }

        else if (this.state.userOwner.company.configuration.zones.mode === "postalCodeOnly") {
            for (let i = 0; i < this.state.userOwner.company.zone.length; i++) {
                let currentZone = this.state.userOwner.company.zone[i];
                if (currentZone.postalCode.includes(this.state.postalCode)) {
                    selectedIndex = i;
                }
                if (selectedIndex !== -1) {
                    break;
                }
            }
        }

        if (selectedIndex === -1) {
            this.props.showNoty('tr', 'error', 'Podany adres wykracza poza strefy dostaw');
            this.setState(
                {
                    calculatedZone: null,
                    calculatedZoneData: null,
                    calculatedPriceClient: (this.state.manualPriceClient) ? this.state.calculatedPriceClient : clientPrice,
                    calculatedPriceFleet: fleetPrice,
                    calculatedPriceAssembler: assemblerPrice,
                    calculatedPriceReal: realPrice,
                    calculatedPoints: points
                },
                callback
            );
            return;
        }

        if (this.state.discountValue) {
            clientPrice -= this.parseFloatFixed(this.state.discountValue);
        }

        selectedZone = this.state.userOwner.company.zone[selectedIndex];
        points = this.parseIntFixed(selectedZone.points, 10);
        slotDelay = this.parseIntFixed(selectedZone.slotDelay, 10);

        if (this.state.withoutDelivery === false) {
            clientPrice += this.parseFloatFixed(selectedZone.basePriceClient);
            fleetPrice += this.parseFloatFixed(selectedZone.basePriceFleet);
            realPrice += this.parseFloatFixed(selectedZone.basePriceReal);
        }

        if (selectedZone.lastMileWarehouse.department?.uuid) {
            department = selectedZone.lastMileWarehouse.department?.uuid;
        }

        if (this.state.availableServices && this.state.availableServices.length) {
            for (let i = 0; i < this.state.availableServices.length; i++) {
                if (this.state.orderService.some(s => s.uuid === this.state.availableServices[i].uuid)) {
                    let orderServiceParams = this.state.orderService.find(s => s.uuid === this.state.availableServices[i].uuid);
                    let serviceQuantity = this.parseIntFixed(orderServiceParams.quantity, 10);
                    if (orderServiceParams.autoRequired && this.state.allowNoBringing) {
                        clientPrice += this.parseFloatFixed(this.state.availableServices[i].fallbackPriceReal) * serviceQuantity;
                        fleetPrice += this.parseFloatFixed(this.state.availableServices[i].fallbackPriceReal) * serviceQuantity;
                        assemblerPrice += this.parseFloatFixed(this.state.availableServices[i].fallbackPriceReal) * serviceQuantity;
                        realPrice += this.parseFloatFixed(this.state.availableServices[i].fallbackPriceReal) * serviceQuantity;
                    } else {
                        clientPrice += this.parseFloatFixed(this.state.availableServices[i].basePriceClient) * serviceQuantity;
                        fleetPrice += this.parseFloatFixed(this.state.availableServices[i].basePriceFleet) * serviceQuantity;
                        assemblerPrice += this.parseFloatFixed(this.state.availableServices[i].basePriceAssembler) * serviceQuantity;
                        realPrice += this.parseFloatFixed(this.state.availableServices[i].basePriceReal) * serviceQuantity;
                    }
                    points += this.parseIntFixed(this.state.availableServices[i].points, 10) * serviceQuantity;
                }
            }
        }

        if (this.state.type?.value === "assembly" && this.state.orderElement.length) {
            for (let i = 0; i < this.state.orderElement.length; i++) {
                if (this.state.orderElement[i].value && this.state.orderElement[i].percentStakeAssembler) {
                    assemblerPrice += this.parseFloatFixed(this.state.orderElement[i].value) * (this.parseIntFixed(this.state.orderElement[i].percentStakeAssembler, 10) * 0.01);
                }
            }

            if (assemblerPrice < 100) { // minumum stake for assembly
                assemblerPrice = 100;
            }

            assemblerPrice += this.parseFloatFixed(selectedZone.basePriceAssembler);

            clientPrice = assemblerPrice;
        }

        if (this.state.realUserCompanyName === 'MediaMarkt [NET]') {
            _api.request(`/order/perimeter-rates/${this.state.lastMileWarehouseCompanyUuid}/${distanceLeft}`,
                {},
                (data) => {
                    clientPrice += this.parseFloatFixed(data.prices.priceClient);
                    fleetPrice += this.parseFloatFixed(data.prices.priceFleet);
                    realPrice += this.parseFloatFixed(data.prices.priceReal);
                    points += this.parseIntFixed(data.points);
                },
                (error) => {
                    this.props.showNoty('tr', 'error', 'Błąd podczas wczytywania danych');
                },
                () => {},
                'GET',
                false
            );
        }

        this.setState({
            calculatedZone: selectedZone.name,
            calculatedZoneData: selectedZone,
            calculatedSlotDelay: slotDelay,
            calculatedPriceClient: (this.state.manualPriceClient) ? this.state.calculatedPriceClient : Math.round(this.parseFloatFixed(clientPrice)).toFixed(2),
            calculatedPriceFleet: Math.round(this.parseFloatFixed(fleetPrice)).toFixed(2),
            calculatedPriceAssembler: Math.round(this.parseFloatFixed(assemblerPrice)).toFixed(2),
            calculatedPriceReal: this.parseFloatFixed(realPrice).toFixed(2),
            calculatedPoints: points,
            department: department,
        }, callback);

    }

    validateDelayRequest(callback, verify) {

        // https://3.basecamp.com/4403908/buckets/15514837/todos/3556902786

        if (!this.state.userOwner.company.configuration.order.additionalFields.delayRequest) {
            callback();
            return true;
        }

        if (!this.state.slot) {
            callback();
            return true;
        }

        if (this.state.type?.value === "assembly") {
            callback();
            return true;
        }

        let nowDate = moment();
        let before18 = moment().isBefore(moment("18:00", "HH:mm"));
        let thenDate = moment(this.state.slot.day);
        let theDiff = thenDate.diff(nowDate, 'days') + 1;
        let maxDaySpan = 0;

        switch (nowDate.day()) {

            case 0: // niedziela
                if (before18) {
                    maxDaySpan = 2;
                } else {
                    maxDaySpan = 3;
                }
                break;

            case 1: // poniedziałek
                if (before18) {
                    maxDaySpan = 2;
                } else {
                    maxDaySpan = 3;
                }
                break;

            case 2: // wtorek
                if (before18) {
                    maxDaySpan = 2;
                } else {
                    maxDaySpan = 3;
                }
                break;


            case 3: // środa
                if (before18) {
                    maxDaySpan = 2;
                } else {
                    maxDaySpan = 3;
                }
                break;

            case 4: // czwartek
                if (before18) {
                    maxDaySpan = 2;
                } else {
                    maxDaySpan = 4;
                }
                break;

            case 5: // piątek
                if (before18) {
                    maxDaySpan = 3;
                } else {
                    maxDaySpan = 4;
                }
                break;

            case 6: // sobota
                if (before18) {
                    maxDaySpan = 3;
                } else {
                    maxDaySpan = 4;
                }
                break;

            default: // default
                maxDaySpan = 3;
                break;
        }

        // https://3.basecamp.com/4403908/buckets/15514837/todos/3745008560

        if (theDiff <= maxDaySpan) {
            if (this.state.delayRequest) {
                this.props.showNoty('tr', 'error', 'Musisz odznaczyć opcję "Termin na życzenie klienta"');
                return false;
            }
            callback();
            return true;
        }

        // https://3.basecamp.com/4403908/buckets/15514837/todos/4161888313#__recording_4161903050
        else {
            verify(callback);
            return true;
        }

        /*
        else if (theDiff <= maxDaySpan + 2) {
            verify(callback);
            return true;
        }

        else { // theDiff > maxDaySpan + 2
            if (!this.state.delayRequest) {
                this.props.showNoty('tr', 'error', 'Musisz zaznaczyć opcję "Termin na życzenie klienta"');
                return false;
            }
            callback();
            return true;
        }
        */

    }

    saveData() {
        let self = this;
        self.setState({ isLoading: true });

        if (self.state.isPreviewOnly) {
            self.props.showNoty('tr', 'info', 'Aktualizacja danych podstawowych..');
            _api.request(`/order/postprocessing/${self.state.uuid}`,
                {
                    "order": {
                        "comment": self.state.comment
                    }
                },
                (data) => {
                    self.setState({ isLoading: false });
                    self.props.showNoty('tr', 'success', 'Dane zostały zaktualizowane..');
                },
                (error) => {
                    self.setState({ isLoading: false });
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisu');
                },
                (progress) => {
                    self.props.setProgress(progress);
                }
            );
            return;
        }

        let validationRules = {
            "isValid": true,
            "items": [
                { "field": "name", "rules": { "empty": "Uzupełnij nazwę odbiorcy" } },
                { "field": "streetAddress", "rules": { "empty": "Uzupełnij ulicę, numer domu i miasto" } },
                { "field": "mobilePhone", "rules": { "empty": "Uzupełnij numer telefonu" } },
                { "field": "payer", "rules": { "empty": "Wybierz rodzaj płatności" } },
                { "field": "calculatedDistance", "rules": { "empty": "Zweryfikuj adres dostawy (dystans nie został obliczony)" } },
                { "field": "calculatedZone", "rules": { "empty": "Zweryfikuj adres dostawy (strefa nie została przypisana)" } },
                { "field": "calculatedPriceClient", "rules": { "empty": "Zweryfikuj dane (kwota nie została obliczona)" } },
            ]
        };

        if (!self.hideField("warehouse")) {
            validationRules.items.push({ "field": "warehouse", "rules": { "empty": "Podaj magazyn" } })
            validationRules.items.push({ "field": "warehousePlainText", "rules": { "empty": "Podaj magazyn" } })
        }

        if (self.state.userOwner.company.configuration.order.additionalValidation) {
            validationRules.items.concat(self.state.userOwner.company.configuration.order.additionalValidation);
        }

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = self.state[element.field];
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
            }
        });

        if (!validationRules.isValid) {
            self.setState({ isLoading: false });
            return;
        }

        self.props.showNoty('tr', 'info', 'Zapisywanie zlecenia..');

        let endpoint = self.state.uuid ? `/order/update/${self.state.uuid}` : `/order/create`;

        _api.request(endpoint,
            {
                "files": self.state.additionalFiles,
                "order": {
                    "identExternal": self.state.identExternal,
                    "identExternalVoucher": self.state.identExternalVoucher,
                    "discountValue": self.state.discountValue,
                    "type": self.state.type ? self.state.type?.value : null,
                    "status": self.state.status ? self.state.status.value : null,
                    "payer": self.state.payer ? self.state.payer.value : null,
                    "comment": self.state.comment,
                    "cod": self.state.cod,
                    "express": self.state.express,
                    "transferable": self.state.transferable,
                    "settled": self.state.settled,
                    "delayRequest": self.state.delayRequest,
                    "withoutDelivery": self.state.withoutDelivery,
                    "allowNoBringing": self.state.allowNoBringing,
                    "releaseElements": self.state.releaseElements,
                    "trash": self.state.trash,
                    "calculatedPriceClient": self.state.calculatedPriceClient,
                    "manualPriceClient": self.state.manualPriceClient,
                    "calculatedPriceFleet": self.state.calculatedPriceFleet,
                    "calculatedPriceAssembler": self.state.calculatedPriceAssembler,
                    "calculatedPriceReal": self.state.calculatedPriceReal,
                    "calculatedPoints": self.state.calculatedPoints,
                    "calculatedDistance": self.state.calculatedDistance,
                    "calculatedWeight": self.state.calculatedWeight,
                    "calculatedSize": self.state.calculatedSize,
                    "commentFleet": self.state.commentFleet,
                    "commentExpert": self.state.commentExpert,
                    "paymentType": self.state.paymentType ? self.state.paymentType.value : null,
                    "warehouse": {
                        "uuid": self.state.warehouse
                            && !self.state.commonWarehouses.find(itm => itm.value === self.state.warehouse.value)
                            ? self.state.warehouse.value
                            : null
                    },
                    "warehouseType": self.state.warehouseType
                },
                "address": {
                    "name": self.state.name,
                    "streetAddress": self.state.streetAddress,
                    "streetNumber": self.state.streetNumber,
                    "cityName": self.state.cityName,
                    "postalCode": self.state.postalCode,
                    "apartmentNumber": self.state.apartmentNumber,
                    "apartmentFloor": self.state.apartmentFloor,
                    "warehouse": self.state.warehousePlainText,
                    "elevator": self.state.elevator,
                    "contactPerson": self.state.contactPerson,
                    "emailAddress": self.state.emailAddress,
                    "mobilePhone": self.state.mobilePhone,
                    "geoLat": self.state.geoLat,
                    "geoLng": self.state.geoLng
                },
                "service": self.state.orderService,
                "element": self.state.orderElement,
                "slot": {
                    "uuid": self.state.slot?.value || null
                },
                "schedule": {
                    "uuid": (self.state.schedule) ? self.state.schedule.value : null
                },
                "zone": {
                    "uuid": (self.state.calculatedZoneData) ? self.state.calculatedZoneData.uuid : null
                },
            },
            (data) => {
                self.setState({
                    isLoading: false,
                    uuid: data.uuid,
                    ident: data.ident
                });
                self.popupSaved();
                self.props.setNavTitle(`Nowe zlecenie - ${data.ident}`);
                self.props.showNoty('tr', 'success', 'Zlecenie zostało zapisane');
                window.dispatchEvent(new CustomEvent('ORDER_SAVED', { 'detail': { 'status': true } }), true);
            },
            (error) => {
                self.setState({ isLoading: false });
                switch (error) {
                    case 'E_SLOT_EXHAUSTED':
                        self.props.showNoty('tr', 'error', 'Ten slot jest wypełniony, wybierz inny przedział czasowy');
                        break;

                    case 'E_POSTAL_CODE_CITY_NAME_MISMATCH':
                        self.props.showNoty('tr', 'error', 'Nazwa miejscowości nie jest zgodna z podanym kodem pocztowym');
                        break;

                    default:
                        self.popupError();
                        break;
                }
                window.dispatchEvent(new CustomEvent('ORDER_SAVED', { 'detail': { 'status': false, 'details': error } }), true);
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.isAdminView) {
            if (!this.state.slot) {
                if (this.state.userOwner.company.configuration.allowEmptyDate) {
                    //...
                } else if (this.state.userOwner.company.configuration.forceEmptyDate) {
                    // ...
                } else {
                    this.props.showNoty('tr', 'error', 'Musisz wybrać przedział czasowy');
                    return;
                }
            }
            if (this.state.slot && this.state.slot.value !== this.state.keepSlot) {
                if (this.state.slot.free - this.state.calculatedPoints < 0) {
                    this.props.showNoty('tr', 'error', 'To zamówienie przekracza pojemność slotu, wybierz inny przedział czasowy');
                    return;
                }
            }
        }
        let saveFn = () => { this.saveData() };
        let verifyFn = () => { this.popupDelayVerify(saveFn) }
        this.validateDelayRequest(saveFn, verifyFn);
    }

    onOrderSaved() {
        window.location.href = _api.config.app + `#/client/edit-order/${this.state.uuid}`;
    }

    popupSaved() {

        const onSavedAndHide = this.props.onSavedAndHide || this.showOrders.bind(this);
        const onSavedAndStay = this.props.onSavedAndStay || this.onOrderSaved.bind(this);

        this.setState({
            alert: (
                <SweetAlert
                    success
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Zlecenie zapisane"
                    cancelBtnText="Zamknij"
                    onCancel={() => { this.setState({ alert: null }); onSavedAndHide(); }}
                    confirmBtnText="Przejdź do edycji zlecenia"
                    onConfirm={() => { this.setState({ alert: null }); onSavedAndStay(); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });

    }

    popupError() {
        this.setState({
            alert: (
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Wystąpił błąd podczas zapisu zlecenia"
                    confirmBtnText="Zamknij"
                    confirmBtnBsStyle="default"
                    onConfirm={() => this.setState({ alert: null })}>
                </SweetAlert>
            )
        });
    }

    popupDelayVerify(callback) {
        this.setState({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-196px" }}
                    title="Czy jest to termin na życzenie klienta?"
                    confirmBtnText="Tak"
                    cancelBtnText="Nie"
                    onConfirm={() => { this.setState({ alert: null, delayRequest: true }, callback); }}
                    onCancel={() => { this.setState({ alert: null, delayRequest: false }, callback); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });
    }

    showOrders() {
        this.setState({ alert: null });
        this.props.history.push('/client/orders');
    }

    onElementsChange = (elements) => {
        let calculatedWeight = 0;
        let calculatedSize = 0;
        let includeServiceUuid = null
        if (elements.length) {
            let weights = elements.map(item => item.trash ? 0 : parseFloat(item.weight) || 0);
            calculatedWeight = weights.reduce((prev, next) => prev + next) || 0;
            let sizes = elements.map(item => item.trash ? 0 : parseFloat(item.size) || 0);
            calculatedSize = sizes.reduce((prev, next) => prev + next) || 0;
        }
        if (this.state.availableServices && this.state.availableServices.length > 0) {
            for (let i = 0; i < this.state.availableServices.length; i++) {
                let service = this.state.availableServices[i];
                if (!service.autoRequired) {
                    continue;
                }
                if (this.parseFloatFixed(service.weight) < calculatedWeight) {
                    continue;
                }
                if (this.parseFloatFixed(service.size) < calculatedSize) {
                    continue;
                }
                includeServiceUuid = service.uuid;
                break;
            }
        }
        this.setState({
            orderElement: elements,
            calculatedWeight: calculatedWeight,
            calculatedSize: calculatedSize
        }, () => {
            if (includeServiceUuid) {
                this.setState({
                    orderService: this.state.orderService.filter(item => { return !item.autoRequired })
                }, () => {
                    this.manageService(includeServiceUuid, true, true)
                });
            } else {
                this.calculateAll()
            }
        });
    }

    renameField = (ident, defaultValue) => {
        let fieldTranslation = this.state.userOwner.company.configuration.order.renameFields[ident];
        return fieldTranslation ? fieldTranslation.label : defaultValue;
    }

    hideField = (ident) => {
        return this.state.userOwner.company.configuration.order.hideFields.includes(ident)
    }

    getConfigPart = (ident) => {
        return this.state.userOwner.company.configuration[ident];
    }

    useAdditionalField = (useFields) => {

        let addonsFields = this.state.userOwner.company.configuration.order.additionalFields;
        return useFields.map(fld => {
            if (fld in addonsFields) {
                if (addonsFields[fld].for) {
                    if (!this.props.userHasRole(addonsFields[fld].for)) {
                        return null;
                    }
                }

                switch (addonsFields[fld].type) {
                    case "select":
                        return (
                            <fieldset key={"additional-" + fld}>
                                <FormGroup>
                                    <ControlLabel>{addonsFields[fld].label}:</ControlLabel>
                                    <Col>
                                        <Select
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={addonsFields[fld].options.filter(itm => itm.value === this.state[fld])[0]}
                                            isDisabled={this.state.isPreviewOnly}
                                            placeholder="Wybierz.."
                                            onChange={event =>
                                                this.setState({ [fld]: event.value })
                                            }
                                            options={addonsFields[fld].options}
                                        />
                                    </Col>
                                </FormGroup>
                            </fieldset>
                        );

                    case "express":
                        return (
                            <fieldset key={"additional-express"}>
                                <FormGroup>
                                    <Checkbox
                                        number="express"
                                        label="Express"
                                        checked={this.state.express}
                                        onChange={event =>
                                            this.setState({ express: event.target.checked })
                                        }
                                    />
                                </FormGroup>
                            </fieldset>
                        );

                    case "releaseElements":
                        return (
                            <fieldset key={"additional-releaseElements"}>
                                <FormGroup>
                                    <Checkbox
                                        number="releaseElements"
                                        label={addonsFields[fld].label}
                                        checked={this.state.releaseElements}
                                        onChange={event =>
                                            this.setState({ releaseElements: event.target.checked })
                                        }
                                    />
                                </FormGroup>
                            </fieldset>
                        );

                    default:
                        return (
                            <fieldset key={"additional-" + fld}>
                                <FormGroup>
                                    <ControlLabel>{addonsFields[fld].label}:</ControlLabel>
                                    <FormControl
                                        type="text"
                                        placeholder="(opcjonalnie)"
                                        value={this.state[fld]}
                                        disabled={this.state.isPreviewOnly}
                                        onChange={event =>
                                            this.setState({ [fld]: event.target.value })
                                        }
                                    />
                                </FormGroup>
                            </fieldset>
                        );
                }
            }
            return null;
        });
    }

    getFilesList = () => {
        let filesList = [];
        let additionalFiles = [];
        let allFiles = [];
        for (let i = 0; i < this.state.additionalFiles.length; i++) {
            additionalFiles.push({ name: this.state.additionalFiles[i].name });
        }
        allFiles = this.state.currentFiles.concat(additionalFiles);
        for (let i = 0; i < allFiles.length; i++) {
            let elem = (<><i className="fa fa-file" /> {allFiles[i].name}</>);
            if (allFiles[i].url) {
                elem = (<a href={allFiles[i].url} target="_blank" rel="noopener noreferrer">{elem}</a>);
            }
            filesList.push(<p key={"file" + i}>{elem}</p>);
        }
        if (allFiles.filter(itm => itm.url !== '').length) {
            filesList.push(<p key={"file-all"}><a href={`${_api.config.url}link/zip/${this.state.uuid}?token=${_api.session.access_token}`} target="_blank" rel="noopener noreferrer"><i className="fa fa-file-archive-o" /> Pobierz wszystkie</a></p>);
        }
        return filesList;
    }

    generatePrinterLink() {
        let self = this;

        self.props.showNoty('tr', 'info', 'Generowanie dokumentów..');
        self.setState({ pdfIsGenerating: true });
        _api.request(`/admin/order/pdf`,
            {
                mode: "ordersList",
                orders: [{ "uuid": self.state.uuid }]
            },
            (data) => {
                self.setState({ pdfIsGenerating: false });
                if (data.url) {
                    window.open(data.url);
                }
            },
            (error) => {
                self.setState({ pdfIsGenerating: false });
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    generateAssemblyCopyPopup() {
        this.setState({
            alert: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz utworzyć zlecenie montażu?"
                    confirmBtnText="Utwórz zlecenie montażu"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ alert: null }); this.generateAssemblyCopy(); }}
                    onCancel={() => { this.setState({ alert: null }); }}
                    confirmBtnBsStyle="info">
                    Zostanie wykonany duplikat zlecenia, bez przypisanego slotu czasowego. Przed wykonaniem operacji, zapisz aktualne zlecenie.
                </SweetAlert>
            )
        });
    }

    generateAssemblyCopy() {
        let self = this;
        self.props.showNoty('tr', 'info', `Kopiowanie zlecenia ${self.state.ident}`);
        _api.request(`/order/duplicate/${self.state.uuid}`,
            {
                "order": {
                    "type": "assembly"
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', `Numer nowego zlecenia: ${data.ident}`);
                self.openAssemblyCopy(data.uuid);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    openAssemblyCopy(uuid) {
        setTimeout(() => {
            window.location.href = _api.config.app + `#/client/edit-order/${uuid}`;
        }, 600);
    }

    render() {

        const filesList = this.getFilesList();

        let directionsFrom = this.state.userOwner.company.address.streetAddress;
        let directionsCenter = { lat: parseFloat(this.state.userOwner.company.address.geoLat) || 52.237049, lng: parseFloat(this.state.userOwner.company.address.geoLng) || 21.017532 };

        if (
            this.state.realUserCompanyName === 'MediaMarkt [NET]' ||
            (this.state.calculateDeliveryFromWarehouse &&
            this.state.lastMileWarehouseStreetAddress &&
            this.state.lastMileWarehouseStreetNumber &&
            this.state.lastMileWarehousePostalCode &&
            this.state.lastMileWarehouseCityName &&
            this.state.lastMileWarehouseGeoLat &&
            this.state.lastMileWarehouseGeoLng)
        ) {
            const streetAddress = this.state.lastMileWarehouseStreetAddress;
            const streetNumber = this.state.lastMileWarehouseStreetNumber;
            const postalCode = this.state.lastMileWarehousePostalCode;
            const cityName = this.state.lastMileWarehouseCityName;
            directionsFrom = `${streetAddress} ${streetNumber}, ${postalCode} ${cityName}`;
            directionsCenter = { lat: parseFloat(this.state.lastMileWarehouseGeoLat) || 52.237049, lng: parseFloat(this.state.lastMileWarehouseGeoLng) || 21.017532 };
        }

        const orderLoading = (
            <div className="main-content">
                <div style={{ "paddingBottom": "26px" }}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        css={{
                            "display": "block",
                            "margin": "10px auto",
                            "width": "1px"
                        }}
                        color={'#777'}
                        loading={true}
                    />
                </div>
            </div>
        );

        const elementsList = (
            <ExpertElementsListComponent
                elements={this.state.orderElement ? this.state.orderElement : []}
                listType={this.state.userOwner.company.configuration.order.element.type}
                companyIndustry={this.state.userOwner.company.industry}
                assemblyMode={this.state.type && this.state.type.value === "assembly"}
                showNoty={this.props.showNoty}
                setProgress={this.props.setProgress}
                onElementsChange={this.onElementsChange}
                isPreviewOnly={this.state.isPreviewOnly}
            />
        );

        const printerButton = this.state.uuid && (
            <Button
                onClick={() => { this.generatePrinterLink() }}
                bsStyle="primary"
                fill wd
                icon>
                <i className="fa fa-print" /> Wydrukuj
            </Button>
        )

        const assemblyItems = this.state.children.filter((subOrder) => { return subOrder.type === 'assembly' });

        const assemblyButton =
            (
                this.state.uuid
                && (!this.state.type || this.state.type?.value !== 'assembly')
                && this.state.userOwner.company.configuration.order.buttons.includes("assembly")
            )
                ?
                assemblyItems.length === 0
                    ?
                    (
                        <Button
                            onClick={() => { this.generateAssemblyCopyPopup() }}
                            bsStyle="default"
                            fill wd
                            icon>
                            <i className="fa fa-wrench" /> <>Nowe zlecenie montażu</>
                        </Button>
                    )
                    :
                    (
                        <Button
                            onClick={() => { this.openAssemblyCopy(assemblyItems[0].uuid) }}
                            bsStyle="default"
                            fill wd
                            icon>
                            <i className="fa fa-wrench" /> <>Zobacz zlecenie montażu</>
                        </Button>
                    )
                :
                null;

        const typeSwitch = this.state.userOwner.company.configuration.order.buttons.includes("assembly") && (
            <FormGroup>
                <ControlLabel>Rodzaj usługi:</ControlLabel>
                <Col>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        value={this.state.type || this.state.availableTypes[0]}
                        isDisabled={this.state.isPreviewOnly}
                        placeholder="Wybierz.."
                        onChange={value =>
                            this.setState({ type: value, withoutDelivery: value.value === "assembly" }, () => { this.calculateAll(); })
                        }
                        options={this.state.availableTypes}
                    />
                </Col>
            </FormGroup>
        )

        const adminToolkitPrice = this.state.isAdminView && (
            <div>
                <FormGroup>
                    <Checkbox
                        number="manualPriceClient"
                        label="Zatrzymaj automatyczną kalkulację"
                        checked={this.state.manualPriceClient}
                        onChange={event =>
                            this.setState({ manualPriceClient: event.target.checked }, () => { if (!this.state.manualPriceClient) { this.calculateAll() } })
                        }
                    />
                </FormGroup>
            </div>
        );

        const adminToolkit = this.state.isAdminView && (
            <div>
                <FormGroup>
                    <ControlLabel>Obliczone punkty:</ControlLabel>
                    <FormControl
                        type="text"
                        name="calculatedPoints"
                        value={this.state.calculatedPoints || "0"}
                        onChange={
                            event => {
                                let value = event.target.value.replace(",", ".");
                                this.setState({ calculatedPoints: value })
                            }
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Przypisany harmonogram:</ControlLabel>
                    <Col>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="schedule"
                            value={this.state.schedule}
                            placeholder="Wybierz.."
                            onChange={value =>
                                this.setState({ schedule: value })
                            }
                            options={this.state.availableSchedule}
                            isClearable={true}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Ustaw nowy status:</ControlLabel>
                    <Col>
                        <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            value={this.state.status}
                            placeholder="Wybierz.."
                            onChange={value =>
                                this.setState({ status: value })
                            }
                            options={this.state.statusOptions}
                        />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Notatka EXPERT:</ControlLabel>
                    <textarea
                        className="form-control"
                        value={this.state.commentExpert}
                        rows={3}
                        onChange={event =>
                            this.setState({ commentExpert: event.target.value })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Notatka Floty:</ControlLabel>
                    <textarea
                        className="form-control"
                        value={this.state.commentFleet}
                        rows={3}
                        onChange={event =>
                            this.setState({ commentFleet: event.target.value })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <Checkbox
                        number="settled"
                        label="Zlecenie zostało rozliczone"
                        checked={this.state.settled}
                        disabled={this.state.wasSettled}
                        onChange={event =>
                            this.setState({ settled: event.target.checked })
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Załączone pliki:</ControlLabel>
                    <ExpertUploadComponent onSelectedFiles={files => { this.setState({ additionalFiles: files }) }} />
                </FormGroup>
                {filesList}
                <fieldset className="pull-right text-danger">
                    <FormGroup>
                        <Checkbox
                            number="trash"
                            label="Anuluj zlecenie"
                            checked={this.state.trash}
                            onChange={event =>
                                this.setState({ trash: event.target.checked })
                            }
                        />
                    </FormGroup>
                </fieldset>
            </div>
        );

        const paymentTypeOptions = this.state.payer && this.state.payer.paymentType && (
            <FormGroup>
                <ControlLabel>Opcje płatności:</ControlLabel>
                <Col>
                    <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        placeholder="Wybierz.."
                        value={this.state.paymentType}
                        onChange={object =>
                            this.setState({ paymentType: object })
                        }
                        options={this.state.payer.paymentType}
                        isDisabled={this.state.isPreviewOnly}
                    />
                </Col>
            </FormGroup>
        );

        const paymentTypeVoucher = this.state.payer && this.state.paymentType && this.state.paymentType.useVouchers && (
            <div>
                <FormGroup>
                    <ControlLabel>Numer bonu:</ControlLabel>
                    <FormControl
                        type="text"
                        name="identExternalVoucher"
                        value={this.state.identExternalVoucher}
                        disabled={this.state.isPreviewOnly}
                        placeholder="..."
                        onChange={
                            event => {
                                let value = event.target.value.replace(",", ".");
                                this.setState({ identExternalVoucher: value })
                            }
                        }
                    />
                </FormGroup>
                <FormGroup>
                    <ControlLabel>Wartość bonu:</ControlLabel>
                    <FormControl
                        type="text"
                        name="discountValue"
                        value={this.state.discountValue}
                        disabled={this.state.isPreviewOnly}
                        placeholder="..."
                        onChange={
                            event => {
                                let value = event.target.value.replace(",", ".");
                                this.setState({ discountValue: value }, () => { this.calculateAll() })
                            }
                        }
                    />
                </FormGroup>
            </div>
        );

        const calculatedZoneUnit = this.state.userOwner.company.configuration.zones.mode === "perimeter" ?
            (
                <FormGroup>
                    <ControlLabel>Liczba kilometrów:</ControlLabel>
                    <FormControl
                        type="text"
                        disabled={true}
                        value={this.state.calculatedDistance}
                        onChange={
                            event => {
                                let value = event.target.value.replace(",", ".");
                                this.setDistance(value);
                            }
                        }
                    />
                </FormGroup>
            ) : (
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <ControlLabel>Łączna&nbsp;waga:</ControlLabel>
                            <FormControl
                                type="text"
                                disabled={true}
                                value={this.parseFloatFixed(this.state.calculatedWeight).toFixed(2)}
                                onChange={e => { e.preventDefault() }}
                            />
                        </FormGroup>
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <ControlLabel>Łączna&nbsp;obj.:</ControlLabel>
                            <FormControl
                                type="text"
                                disabled={true}
                                value={this.parseFloatFixed(this.state.calculatedSize).toFixed(2)}
                                onChange={e => { e.preventDefault() }}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            );

        const orderLoaded = (
            <div className="main-content">
                {this.state.alert}
                <Grid fluid>
                    <Row>
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            <Col md={6}>
                                <Card
                                    title="Dane odbiorcy"
                                    content={
                                        <div>
                                            <FormGroup>
                                                <ControlLabel>Imię i nazwisko lub nazwa firmy:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    value={this.state.name}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={event =>
                                                        this.setState({ name: event.target.value })
                                                    }
                                                />
                                            </FormGroup>
                                            <Row>
                                                <Col md={4}>
                                                    <FormGroup>
                                                        <ControlLabel>Kod pocztowy:</ControlLabel>
                                                        <DebounceInput
                                                            className="form-control"
                                                            minLength={2}
                                                            debounceTimeout={10000}
                                                            type="text"
                                                            value={this.state.postalCode}
                                                            disabled={this.state.isPreviewOnly}
                                                            onChange={event =>
                                                                this.setAddress({ postalCode: event.target.value })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={8}>
                                                    <FormGroup>
                                                        <ControlLabel>Miasto:</ControlLabel>
                                                        <DebounceInput
                                                            className="form-control"
                                                            minLength={2}
                                                            debounceTimeout={10000}
                                                            type="text"
                                                            value={this.state.cityName}
                                                            disabled={this.state.isPreviewOnly}
                                                            onChange={event =>
                                                                this.setAddress({ cityName: event.target.value })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md={6}>
                                                    <FormGroup>
                                                        <ControlLabel>Ulica:</ControlLabel>
                                                        <DebounceInput
                                                            className="form-control"
                                                            minLength={2}
                                                            debounceTimeout={10000}
                                                            type="text"
                                                            value={this.state.streetAddress}
                                                            disabled={this.state.isPreviewOnly}
                                                            onChange={event =>
                                                                this.setAddress({ streetAddress: event.target.value })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>Nr domu:</ControlLabel>
                                                        <DebounceInput
                                                            className="form-control"
                                                            minLength={1}
                                                            debounceTimeout={10000}
                                                            type="text"
                                                            value={this.state.streetNumber}
                                                            disabled={this.state.isPreviewOnly}
                                                            onChange={event =>
                                                                this.setAddress({ streetNumber: event.target.value })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col md={3}>
                                                    <FormGroup>
                                                        <ControlLabel>Nr lokalu:</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            placeholder="(opcjonalnie)"
                                                            value={this.state.apartmentNumber}
                                                            disabled={this.state.isPreviewOnly}
                                                            onChange={event =>
                                                                this.setState({ apartmentNumber: event.target.value })
                                                            }
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup>
                                                <ControlLabel>Osoba kontaktowa:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    placeholder="(opcjonalnie)"
                                                    value={this.state.contactPerson}
                                                    onChange={event =>
                                                        this.setState({ contactPerson: event.target.value })
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>E-mail:</ControlLabel>
                                                <FormControl
                                                    type="email"
                                                    placeholder="(opcjonalnie)"
                                                    value={this.state.emailAddress}
                                                    onChange={event =>
                                                        this.setState({ emailAddress: event.target.value })
                                                    }
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <ControlLabel>Telefon:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    value={this.state.mobilePhone}
                                                    onChange={event =>
                                                        this.setState({ mobilePhone: event.target.value })
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    }
                                />

                                {elementsList}

                                <Card
                                    title="Szczegóły"
                                    content={
                                        <div>
                                            {
                                                this.state.availableServices && this.state.availableServices.length > 0 && (
                                                    <FormGroup>
                                                        <ControlLabel>Usługi dodatkowe:</ControlLabel>
                                                        <Col>
                                                            {
                                                                this.state.availableServices.map((prop, key) => {
                                                                    let serviceQuantity = this.state.orderService.find(e => e.uuid === prop.uuid) ? this.state.orderService.find(e => e.uuid === prop.uuid).quantity : 0;
                                                                    if (prop.autoRequired) {
                                                                        return null;
                                                                    }
                                                                    return (
                                                                        <Checkbox
                                                                            key={prop.uuid}
                                                                            number={"service-" + key}
                                                                            label={
                                                                                <FormGroup>
                                                                                    <FormControl
                                                                                        type="text"
                                                                                        placeholder="ilość"
                                                                                        value={serviceQuantity}
                                                                                        disabled={this.state.isPreviewOnly || prop.autoRequired}
                                                                                        style={{ "borderRadius": "2px", "height": "24px", "width": "48px", "padding": "0", "display": "inline", "marginTop": "-2px", "marginRight": "5px", "textAlign": "center" }}
                                                                                        onChange={event =>
                                                                                            this.manageServiceQuantity(prop.uuid, event.target.value)
                                                                                        }
                                                                                    />
                                                                                    {prop.name}
                                                                                </FormGroup>
                                                                            }
                                                                            checked={this.state.orderService.some(e => e.uuid === prop.uuid)}
                                                                            disabled={this.state.isPreviewOnly || prop.autoRequired}
                                                                            onChange={event => {
                                                                                this.manageService(prop.uuid, event.target.checked);
                                                                            }}
                                                                        />
                                                                    );
                                                                })
                                                            }
                                                            {!this.hideField("withoutDelivery") && (
                                                                <Checkbox
                                                                    className="text-warning"
                                                                    number="withoutDelivery"
                                                                    label="Zlecenie bez usługi dostawy"
                                                                    checked={this.state.withoutDelivery}
                                                                    disabled={this.state.isPreviewOnly}
                                                                    onChange={(event) =>
                                                                        this.setState({ withoutDelivery: event.target.checked },
                                                                            () => {
                                                                                this.calculateAll()
                                                                            }
                                                                        )
                                                                    }
                                                                />
                                                            )}

                                                            {this.getConfigPart('allowNoBringing') && (
                                                                <Checkbox
                                                                    number="allowNoBringing"
                                                                    label={"Zlecenie bez usługi wniesienia"}
                                                                    checked={this.state.allowNoBringing}
                                                                    onChange={event =>
                                                                        this.setState({ allowNoBringing: event.target.checked }, () => { this.calculateAll() })
                                                                    }
                                                                />
                                                            )}
                                                        </Col>
                                                    </FormGroup>
                                                )
                                            }
                                            <FormGroup>
                                                <ControlLabel>Piętro:</ControlLabel>
                                                <FormControl
                                                    type="number"
                                                    placeholder="(opcjonalnie)"
                                                    value={this.state.apartmentFloor}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={event =>
                                                        this.setState({ apartmentFloor: event.target.value })
                                                    }
                                                />
                                                <Checkbox
                                                    number="elevator"
                                                    label="Budynek posiada windę"
                                                    checked={this.state.elevator}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={(event) =>
                                                        this.setState({ elevator: event.target.checked })
                                                    }
                                                />
                                            </FormGroup>
                                            {!this.hideField("warehouse") && (
                                                <FormGroup>
                                                    <ControlLabel>Magazyn:</ControlLabel>
                                                    <Col>
                                                        <Select
                                                            className="react-select primary"
                                                            classNamePrefix="react-select"
                                                            value={this.state.warehouse}
                                                            placeholder="Wybierz.."
                                                            isClearable={!this.hideField("emptyWarehouse")}
                                                            onChange={value => {
                                                                let warehouseNewType = value ? "defined" : "other";
                                                                let isInCommonSet = value ? this.state.commonWarehouses.find(itm => itm.value === value.value) : null;
                                                                if (isInCommonSet) {
                                                                    warehouseNewType = isInCommonSet.value;
                                                                }
                                                                this.setState({
                                                                    warehouse: value,
                                                                    warehousePlainText: value && value.value !== "other" ? value.label : "",
                                                                    warehouseType: warehouseNewType
                                                                });
                                                            }
                                                            }
                                                            options={this.state.warehouses}
                                                            isDisabled={this.state.isPreviewOnly}
                                                        />
                                                    </Col>
                                                    {
                                                        this.state.warehouseType === "other" && (
                                                            <div style={{ marginTop: 10 }}>
                                                                <FormControl
                                                                    type="text"
                                                                    placeholder={!this.hideField("warehouse") ? "podaj magazyn" : "(opcjonalnie)"}
                                                                    value={this.state.warehousePlainText}
                                                                    disabled={this.state.isPreviewOnly}
                                                                    onChange={event =>
                                                                        this.setState({ warehousePlainText: event.target.value })
                                                                    }
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                    {this.useAdditionalField(['releaseElements'])}

                                                </FormGroup>
                                            )}
                                            <FormGroup>
                                                <ControlLabel>Uwagi:</ControlLabel>
                                                <textarea
                                                    className="form-control"
                                                    value={this.state.comment}
                                                    onChange={event =>
                                                        this.setState({ comment: event.target.value })
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                    }
                                />

                            </Col>

                            <Col md={6}>
                                <MapCard
                                    title="Podgląd trasy"
                                    content={
                                        <DirectionsMap
                                            origin={directionsFrom}
                                            destination={this.state.fullAddress}
                                            onDirectionsChange={this.onDirectionsChange}
                                            onDirectionsNotFound={this.onDirectionsNotFound}
                                            defaultCenter={directionsCenter}
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `280px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            placeholder="Ustaw adres docelowy"
                                        />
                                    }
                                />

                                <Card
                                    title="Podsumowanie"
                                    content={
                                        <div>
                                            {typeSwitch}
                                            <Row>
                                                <Col xs={6}>
                                                    {calculatedZoneUnit}
                                                </Col>
                                                <Col xs={6}>
                                                    <FormGroup>
                                                        <ControlLabel>Obliczona strefa:</ControlLabel>
                                                        <FormControl
                                                            type="text"
                                                            placeholder="Strefa.."
                                                            disabled={true}
                                                            value={this.state.calculatedZone ? "Strefa: " + this.state.calculatedZone : ""}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <FormGroup>
                                                <ControlLabel>Koszt usługi (PLN):</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    disabled={!this.state.isAdminView}
                                                    value={this.state.calculatedPriceClient}
                                                    onChange={
                                                        event => {
                                                            let value = event.target.value.replace(",", ".");
                                                            this.setState({ calculatedPriceClient: value })
                                                        }
                                                    }
                                                />
                                            </FormGroup>
                                            {adminToolkitPrice}
                                            <FormGroup>
                                                <ControlLabel>Płatność:</ControlLabel>
                                                <Col>
                                                    <Select
                                                        className="react-select primary"
                                                        classNamePrefix="react-select"
                                                        value={this.state.payer}
                                                        placeholder="Wybierz.."
                                                        onChange={value =>
                                                            this.setState({ payer: value, paymentType: null })
                                                        }
                                                        options={this.state.payerOptions}
                                                        isDisabled={this.state.isPreviewOnly}
                                                    />
                                                </Col>
                                            </FormGroup>
                                            {paymentTypeOptions}
                                            {paymentTypeVoucher}
                                            <FormGroup>
                                                <ControlLabel>{this.renameField('identExternal', 'Numer dokumentu')}:</ControlLabel>
                                                <FormControl
                                                    type="text"
                                                    placeholder="(opcjonalnie)"
                                                    value={this.state.identExternal}
                                                    disabled={this.state.isPreviewOnly}
                                                    onChange={event =>
                                                        this.setState({ identExternal: event.target.value })
                                                    }
                                                />
                                            </FormGroup>
                                            {this.useAdditionalField(['cod'])}
                                            {
                                                (!this.state.isAdminView && this.state.userOwner.company.configuration.forceEmptyDate) ? (
                                                    <></>
                                                ) : (
                                                    <FormGroup>
                                                        <ControlLabel>{this.renameField('slot', 'Termin dostawy')}:</ControlLabel>
                                                        <Col>
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                value={this.state.slot}
                                                                placeholder="Wybierz.."
                                                                onChange={value =>
                                                                    this.setState({ slot: value })
                                                                }
                                                                options={this.state.availableSlots}
                                                                isDisabled={this.state.isPreviewOnly}
                                                            />
                                                        </Col>
                                                    </FormGroup>
                                                )
                                            }
                                            {this.useAdditionalField(['delayRequest'])}
                                            {this.useAdditionalField(['expressRequest'])}
                                            {this.useAdditionalField(['transferable'])}
                                        </div>
                                    }
                                />

                                <Card
                                    title="Opcje"
                                    content={
                                        <div>
                                            {adminToolkit}
                                            <div className="buttons-with-margin">
                                                <Button id="btn-save-order" type="submit" disabled={this.state.isLoading} bsStyle="success" fill wd icon onClick={!this.state.isLoading ? this.handleSubmit.bind(this) : null}>
                                                    <i className="fa fa-save" />  {this.state.isLoading ? 'Zapisywanie…' : this.state.isPreviewOnly ? 'Zapisz komentarz' : 'Zapisz zlecenie'}
                                                </Button>
                                                {printerButton}
                                                {assemblyButton}
                                            </div>
                                        </div>
                                    }
                                />
                            </Col>
                        </form>
                    </Row>
                </Grid>
            </div>
        );

        return (
            <div>
                {this.state.orderLoading ? orderLoading : orderLoaded}
            </div>
        )

    }
}

export default ExpertClientOrder;
