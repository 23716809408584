/* global _api */
/* eslint-disable */
import React, { Component } from "react";
import Card from "../../../components/Card/Card.jsx";
import Button from "../../../components/CustomButton/CustomButton.jsx";
import Select from "react-select";
import ExpertUploadComponent from "../../../components/Expert/UploadComponent.jsx";
import Checkbox from "../../../components/CustomCheckbox/CustomCheckbox.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Grid,
    Row,
    Col,
    FormGroup,
    Nav,
    Tab,
    NavItem,
    Modal,
    ControlLabel,
    FormControl,
} from "react-bootstrap";
import Datatable from "../../../components/Datatable";
import ExpertClientOrder from "../Client/Order.jsx";

class ExpertAdminPreorders extends Component {

    constructor(props) {
        super(props);

        this.companyOptions = [
            {
                "value": "f46cf4b1-1108-4829-9b54-17829fee2198",
                "identAgataDepartment": "WK",
                "type": "agata-excel-wloclawek",
                "typeIntegration": "agata-integracja-wloclawek",
                "filters": ["agata-excel-wloclawek", "agata-form-wloclawek", "agata-integracja-wloclawek"],
                "label": "Salon Agata Włocławek, ul. Kapitulna 39, 87-800 Włocławek",
                "multiImport": false,
                "department": {
                    "uuid": "fe1a190d-90b5-4835-9018-cc86399e77ef"
                }
            },
            {
                "value": "d5888432-349d-45ce-bc41-ac61884c5660",
                "identAgataDepartment": "TO",
                "type": "agata-excel-torun",
                "typeIntegration": "agata-integracja-torun",
                "filters": ["agata-excel-torun", "agata-form-torun", "agata-integracja-torun"],
                "label": "Salon Agata Toruń, ul. Olsztyńska 15, 87-100 Toruń",
                "multiImport": false,
                "department": {
                    "uuid": "f3b65663-1382-4e16-9ac6-ecbd6539cf08"
                }
            },
            {
                "value": "0c67b733-68e4-42a2-aa8f-a01e8ab8542d",
                "identAgataDepartment": 'GK',
                "type": "agata-excel-gdansk",
                "typeIntegration": "agata-integracja-gdansk",
                "filters": ["agata-excel-gdansk", "agata-form-gdansk", "agata-integracja-gdansk"],
                "label": "Salon Agata Gdańsk,\nul. Przywidzka 10, 80-180 Gdańsk",
                "multiImport": false,
                "department": {
                    "uuid": "8a3d0145-1842-4952-944b-7e2af5723af7"
                }
            },
            {
                "value": "154d63ce-0d48-483f-aa2e-c81115f3173c",
                "identAgataDepartment": "RU",
                "type": "agata-excel-rumia",
                "typeIntegration": "agata-integracja-rumia",
                "filters": ["agata-excel-rumia", "agata-form-rumia", "agata-integracja-rumia"],
                "label": "Salon Agata Rumia,\nul. Grunwaldzka 112, 84-230 Rumia",
                "multiImport": false,
                "department": {
                    "uuid": "1463faee-9887-440a-817e-5a9a13b5b73f"
                }
            },
            {
                "value": "b500c10d-83e8-422a-af63-123944de5894",
                "identAgataDepartment": "SZ",
                "type": "agata-excel-szczecin",
                "typeIntegration": "agata-integracja-szczecin",
                "filters": ["agata-excel-szczecin", "agata-form-szczecin", "agata-integracja-szczecin"],
                "label": "Salon Agata Szczecin,\nul. Wiosenna 30, 70-807 Szczecin",
                "pokEmailAddress": "pok.szczecin@expert-transport.pl",
                "multiImport": false,
                "department": {
                    "uuid": "d434e5a0-e4ce-4994-a891-403757958949"
                }
            },
            {
                "value": "5fda2743-eda1-4f82-a3c5-117ad0cd5a58",
                "identAgataDepartment": "BD",
                "type": "agata-excel-bydgoszcz",
                "typeIntegration": "agata-integracja-bydgoszcz",
                "filters": ["agata-excel-bydgoszcz", "agata-form-bydgoszcz", "agata-integracja-bydgoszcz"],
                "label": "Salon Agata Bydgoszcz,\nul. Jasiniecka 4, 85-796 Bydgoszcz",
                "pokEmailAddress": "pok.bydgoszcz@expert-transport.pl",
                "multiImport": false,
                "department": {
                    "uuid": "dbf5bd11-08df-4abd-8d52-3cbe738e0abd"
                }
            },
            {
                "value": "8e3d9615-34d7-4d6e-88fd-999fb337c290",
                "identAgataDepartment": "SŁ",
                "type": "agata-excel-slupsk",
                "typeIntegration": "agata-integracja-slupsk",
                "filters": ["agata-excel-slupsk", "agata-form-slupsk", "agata-integracja-slupsk"],
                "label": "Salon Agata Słupsk,\nul. Szczecińska 3D, 76-251 Kobylnica",
                "pokEmailAddress": "pok.slupsk@expert-transport.pl",
                "multiImport": false,
                "department": {
                    "uuid": "0c17099a-9eeb-4d9b-b347-9939b85fbd70"
                }
            },
            {
                "value": "46cdb758-a5b3-40fe-8909-fbcc5e89b0f3",
                "identAgataDepartment": "PZ",
                "type": "agata-excel-poznan",
                "typeIntegration": "agata-integracja-poznan",
                "filters": ["agata-excel-poznan", "agata-form-poznan", "agata-integracja-poznan"],
                "label": "Salon Agata Poznań,\nul. Haliny Konopackiej 18, 60-205 Poznań",
                "pokEmailAddress": "pok.poznan@expert-transport.pl",
                "multiImport": false,
                "department": {
                    "uuid": "4238e039-12f3-479d-b268-ead66521d19a"
                }
            },
            {
                "value": "6827d790-0140-4bbc-9087-a2b5e9b0f16f",
                "identAgataDepartment": "GW",
                "type": "agata-excel-gorzow",
                "typeIntegration": "agata-integracja-gorzow",
                "filters": ["agata-excel-gorzow", "agata-form-gorzow", "agata-integracja-gorzow"],
                "label": "Salon Agata Gorzów Wielkopolski,\nMyśliborska 35, 66-400 Gorzów Wielkopolski",
                "pokEmailAddress": "pok.gorzow@expert-transport.pl",
                "multiImport": false,
                "department": {
                    "uuid": "c33d7e98-6982-4533-b205-ca5a9c4fcf42"
                }
            },
            {
                "value": "4034a7f5-aa74-4945-aaf7-f3a7276e6f08",
                "identAgataDepartment": "KO",
                "type": "agata-excel-koszalin",
                "typeIntegration": "agata-integracja-koszalin",
                "filters": ["agata-excel-koszalin", "agata-form-koszalin", "agata-integracja-koszalin"],
                "label": "Salon Agata Koszalin,\nul. Koszalińska 5, 75-900 Koszalin",
                "pokEmailAddress": "pok.koszalin@expert-transport.pl",
                "multiImport": false,
                "department": {
                    "uuid": "b89a842b-3047-4f53-a424-21cd2ab641fe"
                }
            },
            {
                "value": "677f37d5-2ed4-4601-b131-2ea9f4fc5764",
                "identAgataDepartment": null,
                "type": "brw-excel-poznan",
                "typeIntegration": null,
                "filters": ["brw-excel-poznan"],
                "label": "BRW - SERBRSKA [S27A],\nSerbska 9, 61-696 Poznań",
                "pokEmailAddress": null,
                "multiImport": false,
                "department": {
                    "uuid": "069dd740-b9d1-48ba-a4d8-7b70961ba9f4"
                }
            },
            {
                "value": "d9f8b7dd-6499-4064-908d-7e3398601207",
                "identAgataDepartment": null,
                "type": "selsey-excel-net",
                "typeIntegration": null,
                "filters": ["selsey-excel-net"],
                "label": "Selsey.pl [NET]",
                "pokEmailAddress": null,
                "multiImport": true,
                "department": {
                    "uuid": "b8c9ec8f-4856-4837-91a1-24d7e481983b"
                }
            },
            {
                "value": "47384cae-28fd-4033-a5b1-0a0f27513b29",
                "identAgataDepartment": null,
                "type": "expert-form-b2c-meble",
                "typeIntegration": null,
                "filters": ["expert-form-b2c-meble"],
                "label": "Sprzedaż B2C",
                "pokEmailAddress": null,
                "multiImport": false,
                "department": {
                    "uuid": "b5870bcd-9e46-4c79-bb79-e0190db3324e"
                }
            },
        ];

        if (!props.userHasRole(['admin'])) {
            this.companyOptions = this.companyOptions.filter((elem) => { return elem.department.uuid === props.user.user.department.uuid });
        }

        this.state = {
            data: [],
            pages: -1,
            isLoading: false,
            showTrackerModal: false,
            showOrderModal: false,
            orderTracker: null,
            orderUuid: null,
            checkAll: false,
            askAbout: null,
            additionalFiles: [],
            company: this.companyOptions.length ? this.companyOptions[0] : null,
            importNumer: "",
            importNagId: "",
            importLogoOdbiorcy: "",
            showImportModal: false,
        };

        this.isAutoMode = false;
        this.preordersToBeCreated = [];
        this.preordersToBeCreatedNumber = 0;
        this.onOrderLoaded = this.onOrderLoaded.bind(this);
        this.onOrderSaved = this.onOrderSaved.bind(this);

    }

    componentDidMount() {
        window.addEventListener("ORDER_LOADED", this.onOrderLoaded, false);
        window.addEventListener("ORDER_SAVED", this.onOrderSaved, false);
    }

    componentWillUnmount() {
        window.removeEventListener("ORDER_LOADED", this.onOrderLoaded, false);
        window.removeEventListener("ORDER_SAVED", this.onOrderSaved, false);
        _api.abort();
    }

    openOrder(uuid, e) {
        const url = "/#/client/edit-order/" + uuid;
        window.open(url);
    }

    reloadTable(callback) {
        let self = this;
        self.fetchTable(
            {
                page: self.state.page,
                pageSize: self.state.pageSize,
                sorted: self.state.sorted,
                filtered: self.state.filtered
            },
            null,
            () => {
                if (typeof callback === "function") {
                    callback();
                }
            }
        );
    }

    createOrderFromPreorder(preorderUuid) {
        let self = this;
        self.props.showNoty('tr', 'info', `Tworzenie nowego zlecenia`);
        _api.request(`/admin/preorder/create-order/${preorderUuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'success', `Numer nowego zlecenia: ${data.ident}`);
                self.reloadTable();
                setTimeout(() => { self.openOrder(data.uuid); }, 600);
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    removePreorder(uuid) {
        let self = this;
        self.props.showNoty('tr', 'info', `Usuwanie zlecenia do realizacji`);
        _api.request(`/admin/trash/recursive/preorder/${uuid}`, {},
            (data) => {
                self.props.showNoty('tr', 'success', `Zlecenie zostało usunięte`);
                this.reloadTable();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    removePreorderPopup(preorderUuid) {
        this.setState({
            askAbout: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz usunąć to zlecenie do realizacji?"
                    confirmBtnText="Usuń"
                    cancelBtnText="Anuluj"
                    onConfirm={() => { this.setState({ askAbout: null }); this.removePreorder(preorderUuid); }}
                    onCancel={() => { this.setState({ askAbout: null }); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });
    }

    checkAll(checked) {
        const checkAll = checked;
        const newItems = this.state.data.map((item) => {
            let updatedItem = { ...item };
            updatedItem.checked = checkAll;
            return updatedItem;
        });
        this.setState({
            data: this.reMapData(newItems),
            checkAll
        });
    }

    checkElement(itemUuid, checked) {
        const newItems = this.state.data.map((item) => {
            let updatedItem = { ...item };
            if (itemUuid !== null && item.uuid === itemUuid) {
                updatedItem.checked = checked;
            }
            return updatedItem;
        });
        this.setState({
            data: this.reMapData(newItems),
        });
    }

    reMapData(data) {
        let self = this;
        return data.map((prop, key) => {
            prop.checked = prop.hasOwnProperty('checked') ? prop.checked : false;
            prop.checkbox = (
                <Checkbox
                    key={prop.uuid}
                    number={key}
                    checked={prop.checked}
                    onChange={event => {
                        this.checkElement(prop.uuid, event.target.checked);
                    }}
                />
            );
            if (prop.hasActions) {
                return prop;
            }
            prop.hasActions = true;
            prop.orderIdent = prop.orderIdent
                ? <Button style={{ padding: 0 }} onClick={(e) => { self.openOrder(prop.orderUuid, e) }} bsStyle="info" round wd simple>{prop.orderIdent}</Button>
                : <Button style={{ padding: 0 }} onClick={(e) => { self.createOrderFromPreorder(prop.uuid, e) }} bsStyle="primary" round wd simple>
                    <span className="btn-label" style={{ paddingRight: "5px" }}>
                        <i className="fa fa-plus" />
                    </span>
                    stwórz
                </Button>;
            prop.actions = (
                <div className="actions-right">
                    <Button
                        onClick={() => { this.removePreorderPopup(prop.uuid) }}
                        bsStyle="default"
                        simple
                        icon>
                        <i className="fa fa-times" />
                    </Button>
                </div>
            )
            return prop;
        });
    }

    fetchTable = (state, instance, callback) => {
        let self = this;
        self.setState({ loading: true });
        _api.request("/admin/preorder/list", {
            page: state.page,
            pageSize: state.pageSize,
            sorted: state.sorted,
            filtered: state.filtered,
            types: self.state.company ? self.state.company.filters : null
        }, (res) => {
            self.setState({
                data: self.reMapData(res.rows),
                pages: res.pages,
                loading: false,
                page: res.page,
                pageSize: res.pageSize,
                sorted: res.sorted,
                filtered: res.filtered
            },
                () => {
                    if (typeof callback === "function") {
                        callback();
                    }
                }
            );
        }, (error) => {
            self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            self.setState({ loading: false });
        }, (progress) => {
            self.props.setProgress(progress);
        });
    }

    getFilesList = () => {
        let filesList = [];
        let allFiles = [];
        for (let i = 0; i < this.state.additionalFiles.length; i++) {
            allFiles.push({ name: this.state.additionalFiles[i].name });
        }
        for (let i = 0; i < allFiles.length; i++) {
            let elem = (<><i className="fa fa-file" /> {allFiles[i].name}</>);
            if (allFiles[i].url) {
                elem = (<a href={allFiles[i].url} target="_blank" rel="noopener noreferrer">{elem}</a>);
            }
            filesList.push(<p key={"file" + i}>{elem}</p>);
        }
        return filesList;
    }

    handleSubmit(event) {
        event.preventDefault();
        let self = this;
        if (!self.state.additionalFiles) {
            self.props.showNoty('tr', 'error', 'Wybierz plik(i)');
            return;
        }
        self.props.showNoty('tr', 'info', 'Wgrywanie pliku..');
        _api.request(`/admin/import/preorders/${this.state.company.type}`,
            {
                "files": self.state.additionalFiles,
                "type": self.state.company.type,
                "company": {
                    "uuid": self.state.company.value
                }
            },
            (data) => {
                self.props.showNoty('tr', 'success', 'Plik został zaimportowany');
                self.reloadTable();
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas odczytu pliku');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleSync(event, callback, numer, nagId, logoOdbiorcy) {
        event.preventDefault();
        let self = this;
        if (typeof callback !== "function") {
            callback = () => { }
        }
        if (!self.state.additionalFiles) {
            self.props.showNoty('tr', 'error', 'Błędna konfiguracja oddziału AM');
            return;
        }
        self.setState({ loading: true });
        self.props.showNoty('tr', 'info', 'Pobieranie danych..');
        _api.request(`/admin/import/preorders/agata-remote`,
            {
                "identAgataDepartment": self.state.company.identAgataDepartment,
                "type": self.state.company.typeIntegration,
                "numer": numer || null,
                "nagId": nagId || null,
                "logoOdbiorcy": logoOdbiorcy || null,
            },
            (data) => {
                self.props.showNoty('tr', 'success', `Zlecenia zostały zaimportowane (${data.count})`);
                self.reloadTable();
                callback();
                self.setState({ loading: false });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas odczytu z serwera AM');
                self.props.showNoty('tr', 'error', JSON.stringify(error));
                self.setState({ loading: false });
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleSyncSingle(event) {
        this.handleSync(
            event,
            () => {
                this.setState({ showImportModal: false });
            },
            this.state.importNumer,
            this.state.importNagId,
            this.state.importLogoOdbiorcy
        );
    }

    maybeCreateMultiOrders() {
        this.setState({
            askAbout: (
                <SweetAlert
                    info
                    showCancel
                    style={{ display: "block", marginTop: "-300px" }}
                    title="Czy chcesz stworzyć zlecenia zbiorczo?"
                    confirmBtnText="Stwórz zlecenia"
                    cancelBtnText="Anuluj"
                    onConfirm={() => {
                        this.setState({ askAbout: null }, () => {
                            this.preordersToBeCreated = this.state.data.filter(itm => itm.checked).map(itm => itm.uuid);
                            if (!this.preordersToBeCreated.length) {
                                this.props.showNoty('tr', 'error', 'Musisz wybrać przynajmniej jedno zlecenie');
                                return;
                            }
                            this.props.showNoty('tr', 'info', `Rozpoczynam proces, nie zamykaj okna przeglądarki!`);
                            this.isAutoMode = true;
                            this.createMultiOrders();
                        });
                    }}
                    onCancel={() => { this.setState({ askAbout: null }, () => { this.isAutoMode = false; this.preordersToBeCreated = [], this.preordersToBeCreatedNumber = 0 }); }}
                    confirmBtnBsStyle="info">
                </SweetAlert>
            )
        });
    }

    createMultiOrders() {
        if (!this.preordersToBeCreated.length) {
            return;
        }
        if (!this.isAutoMode) {
            return;
        }
        this.props.showNoty('tr', 'info', `Tworzenie zlecenia`);
        const preorderUuid = this.preordersToBeCreated[this.preordersToBeCreatedNumber];
        if (!preorderUuid) {
            this.finishAutoImport();
            return;
        }
        _api.request(`/admin/preorder/create-order/${preorderUuid}`, {},
            (data) => {
                this.setState({ orderUuid: data.uuid, showOrderModal: true });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas tworzenia zlecenia');
                this.finishAutoImport();
            }
        );
    }

    onOrderLoaded() {
        if (!this.isAutoMode) {
            return;
        }
        window.dispatchEvent(new CustomEvent('ORDER_REFRESH', { 'detail': {} }), true);
        setTimeout(() => { document.getElementById('btn-save-order').click(); }, 2500);
    }

    onOrderSaved() {
        if (!this.isAutoMode) {
            return;
        }
        this.props.showNoty('tr', 'info', `Zlecenie zostalo stworzone`);
        this.setState({ showOrderModal: false, orderUuid: null }, () => {
            this.preordersToBeCreatedNumber++;
            this.createMultiOrders();
        })
    }

    finishAutoImport() {
        this.isAutoMode = false;
        this.preordersToBeCreated = [];
        this.preordersToBeCreatedNumber = 0;
        this.setState({
            showOrderModal: false,
            orderUuid: null,
        }, () => {
            this.props.showNoty('tr', 'info', `Proces tworzenia zleceń został zakończony`);
            this.reloadTable();
        })
    }

    render() {

        let cols = [];

        if (this.state.company.multiImport) {
            cols = cols.concat([
                {
                    Header: (
                        <>
                            <Checkbox
                                key="check-all"
                                number={99999}
                                checked={this.state.checkAll}
                                className="mb-0"
                                onChange={event => {
                                    this.checkAll(event.target.checked);
                                }}
                            />
                            <Button
                                disabled={this.state.isLoading || this.state.loading}
                                style={{ position: 'absolute', marginTop: 10, padding: 6, zIndex: 10 }}
                                onClick={() => { this.maybeCreateMultiOrders(); }}
                                bsStyle="default"
                                round
                                icon>
                                <i className="fa fa-plus" />
                            </Button>
                        </>
                    ),
                    accessor: "checkbox",
                    sortable: false,
                    filterable: false,
                    width: 50,
                }
            ])
        };

        cols = cols.concat([
            {
                Header: "Nr zlecenia",
                accessor: "orderIdent",
                width: 160
            },
            {
                Header: "Data importu",
                accessor: "created",
                width: 160
            },
            {
                Header: "Źródło danych",
                accessor: "type",
                width: 160
            },
            {
                Header: "Odbiorca",
                accessor: "recipientName"
            },
            {
                Header: "Adres",
                accessor: "addressFull"
            },
            {
                Header: "Nr dok. zew.",
                accessor: "identExternal"
            },
            {
                Header: "Uwagi",
                accessor: "comment"
            },
            {
                Header: "PayU ID",
                accessor: "paymentId"
            },
            {
                Header: "PayU Status",
                accessor: "paymentStataus"
            },
            {
                Header: "Opcje",
                accessor: "actions",
                sortable: false,
                filterable: false,
                width: 60
            }
        ]);

        const filesList = this.getFilesList();

        const modalOrder = this.state.showOrderModal && (
            <Modal show={this.state.showOrderModal} onHide={() => { this.finishAutoImport() }} bsSize="large" style={{ paddingLeft: 0 }} backdrop="static">
                <Modal.Header closeButton>Edycja zamówienia</Modal.Header>
                <Modal.Body>
                    <ExpertClientOrder
                        {...this.props}
                        uuid={this.state.orderUuid}
                        selectedDay={null}
                        includeUnavailableSlots={true}
                        nextDaySpan={14}
                        isAdminView={true}
                        department={null}
                        onSaved={() => { }}
                        onSavedAndHide={() => { }}
                    />
                </Modal.Body>
            </Modal>
        )

        return (
            <div className="main-content">
                {this.state.askAbout}
                {modalOrder}

                <Modal show={this.state.showImportModal} onHide={() => { this.setState({ showImportModal: false }) }} style={{ paddingLeft: 0 }} backdrop="static">
                    <Modal.Header closeButton>Edycja użytkownika</Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSyncSingle.bind(this)}>
                            <Row>
                                <Col sm={12}>
                                    <FormGroup>
                                        <ControlLabel bsClass="text-left">Numer faktury transportowej: </ControlLabel>
                                        <FormControl placeholder="(Numer)" value={this.state.importNumer}
                                            onChange={event => {
                                                this.setState({ importNumer: event.target.value });
                                            }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel bsClass="text-left">Kod dokumentu sprzedaży: </ControlLabel>
                                        <FormControl placeholder="(Nagid)" value={this.state.importNagId}
                                            onChange={event => {
                                                this.setState({ importNagId: event.target.value });
                                            }} />
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel bsClass="text-left">Logo odbiorcy: </ControlLabel>
                                        <FormControl placeholder="(LogoOdbiorcy)" value={this.state.importLogoOdbiorcy}
                                            onChange={event => {
                                                this.setState({ importLogoOdbiorcy: event.target.value });
                                            }} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <Button type="submit" disabled={this.state.loading} bsStyle="default" onClick={!this.state.loading ? this.handleSyncSingle.bind(this) : null}>
                                        {this.state.loading ? 'Pobieranie…' : 'Pobierz dane'}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </Modal.Body>
                </Modal>

                <Grid fluid>
                    <Row>
                        <Col md={12}>
                            <Card
                                title="Wybór oddziału"
                                content={
                                    <form onSubmit={this.handleSubmit.bind(this)}>
                                        <Row>
                                            <Col sm={12}>
                                                <FormGroup>
                                                    <Select
                                                        className="react-select primary react-select-intable"
                                                        classNamePrefix="react-select"
                                                        placeholder="Wybierz.."
                                                        value={this.state.company}
                                                        options={this.companyOptions}
                                                        onChange={(ev) => { this.setState({ company: ev }, () => { this.reloadTable() }) }}
                                                        isClearable={false}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col sm={12} style={{ padding: "0 15px" }}>
                                                <Tab.Container
                                                    id="tabs-preorders"
                                                >
                                                    <>
                                                        <Nav bsStyle="tabs">
                                                            <NavItem eventKey="excel">Import - Excel</NavItem>
                                                            {
                                                                this.state.company?.typeIntegration && (
                                                                    <NavItem eventKey="integration">Import - Integracja</NavItem>
                                                                )
                                                            }
                                                        </Nav>
                                                        <Tab.Content animation={false}>
                                                            <Tab.Pane eventKey="excel">
                                                                <Row>
                                                                    <Col sm={6}>
                                                                        <FormGroup>
                                                                            <ExpertUploadComponent onSelectedFiles={files => { this.setState({ additionalFiles: files }) }} />
                                                                        </FormGroup>
                                                                        <FormGroup>
                                                                            {filesList}
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col sm={6}>
                                                                        <FormGroup>
                                                                            <Button type="submit" disabled={this.state.isLoading || this.state.company === null} className="btn-fill btn-wd btn-icon btn btn-success" onClick={!this.state.isLoading ? this.handleSubmit.bind(this) : null}>
                                                                                <i className="fa fa-save" />  {this.state.isLoading ? 'Ładowanie…' : 'Wgraj plik(i)'}
                                                                            </Button>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Tab.Pane>
                                                            <Tab.Pane eventKey="integration">
                                                                <Row>
                                                                    <Col sm={12}>
                                                                        <FormGroup style={{ display: 'inline-block', marginRight: 15 }}>
                                                                            <Button type="button" disabled={this.state.isLoading || this.state.company === null || this.state.company.identAgataDepartment === null} className="btn-fill btn-wd btn-icon btn btn-success" onClick={!this.state.isLoading ? () => { this.setState({ showImportModal: true }) } : null}>
                                                                                <i className="fa fa-download" />  {this.state.isLoading ? 'Ładowanie…' : 'Pobierz dane na podstawie zamówienia'}
                                                                            </Button>
                                                                        </FormGroup>
                                                                        <FormGroup style={{ display: 'inline-block', marginRight: 15 }}>
                                                                            <Button type="button" disabled={this.state.isLoading || this.state.company === null || this.state.company.identAgataDepartment === null} className="btn-fill btn-wd btn-icon btn btn-info" onClick={!this.state.isLoading ? this.handleSync.bind(this) : null}>
                                                                                <i className="fa fa-retweet" />  {this.state.isLoading ? 'Ładowanie…' : 'Pobierz dane z listy zamówień'}
                                                                            </Button>
                                                                        </FormGroup>
                                                                    </Col>

                                                                </Row>
                                                            </Tab.Pane>
                                                        </Tab.Content>
                                                    </>
                                                </Tab.Container>
                                            </Col>
                                        </Row>
                                    </form>
                                }
                            />
                            <Card
                                content={
                                    <Datatable
                                        data={this.state.data}
                                        pages={this.state.pages}
                                        loading={this.state.loading}
                                        manual
                                        filterable
                                        columns={cols}
                                        defaultSorted={[
                                            {
                                                id: "created",
                                                desc: true
                                            }
                                        ]}
                                        onFetchData={this.fetchTable}
                                        defaultPageSize={25}
                                        pageSizeOptions={[25, 50, 100, 150, 200]}
                                        showPaginationBottom
                                        className="-striped -highlight"
                                        previousText="Poprzednia"
                                        nextText="Następna"
                                        noDataText="Brak danych"
                                        loadingText="Pobieranie danych"
                                        pageText="Strona"
                                        ofText=" z "
                                        rowsText="wierszy"
                                    />
                                }
                            />
                        </Col>
                    </Row>
                </Grid>
            </div >
        );
    }
}

export default ExpertAdminPreorders;
