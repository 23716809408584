/* global _api */
import React, { Component } from "react";
import Card from "../../../../components/Card/Card.jsx";
import Button from "../../../../components/CustomButton/CustomButton.jsx";
import moment from "moment";
import "moment/min/locales";
import Select from "react-select";
import Datetime from "react-datetime";
import {
    Row,
    Col,
    FormGroup,
    ControlLabel,
} from "react-bootstrap";

moment.locale('pl');

class ExpertAdminHubFilter extends Component {

    render() {

        const {
            filters,
            setFilters,
            getReport,
            withRosebrockReport
        } = this.props;

        return (
            <Card
                title={
                    <div>
                        <span>
                            Filtr
                        </span>
                        {
                            withRosebrockReport && (
                                <Button bsStyle="default" style={{ marginLeft: 10 }} pullRight round onClick={() => { getReport(withRosebrockReport) }} disabled={filters.excelIsGenerating}>
                                    <span className="btn-label">
                                        <i className="fa fa-download" /> Pobierz .xlsx (RB)
                                    </span>
                                </Button>
                            )
                        }
                        {
                            filters.excelIsGenerating ?
                                (
                                    <Button bsStyle="default" pullRight round disabled>
                                        <span className="btn-label">
                                            <i className="fa fa-download" /> Generowanie…
                                        </span>
                                    </Button>
                                )
                                :
                                (
                                    <Button bsStyle="default" pullRight round onClick={() => { getReport() }}>
                                        <span className="btn-label">
                                            <i className="fa fa-download" /> Pobierz .xlsx
                                        </span>
                                    </Button>
                                )
                        }
                    </div>
                }
                content={
                    <div>
                        <Row>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Zakres odbioru od: </ControlLabel>
                                    <Datetime
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        value={filters.transferMaxFirstMilePickupDayIdentFrom ? moment(filters.transferMaxFirstMilePickupDayIdentFrom) : ''}
                                        defaultValue={filters.transferMaxFirstMilePickupDayIdentFrom ? moment(filters.transferMaxFirstMilePickupDayIdentFrom) : ''}
                                        onChange={value => setFilters('transferMaxFirstMilePickupDayIdentFrom', value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Zares odbioru do: </ControlLabel>
                                    <Datetime
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        value={filters.transferMaxFirstMilePickupDayIdentTo ? moment(filters.transferMaxFirstMilePickupDayIdentTo) : ''}
                                        defaultValue={filters.transferMaxFirstMilePickupDayIdentTo ? moment(filters.transferMaxFirstMilePickupDayIdentTo) : ''}
                                        onChange={value => setFilters('transferMaxFirstMilePickupDayIdentTo', value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Zakres dostawy od: </ControlLabel>
                                    <Datetime
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        value={filters.slotDayIdentFrom ? moment(filters.slotDayIdentFrom) : ''}
                                        defaultValue={filters.slotDayIdentFrom ? moment(filters.slotDayIdentFrom) : ''}
                                        onChange={value => setFilters('slotDayIdentFrom', value)}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Zares dostawy do: </ControlLabel>
                                    <Datetime
                                        timeFormat={false}
                                        inputProps={{ placeholder: "Wybierz datę" }}
                                        value={filters.slotDayIdentTo ? moment(filters.slotDayIdentTo) : ''}
                                        defaultValue={filters.slotDayIdentTo ? moment(filters.slotDayIdentTo) : ''}
                                        onChange={value => setFilters('slotDayIdentTo', value)}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Miejsce odbioru: </ControlLabel>
                                    <Col>
                                        <Select
                                            isClearable
                                            isMulti
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filters.fromWarehouse}
                                            placeholder="Wybierz oddział"
                                            onChange={value => setFilters('fromWarehouse', value)}
                                            options={filters.warehouses}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Magazyn last mile: </ControlLabel>
                                    <Col>
                                        <Select
                                            isClearable
                                            isMulti
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filters.toWarehouse}
                                            placeholder="Wybierz oddział"
                                            onChange={value => setFilters('toWarehouse', value)}
                                            options={filters.warehouses}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Kontrahent: </ControlLabel>
                                    <Col>
                                        <Select
                                            isClearable
                                            isMulti
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filters.fromCompany}
                                            placeholder="Wybierz firmę"
                                            onChange={value => setFilters('fromCompany', value)}
                                            options={filters.companies}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Przewoźnik: </ControlLabel>
                                    <Col>
                                        <Select
                                            isClearable
                                            isMulti
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filters.driver}
                                            placeholder="Wybierz"
                                            onChange={value => setFilters('driver', value)}
                                            options={filters.drivers}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} lg={3}>
                                <FormGroup>
                                    <ControlLabel>Status: </ControlLabel>
                                    <Col>
                                        <Select
                                            isClearable
                                            isMulti
                                            className="react-select primary"
                                            classNamePrefix="react-select"
                                            value={filters.status}
                                            placeholder="Wybierz status" onChange={value => setFilters('status', value)}
                                            options={filters.statuses}
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                }
            />
        );
    }
}

export default ExpertAdminHubFilter;
