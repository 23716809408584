/* global _api */
import React, { Component } from "react";
import PropagateLoader from 'react-spinners/PropagateLoader';
import Checkbox from "../CustomCheckbox/CustomCheckbox.jsx";
import {
    FormGroup,
    ControlLabel,
    FormControl,
    Row,
    Col,
    Button
} from "react-bootstrap";

class ExpertProfileComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isSaving: false,
            isLoading: false,
            modifier: this.props.isAdminView ? "admin" : "self",
            uuid: this.props.uuid || "",
            emailAddress: this.props.emailAddress || "",
            firstname: this.props.firstname || "",
            lastname: this.props.lastname || "",
            mobilePhone: this.props.mobilePhone || "",
            department: this.props.department || "",
            company: this.props.company || "",
            fleet: this.props.fleet || "",
            releaseElements: this.props.releaseElements || false,
            companyName: this.props.companyName || "",
            password: "",
            randomPassword: "",
            passwordSendMail: false,
            endpoint: this.props.endpoint
        };
    }

    componentDidMount() {
        if (this.props.preloadUser) {
            this.loadUser(this.props.preloadUser);
        }
    }

    loadUser(userUuid) {
        let self = this;
        self.setState({ uuid: userUuid, isLoading: true });
        _api.request(`/admin/user/details/${userUuid}`, {},
            (data) => {
                self.setState({
                    isLoading: false,
                    uuid: data.uuid,
                    emailAddress: data.emailAddress,
                    firstname: data.firstname,
                    lastname: data.lastname,
                    mobilePhone: data.mobilePhone,
                    department: data.department.uuid,
                    company: data.company.uuid,
                    fleet: data.fleet.uuid,
                    releaseElements: data.releaseElements || false,
                    companyName: data.companyName || '',
                });
            },
            (error) => {
                self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas pobierania danych');
            },
            (progress) => {
                self.props.setProgress(progress);
            }
        );
    }

    handleRandomPassword(event) {
        event.preventDefault();
        let items = '!@#$%^&*()_+[]'.split('');
        let randomPassword = (0 | Math.random() * 9e6).toString(36) + items[Math.floor(Math.random() * items.length)] + (0 | Math.random() * 9e6).toString(36)
        this.setState({ randomPassword: randomPassword, password: randomPassword });
    }

    handleSubmit(event) {
        event.preventDefault();

        let self = this;

        let validationRules = {
            "isValid": true,
            "items": [
                { "field": "firstname", "rules": { "empty": "Podaj imię" } },
                { "field": "lastname", "rules": { "empty": "Podaj nazwisko" } },
                { "field": "mobilePhone", "rules": { "empty": "Podaj numer telefonu" } },
                { "field": "emailAddress", "rules": { "empty": "Podaj adres e-mail" } }
            ]
        };

        if (!self.state.uuid) {
            validationRules.items.push({ "field": "password", "rules": { "empty": "Nowy użytkownik musi posiadać ustalone hasło" } });
        }

        validationRules.items.forEach(element => {
            if (!validationRules.isValid) {
                return;
            } else {
                let elementValue = self.state[element.field];
                if ("empty" in element.rules && (elementValue === "" || elementValue === null || elementValue === undefined)) {
                    validationRules.isValid = false;
                    self.props.showNoty('tr', 'error', element.rules.empty);
                }
            }
        });

        if (!validationRules.isValid) {
            return;
        }

        self.setState({ isSaving: true });
        self.props.setProgress(0);
        _api.request(this.state.endpoint,
            {
                "modifier": self.state.modifier,
                "user": {
                    "uuid": self.state.uuid,
                    "emailAddress": self.state.emailAddress,
                    "firstname": self.state.firstname,
                    "lastname": self.state.lastname,
                    "mobilePhone": self.state.mobilePhone,
                    "password": self.state.password,
                    "passwordSendMail": self.state.passwordSendMail,
                    "releaseElements": self.state.releaseElements,
                    "companyName": self.state.companyName,
                },
                "department": {
                    "uuid": self.state.department,
                },
                "company": {
                    "uuid": self.state.company
                },
                "fleet": {
                    "uuid": self.state.fleet
                }
            },
            (data) => {
                if (typeof self.props.onSaved === "function") {
                    self.props.onSaved();
                } else {
                    self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                }
                self.setState({ isSaving: false });
                self.props.setProgress(100);
            },
            (error) => {
                if (typeof self.props.onError === "function") {
                    self.props.showNoty('tr', 'error', 'Wystąpił błąd podczas zapisywania danych');
                } else {
                    self.props.showNoty('tr', 'info', `Zmiany zostały zapisane`);
                }
                self.setState({ isSaving: false });
                self.props.setProgress(100);
            }
        );
    }

    render() {

        const userLoading = (
            <div className="main-content">
                <div style={{ "paddingBottom": "26px" }}>
                    <PropagateLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        css={{
                            "display": "block",
                            "margin": "10px auto",
                            "width": "1px"
                        }}
                        color={'#777'}
                        loading={true}
                    />
                </div>
            </div>
        );

        const adminOptionsLeft = (
            <div>
                {
                    this.props.endpoint?.includes('expertFleet') && (
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Firma: </ControlLabel>
                            <FormControl placeholder="Wprowadź nazwę firmy" name="companyName" value={this.state.companyName}
                                onChange={event => {
                                    this.setState({ companyName: event.target.value });
                                }} />
                        </FormGroup>
                    )
                }
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Opcje dodatkowe:</ControlLabel>
                        <Col>
                            <Checkbox
                                number="releaseElements"
                                label="Pokaż zakładkę 'Wydanie towaru'"
                                checked={this.state.releaseElements}
                                onChange={event =>
                                    this.setState({ releaseElements: event.target.checked })
                                }
                            />
                        </Col>
                    </FormGroup>
                </fieldset>
            </div>
        );

        const adminOptionsRight = (
            <div>
                <fieldset>
                    <FormGroup>
                        <ControlLabel>Powiadomienia:</ControlLabel>
                        <Col>
                            <Checkbox
                                number="passwordSendMail"
                                label="Wyślij hasło do konta na maila"
                                checked={this.state.passwordSendMail}
                                disabled={this.state.password ? false : true}
                                onChange={event =>
                                    this.setState({ passwordSendMail: event.target.checked })
                                }
                            />
                        </Col>
                    </FormGroup>
                </fieldset>
            </div>
        );

        const userLoaded = (
            <form onSubmit={this.handleSubmit.bind(this)}>
                <Row>
                    <Col sm={6}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Imię: </ControlLabel>
                            <FormControl placeholder="Wprowadź imię" name="firstname" value={this.state.firstname}
                                onChange={event => {
                                    this.setState({ firstname: event.target.value });
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Nazwisko: </ControlLabel>
                            <FormControl placeholder="Wprowadź nazwisko" name="lastname" value={this.state.lastname}
                                onChange={event => {
                                    this.setState({ lastname: event.target.value });
                                }} />
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Numer telefonu: </ControlLabel>
                            <FormControl placeholder="Wprowadź numer telefonu" name="mobilePhone" value={this.state.mobilePhone}
                                onChange={event => {
                                    this.setState({ mobilePhone: event.target.value });
                                }} />
                        </FormGroup>
                        {this.props.isAdminView ? adminOptionsLeft : ""}
                    </Col>
                    <Col sm={6}>
                        <FormGroup>
                            <ControlLabel bsClass="text-left">Adres e-mail: </ControlLabel>
                            <FormControl placeholder="Wprowadź e-mail" name="emailAddress" type="email" value={this.state.emailAddress}
                                onChange={event => {
                                    this.setState({ emailAddress: event.target.value });
                                }} />
                        </FormGroup>

                        <FormGroup>
                            <ControlLabel>Nowe hasło: {this.props.isAdminView ? (<Button bsStyle="link" style={{ padding: 0, border: 0 }} onClick={this.handleRandomPassword.bind(this)}>losowe</Button>) : ""}</ControlLabel>
                            <FormControl placeholder="Wprowadź, jeżeli chcesz zmienić" type={this.state.randomPassword ? "text" : "password"}
                                name="password" autoComplete="new-password" value={this.state.password}
                                onChange={event => {
                                    this.setState({ password: event.target.value });
                                }} />
                        </FormGroup>
                        {this.props.isAdminView ? adminOptionsRight : ""}
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <Button type="submit" disabled={this.state.isSaving} bsStyle="default" onClick={!this.state.isSaving ? this.handleSubmit.bind(this) : null}>
                            {this.state.isSaving ? 'Zapisywanie…' : 'Zapisz dane'}
                        </Button>
                    </Col>
                </Row>
            </form>
        );

        return (
            <div>
                {this.state.isLoading ? userLoading : userLoaded}
            </div>
        )

    }

}

export default ExpertProfileComponent;
